@charset "UTF-8";
@import "./assets/components/_toastr.css";
.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1,
.mat-typography .h1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2,
.mat-typography .h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3,
.mat-typography .h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4,
.mat-typography .h4 {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5,
.mat-typography .h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6,
.mat-typography .h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font-size: 112px;
  font-weight: 300;
  line-height: 112px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font-size: 56px;
  font-weight: 400;
  line-height: 56px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-bottom-sheet-container {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar .h1,
.mat-toolbar h2,
.mat-toolbar .h2,
.mat-toolbar h3,
.mat-toolbar .h3,
.mat-toolbar h4,
.mat-toolbar .h4,
.mat-toolbar h5,
.mat-toolbar .h5,
.mat-toolbar h6,
.mat-toolbar .h6 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-datepicker-content .mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 14px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-dialog-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #3f51b5;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #ff4081;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #f44336;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #3f51b5;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ff4081;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #3f51b5;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #ff4081;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-content .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #3f51b5;
}
.mat-datepicker-content .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #ff4081;
}
.mat-datepicker-content .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.mat-datepicker-content .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #3f51b5;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #ff4081;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.mat-datepicker-content .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #3f51b5;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #ff4081;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.mat-datepicker-content .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #3f51b5;
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #ff4081;
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.mat-datepicker-content .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-datepicker-content .mat-mdc-button, .mat-datepicker-content .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-datepicker-content .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-datepicker-content .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-button.mat-primary, .mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #3f51b5;
  --mat-mdc-button-ripple-color: rgba(63, 81, 181, 0.1);
}
.mat-datepicker-content .mat-mdc-button.mat-accent, .mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #ff4081;
  --mat-mdc-button-ripple-color: rgba(255, 64, 129, 0.1);
}
.mat-datepicker-content .mat-mdc-button.mat-warn, .mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button, .mat-datepicker-content .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-datepicker-content .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-primary, .mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button.mat-accent, .mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button.mat-warn, .mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-datepicker-content .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #3f51b5;
  --mat-mdc-button-ripple-color: rgba(63, 81, 181, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #ff4081;
  --mat-mdc-button-ripple-color: rgba(255, 64, 129, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #3f51b5;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #ff4081;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
}
.mat-datepicker-content .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-datepicker-content .mat-mdc-button.mat-mdc-button-base,
.mat-datepicker-content .mat-mdc-raised-button.mat-mdc-button-base,
.mat-datepicker-content .mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-datepicker-content .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base {
  width: 48px;
  height: 48px;
  padding: 12px;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 48px;
  max-width: 48px;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #3f51b5;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff4081;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #3f51b5;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #3f51b5;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ff4081;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ff4081;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ff4081;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ff4081;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-progress-bar-background {
  fill: #cbd0e9;
}

.mat-progress-bar-buffer {
  background-color: #cbd0e9;
}

.mat-progress-bar-fill::after {
  background-color: #3f51b5;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #fbccdc;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #fbccdc;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ff4081;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #3f51b5;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #ff4081;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336;
}

.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #3f51b5;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #ff4081;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field-disabled .mat-form-field-prefix,
.mat-form-field-disabled .mat-form-field-suffix {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #3f51b5;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ff4081;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}

.mat-focused .mat-form-field-required-marker {
  color: #ff4081;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #3f51b5;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ff4081;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #3f51b5;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ff4081;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}

.mat-error {
  color: #f44336;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #3f51b5;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ff4081;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #3f51b5;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ff4081;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #3f51b5;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ff4081;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #3f51b5;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ff4081;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #3f51b5;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ff4081;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff4081;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 64, 129, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ff4081;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #3f51b5;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(63, 81, 181, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #3f51b5;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #3f51b5;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #3f51b5;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff4081;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ff4081;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #3f51b5;
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(63, 81, 181, 0.2);
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ff4081;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 64, 129, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused {
  background-color: rgba(197, 202, 233, 0.3);
}
.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #3f51b5;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 128, 171, 0.3);
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ff4081;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused {
  background-color: rgba(197, 202, 233, 0.3);
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #3f51b5;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 128, 171, 0.3);
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ff4081;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #ff4081;
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #3f51b5;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #ff4081;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(63, 81, 181, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #3f51b5;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(63, 81, 181, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(63, 81, 181, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(63, 81, 181, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 64, 129, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ff4081;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 64, 129, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 64, 129, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 64, 129, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #3f51b5;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #ff4081;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 40px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 40px;
  transform: translate(-50%, -50%);
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-icon.mat-primary {
  color: #3f51b5;
}
.mat-icon.mat-accent {
  color: #ff4081;
}
.mat-icon.mat-warn {
  color: #f44336;
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #3f51b5;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ff4081;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #3f51b5;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #ff4081;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
} /* You can add global styles to this file, and also import other style files */
/*!
 * Bootstrap  v5.3.2 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #a6b5cc;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #b5b6b7;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #a7b9b1;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #a6c3ca;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #ccc2a4;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #c6acae;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #c6c7c8;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #4d5154;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23052c65'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(10, 88, 202, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(20, 108, 67, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
@font-face {
  font-family: "Circular Std";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/CircularStd-Book.eot");
  src: url("../fonts/CircularStd-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/CircularStd-Book.woff2") format("woff2"), url("../fonts/CircularStd-Book.woff") format("woff"), url("../fonts/CircularStd-Book.svg#Circular Std") format("svg");
}
@font-face {
  font-family: "Circular Std";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/CircularStd-Medium.eot");
  src: url("../fonts/CircularStd-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/CircularStd-Medium.woff2") format("woff2"), url("../fonts/CircularStd-Medium.woff") format("woff"), url("../fonts/CircularStd-Medium.svg#Circular Std") format("svg");
}
@font-face {
  font-family: "Circular Std";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/CircularStd-Bold.eot");
  src: url("../fonts/CircularStd-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/CircularStd-Bold.woff2") format("woff2"), url("../fonts/CircularStd-Bold.woff") format("woff"), url("../fonts/CircularStd-Bold.svg#Circular Std") format("svg");
}
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"), url(../fonts/MaterialIcons-Regular.woff2) format("woff2"), url(../fonts/MaterialIcons-Regular.woff) format("woff"), url(../fonts/MaterialIcons-Regular.ttf) format("truetype");
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: inherit;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  vertical-align: middle;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

html {
  height: 100%;
}

body {
  background-color: #ffffff;
  color: #353535;
  font-family: "Encode Sans", sans-serif;
  font-size: 16px;
  height: 100%;
  overflow-x: hidden;
}

.bg-one {
  background-color: #fdfdfd;
}

.bg-one .container,
.bg-two .container,
.bg-three .container,
.bg-four .container,
.bg-five .container {
  max-width: 1200px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: "Encode Sans", sans-serif;
}

.h1, h1 {
  font-size: 32px;
  font-weight: bold;
}

.h2, h2 {
  font-size: 28px;
  font-weight: bold;
}

.h3, h3 {
  font-size: 24px;
  font-weight: 600;
}

.h4, h4 {
  font-size: 20px;
  font-weight: 600;
}

.h5, h5 {
  font-size: 18px;
  font-weight: 600;
}

.h6, h6 {
  font-size: 16px;
  font-weight: 500;
}

label {
  color: #2E2E2E;
  display: inline-block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: 15px;
}
label.file-upload {
  position: relative;
  overflow: hidden;
  color: #86E53E;
  font-size: 15px !important;
  border: 1px solid #ffbe9d;
  border-radius: 5px;
  padding: 12px 6px;
}
label.file-upload.image-btn {
  background: #fff;
  border: 1px solid #86E53E;
  border-radius: 2px;
  color: #86E53E;
  padding: 12px 15px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
}
label.file-upload input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
label.file-upload.image-upbtn {
  background: #13323C;
  border: 1px solid #13323C;
  border-radius: 5px;
  color: #fff;
  padding: 12px 15px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
label.award-upload.image-upbtn {
  border: 1px solid #F15C3D;
  border-radius: 5px;
  width: 76px;
  height: 46px;
  cursor: pointer;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #F15C3D;
}
label.award-upload input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

a {
  color: #2E2E2E;
  text-decoration: none;
}
a:hover {
  color: #86E53E;
}

a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

input,
button,
a {
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

button:focus {
  outline: 0;
}
button.scroll-top:focus {
  outline: none;
}

input[type=text],
input[type=password] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.dropdown-menu {
  color: #161c2d;
  font-size: 14px;
  border: 1px solid #eff2f7;
  background-color: #fff;
  box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.08);
}

.btn.focus, .btn:focus {
  box-shadow: inherit;
  box-shadow: unset;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #86E53E;
  border-color: #86E53E;
}

.btn-primary {
  color: #fff;
  background-color: #86E53E;
  border-color: #86E53E;
  background-color: #86E53E;
  border: 1px solid #86E53E;
}

.form-control {
  border-color: #E7E8EA;
  color: #353535;
  font-size: 15px;
  min-height: 46px;
  padding: 6px 15px;
}
.form-control:focus {
  border-color: #C7C8CA;
  box-shadow: none;
  outline: 0 none;
}

.nav-link {
  font-weight: bold;
  font-size: 14px;
  color: #6A1B9A;
  color: #444444;
}

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col, .form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.dashboard-page .content {
  padding: 144px 0 30px;
}
.dashboard-page .content > .container-fluid, .dashboard-page .content > .container-sm, .dashboard-page .content > .container-md, .dashboard-page .content > .container-lg, .dashboard-page .content > .container-xl, .dashboard-page .content > .container-xxl {
  padding-left: 30px;
  padding-right: 30px;
}
.dashboard-page .content-page {
  padding: 60px 0 30px;
}
.dashboard-page .header {
  border-bottom: 1px solid #e3e3e3;
}
.dashboard-page .footer .container {
  max-width: 100%;
  padding-left: 30px;
  padding-right: 30px;
}

.table {
  color: #161c2d;
  max-width: 100%;
  margin-bottom: 0;
  width: 100%;
}
.table > :not(:first-child) {
  border-top: 1px solid rgba(0, 0, 0, 0.13);
}
.table > :not(caption) > * > * {
  box-shadow: unset;
}
.table.dataTable {
  border-collapse: collapse !important;
}
.table thead {
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}
.table thead tr th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.table tbody tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.table tbody tr:last-child {
  border-color: transparent;
}
.table .thead-pink th {
  color: #86E53E;
  background-color: #FFF6F4;
  border-color: #FFF6F4;
  font-size: 14px;
}

.table-striped > tbody > tr:nth-of-type(2n+1) {
  background-color: #f8f9fa;
}
.table-striped thead tr {
  border-color: transparent;
}
.table-striped tbody tr {
  border-color: transparent;
}
.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(255, 255, 255, 0.3);
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(235, 235, 235, 0.4);
}

.table.no-border > tbody > tr > td,
.table > tbody > tr > th,
.table.no-border > tfoot > tr > td,
.table.no-border > tfoot > tr > th,
.table.no-border > thead > tr > td,
.table.no-border > thead > tr > th {
  border-top: 0;
  padding: 10px 8px;
}

.table-nowrap td,
.table-nowrap th {
  white-space: nowrap;
}

table.table td h2, table.table td .h2 {
  display: inline-block;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
table.table td h2.table-avatar, table.table td .table-avatar.h2 {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  white-space: nowrap;
}
table.table td h2.table-avatar .profile-name, table.table td .table-avatar.h2 .profile-name {
  font-weight: 600;
  font-size: 15px;
  color: #000000;
}
table.table td h2 a, table.table td .h2 a {
  color: #161c2d;
}
table.table td h2 a:hover, table.table td .h2 a:hover {
  color: #ff508b;
}
table.table td h2 span, table.table td .h2 span {
  color: #6e727d;
  display: block;
  font-size: 12px;
  margin-top: 3px;
}
table.dataTable {
  margin: 0 !important;
}

.table td, .table th {
  padding: 1rem 0.75rem;
}

.table.table-center td, .table-center td, .table-center th, .table.table-center th {
  vertical-align: middle;
  font-size: 15px;
}

.table-hover tbody tr:hover {
  background-color: #f7f7f7;
}
.table-hover tbody tr:hover td {
  color: #474648;
}

.table tr td:first-child, .table tr th:first-child {
  padding-left: 20px;
}

.table tr td:last-child, .table tr th:last-child {
  padding-right: 20px;
}

.table-bordered {
  border: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.table-bordered th,
.table-bordered td {
  border-color: rgba(0, 0, 0, 0.05);
}

.card-table .card-body {
  padding: 0;
}
.card-table .card-body .table > thead > tr > th {
  border-top: 0;
  font-size: 14px;
}

.card-table .table tr td:first-child,
.card-table .table tr th:first-child {
  padding-left: 1.5rem;
}

.card-table .table tr td:last-child,
.card-table .table tr th:last-child {
  padding-right: 1.5rem;
}

.card-table .table td, .card-table .table th {
  border-top: 1px solid #e2e5e8;
  padding: 1rem 0.75rem;
  white-space: nowrap;
}

.btn-block {
  width: 100%;
}

.btn-link {
  text-decoration: none;
}
.btn-link:hover {
  text-decoration: underline;
}

.btn-white {
  background-color: #fff;
  border-color: #ebebeb;
}

.btn.btn-rounded {
  border-radius: 50px;
}
.btn.btn-danger.trash {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 46px;
  width: 46px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.bg-primary,
.badge-primary {
  background-color: #86E53E !important;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #86E53E !important;
}

.bg-success,
.badge-success {
  background-color: #00e65b !important;
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #00cc52 !important;
}

.bg-info,
.badge-info {
  background-color: #86E53E !important;
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #028ee1 !important;
}

.bg-warning,
.badge-warning {
  background-color: #ffbc34 !important;
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #e9ab2e !important;
}

.bg-danger,
.badge-danger {
  background-color: #ff0100 !important;
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #e63333 !important;
}

.bg-white {
  background-color: #fff;
}

.bg-purple,
.badge-purple {
  background-color: #9368e9 !important;
}

.text-primary,
.dropdown-menu > li > a.text-primary {
  color: #86E53E !important;
}

.text-success,
.dropdown-menu > li > a.text-success {
  color: #00cc52 !important;
}

.text-danger,
.dropdown-menu > li > a.text-danger {
  color: #ff0100 !important;
}

.text-info,
.dropdown-menu > li > a.text-info {
  color: #ff3115 !important;
}

.text-warning,
.dropdown-menu > li > a.text-warning {
  color: #ffbc34 !important;
}

.text-purple,
.dropdown-menu > li > a.text-purple {
  color: #7460ee !important;
}

.text-muted {
  color: #757575 !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
  background-color: #86E53E;
  border: 1px solid #86E53E;
  box-shadow: unset;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
  background-color: #86E53E;
  border: 1px solid #86E53E;
}

.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle {
  background-color: #86E53E;
  border-color: #86E53E;
  color: #fff;
}

.btn-primary.active:focus:not(:disabled):not(.disabled),
.btn-primary:active:focus:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: #c7c7c7;
  border-color: #c7c7c7;
  color: #000;
}

.btn-secondary.active:focus:not(:disabled):not(.disabled),
.btn-secondary:active:focus:not(:disabled):not(.disabled),
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-success {
  background-color: #00e65b;
  border: 1px solid #00e65b;
  color: #fff;
  background-color: #FF3115;
  border-color: #FF3115;
}
.btn-success:hover {
  background-color: #86E53E;
  border-color: #86E53E;
}

.btn-success:hover,
.btn-success:focus,
.btn-success.active,
.btn-success:active,
.open > .dropdown-toggle.btn-success {
  background-color: #00cc52;
  border: 1px solid #00cc52;
  color: #fff;
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover {
  background-color: #00cc52;
  border: 1px solid #00cc52;
}

.btn-success.active:not(:disabled):not(.disabled),
.btn-success:active:not(:disabled):not(.disabled),
.show > .btn-success.dropdown-toggle {
  background-color: #00cc52;
  border-color: #00cc52;
  color: #fff;
}

.btn-success.active:focus:not(:disabled):not(.disabled),
.btn-success:active:focus:not(:disabled):not(.disabled),
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-success.disabled, .btn-success:disabled {
  background-color: #00e65b;
  border-color: #00e65b;
  color: #fff;
}

.btn-info {
  background-color: #009efb;
  border: 1px solid #009efb;
}

.btn-info:hover,
.btn-info:focus,
.btn-info.active,
.btn-info:active,
.open > .dropdown-toggle.btn-info {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}

.btn-info.active:not(:disabled):not(.disabled),
.btn-info:active:not(:disabled):not(.disabled),
.show > .btn-info.dropdown-toggle {
  background-color: #028ee1;
  border-color: #028ee1;
  color: #fff;
}

.btn-info.active:focus:not(:disabled):not(.disabled),
.btn-info:active:focus:not(:disabled):not(.disabled),
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-info.disabled, .btn-info:disabled {
  background-color: #009efb;
  border-color: #009efb;
  color: #fff;
}

.btn-warning {
  background-color: #ffbc34;
  border: 1px solid #ffbc34;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning.active,
.btn-warning:active,
.open > .dropdown-toggle.btn-warning {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e;
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e;
}

.btn-warning.active:not(:disabled):not(.disabled),
.btn-warning:active:not(:disabled):not(.disabled),
.show > .btn-warning.dropdown-toggle {
  background-color: #e9ab2e;
  border-color: #e9ab2e;
  color: #fff;
}

.btn-warning.active:focus:not(:disabled):not(.disabled),
.btn-warning:active:focus:not(:disabled):not(.disabled),
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-warning.disabled, .btn-warning:disabled {
  background-color: #ffbc34;
  border-color: #ffbc34;
  color: #fff;
}

.btn-danger {
  background-color: #ff0100;
  border: 1px solid #ff0100;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active,
.btn-danger:active,
.open > .dropdown-toggle.btn-danger {
  background-color: #e63333;
  border: 1px solid #e63333;
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover {
  background-color: #e63333;
  border: 1px solid #e63333;
}

.btn-danger.active:not(:disabled):not(.disabled),
.btn-danger:active:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle {
  background-color: #e63333;
  border-color: #e63333;
  color: #fff;
}

.btn-danger.active:focus:not(:disabled):not(.disabled),
.btn-danger:active:focus:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-danger.disabled, .btn-danger:disabled {
  background-color: #f62d51;
  border-color: #f62d51;
  color: #fff;
}

.btn-light.active:focus:not(:disabled):not(.disabled),
.btn-light:active:focus:not(:disabled):not(.disabled),
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-dark.active:focus:not(:disabled):not(.disabled),
.btn-dark:active:focus:not(:disabled):not(.disabled),
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-outline-primary {
  color: #86E53E;
  border-color: #86E53E;
  color: #86E53E;
  border-color: #86E53E;
}
.btn-outline-primary:hover {
  background-color: #86E53E;
  border-color: #86E53E;
  background-color: #86E53E;
  border-color: #86E53E;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: none;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #86E53E;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: #86E53E;
  border-color: #86E53E;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-success {
  color: #00e65b;
  border-color: #00e65b;
}
.btn-outline-success:hover {
  background-color: #00e65b;
  border-color: #00e65b;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: none;
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #00e65b;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  background-color: #00e65b;
  border-color: #00e65b;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-info {
  color: #009efb;
  border-color: #009efb;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #009efb;
  border-color: #009efb;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: none;
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  background-color: transparent;
  color: #009efb;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  background-color: #009efb;
  border-color: #009efb;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-warning {
  color: #ffbc34;
  border-color: #ffbc34;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffbc34;
  border-color: #ffbc34;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: none;
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  background-color: transparent;
  color: #ffbc34;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffbc34;
  border-color: #ffbc34;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-danger {
  color: #ff0100;
  border-color: #ff0100;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #ff0100;
  border-color: #ff0100;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: none;
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  background-color: transparent;
  color: #ff0100;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  background-color: #ff0100;
  border-color: #ff0100;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-light {
  color: #ababab;
  border-color: #e6e6e6;
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #ababab;
}

.add-btn {
  max-width: 140px;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: #ff3115;
  border-color: #ff3115;
}

.pagination > li > a,
.pagination > li > span {
  color: #ff3115;
}

.page-link:hover {
  color: #ff3115;
}
.page-link:focus {
  box-shadow: unset;
}

.page-item.active .page-link {
  background-color: #ff3115;
  border-color: #ff3115;
}

.card {
  border: 1px solid #e8e8e8;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.25);
  margin-bottom: 30px;
}
.card .card-header {
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
}
.card .card-header .card-title {
  margin-bottom: 0;
  font-weight: bold;
  font-size: 18px;
  color: #161c2d;
}

.card-body {
  padding: 1.5rem;
}

.card-header {
  border-bottom: 1px solid #f0f0f0;
  padding: 1rem 1.5rem;
}

.card-footer {
  background-color: #fff;
  border-top: 1px solid #f0f0f0;
  padding: 1rem 1.5rem;
}

.btn-light {
  border-color: #e6e6e6;
  color: #a6a6a6;
}
.btn-light:hover {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #ff508b;
  text-shadow: unset;
}

.bootstrap-datetimepicker-widget table td.today::before {
  border-bottom-color: #ff508b;
}

.bg-info-light {
  background-color: rgba(2, 182, 179, 0.12) !important;
  color: #1db9aa !important;
}

.bg-primary-light {
  background-color: rgba(17, 148, 247, 0.12) !important;
  color: #2196f3 !important;
}

.bg-danger-light {
  background-color: rgba(242, 17, 54, 0.12) !important;
  color: #e63c3c !important;
}

.bg-warning-light {
  background-color: rgba(255, 152, 0, 0.12) !important;
  color: #f39c12 !important;
}

.bg-success-light {
  background-color: rgba(15, 183, 107, 0.12) !important;
  color: #26af48 !important;
}

.bg-purple-light {
  background-color: rgba(197, 128, 255, 0.12) !important;
  color: #c580ff !important;
}

.bg-default-light {
  background-color: rgba(40, 52, 71, 0.12) !important;
  color: #283447 !important;
}

.text-xs {
  font-size: 0.75rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
}

.text-lg {
  font-size: 1.25rem !important;
}

.text-xl {
  font-size: 1.5rem !important;
}

.form-group {
  margin-bottom: 1.25rem;
}
.form-group.working-position {
  position: relative;
  margin-bottom: 0;
}

.form-check {
  margin-bottom: 1.25rem;
}

.slider-btn {
  background: #09e5ab;
  color: #fff;
  border: 1px solid #09e5ab;
  padding: 8px 16px !important;
  transition: 0.5s;
}
.slider-btn:hover {
  background: #fff;
  color: #09e5ab;
  border: 1px solid #fff;
  padding: 8px 16px !important;
}

.avatar {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 3rem;
}
.avatar > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.avatar .border {
  border-width: 3px !important;
}
.avatar .rounded {
  border-radius: 6px !important;
}
.avatar .avatar-title {
  font-size: 18px;
}

.avatar-title {
  width: 100%;
  height: 100%;
  background-color: #ff508b;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.avatar-away::before,
.avatar-offline::before,
.avatar-online::before {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 25%;
  height: 25%;
  border-radius: 50%;
  content: "";
  border: 2px solid #fff;
}

.avatar-online::before {
  background-color: #00e65b;
}

.avatar-offline::before {
  background-color: #ff0100;
}

.avatar-away::before {
  background-color: #ffbc34;
}

.avatar-xs {
  width: 1.65rem;
  height: 1.65rem;
}
.avatar-xs .border {
  border-width: 2px !important;
}
.avatar-xs .rounded {
  border-radius: 4px !important;
}
.avatar-xs .avatar-title {
  font-size: 12px;
}

.avatar-xs.avatar-away::before,
.avatar-xs.avatar-offline::before,
.avatar-xs.avatar-online::before {
  border-width: 1px;
}

.avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
}
.avatar-sm .border {
  border-width: 3px !important;
}
.avatar-sm .rounded {
  border-radius: 4px !important;
}
.avatar-sm .avatar-title {
  font-size: 15px;
}

.avatar-sm.avatar-away::before,
.avatar-sm.avatar-offline::before,
.avatar-sm.avatar-online::before {
  border-width: 2px;
}

.avatar-md {
  width: 3.125rem;
  height: 3.125rem;
}

.avatar-lg {
  width: 3.75rem;
  height: 3.75rem;
}
.avatar-lg .border {
  border-width: 3px !important;
}
.avatar-lg .rounded {
  border-radius: 8px !important;
}
.avatar-lg .avatar-title {
  font-size: 24px;
}

.avatar-lg.avatar-away::before,
.avatar-lg.avatar-offline::before,
.avatar-lg.avatar-online::before {
  border-width: 3px;
}

.avatar-xl {
  width: 5rem;
  height: 5rem;
}
.avatar-xl .border {
  border-width: 4px !important;
}
.avatar-xl .rounded {
  border-radius: 8px !important;
}
.avatar-xl .avatar-title {
  font-size: 28px;
}

.avatar-xl.avatar-away::before,
.avatar-xl.avatar-offline::before,
.avatar-xl.avatar-online::before {
  border-width: 4px;
}

.avatar-xxl {
  width: 5.125rem;
  height: 5.125rem;
}
.avatar-xxl .border {
  border-width: 6px !important;
}
.avatar-xxl .rounded {
  border-radius: 8px !important;
}
.avatar-xxl .avatar-title {
  font-size: 30px;
}

.avatar-xxl.avatar-away::before,
.avatar-xxl.avatar-offline::before,
.avatar-xxl.avatar-online::before {
  border-width: 4px;
}

.avatar-group {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.avatar-group .avatar + .avatar {
  margin-left: -0.75rem;
}
.avatar-group .avatar:hover {
  z-index: 1;
}
.avatar-group .avatar-xs + .avatar-xs {
  margin-left: -0.40625rem;
}
.avatar-group .avatar-sm + .avatar-sm {
  margin-left: -0.625rem;
}
.avatar-group .avatar-lg + .avatar-lg {
  margin-left: -1rem;
}
.avatar-group .avatar-xl + .avatar-xl {
  margin-left: -1.28125rem;
}

.nav-tabs {
  border-bottom: 1px solid #f0f0f0;
}
.nav-tabs > li > a {
  margin-right: 0;
  color: #888;
  border-radius: 0;
}
.nav-tabs.nav-tabs-solid {
  background-color: #fbfcff;
  border: 0;
}
.nav-tabs.nav-tabs-solid > li {
  margin-bottom: 0;
  text-align: center;
}
.nav-tabs.nav-tabs-solid > li > a {
  color: #161c2d;
  color: #161c2d;
  border-color: transparent;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
  border-radius: 50px;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a {
  border-radius: 50px;
}
.nav-tabs.nav-tabs-solid > .open:not(.active) > a {
  background-color: #f5f5f5;
  border-color: transparent;
}
.nav-tabs .nav-link {
  border-radius: 0;
}
.nav-tabs.nav-justified > li > a {
  border-radius: 0;
  margin-bottom: 0;
}
.nav-tabs.nav-justified.nav-tabs-solid > li > a {
  border-color: transparent;
}
.nav-tabs.nav-justified.nav-tabs-top {
  border-bottom: 1px solid #ddd;
}
.nav-tabs.nav-tabs-top > li {
  margin-bottom: 0;
}
.nav-tabs.nav-tabs-top > li + li > a {
  margin-left: 1px;
}

.card-header-tabs {
  border-bottom: 0;
}

.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  border-color: transparent;
  color: #161c2d;
}

.nav-tabs.nav-tabs-solid > .active > a,
.nav-tabs.nav-tabs-solid > .active > a:hover,
.nav-tabs.nav-tabs-solid > .active > a:focus {
  background-color: #ff508b;
  border-color: #ff508b;
  color: #fff;
}

.tab-content {
  padding-top: 20px;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  background-color: #eee;
  border-color: transparent;
  color: #161c2d;
}

.nav-tabs.nav-justified > li > a:hover,
.nav-tabs.nav-justified > li > a:focus {
  border-bottom-color: #ddd;
}

.nav-tabs.nav-tabs-solid > li > a.active,
.nav-tabs.nav-tabs-solid > li > a.active:hover,
.nav-tabs.nav-tabs-solid > li > a.active:focus {
  background-color: #fff;
  border-color: #fff;
  color: #86E53E;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:hover,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:focus {
  border-radius: 50px;
}

.nav-tabs-justified > li > a {
  border-radius: 0;
  margin-bottom: 0;
}
.nav-tabs-justified.nav-tabs-solid > li > a {
  border-color: transparent;
}
.nav-tabs-justified.nav-tabs-top {
  border-bottom: 1px solid #ddd;
}

.nav-tabs-justified > li > a:hover,
.nav-tabs-justified > li > a:focus {
  border-bottom-color: #ddd;
}

.nav-tabs.nav-justified.nav-tabs-top > li > a,
.nav-tabs.nav-justified.nav-tabs-top > li > a:hover,
.nav-tabs.nav-justified.nav-tabs-top > li > a:focus {
  border-width: 2px 0 0 0;
}

.nav-tabs.nav-tabs-top > li > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
  border-width: 2px 0 0 0;
}

.nav-tabs.nav-tabs-top > li.open > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
  border-top-color: #ddd;
}

.nav-tabs.nav-tabs-top > li > a.active,
.nav-tabs.nav-tabs-top > li > a.active:hover,
.nav-tabs.nav-tabs-top > li > a.active:focus {
  border-top-color: #ff508b;
}

.nav-tabs.nav-tabs-bottom > li > a.active,
.nav-tabs.nav-tabs-bottom > li > a.active:hover,
.nav-tabs.nav-tabs-bottom > li > a.active:focus {
  border-bottom-width: 2px;
  border-color: transparent;
  border-bottom-color: #86E53E;
  background-color: transparent;
  transition: none 0s ease 0s;
  -moz-transition: none 0s ease 0s;
  -o-transition: none 0s ease 0s;
  -ms-transition: none 0s ease 0s;
  -webkit-transition: none 0s ease 0s;
}

.nav-tabs.nav-tabs-solid > li > a:hover,
.nav-tabs.nav-tabs-solid > li > a:focus {
  background-color: #f5f5f5;
}

.nav-tabs-justified.nav-tabs-top > li > a,
.nav-tabs-justified.nav-tabs-top > li > a:hover,
.nav-tabs-justified.nav-tabs-top > li > a:focus {
  border-width: 2px 0 0 0;
}

.provider-tabs .nav-tabs.nav-tabs-solid {
  background: #fff;
  box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
  border-radius: 5px;
  border: 0;
  padding: 10px;
}
.provider-tabs .nav-tabs.nav-tabs-solid > li > a p {
  margin: 5px 0 0;
  font-weight: 500;
  font-size: 15px;
}
.provider-tabs .nav-tabs.nav-justified.nav-tabs-top {
  border-bottom: 1px solid #ddd;
}
.provider-tabs .nav-tabs-justified > li > a {
  border-radius: 0;
  margin-bottom: 0;
}
.provider-tabs .nav-tabs-justified.nav-tabs-solid > li > a {
  border-color: transparent;
}
.provider-tabs.abouts-view .nav-tabs.nav-tabs-solid li .active-about p {
  color: #F15C3D;
  font-weight: 500;
}
.provider-tabs.abouts-view .nav-tabs.nav-tabs-solid > li > a:hover {
  background-color: rgba(255, 91, 55, 0.15);
  border-radius: 5px;
}
.provider-tabs.abouts-view .nav-tabs.nav-tabs-solid > li > a p {
  color: #13323C;
}

.provider-tabs .nav-tabs.nav-tabs-solid > li > a:hover, .provider-tabs .nav-tabs.nav-tabs-solid > li > a:focus, .provider-tabs .nav-tabs.nav-tabs-solid > li > a.active {
  background-color: #f9f9f9;
  border-color: #f9f9f9;
  color: inherit;
}

.provider-tabs .nav-tabs-justified > li > a:hover,
.provider-tabs .nav-tabs-justified > li > a:focus {
  border-bottom-color: #ddd;
}

.provider-tabs .nav-tabs.nav-justified.nav-tabs-top > li > a,
.provider-tabs .nav-tabs.nav-justified.nav-tabs-top > li > a:hover,
.provider-tabs .nav-tabs.nav-justified.nav-tabs-top > li > a:focus {
  border-width: 2px 0 0 0;
}

.bg-red {
  color: #FF8B7B;
}

.bg-blue {
  color: #129BE8;
}

.bg-violet {
  color: #8553EE;
}

.bg-yellow {
  color: #FEBE42;
}

.bg-pink {
  color: #F5365C;
}
.bg-pink .input-group-text {
  background: #ffe6e1;
  border-color: #ffe6e1;
  color: #86E53E;
}

.bg-green {
  color: #159C5B;
}

.bg-navy {
  color: #11099F;
}

.user-tabs ul li + li {
  margin-left: 26px;
}
.user-tabs .nav-pills .nav-link {
  background: #fff;
  border-radius: 3px;
  color: #86E53E;
  border: 1px solid #86E53E;
  padding: 0.75rem 1rem;
}
.user-tabs .nav-tabs > li > a {
  border: 0;
  border-bottom: 3px solid transparent;
  color: #161c2d;
  font-weight: 600;
  padding: 20px;
}
.user-tabs .nav-tabs > li > a:hover {
  background-color: unset;
  color: #ff3115;
}
.user-tabs .med-records {
  display: inline-block;
  min-width: 130px;
}
.user-tabs.project-tabs {
  margin-bottom: 30px;
}

.user-tabs .nav-pills .nav-link.active, .user-tabs .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #86E53E;
  border: 1px solid #86E53E;
}

.form-focus {
  height: 58px;
  position: relative;
}
.form-focus .focus-label {
  font-size: 14px;
  font-weight: 400;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate3d(0, 22px, 0) scale(1);
  -ms-transform: translate3d(0, 22px, 0) scale(1);
  -o-transform: translate3d(0, 22px, 0) scale(1);
  transform: translate3d(0, 22px, 0) scale(1);
  transform-origin: left top;
  transition: 240ms;
  left: 12px;
  top: -3px;
  z-index: 1;
  color: #8C635B;
  margin-bottom: 0;
}
.form-focus.focused .focus-label {
  opacity: 1;
  top: -18px;
  font-size: 12px;
  z-index: 1;
}
.form-focus.focused .form-control::-webkit-input-placeholder {
  color: #bbb;
}
.form-focus .form-control {
  height: 58px;
  padding: 21px 12px 6px;
  border: 1px solid #ffe6e1;
}
.form-focus .form-control::-webkit-input-placeholder {
  color: transparent;
  transition: 240ms;
}
.form-focus .form-control:focus::-webkit-input-placeholder {
  transition: none;
}
.form-focus.select-focus .focus-label {
  opacity: 1;
  font-weight: 300;
  top: -20px;
  font-size: 12px;
  z-index: 1;
}
.form-focus .select2-container .select2-selection--single {
  border: 1px solid #e3e3e3;
  height: 50px;
}
.form-focus .select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 30px;
  padding-left: 12px;
  padding-top: 10px;
}
.form-focus .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 48px;
  right: 7px;
}
.form-focus .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #ccc transparent transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  height: 0;
  left: 50%;
  margin-left: -10px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.form-focus .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #676767;
  font-size: 14px;
  font-weight: normal;
  line-height: 38px;
}
.form-focus .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #ccc;
  border-width: 0 6px 6px;
}
.form-focus .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #fc6075;
}

.form-focus .form-control:focus ~ .focus-label,
.form-focus .form-control:-webkit-autofill ~ .focus-label {
  opacity: 1;
  font-weight: 400;
  top: -18px;
  font-size: 12px;
  z-index: 1;
}

.modal {
  -webkit-overflow-scrolling: touch;
}
.modal .card {
  box-shadow: unset;
}
.modal label {
  font-weight: 500;
  color: #161c2d;
}

.modal-footer.text-center {
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.modal-footer.text-left {
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
}

.modal-dialog {
  overflow: hidden;
}
.modal-dialog.modal-md {
  max-width: 600px;
}

.modal-header {
  padding: 20px 30px;
  align-items: center;
}
.modal-header .close {
  width: 30px;
  height: 30px;
  line-height: 30px;
  padding: 0px;
  background: #fff;
  color: #86E53E;
  border: 1px solid #86E53E;
  border-radius: 50%;
  margin: -1rem -1rem -1rem auto;
}
.modal-header .close:hover {
  opacity: 0.75;
}

.modal-body {
  padding: 30px;
}

.custom-modal .modal-content {
  border: 0;
  border-radius: 10px;
}
.custom-modal .modal-header {
  padding: 20px 20px 0px;
  border-bottom: 0;
}
.custom-modal .modal-footer {
  padding: 1.25rem;
}
.custom-modal .modal-body {
  padding: 2rem;
}
.custom-modal .close {
  border: 2px solid #86E53E;
  border-radius: 50%;
  color: #86E53E;
  font-size: 17px;
  height: 20px;
  line-height: 16px;
  margin: 0;
  opacity: 1;
  padding: 0;
  position: absolute;
  right: 20px;
  top: 26px;
  width: 20px;
  z-index: 99;
}
.custom-modal .modal-title {
  font-size: 24px;
  margin-bottom: 5px;
}
.custom-modal .modal-subtitle {
  font-size: 16px;
}

.rate-bottom {
  border-bottom: 1px solid #F3F3F3;
}

.modal-backdrop.show {
  opacity: 0.4;
  -webkit-transition-duration: 400ms;
  transition-duration: 400ms;
}

.bg-modal {
  position: relative;
}
.bg-modal:before {
  content: "";
  position: absolute;
  margin: -32px;
  width: 100px;
  top: 0;
  left: 0;
  height: 100px;
  border-radius: 50%;
  background-color: #86E53E;
  overflow: hidden;
}

.comp-header {
  margin-bottom: 1.875rem;
}
.comp-header .comp-title {
  color: #161c2d;
}

.line {
  background-color: #ff3115;
  height: 2px;
  width: 60px;
}

.comp-buttons .btn {
  margin-bottom: 5px;
}

.pagination-box .pagination {
  margin-top: 0;
}

.comp-dropdowns .btn-group {
  margin-bottom: 5px;
}

.progress-example .progress {
  margin-bottom: 1.5rem;
}

.progress-xs {
  height: 4px;
}

.progress-sm {
  height: 15px;
}

.progress {
  margin-bottom: 25px;
  width: 100%;
  margin-bottom: 0;
  height: 12px;
  border-radius: 50px;
}
.progress.progress-sm {
  height: 6px;
}
.progress.progress-md {
  height: 8px;
}
.progress.progress-lg {
  height: 18px;
}

.header {
  width: 100%;
  position: fixed;
  z-index: 1040;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  background-color: transparent;
}
.header.min-header {
  width: 100%;
  position: fixed;
  z-index: 1040;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  background-color: transparent;
}
.header .has-arrow .dropdown-toggle:after {
  border-top: 0;
  border-left: 0;
  border-bottom: 2px solid #161c2d;
  border-right: 2px solid #161c2d;
  content: "";
  height: 8px;
  display: inline-block;
  pointer-events: none;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 8px;
  vertical-align: 2px;
  margin-left: 10px;
}
.header .has-arrow .dropdown-toggle[aria-expanded=true]:after {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.header.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 99999;
  display: block;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(8, 0, 42, 0.08);
  -moz-box-shadow: 0px 10px 20px 0px rgba(8, 0, 42, 0.08);
  box-shadow: 0px 10px 20px 0px rgba(8, 0, 42, 0.08);
}

.home-page .header {
  border-color: transparent;
}

.header-nav {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  border: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  position: relative;
  padding: 0 30px;
  margin-bottom: 0;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  box-shadow: 0px 0px 15px rgba(134, 229, 62, 0.7);
}

.main-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
.main-nav > li.active > a {
  color: #86E53E;
}
.main-nav > li > a {
  position: relative;
}
.main-nav > li > a:hover {
  color: #86E53E;
}
.main-nav > li > a > i.fa-search {
  font-size: 14px;
}
.main-nav > li .submenu li {
  position: relative;
}
.main-nav > li .submenu li a {
  display: block;
  padding: 10px 15px;
  clear: both;
  white-space: nowrap;
  font-size: 15px;
  color: #2B2B2B;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  width: 100%;
  border-top: 1px solid #f0f0f0;
}
.main-nav > li .submenu > li.has-submenu > a::after {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  right: 15px;
  top: 12px;
  font-size: 13px;
}
.main-nav li a {
  display: block;
  font-size: 15px;
  font-weight: 600;
}
.main-nav li.login-link {
  display: none;
}
.main-nav .log-select {
  background-color: #fff;
  padding: 9px 7px !important;
  color: #cc2d78;
  border-radius: 30px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 85px;
  cursor: pointer;
}
.main-nav .log-select.dropdown-toggle:after {
  color: #000;
}
.main-nav .log-select i {
  background-color: #cc2d78;
  padding: 5px 7px;
  border-radius: 50%;
  color: #fff;
}
.main-nav .log-menu .dropdown-item {
  color: #161c2d !important;
  font-weight: 400;
}

.main-menu-wrapper {
  margin: 0;
  padding: 0;
}

.logo {
  display: inline-block;
  margin-right: 0;
}

.togglesearch {
  position: absolute;
  top: 55px;
  left: 0;
  width: 285px;
  display: none;
  z-index: 999;
  background: #fff;
  padding: 10px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(183, 183, 183, 0.25);
}
.togglesearch input[type=text] {
  width: 182px;
  padding: 5px 10px;
  border: 1px solid #ff3115;
  outline: none;
  height: 39px;
  border-radius: 5px 0 0 5px;
}
.togglesearch button {
  background: #ff3115;
  color: #fff;
  border: 1px solid #ff3115;
  outline: none;
  cursor: pointer;
  border-radius: 0 5px 5px 0;
}

.header-contact-img {
  display: inline-block;
}
.header-contact-img i {
  color: rgba(0, 0, 0, 0.5);
  font-size: 30px;
}

.header-contact-detail {
  display: inline-block;
  padding-left: 10px;
}
.header-contact-detail p.contact-header {
  color: #484848;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 2px;
  text-align: left;
}
.header-contact-detail p.contact-info-header {
  color: #000;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
}

.header-navbar-rht {
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  margin-right: 2px;
}
.header-navbar-rht li {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  font-size: 14px;
  margin-right: 25px;
}
.header-navbar-rht li.searchbar {
  width: 30px;
  height: 30px;
  background: #86E53E;
  border-radius: 50%;
  border: 1px solid #86E53E;
  position: relative;
  cursor: pointer;
}
.header-navbar-rht li a {
  color: #2E2E2E;
  font-weight: 600;
  font-size: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header-navbar-rht li a img {
  padding-bottom: 1px;
}
.header-navbar-rht li a i {
  margin-right: 5px;
}
.header-navbar-rht li a.header-login {
  border: 2px solid #cc2d78;
  border-radius: 4px;
  padding: 10px 15px !important;
  text-align: center;
  font-size: 15px;
  color: #cc2d78;
  text-transform: uppercase;
  font-weight: 500;
}
.header-navbar-rht li a.header-login:hover {
  background-color: #cc2d78;
  border-color: #cc2d78;
  color: #fff;
}
.header-navbar-rht li.lang-item > a {
  padding-left: 0;
  padding-right: 0;
}
.header-navbar-rht li .dropdown-menu {
  padding: 0;
  margin: 0;
  border-radius: 5px;
}
.header-navbar-rht li .dropdown-menu:before {
  border: 7px solid #fff;
  border-color: transparent transparent #fff #fff;
  box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
  content: "";
  left: 45px;
  position: absolute;
  top: 2px;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.header-navbar-rht li.account-item .dropdown-menu {
  min-width: 280px;
  padding: 20px;
  box-shadow: 0px 2px 14px rgba(189, 189, 189, 0.25);
  border-radius: 0;
  border: 0;
}
.header-navbar-rht li.account-item .dropdown-menu:before {
  content: none;
}
.header-navbar-rht li.account-item .dropdown-menu.emp {
  padding: 0;
  border: 1px solid #f0f0f0;
  box-shadow: unset;
}
.header-navbar-rht li:last-child {
  margin-right: 0;
}
.header-navbar-rht .dropdown-item {
  color: #161c2d;
  padding: 6px 15px;
  font-size: 14px;
  border-bottom: 1px solid #f0f0f0;
}
.header-navbar-rht .dropdown-item:last-child {
  border-bottom: 0;
}
.header-navbar-rht .dropdown-item img {
  margin-right: 7px;
}
.header-navbar-rht .login-btn {
  background-color: #86E53E;
  border-color: #86E53E;
  border-radius: 50px;
  padding: 10px 39px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  border-radius: 5px;
}
.header-navbar-rht .account-item .emp .dropdown-item {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  border-top: 1px solid #f0f0f0;
}
.header-navbar-rht .account-item .emp .dropdown-item:hover {
  color: #86E53E;
}
.header-navbar-rht .account-item .emp .dropdown-item i {
  font-size: 14px;
  margin-right: 5px;
}
.header-navbar-rht .account-item .emp .dropdown-item.bal {
  padding-left: 25px;
}
.header-navbar-rht .account-item .emp .dropdown-item .amt {
  float: right;
  color: #86E53E;
}
.header-navbar-rht .account-item .dropdown-item {
  color: #161c2d;
  padding: 6px 0;
  font-size: 14px;
  font-weight: normal;
  border-bottom: 0;
}
.header-navbar-rht .signout-btn {
  background-color: #86E53E;
  border-radius: 130px;
  color: #fff;
  padding: 11px 30px;
  font-size: 14px;
  margin-right: 8px;
  font-weight: 700;
  color: #fff;
  display: inline-block;
  text-transform: uppercase;
}

.space-login {
  color: #2e2e2e;
  margin: 0px 5px;
}

.header-navbar-rht li a:hover, .header-navbar-rht li a:focus {
  color: #86E53E;
}

.header-navbar-rht .login-btn:hover, .header-navbar-rht .login-btn:focus {
  background: #13323C;
  border-color: #13323C;
  color: #fff;
}

.account-item .dropdown-menu h6, .account-item .dropdown-menu .h6 {
  font-size: 17px;
  color: #161c2d;
  font-weight: normal;
}
.account-item .custom_radio {
  color: #8C635B;
  font-weight: normal;
}
.account-item .arrow-circle {
  background: #13323C;
  color: #FFFBFB;
  width: 31px;
  height: 31px;
  border-radius: 50%;
  align-items: center;
  text-align: center;
  line-height: 31px;
}

.emp .drop-head {
  background: #f3f3f3;
  padding: 10px 20px;
  font-weight: bold;
  font-size: 14px;
  border-top: 1px solid #f0f0f0;
}

.account-item .dropdown-item:focus, .account-item .dropdown-item:hover {
  background-color: transparent;
}

.min-header .header-navbar-rht li a.header-login {
  border-color: #fff;
  color: #fff;
}
.min-header .header-navbar-rht li a.header-login:hover {
  background-color: #cc2d78;
  border-color: #cc2d78;
}

.user-menu {
  float: right;
  margin: 0;
  position: relative;
  z-index: 99;
}
.user-menu.nav > li > a {
  color: #fff;
  font-size: 14px;
  line-height: 58px;
  padding: 0 15px;
  height: 60px;
}
.user-menu.nav > li > a.mobile_btn {
  border: 0;
  position: relative;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.user-menu.nav > li > a:hover,
.user-menu.nav > li > a:focus {
  background-color: rgba(0, 0, 0, 0.2);
}

.user-menu.nav > li > a:hover i,
.user-menu.nav > li > a:focus i {
  color: #fff;
}

.user-img {
  display: inline-block;
  position: relative;
  margin-right: 5px;
}
.user-img > img {
  height: 46px;
  object-fit: cover;
  width: 46px;
  border-radius: 50%;
}

.user-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 15px;
}
.user-header .user-text {
  margin-left: 10px;
}
.user-header .user-text h6, .user-header .user-text .h6 {
  font-size: 15px;
  margin-bottom: 2px;
}

.course {
  margin-left: 10px;
  padding-top: 17px;
}

.language-select {
  cursor: pointer;
}

.dot-menu .nav-link {
  color: #c4c4c4;
  padding: 2px;
}
.dot-menu .dropdown-menu {
  padding: 0;
  right: 0;
  left: auto !important;
}
.dot-menu .dropdown-toggle::after {
  content: none;
}

.dot-menu .dropdown-item:focus, .dot-menu .dropdown-item:hover {
  color: #86E53E;
  background-color: transparent;
}

.header-two {
  background: #fff;
  top: 0;
}
.header-two .header-navbar-rht li a.reg-btn {
  background: #FFF5F1;
  border: 1px solid #EBEBEB;
  width: 55px;
  height: 55px;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.header-two .header-navbar-rht .login-btn {
  padding: 12px 39px;
  border-radius: 5px;
}

.header-three .header-navbar-rht .login-btn {
  border-radius: 5px;
}
.header-three .header-navbar-rht li a {
  color: #fff;
  font-weight: 600;
}
.header-three .header-navbar-rht li a.log-btn:hover {
  color: #86E53E;
}
.header-three .header-navbar-rht li span {
  color: #fff;
}
.header-three.sticky .header-navbar-rht li span {
  color: #2e2e2e;
}
.header-three.sticky .scroll-logo {
  display: none;
}
.header-three.sticky .original-logo {
  display: block;
}
.header-three .main-nav li a {
  color: #FFF5F1;
}

.header-three .main-nav > li.active > a,
.header-three.sticky .main-nav > li.active > a {
  color: #86E53E;
}

.header-three .main-nav li a:hover,
.header-three.sticky .main-nav li a:hover {
  color: #86E53E;
}

.original-logo {
  display: none;
}

.header-three.sticky .main-nav li a,
.header-three.sticky .header-navbar-rht li a.log-btn {
  color: #2e2e2e;
}

.trend-projects {
  padding: 80px 0px;
}

.section-header.section-header-three p,
.section-header.section-header-four p {
  color: #515151;
}

.header-four {
  background: #fff;
  top: 0;
}
.header-four .header-navbar-rht li a.reg-btn {
  color: #101828;
  font-weight: 600;
  font-size: 15px;
}
.header-four .header-navbar-rht .login-btn {
  border-radius: 5px;
}

.header-five .header-navbar-rht li .reg-btn {
  font-size: 15px;
  color: #101828;
  justify-content: center;
}
.header-five .header-navbar-rht .sign-reg {
  background: #FFFFFF;
  padding: 11px 15px;
  border-radius: 5px;
  min-width: 198px;
}
.header-five.sticky .header-navbar-rht li .reg-btn {
  box-shadow: 0px 4px 48px rgba(160, 160, 160, 0.1);
}
.header-five.sticky .main-nav li a {
  color: #2E2E2E;
}
.header-five.sticky .logo.scroll-logo {
  display: block;
}
.header-five .main-nav li a {
  color: #fff;
}

.header-five .main-nav > li.active > a,
.header-five.sticky .main-nav > li.active > a {
  color: #86E53E;
}

.header-five .scroll-logo,
.header-five.sticky .logo {
  display: none;
}

.sidebar-overlay {
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.menu-opened .main-menu-wrapper {
  transform: translateX(0);
}

.menu-header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff4f0;
  height: 60px;
  padding: 0 20px;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  border-bottom: 1px solid #f7d0c6;
  display: none;
}

.menu-close {
  font-size: 18px;
}
.menu-close i {
  color: #f38244;
}

.bar-icon {
  display: inline-block;
  width: 31px;
}
.bar-icon span {
  background-color: #ee6c2f;
  display: block;
  float: left;
  height: 3px;
  margin-bottom: 7px;
  width: 31px;
  border-radius: 2px;
}
.bar-icon span:nth-child(2) {
  width: 16px;
}
.bar-icon span:nth-child(3) {
  margin-bottom: 0;
}

#mobile_btn {
  display: none;
  margin-right: 30px;
}

html.menu-opened body {
  overflow: hidden;
}

.social-icon ul {
  display: inline-flex;
  display: -ms-inline-flexbox;
  list-style: none;
  margin: 0;
  padding: 0;
}
.social-icon ul li a {
  font-weight: 900;
  font-size: 15px;
  color: #86E53E;
}
.social-icon ul li a i {
  width: 25px;
  height: 25px;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  color: #fff;
}
.social-icon ul li .fa-facebook-f {
  background: #337FFF;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.social-icon ul li .fa-instagram {
  background: linear-gradient(315deg, #FBE18A 0.96%, #FCBB45 21.96%, #F75274 38.96%, #D53692 52.96%, #8F39CE 74.96%, #5B4FE9 100.96%);
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.social-icon ul li .fa-twitter {
  background: #33CCFF;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.social-icon ul li + li {
  margin-left: 10px;
}
.social-icon ul li .icon {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.social-icon span {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
}

html.menu-opened body:before,
html.menu-opened body header:before {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
}

.home-banner {
  background-image: url(../img/banner2.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 84px;
}

.banner-img {
  float: right;
  padding: 40px 0;
}

.banner-content .rating .checked {
  color: #86E53E;
}
.banner-content .rating h5, .banner-content .rating .h5 {
  padding-left: 15px;
}
.banner-content h5, .banner-content .h5 {
  font-weight: 500;
  font-size: 14px;
  color: #000;
  text-transform: uppercase;
}
.banner-content h1, .banner-content .h1 {
  font-weight: bold;
  font-size: 48px;
  color: #515151;
  margin-bottom: 20px;
}
.banner-content h1 .orange-text, .banner-content .h1 .orange-text {
  font-weight: 700;
  color: #86E53E;
}
.banner-content p {
  font-size: 20px;
  color: #2B2B2B;
  font-weight: 600;
}
.banner-content .form-inner {
  padding: 2px;
  background-color: #fff;
  border-radius: 130px;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(207, 207, 207, 0.25);
}
.banner-content .input-group {
  background: #fff;
  border-radius: 130px;
}
.banner-content .input-group .form-control::placeholder {
  color: #353535;
}
.banner-content .input-group .input-group-prepend {
  margin-right: 0;
}
.banner-content .form-control {
  background: #fff;
  border-radius: 40px;
  border: 0;
  height: 70px;
  color: #353535;
  font-size: 16px;
  font-weight: 500;
}
.banner-content .select2-container {
  border-right: 1px solid #CFCFCF;
  width: 99% !important;
  z-index: unset;
}
.banner-content .select2-container .select2-selection--single {
  border: 0;
  align-items: center;
  display: flex;
  background: transparent;
  height: 30px;
}
.banner-content .select2-container .select2-selection--single .select2-selection__rendered {
  font-size: 16px;
  padding: 0 25px;
}
.banner-content .select2-container--default .select2-selection--single .select2-selection__arrow {
  top: auto;
}
.banner-content .sub-btn {
  background: #86E53E;
  border-radius: 50px !important;
  font-weight: bold;
  border: 1px solid #86E53E;
  height: 52px;
  margin: 9px;
  font-size: 16px;
  line-height: 18px;
  padding: 10px 30px;
  text-transform: capitalize;
  color: #fff;
  min-width: 172px;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.drop-detail {
  width: 165px;
  display: flex;
  align-items: center;
}

.banner-content .sub-btn:hover,
.banner-content .sub-btn:focus {
  background: #13323C;
  border-color: #13323C;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.btn-item {
  margin-top: 25px;
}

.get-btn {
  background: #86E53E;
  border-radius: 130px;
  border-color: #86E53E;
  font-weight: bold;
  padding: 14px 42px;
  color: #fff;
  margin-right: 10px;
  text-transform: uppercase;
  font-size: 14px;
}
.get-btn:hover {
  color: #fff;
  background-color: #fb451d;
  border-color: #fb451d;
}

.courses-btn {
  background: #13323C;
  border-radius: 130px;
  font-weight: bold;
  padding: 12px 39px;
  color: #fff;
  text-transform: uppercase;
  font-size: 15px;
}
.courses-btn:hover {
  color: #fff;
  background-color: #86E53E;
  border-color: #86E53E;
}

.feature {
  position: relative;
  padding-bottom: 20px;
  background-image: url(../../assets/img/bg/bg-07.png);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 20%;
  padding-top: 50px;
}
.feature .feature-icon {
  margin-bottom: 25px;
  position: relative;
  min-height: 78px;
}
.feature .feature-icon::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 108px;
  height: 70px;
  background-image: url(../img/icon/icon-bg.png);
}
.feature .feature-icon img {
  position: relative;
}
.feature .feature-content h3, .feature .feature-content .h3 {
  color: #101828;
  font-weight: bold;
  margin-bottom: 10px;
}
.feature .feature-content p {
  font-size: 18px;
  text-transform: capitalize;
  color: #5F5F5F;
  margin-bottom: 0;
  font-weight: 500;
}

.feature-item {
  position: relative;
  padding: 30px;
  margin-bottom: 30px;
  background: #fff;
  box-shadow: 0px 4px 48px rgba(160, 160, 160, 0.1);
  border-radius: 6px;
  text-align: center;
  overflow: hidden;
  border: 1px solid #fff;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.feature-item:hover {
  border: 1px solid #86E53E;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.work {
  position: relative;
}
.work:before {
  content: "";
  position: absolute;
  width: 108px;
  height: 128px;
  z-index: 9;
  bottom: 60px;
  left: 0;
  transform: translate(-50%, 0);
  background: url(../../assets/img/bg/bg-08.png) top center no-repeat;
}
.work:after {
  content: "";
  position: absolute;
  width: 108px;
  height: 128px;
  z-index: 9;
  top: 60px;
  right: 0;
  transform: translate(50%, 0);
  background: url(../../assets/img/bg/bg-14.png) top center no-repeat;
}

.work-box {
  padding: 0;
  overflow: hidden;
}

.bg1 {
  background: #86E53E;
  background-image: url(../../assets/img/bg/project-bg.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 270px;
}
.bg1:before {
  content: "";
  position: absolute;
  width: 28px;
  top: 50px;
  left: 80px;
  height: 28px;
  border-radius: 50%;
  background-color: #fff;
}

.bg2 {
  background: #FFF5F3;
  background-image: url(../../assets/img/bg/work-bg.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 270px;
}
.bg2 .work-content h2, .bg2 .work-content .h2 {
  color: #161c2d;
}
.bg2 .work-content h2:after, .bg2 .work-content .h2:after {
  background: #86E53E;
}
.bg2 .work-content p {
  color: #161c2d;
}
.bg2 .work-content .long-arrow {
  background: #13323C;
  color: #FFFBFB;
}

.work-content {
  width: 84%;
  padding: 70px 50px 50px 120px;
}
.work-content h2, .work-content .h2 {
  font-weight: bold;
  color: #fff;
  padding-bottom: 20px;
  position: relative;
  margin-bottom: 20px;
}
.work-content h2 span, .work-content .h2 span {
  display: block;
}
.work-content h2:after, .work-content .h2:after {
  content: "";
  width: 48px;
  height: 3px;
  background: #fff;
  left: 0px;
  right: 0;
  bottom: 0;
  position: absolute;
  border-radius: 170px;
}
.work-content p {
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  margin-bottom: 25px;
}
.work-content .long-arrow {
  width: 37px;
  height: 37px;
  background: #FFFAF9;
  color: #86E53E;
  border-radius: 50%;
  line-height: 37px;
  text-align: center;
  font-size: 20px;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.want-works .work-content {
  width: 74%;
  padding: 70px 50px;
}

.work-content .long-arrow:hover,
.work-content .long-arrow:focus {
  background: #13323C;
  color: #fff;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.bg2 .work-content .long-arrow:hover, .bg2 .work-content .long-arrow:focus {
  background: #fb451d;
}

.services {
  position: relative;
  padding: 80px 0;
  background: #FFFBFB;
  background-image: url(../../assets/img/bg/bg-01.png), url(../../assets/img/bg/bg-02.png);
  background-repeat: no-repeat, no-repeat;
  background-position: right bottom, left top;
  background-size: 270px, 56%;
}

.service-item {
  background: #fff;
  border: 1px solid #ececec;
  text-align: center;
  border-radius: 2px;
  padding: 20px;
  margin-bottom: 30px;
  transition: ease-in-out 0.15s;
  -moz-transition: ease-in-out 0.15s;
  -o-transition: ease-in-out 0.15s;
  -webkit-transition: ease-in-out 0.15s;
  cursor: pointer;
}

.service-item:hover, .service-item:focus {
  background: #FFF0F0;
  border-color: #FFF0F0;
}

.select-item.selected {
  box-shadow: 0px 8px 16px 0px #EEEEEE;
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  filter: grayscale(0%);
}

.selected {
  background: #86E53E;
}
.selected .service-content h5, .selected .service-content .h5 {
  color: #fff;
}

.service-icon {
  margin-bottom: 20px;
  line-height: 77px;
}

.service-content h5, .service-content .h5 {
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.services-item {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 15px;
}
.services-item img {
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}
.services-item .content-text {
  position: absolute;
  transition: all 0.3s ease-in-out;
  text-align: center;
  bottom: 10%;
  width: 100%;
}
.services-item .content-part h3, .services-item .content-part .h3 {
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
}
.services-item .content-part h4, .services-item .content-part .h4 {
  font-size: 17px;
  font-weight: 600;
  color: #fff;
}
.services-item .text {
  text-align: center;
  position: absolute;
  top: 70%;
}
.services-item:hover img {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  transform: scale(1.15);
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}

.section-header {
  margin-bottom: 50px;
}
.section-header h5, .section-header .h5 {
  font-size: 21px;
  color: #86E53E;
  font-weight: 700;
  position: relative;
  margin-bottom: 0;
  color: #2E2E2E;
  font-size: 18px;
  font-weight: 500;
}
.section-header p {
  font-weight: 500;
  font-size: 18px;
  color: #86E53E;
  margin-bottom: 0;
}
.section-header h6, .section-header .h6 {
  font-size: 20px;
  color: #2E2E2E;
  font-weight: 600;
}

.section-line {
  width: 35px;
  height: 8px;
  background: #86E53E;
  border-radius: 5px;
  margin: auto;
  position: relative;
  margin-bottom: 20px;
}

.header-title {
  color: #2E2E2E;
  font-weight: bold;
  position: relative;
  margin-bottom: 15px;
  font-size: 24px;
}
.header-title span {
  color: #86E53E;
}

.see-all {
  margin-top: 20px;
}

.all-btn {
  padding: 12px 40px;
  background: #86E53E;
  border: 3px solid #86E53E;
  border-radius: 50px;
  color: #FFF5F3;
  font-weight: bold;
  font-size: 16px;
}
.all-btn:hover {
  background: #13323C;
  border: 3px solid #13323C;
  color: #fff;
  transition: all 0.3s ease;
}
.all-btn:hover .right-arrow {
  color: #115241;
}

.right-arrow {
  height: 20px;
  width: 20px;
  background: #fff;
  color: #86E53E;
  text-align: center;
  border-radius: 50%;
  line-height: 20px;
  padding-left: 2px;
  font-size: 14px;
  margin-left: 10px;
}

.projects {
  position: relative;
  padding: 80px 0;
  background: #fbfbfb;
}

.project-item {
  background: #fff;
  margin-bottom: 25px;
  width: 100%;
  border: 1px solid #EBEBEB;
  box-shadow: 0px 4px 48px rgba(160, 160, 160, 0.1);
  border-radius: 6px;
}
.project-item:hover .project-icon {
  border-color: #FFCCC1;
}
.project-item.feature-project-item {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(232, 232, 232, 0.25);
  border: 0;
  padding: 10px;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.project-item.feature-project-item .project-img {
  padding: 0;
}
.project-item.feature-project-item:hover {
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
}
.project-item.trend-project-item {
  padding: 15px;
  border: 1px solid #EBEBEB;
  border-radius: 5px;
  box-shadow: none;
}
.project-item.project-item-feature {
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  box-shadow: 0px 4px 34px rgba(233, 233, 233, 0.25);
  border-radius: 10px;
  padding: 10px;
}
.project-item.project-popular-item {
  background: #FFFFFF;
  border: 1px solid #FFF5F1;
  box-shadow: 0px 4px 44px rgba(220, 220, 220, 0.25);
  border-radius: 10px;
  padding: 10px;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.project-item.project-popular-item:hover {
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
}
.project-item.project-popular-item .project-img {
  padding: 0;
}
.project-item .feature-foot p {
  color: #5F5F5F;
  font-weight: 500;
  margin-bottom: 0;
  font-size: 15px;
}
.project-item .feature-foot p span {
  color: #86E53E;
  font-weight: 700;
}

.project-img {
  overflow: hidden;
  padding: 3px 3px 0 3px;
  border-radius: 6px;
}
.project-img img {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  -moz-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -o-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  width: 100%;
  border-radius: 6px;
}
.project-img:hover img {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  transform: scale(1.15);
}
.project-img:hover .hour-dollr img {
  transform: unset;
}

.project-icon {
  position: relative;
  width: 5px;
  height: 48px;
  border-radius: 50%;
  background: transparent;
  border: 0;
  margin: 15px 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.pro-icon {
  position: relative;
}
.pro-icon:before {
  content: "";
  width: 1px;
  height: 100%;
  background: #ECECEC;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}

.project-content {
  margin: 0 auto;
  text-align: center;
  width: 42%;
}
.project-content h4, .project-content .h4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  text-transform: uppercase;
  color: #86E53E;
  margin-bottom: 0;
}
.project-content h5, .project-content .h5 {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 0;
}
.project-content h5 a, .project-content .h5 a {
  color: #353535;
}
.project-content h5 a:hover, .project-content .h5 a:hover {
  color: #86E53E;
}

.developer {
  position: relative;
  padding: 40px 0 50px;
  background-repeat: no-repeat, no-repeat;
  background-position: right center;
}

.dev-item {
  position: relative;
}

.dev-img, .dev-top {
  position: relative;
}

.dev-text {
  position: absolute;
  top: 20px;
  text-align: center;
  right: 20px;
}
.dev-text .right {
  padding: 10px 15px;
  color: #86E53E;
  font-weight: bold;
  background: #FFFBFB;
  border-radius: 50px;
  font-size: 14px;
  display: inline-block;
}

.dev-user {
  position: absolute;
  left: 20px;
  top: 20px;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
}
.dev-user img {
  margin: auto;
  width: 40px;
}

.dev-content {
  background: #fff;
  border: 1px solid #FFEEEA;
  width: 100%;
  text-align: center;
  padding: 20px;
}
.dev-content .rating {
  margin-bottom: 10px;
}
.dev-content .rating i {
  margin-bottom: 10px;
  font-size: 13px;
}
.dev-content h3, .dev-content .h3 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 12px;
}
.dev-content h4, .dev-content .h4 {
  font-weight: 500;
  font-size: 15px;
  color: #86E53E;
  margin-bottom: 14px;
}
.dev-content p {
  font-weight: 600;
  font-size: 16px;
  color: #6e727d;
  margin-bottom: 0;
  text-transform: uppercase;
}

.btn-view {
  color: #fff;
  background-color: #86E53E;
  padding: 15px 22px;
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  border-radius: 0px 0px 15px 15px;
}
.btn-view:hover {
  color: #fff;
  background-color: #fb451d;
  border-color: #fb451d;
}

.developer-slider .slick-slide {
  display: block;
  margin-left: 0;
  padding: 0 10px;
}

.rating .checked {
  color: #ff9415;
}
.rating .non-checked {
  color: #C4C4C4;
}
.rating i {
  color: #5F5F5F;
  font-size: 12px;
}
.rating i.filled {
  color: #86E53E;
}
.rating .average-rating {
  font-size: 14px;
  color: #6e727d;
  font-weight: 500;
}
.rating.red i.filled {
  color: #febe42;
}

.test-quote-img {
  margin-left: auto;
}

.right-text {
  text-align: right;
}

.review {
  background: #FFF5F1;
  padding: 80px 0;
  background-image: url(../../assets/img/bg/bg-16.png);
  background-repeat: no-repeat, no-repeat;
  background-position: left center;
}
.review.review-two {
  background-image: none;
}

.review-blog {
  background-color: #fff;
  padding: 20px;
  position: relative;
  box-shadow: 0px 4px 48px rgba(160, 160, 160, 0.1);
  border-radius: 6px;
}
.review-blog .review-content {
  padding: 0;
  border-bottom: 0;
  margin-bottom: 0px;
}
.review-blog .review-content p {
  font-size: 15px;
  margin: 0;
  line-height: 26px;
  font-weight: 500;
}
.review-blog .review-img img {
  width: 75px;
  border-radius: 50%;
  margin-right: 20px;
}
.review-blog .review-top {
  margin-bottom: 20px;
}
.review-blog.user-review {
  border: 1px solid #EBEBEB;
  box-shadow: 20px 24px 34px rgba(225, 225, 225, 0.25);
  border-radius: 10px;
}
.review-blog.user-review .review-img {
  margin-bottom: 15px;
}
.review-blog.user-review .review-img img {
  margin: auto;
}

.owl-carousel .owl-item .review-img img {
  width: 96px;
}
.owl-carousel .owl-item .company-logos img {
  width: auto;
  margin: auto;
}
.owl-carousel .owl-item .trend-project-list img {
  display: flex;
  width: inherit;
}
.owl-carousel .owl-item img {
  display: block;
  width: inherit;
}
.owl-carousel .owl-item .reviw-profile-img img {
  width: 48px;
  height: 48px;
  border-radius: 50px;
}
.owl-carousel.trend-slider .owl-nav {
  position: absolute;
  margin: 0;
  right: 0;
  top: -97px;
  min-width: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.owl-carousel.trend-slider .owl-dots {
  position: absolute;
  margin: 0;
  right: 0;
  min-width: 90px;
  top: -94px;
}
.owl-carousel.trend-slider .owl-dots span {
  margin: 0px 2px;
  width: 7px;
  height: 7px;
}
.owl-carousel .hour-dollr img {
  width: auto;
}

.trend-slider .owl-nav {
  position: absolute;
  margin: 0;
  right: 0;
  top: -97px;
  min-width: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.trend-slider .owl-nav .owl-prev, .trend-slider .owl-nav .owl-next {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}
.trend-slider .owl-theme .owl-dots {
  position: absolute;
  margin: 0;
  right: 0;
  min-width: 90px;
  top: -94px;
}
.trend-slider .owl-theme .owl-dots span {
  margin: 0px 2px;
  width: 7px;
  height: 7px;
}

.review-info h3, .review-info .h3 {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
}
.review-info h5, .review-info .h5 {
  font-size: 14px;
  color: #5F5F5F;
  margin-bottom: 6px;
  font-weight: 500;
}
.review-info .rating i {
  font-size: 12px;
}
.review-info .rating i.filled {
  color: #86E53E;
}
.review-info .rating .average-rating {
  font-size: 14px;
  color: #5F5F5F;
}

.review-bottom {
  margin-top: 60px;
}
.review-bottom .review-img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}
.review-bottom .review-info h3, .review-bottom .review-info .h3 {
  font-size: 18px;
  font-weight: bold;
  color: #161c2d;
  margin-bottom: 3px;
}
.review-bottom p {
  color: #5F5F5F;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}

.review-icon img {
  max-width: 50px;
}

.review-slider .slick-slide {
  display: block;
  margin-left: 0;
  padding: 0 10px;
  width: 280px;
}

.app-version {
  padding: 80px 0;
  background-image: url(../../assets/img/bg/bg-17.png);
  background-repeat: no-repeat, no-repeat;
  background-position: right bottom;
  background-size: cover;
}

.app-version-blk h2, .app-version-blk .h2 {
  color: #2e2e2e;
  margin-bottom: 20px;
}
.app-version-blk p {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 25px;
}
.app-version-blk h5, .app-version-blk .h5 {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}

.top-company {
  padding: 80px 0;
  border-bottom: 1px solid #EBEBEB;
}

.news {
  padding: 80px 0px;
}
.news .see-all {
  margin-top: 20px;
  text-align: center;
}

.news-content {
  background: #fff;
  border-radius: 0px 0px 10px 10px;
  padding: 20px;
}
.news-content h2, .news-content .h2 {
  font-size: 24px;
  color: #161c2d;
}
.news-content p {
  font-size: 16px;
  color: #161c2d;
}
.news-content .entry-meta {
  background: #FFFBFB;
  display: inline-block;
  padding: 5px 10px 5px 0;
  border-radius: 0 25px 25px 0;
  color: #757575;
  list-style: none;
  margin-bottom: 15px;
}
.news-content .entry-meta li {
  display: inline-block;
  margin-right: 15px;
  font-size: 15px;
  font-weight: 500;
  color: #6e727d;
}
.news-content .entry-meta li.name i {
  font-size: 25px;
}
.news-content .entry-meta li.name img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
.news-content .entry-meta li.cal i {
  color: #86E53E;
}
.news-content a.read {
  font-weight: 600;
  font-size: 14px;
  color: #86E53E;
}

.clients {
  padding-top: 50px;
}

.client-img {
  text-align: center;
}

.job-counter {
  padding: 60px 0;
  background: #86E53E;
}

.counter h2, .counter .h2 {
  font-weight: 600;
  font-size: 36px;
  color: #fff;
}
.counter h4, .counter .h4 {
  font-weight: 500;
  font-size: 20px;
  color: #fff;
}

.about {
  padding: 80px 0;
}

.about-content h2, .about-content .h2 {
  font-weight: 500;
  font-size: 28px;
  color: #161c2d;
  margin-bottom: 17px;
  font-weight: 600;
}
.about-content p {
  font-size: 15px;
  margin-bottom: 20px;
  line-height: 30px;
  font-weight: 500;
}

.orange-text {
  color: #86E53E;
}

.learn-btn {
  background: #13323C;
  border-radius: 150px;
  font-weight: 600;
  font-size: 14px;
  padding: 8px 32px;
  color: #fff;
}
.learn-btn:hover {
  color: #fff;
  background: #86E53E;
}

.expert {
  padding: 80px 0;
  text-align: center;
  background-image: url(../img/bg/about-bg.png);
  background-size: cover;
}
.expert h5, .expert .h5 {
  font-weight: 600;
  font-size: 16px;
  color: #86E53E;
}
.expert h2, .expert .h2 {
  font-size: 30px;
  color: #161c2d;
  margin-bottom: 25px;
}
.expert p {
  font-size: 16px;
  color: #161c2d;
}
.expert .btn-item {
  margin-top: 50px;
}

.agency {
  position: relative;
}

.agency-box {
  padding: 0;
  overflow: hidden;
}

.black-bg {
  background: #13323C;
}

.agency-content {
  padding: 0 100px;
}
.agency-content h2, .agency-content .h2 {
  font-weight: 600;
  font-size: 28px;
  color: #fff;
  position: relative;
  margin-bottom: 25px;
  line-height: 36px;
}
.agency-content p {
  font-size: 16px;
  color: #fff;
  margin-bottom: 25px;
}
.agency-content .learn-btn {
  background: #86E53E;
}
.agency-content .learn-btn:hover {
  color: #fff;
  background: #fb451d;
}

.about-img img {
  position: relative;
}
.about-img img:before {
  content: "";
  position: absolute;
  top: 100%;
  left: -5px;
  right: -5px;
  bottom: -5px;
  background-color: rgba(0, 0, 0, 0.2);
}

.job-register {
  background: #161515;
  padding: 50px 0px;
}

.register-job-blk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.job-content-blk h1, .job-content-blk .h1 {
  color: #fff;
}
.job-content-blk p {
  color: #FFF5F1;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
}

.home-two {
  background-image: url(../img/banner3.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 100px;
}
.home-two .banner-img {
  padding: 24px 0;
}
.home-two .banner-content h1, .home-two .banner-content .h1 {
  margin-bottom: 40px;
}

.banner-dot span {
  width: 11px;
  height: 11px;
  border-radius: 50px;
  display: block;
  position: absolute;
}

.blue-dot-banner {
  background: #337FFF;
  top: 0;
  right: 0;
}

.red-dot-banner {
  background: #86E53E;
  bottom: 100px;
  left: -40px;
}

.banner-top-bottom {
  position: absolute;
  bottom: -25px;
  left: 22%;
  z-index: 9;
}
.banner-top-bottom a {
  background: #FFFFFF;
  box-shadow: 0px 4px 14px rgba(217, 217, 217, 0.25);
  width: 55px;
  height: 55px;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  color: #86E53E;
}

.market-place h3, .market-place .h3 {
  color: #86E53E;
}

.home-count {
  margin-top: 45px;
}
.home-count ul li {
  color: #101828;
  font-size: 24px;
  font-weight: 700;
}
.home-count ul li .list-count {
  color: #5F5F5F;
  font-size: 18px;
  font-weight: 500;
  display: block;
  margin-bottom: 0;
}
.home-count ul li + li {
  border-left: 1px solid #EBEBEB;
  padding-left: 20px;
  margin-left: 20px;
}

.section-header-two .header-title {
  margin-bottom: 10px;
}
.section-header-two p {
  color: #515151;
}

.update-project {
  position: relative;
  padding-bottom: 80px;
  background-image: url(../../assets/img/bg/bg-19.png);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 30%;
  padding-top: 80px;
}

.update-project-blk {
  background: #FFFFFF;
  box-shadow: 20px 4px 34px rgba(181, 181, 181, 0.15);
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.update-project-blk:hover .update-content h6, .update-project-blk:hover .update-content .h6 {
  color: #86E53E;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.update-content h6, .update-content .h6 {
  color: #2E2E2E;
  font-weight: 600;
  margin-bottom: 10px;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.update-content p {
  color: #5F5F5F;
  font-weight: 500;
  margin-bottom: 0;
  font-size: 14px;
}

.platform-group h1, .platform-group .h1 {
  color: #2E2E2E;
  margin-bottom: 25px;
}
.platform-group h1 span, .platform-group .h1 span {
  color: #86E53E;
}
.platform-group h5, .platform-group .h5 {
  color: #515151;
  margin-bottom: 15px;
  font-weight: 500;
}
.platform-group p {
  color: #000;
  font-size: 14px;
  margin-bottom: 20px;
}

.platform {
  padding: 80px 0px;
  background: linear-gradient(270.05deg, #F8ECF8 -28.55%, #FFF3EA 112.03%);
  position: relative;
  z-index: 1;
}
.platform:before {
  content: "";
  background-image: url(../../assets/img/bg/bg-20.png);
  background-repeat: no-repeat, no-repeat;
  background-position: left top;
  position: absolute;
  width: 220px;
  height: 390px;
  left: 0;
  top: 0;
  z-index: -1;
}

.plat-form-img img {
  width: 100%;
}

.more-project {
  margin-top: 25px;
}
.more-project .btn-primary {
  min-width: 200px;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.more-project .btn-primary:hover {
  background-color: #13323C;
  border: 1px solid #13323C;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.more-project .start-bid {
  background: #101828;
  border: 1px solid #101828;
}
.more-project .start-bid:hover {
  background: #86E53E;
  border: 1px solid #86E53E;
}
.more-project .all-article {
  min-width: 177px;
}

.feature-project {
  padding: 80px 0px;
  background-image: url(../../assets/img/bg/bg-22.png);
  background-repeat: no-repeat, no-repeat;
  background-position: right center;
}
.feature-project:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(../../assets/img/bg/bg-frame.png);
  background-repeat: no-repeat, no-repeat;
}

.feature-content {
  margin-top: 15px;
}
.feature-content h4, .feature-content .h4 {
  margin-bottom: 15px;
  font-size: 18px;
}
.feature-content h4 a, .feature-content .h4 a {
  color: #353535;
}
.feature-content h4 a:hover, .feature-content .h4 a:hover {
  color: #86E53E;
}

.feature-time-blk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 15px;
}
.feature-time-blk .btn-primary {
  min-width: 88px;
  padding: 2px 10px;
  font-weight: 500;
  font-size: 14px;
  color: #FFF5F1;
}
.feature-time-blk .green-active {
  background: #2DA646;
  border: 1px solid #2DA646;
}
.feature-time-blk .green-active:hover {
  background: #1b8b32;
  border: 1px solid #1b8b32;
}
.feature-time-blk .sky-active {
  background: #8F39CE;
  border: 1px solid #8F39CE;
}
.feature-time-blk .sky-active:hover {
  background: #792faf;
  border: 1px solid #792faf;
}
.feature-time-blk span {
  font-weight: 500;
  font-size: 14px;
}
.feature-time-blk span i {
  font-size: 12px;
}

.feature-project-list {
  padding-bottom: 15px;
  margin-bottom: 10px;
  border-bottom: 1px solid #EBEBEB;
}
.feature-project-list li {
  font-weight: 500;
  font-size: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.feature-project-list li + li {
  margin: auto 0 0 auto;
}

.feature-foot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.logo-company span {
  color: #5F5F5F;
  font-weight: 500;
  font-size: 14px;
}

.bid-now {
  color: #86E53E;
  font-size: 15px;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.bid-now:hover {
  color: #13323C;
}

.top-skill {
  padding: 80px 0px;
  background: #FBFBFB;
}

.skill-head .nav-pills .nav-link.active,
.skill-head .nav-pills .show > .nav-link {
  background-color: transparent;
  color: #101828;
}

.skill-head .nav-pills .nav-link {
  font-size: 24px;
  color: #EBEBEB;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.skill-head .nav-pills .nav-link:hover {
  color: #101828;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.skill-head .nav {
  display: block;
}
.skill-head.skill-head-two .nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin-bottom: 55px;
}
.skill-head.skill-head-two .nav-pills .nav-link {
  padding: 0 15px;
}

.skill-list-out ul li {
  list-style: none;
  margin-bottom: 15px;
}
.skill-list-out ul li a {
  color: #101828;
  font-size: 14px;
  font-weight: 500;
}
.skill-list-out ul li a:hover {
  color: #86E53E;
}

.feature-develop-blk {
  border: 1px solid #EBEBEB;
  box-shadow: 20px 24px 34px rgba(225, 225, 225, 0.25);
  border-radius: 10px;
  margin-bottom: 25px;
  background: #FFFFFF;
}

.developer-sub-blk {
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.feature-developer {
  padding: 80px 0px;
  position: relative;
  background-image: url(../../assets/img/bg/bg-19.png);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 30%;
}

.img-developer img {
  border-radius: 10px;
}

.developer-content h4, .developer-content .h4 {
  font-size: 16px;
  margin-bottom: 5px;
  color: #353535;
}
.developer-content p {
  font-size: 14px;
  color: #515151;
  font-weight: 500;
  margin-bottom: 4px;
}
.developer-content .rating .average-rating {
  color: #5f5f5f;
}

.verify {
  background-color: #fff;
  color: #159C5B;
  z-index: 2;
  width: 16px;
  height: 16px;
}

.hour-rate ul {
  padding: 10px;
  border-top: 1px solid #EBEBEB;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.hour-rate ul li {
  color: #101828;
  font-weight: 500;
  font-size: 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.hour-rate .feather-map-pin {
  color: #9D9D9D;
  font-size: 16px;
}

.client-rate {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin-bottom: 10px;
}
.client-rate h4, .client-rate .h4 {
  color: #2e2e2e;
  margin-bottom: 0;
  margin-right: 15px;
}

.great-about {
  padding: 80px 0px 460px;
  background: linear-gradient(270.05deg, #F8ECF8 -28.55%, #FFF3EA 112.03%);
  z-index: 1;
  position: relative;
}
.great-about:before {
  content: "";
  background-image: url(../../assets/img/bg/bg-21.png);
  background-repeat: no-repeat, no-repeat;
  background-position: right top;
  position: absolute;
  width: 220px;
  height: 390px;
  right: 0;
  top: 0;
  z-index: -1;
}

.great-blk {
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  border-radius: 9px;
  padding: 30px;
  margin-bottom: 25px;
}

.great-icon {
  background: linear-gradient(270.05deg, #F8ECF8 -28.55%, #FFF3EA 112.03%);
  width: 71px;
  height: 71px;
  margin-bottom: 15px;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.great-content h4, .great-content .h4 {
  color: #000000;
  margin-bottom: 15px;
}
.great-content p {
  color: #515151;
  font-size: 14px;
  margin-bottom: 0;
}

.about-it-blk {
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  box-shadow: 0px 4px 24px rgba(224, 224, 224, 0.25);
  border-radius: 25px;
  padding: 20px;
}

.about-it-content {
  margin-top: 20px;
}
.about-it-content h4, .about-it-content .h4 {
  color: #2E2E2E;
  font-weight: 700;
  margin-bottom: 15px;
}
.about-it-content p {
  color: #515151;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 20px;
}

.location-img {
  overflow: hidden;
  border-radius: 10px;
}
.location-img img {
  border-radius: 10px;
}

.about-it-img {
  border-radius: 20px;
  overflow: hidden;
}
.about-it-img img {
  border-radius: 20px;
}

.about-it-img img,
.location-img img {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  -moz-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -o-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  width: 100%;
}

.about-it-img:hover img,
.location-img:hover img {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  transform: scale(1.15);
}

.about-project {
  min-height: 270px;
  position: relative;
}

.about-position {
  position: absolute;
  bottom: 80px;
  z-index: 9;
  width: 100%;
}

.job-location {
  padding: 80px 0px 55px;
  background: #FBFBFB;
}

.section-locate {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.view-locations .btn-primary {
  border: 1px solid #101828;
  border-radius: 5px;
  background: transparent;
  color: #000000;
  font-size: 14px;
  min-width: 191px;
  padding: 10px 15px;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.view-locations .btn-primary:hover {
  border: 1px solid #101828;
  background: #101828;
  color: #fff;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.view-locations.all-views {
  margin-top: 25px;
  display: inline-flex;
}
.view-locations.all-views .btn-primary {
  max-width: 191px;
}

.location-right {
  background: #86E53E;
  width: 18px;
  height: 18px;
  border-radius: 50px;
  color: #fff;
  margin-left: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.job-locate-blk {
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 25px;
}

.job-it-content {
  margin-top: 10px;
}
.job-it-content h6, .job-it-content .h6 {
  font-weight: 600;
  margin-bottom: 8px;
}
.job-it-content h6 a, .job-it-content .h6 a {
  color: #000000;
}

.job-locate-foot {
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.job-locate-foot li {
  color: #474747;
  font-size: 14px;
  font-weight: 500;
}

.user-review .review-info h5, .user-review .review-info .h5 {
  font-size: 14px;
  color: #2B2B2B;
  font-weight: 400;
}
.user-review .rating {
  text-align: center;
  margin-top: 20px;
}

.review-two .owl-nav {
  position: absolute;
  overflow: hidden;
  bottom: 100%;
  right: -5px;
  margin-bottom: 50px;
}
.review-two .owl-theme .owl-nav [class*=owl-]:hover {
  background: #86E53E;
}
.review-two .owl-theme .owl-nav [class*=owl-]:hover i {
  color: #fff;
}

.top-company-two {
  padding: 80px 0px 0px;
}
.top-company-two .company-logos {
  border: 1px solid #EBEBEB;
  background: #fff;
  padding: 7px 0px;
  border: 1px solid #EBEBEB;
  border-radius: 4px;
}

.review-two .owl-carousel .owl-nav button.owl-next,
.review-two .owl-carousel .owl-nav button.owl-prev,
.review-two .owl-carousel button.owl-dot {
  display: block;
  float: left;
  width: 32px;
  height: 32px;
  font-size: 13px;
  color: #fff;
  border: 0;
  background: #13323C;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  text-align: center;
  -webkit-transition: color 0.7s ease, background-color 0.7s ease, border-color 0.7s ease;
  transition: color 0.7s ease, background-color 0.7s ease, border-color 0.7s ease;
  box-shadow: 0px 1px 4px rgba(183, 183, 183, 0.25);
  border-radius: 50px;
}

.review-two .page-prev,
.review-two .owl-nav .owl-prev {
  border-radius: 50px;
  border: 1px solid #EBEBEB;
  color: #86E53E;
  background: #FFFFFF;
  width: 32px;
  height: 32px;
}

.review-two .page-next,
.review-two .owl-nav .owl-next {
  color: #86E53E;
  border-radius: 50px;
  border: 1px solid #EBEBEB;
  background: #FFFFFF;
  width: 32px;
  height: 32px;
}

.review-two .owl-carousel .owl-nav button.owl-next i,
.review-two .owl-carousel .owl-nav button.owl-prev i {
  color: #86E53E;
  line-height: 2.5;
}

.grid-blog.blog-two {
  background: #FFFFFF;
  border: 1px solid #F5F6FA;
  box-shadow: 0px 4px 24px rgba(214, 214, 214, 0.25);
  border-radius: 10px;
}
.grid-blog.blog-two .blog-content p {
  margin: 0;
}
.grid-blog.blog-five {
  padding: 20px;
  border: 1px solid #EBEBEB;
  box-shadow: 0px 4px 24px rgba(204, 204, 204, 0.25);
  border-radius: 10px;
}
.grid-blog.blog-five .blog-trick-read p {
  margin-bottom: 0;
  color: #86E53E;
}
.grid-blog.blog-five .blog-content {
  padding: 20px 0px 0px;
}
.grid-blog.blog-five .blog-title {
  color: #2b2b2b;
}
.grid-blog.blog-five .blog-reead {
  color: #5f5f5f;
}
.grid-blog .blog-title {
  font-size: 16px;
  margin: 10px 0 10px;
  color: #2e2e2e;
  font-weight: 700;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.grid-blog .blog-content p {
  margin: 0 0 15px;
}
.grid-blog .blog-info {
  margin-bottom: 15px;
}
.grid-blog .blog-image {
  margin-bottom: 0px;
  border-radius: 6px 6px 0px 0px;
}
.grid-blog .entry-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 0;
}
.grid-blog .entry-meta li {
  margin-bottom: 10px;
}
.grid-blog .entry-meta li .fa-calendar-alt {
  font-weight: 500;
}
.grid-blog .entry-meta li:last-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: end;
  -webkit-justify-content: end;
  -ms-flex-pack: end;
}
.grid-blog .entry-meta li i {
  margin-right: 3px;
}
.grid-blog .post-author {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.grid-blog .post-author a {
  color: #5F5F5F;
  font-size: 14px;
  font-weight: 500;
}
.grid-blog .post-author a:hover {
  color: #ff3115;
}

.blog-two .blog-read {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.blog-two .blog-content {
  padding: 20px;
}

.blog-tricks {
  padding: 80px 0px;
}
.blog-tricks .owl-dots {
  display: none;
}

.blog-slider.owl-carousel .owl-nav button.owl-prev,
.blog-slider.owl-carousel .owl-nav button.owl-next {
  width: 44px;
  height: 44px;
  font-size: 16px;
  background: #fff !important;
  color: #86E53E;
  border-radius: 50px;
  position: absolute;
  border: 1px solid #EBEBEB;
}

.blog-slider.owl-carousel .owl-nav button.owl-prev:hover,
.blog-slider.owl-carousel .owl-nav button.owl-next:hover {
  background: #86E53E;
  color: #fff;
}

.blog-slider.owl-carousel .owl-nav button.owl-prev {
  top: 40%;
  left: -25px;
  -webkit-transform: translate(0, 50%);
  -ms-transform: translate(0, 50%);
  transform: translate(0, 50%);
}
.blog-slider.owl-carousel .owl-nav button.owl-next {
  top: 40%;
  right: -25px;
  -webkit-transform: translate(0, 50%);
  -ms-transform: translate(0, 50%);
  transform: translate(0, 50%);
}

.owl-theme.blog-slider .owl-dots .owl-dot span {
  width: 16px;
  height: 16px;
  background: #C4C4C4;
}

.owl-theme.blog-slider .owl-dots .owl-dot.active span,
.owl-theme.blog-slider .owl-dots .owl-dot:hover span {
  background: #2567E5;
}

.sign-in-btn .btn-primary {
  min-width: 202px;
  padding: 10px 15px;
  background: #101828;
  border-radius: 5px;
  border: 1px solid #101828;
  font-weight: 600;
}
.sign-in-btn .btn-primary:hover {
  background: #000;
  border: 1px solid #000;
}

.job-register-two {
  background: #86E53E;
  padding: 50px 0px;
}

.home-three {
  background-image: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 0;
}
.home-three .slick-prev {
  right: 90px;
}
.home-three .slick-prev:before {
  background-image: url(../../assets/img/pre.svg);
}
.home-three .slick-next {
  right: 40px;
}
.home-three .slick-next:before {
  background-image: url(../../assets/img/next.svg);
}
.home-three .banner-content h1, .home-three .banner-content .h1 {
  font-size: 32px;
  color: #fff;
  margin-bottom: 34px;
}

.home-three .banner-content .form-control::placeholder,
.home-three .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #9d9d9d;
}

.dot-slider .slick-dots {
  position: absolute;
  top: 30%;
  right: 50px;
  bottom: unset;
  width: unset;
  position: absolute;
  top: 50%;
  right: 50px;
  bottom: unset;
  width: unset;
  left: unset;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.dot-slider .slick-dots li {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 0;
}
.dot-slider .slick-dots li button {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #fff;
}
.dot-slider .slick-dots li button:before {
  display: none;
  font-family: "slick";
  font-size: 76px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "-";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dot-slider .slick-dots li.slick-active button {
  background-color: #1860CA;
  background-color: #86E53E;
}
.dot-slider .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #0071DC;
}
.dot-slider.slick-vertical .slick-slide {
  height: 589px;
  border: 0px solid transparent;
}
.dot-slider .slick-slide {
  outline: none !important;
}
.dot-slider.slick-dotted.slick-slider {
  margin-bottom: 0px;
}
.dot-slider .slick-track {
  height: 589px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  position: absolute;
  bottom: -25px;
  display: block;
  width: auto;
  transform: translate(-50px, 0);
  left: 50%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 5px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: #C0C0C0;
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:before {
  font-family: "slick";
  font-size: 40px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: #86E53E;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #86E53E;
}

.dot-slider .slick-dots li button:hover,
.dot-slider .slick-dots li button:focus {
  outline: none;
}

.dot-slider .slick-dots li button:hover:before,
.dot-slider .slick-dots li button:focus:before {
  opacity: 1;
}

.home-three .slick-prev,
.home-three .slick-next {
  background: #fff;
  width: 40px;
  height: 40px;
  top: inherit;
  bottom: 35px;
  border-radius: 50px;
  border: 1px solid #EBEBEB;
}

.home-three .slick-prev:hover:before,
.home-three .slick-next:hover:before {
  filter: invert(0) brightness(100);
}

.home-three .slick-prev:before,
.home-three .slick-next:before {
  content: "";
  background-image: url(../../assets/img/pre.svg);
  background-repeat: no-repeat;
  position: absolute;
  width: 13px;
  height: 13px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  -ms-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
  background-size: cover;
}

.banner-kofe {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.banner-kofe .banner-content .rating .checked {
  color: #FFC700;
}
.banner-kofe .banner-content h5, .banner-kofe .banner-content .h5 {
  color: #fff;
}

.home-three .banner-content .form-inner,
.home-three .banner-content .sub-btn {
  border-radius: 5px !important;
}

.company-hire {
  padding: 60px 0px;
  background: #86E53E;
}
.company-hire .company-logos {
  background: #fff;
  padding: 7px 0px;
  border: 1px solid #EBEBEB;
  border-radius: 4px;
}

.hire-heading h6, .hire-heading .h6 {
  color: #fff;
}

.section-header-three .header-title,
.section-header-four .header-title {
  margin-bottom: 10px;
  color: #000000;
}

.different-categories {
  padding: 80px 0px 55px;
}

.different-categories-blk {
  background: #FFFFFF;
  margin-bottom: 25px;
  width: 100%;
  border: 1px solid #EBEBEB;
  border-radius: 5px;
  padding: 15px;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.different-categories-blk:hover {
  border: 1px solid #EBEBEB;
  box-shadow: -10px 54px 44px rgba(218, 218, 218, 0.25);
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.category-foot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.category-foot a {
  border: 1px solid #EBEBEB;
  width: 31px;
  height: 31px;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.category-foot a:hover,
.feature-developer-foot a:hover {
  background: #13323C;
  border: 1px solid #13323C;
  color: #fff;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.differ-img {
  margin-bottom: 20px;
}

.differ-content h6, .differ-content .h6 {
  color: #2E2E2E;
  margin-bottom: 10px;
}
.differ-content p {
  margin-bottom: 25px;
}

.differ-content p,
.category-foot span {
  color: #5F5F5F;
  font-weight: 500;
  font-size: 14px;
}

.best-marketplace {
  padding: 80px 0px;
  background: #FBFBFB;
}

.market-place-group h2, .market-place-group .h2 {
  color: #000000;
  font-weight: 700;
  margin-bottom: 30px;
}
.market-place-group h2 span, .market-place-group .h2 span {
  color: #86E53E;
}
.market-place-group h2 .platform-head, .market-place-group .h2 .platform-head {
  color: #2E2E2E;
  display: block;
}
.market-place-group p {
  color: #515151;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}
.market-place-group.join-place-blk h2 span, .market-place-group.join-place-blk .h2 span {
  color: #2B2B2B;
}
.market-place-group .platform-pasage {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 25px;
}

.market-list-out {
  margin-bottom: 35px;
}
.market-list-out li {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
  list-style: none;
}

.market-place-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.market-place-btn .btn-primary {
  min-width: 137px;
  padding: 10px 0px;
  font-weight: 600;
  font-size: 15px;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.market-place-btn .btn-primary:hover {
  background: #13323C;
  border-color: #13323C;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.market-place-btn .market-developer {
  border: 1px solid #101828;
  border-radius: 5px;
  background: transparent;
  color: #000000;
  font-size: 15px;
}
.market-place-btn .market-developer:hover {
  border: 1px solid #101828;
  background: #101828;
  color: #fff;
}
.market-place-btn.platform-btn .btn-primary {
  min-width: 200px;
  padding: 10px 0px;
  font-weight: 600;
}
.market-place-btn.platform-btn .project-post {
  background: #101828;
  border: 1px solid #2E2E2E;
}
.market-place-btn.platform-btn .project-post:hover {
  background: #86E53E;
  border: 1px solid #86E53E;
}
.market-place-btn.bidding-btn .btn-primary {
  min-width: 157px;
}
.market-place-btn.bidding-btn .project-post {
  background: #101828;
  border: 1px solid #101828;
}
.market-place-btn.bidding-btn .project-post:hover {
  background: #86E53E;
  border: 1px solid #86E53E;
}
.market-place-btn.bidding-btn .market-project:hover {
  background: #13323C;
  border: 1px solid #13323C;
}
.market-place-btn.company-register-btn .market-project {
  min-width: 177px;
}
.market-place-btn.company-register-btn .market-project:hover {
  background: #13323C;
  border: 1px solid #13323C;
}
.market-place-btn.company-register-btn .project-post {
  min-width: 206px;
  background: #101828;
  border: 1px solid #101828;
}
.market-place-btn.company-register-btn .project-post:hover {
  background: #86E53E;
  border: 1px solid #86E53E;
}

.trend-project-item .project-img {
  padding: 0;
  margin-bottom: 10px;
}

.trend-project-content h5, .trend-project-content .h5 {
  color: #86E53E;
  margin-bottom: 10px;
  font-size: 14px;
}
.trend-project-content h4, .trend-project-content .h4 {
  font-size: 18px;
  color: #101828;
  font-weight: 600;
  margin-bottom: 15px;
}

.trend-project-list {
  border-bottom: 1px solid #EBEBEB;
  margin-bottom: 15px;
  padding-bottom: 20px;
}
.trend-project-list li {
  list-style: none;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.trend-project-list li + li {
  margin-top: 12px;
}

.trend-rate .nav {
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.trend-rate li {
  color: #515151;
  font-size: 14px;
}
.trend-rate li span {
  color: #86E53E;
  font-weight: 600;
}

.owl-carousel.trend-slider .owl-nav .owl-prev,
.owl-carousel.trend-slider .owl-nav .owl-next {
  z-index: 9;
}

.owl-carousel.trend-slider .owl-nav .owl-prev:hover,
.owl-carousel.trend-slider .owl-nav .owl-next:hover {
  background: transparent;
  color: #000;
}

.trend-slider.owl-theme .owl-dots .owl-dot span {
  background: #D9D9D9;
}
.trend-slider.owl-theme .owl-dots .owl-dot.active span {
  background: #263238;
}

.feature-count-head .header-title {
  font-size: 24px;
  color: #fff;
}

.feature-count {
  position: relative;
  padding-bottom: 20px;
  padding-top: 50px;
  background: #86E53E;
}
.feature-count .feature-content {
  margin-top: 0;
}
.feature-count .feature-content h3, .feature-count .feature-content .h3 {
  color: #fff;
  margin-bottom: 3px;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.feature-count .feature-content p {
  color: #FFF5F1;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.feature-count .feature-icon {
  margin-bottom: 25px;
  position: relative;
}
.feature-count .feature-item {
  background: transparent;
  box-shadow: none;
  border: 0;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.feature-count .feature-item:hover {
  background: #fff;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.feature-count .feature-item:hover img {
  filter: invert(64%) sepia(215%) saturate(478%) hue-rotate(266deg) brightness(68%) contrast(628%);
}
.feature-count .feature-item:hover .feature-content h3, .feature-count .feature-item:hover .feature-content .h3 {
  color: #101828;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.feature-count .feature-item:hover .feature-content p {
  color: #5F5F5F;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.heart-blk .favourite {
  width: 28px;
  height: 28px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(206, 206, 206, 0.25);
  border: 0;
  left: 10px;
  right: unset;
  top: 10px;
}
.heart-blk .favourite.three-active {
  color: #fff;
  background: #86E53E;
  border: 1px solid #86E53E;
}

.project-item-feature .project-img {
  padding: 0;
  position: relative;
}

.feaure-project {
  padding: 80px 0px;
}

.feature-project-content {
  padding: 20px 0px 10px;
}
.feature-project-content h4, .feature-project-content .h4 {
  margin-bottom: 5px;
  font-size: 18px;
  color: #353535;
}
.feature-project-content p {
  color: #515151;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}
.feature-project-content .rating {
  margin-bottom: 20px;
}

.feature-developer-foot a {
  border: 1px solid #EBEBEB;
  width: 32px;
  height: 32px;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.circle-active {
  background-color: #fff;
  color: #38B653;
  font-size: 16px;
  position: absolute;
  right: 10px;
  top: 14px;
  border-radius: 100%;
  z-index: 2;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.hour-dollr {
  background: #FFFFFF;
  border-radius: 5px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 14px;
  font-weight: 500;
  padding: 3px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.hour-dollr.develop-dollr {
  right: unset;
  left: 10px;
}

.blog {
  margin-bottom: 30px;
  padding: 1.5rem;
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 4px 48px rgba(160, 160, 160, 0.1);
  border-radius: 6px;
}
.blog.blog-trick {
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  box-shadow: 0px 4px 34px rgba(233, 233, 233, 0.25);
  border-radius: 10px;
  padding: 20px;
  box-shadow: none;
}
.blog.grid-blog {
  padding: 0px;
}
.blog.grid-blog .blog-content {
  padding: 15px;
}

.blog-trick .blog-image {
  margin-bottom: 20px;
  border-radius: 5px;
}
.blog-trick .blog-image img {
  border-radius: 5px;
}

.blog-trick-content p {
  color: #5F5F5F;
  margin-bottom: 30px;
}
.blog-trick-content h3, .blog-trick-content .h3 {
  font-size: 20px;
  color: #2B2B2B;
}

.post-author.blog-hover-img img {
  width: 45px !important;
  height: 45px;
}
.post-author.blog-hover-img a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.post-author a {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  align-items: center;
}
.post-author img {
  border-radius: 100%;
  width: 28px !important;
  margin-right: 7px;
}

.post-author-three span {
  color: #2B2B2B;
  font-weight: 500;
  font-size: 14px;
}

.skill-head.skill-head-two .nav-pills .nav-link.active,
.skill-head.skill-head-two .nav-pills .show > .nav-link {
  color: #101828;
}

.next-job {
  padding: 80px 0;
  background-image: url(../../assets/img/bg/bg-23.jpg);
  background-repeat: no-repeat, no-repeat;
  background-position: right bottom;
  background-size: cover;
  position: relative;
}
.next-job:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #86E53E;
  opacity: 0.97;
  top: 0;
}

.job-next-blk h2, .job-next-blk .h2 {
  color: #fff;
  font-size: 32px;
  margin-bottom: 20px;
}
.job-next-blk p {
  color: #FFF5F1;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 35px;
}

.next-job-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.next-job-btn .btn-primary {
  font-size: 15px;
  font-weight: 600;
  min-width: 158px;
  padding: 10px 15px;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.next-job-btn .btn-primary.next-sign {
  background: #fff;
  border: 1px solid #fff;
  color: #101828;
}
.next-job-btn .btn-primary.next-sign:hover {
  background: transparent;
  color: #fff;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.next-job-btn .btn-primary.next-register {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}
.next-job-btn .btn-primary.next-register:hover {
  background: #fff;
  border: 1px solid #fff;
  color: #101828;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.next-job-btn.next-with-job .next-register {
  background: #101828;
  border: 1px solid #FFFFFF;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.next-job-btn.next-with-job .next-register:hover {
  background: #fff;
  border: 1px solid #fff;
  color: #101828;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.next-job-btn.next-with-job .next-sign:hover {
  background: #13323C;
  border: 1px solid #13323C;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.next-job-btn.next-reg-now {
  justify-content: start;
  -webkit-justify-content: start;
  -ms-flex-pack: start;
}
.next-job-btn.next-reg-now .next-sign {
  background: #86E53E;
  border: 1px solid #86E53E;
  color: #fff;
}
.next-job-btn.next-reg-now .next-sign:hover {
  background: #13323C;
  border: 1px solid #13323C;
}
.next-job-btn.next-reg-now .next-register {
  background: #101828;
  border: 1px solid #101828;
  color: #fff;
}
.next-job-btn.next-reg-now .next-register:hover {
  background: #86E53E;
  border: 1px solid #86E53E;
  color: #fff;
}

.review-people {
  padding: 80px 0 130px;
  background-color: #F7F7F7;
  background-image: url(../../assets/img/bg/bg-17.png);
  background-repeat: no-repeat, no-repeat;
  background-position: right bottom;
  background-size: cover;
}
.review-people .owl-carousel.trend-slider .owl-dots {
  position: absolute;
  margin: 0;
  left: 50%;
  min-width: 90px;
  bottom: -42px;
  top: inherit;
  right: inherit;
  -webkit-transform: translate(-50%, 50%);
  -ms-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}
.review-people .owl-carousel.trend-slider .owl-nav {
  left: 46%;
  bottom: -62px;
  min-width: 90px;
  top: inherit;
  right: inherit;
}

.review-love-group {
  max-width: 680px;
  margin: auto;
}

.review-passage {
  text-align: center;
  max-width: 600px;
  margin: auto;
  margin-bottom: 40px;
}

.quote-love-img {
  margin-bottom: 45px;
}
.quote-love-img img {
  margin: auto;
}

.review-profile-blk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #FFFFFF;
  border-radius: 110px;
  padding: 8px;
  margin-right: 15px;
  min-width: 200px;
}

.review-name-blk h6, .review-name-blk .h6 {
  margin-bottom: 2px;
}
.review-name-blk h6 a, .review-name-blk .h6 a {
  color: #000000;
}
.review-name-blk p {
  color: #515151;
  font-size: 14px;
  margin-bottom: 0;
}

.review-love-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.scroll-top {
  width: 50px;
  height: 50px;
  line-height: 50px;
  position: fixed;
  bottom: 105%;
  right: 35px;
  border-radius: 10px;
  z-index: 9;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  background: #86E53E;
  transition: 1s ease;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.scroll-top.scroll-top-next {
  -webkit-transform: rotate(46deg);
  -ms-transform: rotate(46deg);
  transform: rotate(46deg);
  background: #fff;
  border-radius: 0;
  box-shadow: 0px 4px 20px rgba(224, 224, 224, 0.4);
}
.scroll-top.scroll-top-next:hover {
  background: #86E53E;
}
.scroll-top.scroll-top-next:hover span {
  color: #fff;
}
.scroll-top.scroll-top-next span {
  -webkit-transform: rotate(-46deg);
  -ms-transform: rotate(-46deg);
  transform: rotate(-46deg);
  color: #161515;
}
.scroll-top.open {
  bottom: 30px;
}
.scroll-top:hover {
  background: #13323C;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.scroll-top span {
  color: #fff;
  line-height: 0;
}

.home-four {
  background-image: url(../img/banner5.jpg);
  padding: 0;
  margin-top: 85px;
  min-height: 580px;
}
.home-four:before {
  content: "";
  position: absolute;
  background-image: url(../../assets/img/bg/bg-24.svg);
  background-repeat: no-repeat;
  background-position: left center;
  width: 120px;
  height: 250px;
  background-size: 95%;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.home-four .banner-content h5, .home-four .banner-content .h5 {
  font-weight: 600;
  font-size: 20px;
  color: #86E53E;
  text-transform: unset;
  margin-bottom: 15px;
}
.home-four .banner-content h1, .home-four .banner-content .h1 {
  font-weight: bold;
  font-size: 48px;
  color: #2E2E2E;
  margin-bottom: 40px;
}
.home-four .banner-content .form-control::placeholder {
  color: #9d9d9d;
}
.home-four .banner-content .sub-btn {
  background: #2B2B2B;
  border-radius: 5px !important;
  border: 1px solid #2B2B2B;
}
.home-four .banner-content .sub-btn:hover {
  background: #86E53E;
  border: 1px solid #86E53E;
}

.banner-blk-four {
  min-height: 580px;
  width: 100%;
  display: inline-grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.home-four .banner-content .form-inner,
.home-four .banner-content .sub-btn {
  border-radius: 5px !important;
}

.fullstack-blk {
  background: #FFFFFF;
  box-shadow: 0px 4px 64px rgba(143, 143, 143, 0.25);
  border-radius: 10px;
  padding: 15px;
  max-width: 234px;
  text-align: center;
  position: absolute;
  top: 120px;
  right: 30px;
}

.stacker-active {
  background-color: #fff;
  color: #38B653;
  font-size: 14px;
  position: absolute;
  right: 10px;
  bottom: 0px;
  border-radius: 100%;
  z-index: 2;
  width: 19px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.fullstacker-img {
  width: 90px;
  height: 90px;
  position: relative;
  margin: auto;
  margin-bottom: 20px;
}
.fullstacker-img img {
  border-radius: 50px;
}

.fullstacker-name h4, .fullstacker-name .h4 {
  color: #2B2B2B;
  font-weight: 600;
}
.fullstacker-name p {
  color: #515151;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 2px;
}

.coplete-job {
  background: #FFFFFF;
  border-radius: 10px;
  position: absolute;
  top: 40px;
  left: 42%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px;
}

.complete-icon {
  width: 60px;
  height: 60px;
  background: #86E53E;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.complete-icon i {
  font-size: 20px;
  color: #fff;
}

.complete-content h3, .complete-content .h3 {
  font-weight: 700;
  color: #2E2E2E;
  margin-bottom: 5px;
}
.complete-content p {
  font-weight: 500;
  color: #5F5F5F;
  font-size: 14px;
  margin-bottom: 0;
}

.register-profesion {
  background: #FFFFFF;
  border-radius: 10px;
  position: absolute;
  bottom: 40px;
  left: 37%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px;
}
.register-profesion p {
  margin-bottom: 0;
  color: #000000;
  font-weight: 600;
  margin-right: 15px;
}
.register-profesion .avatar-group .avatar {
  width: 48px;
  height: 48px;
  border: 3px solid #fff;
  border-radius: 50px;
}
.register-profesion .avatar-group .avatar-xs + .avatar-xs {
  margin-left: -20px;
}

.browse-categories {
  padding: 80px 0px 55px;
}

.browse-circle-blk {
  background: #F5F4F8;
  padding: 5px;
  margin-bottom: 25px;
  border-radius: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.browse-circle-blk:hover {
  box-shadow: 0px 4px 24px rgba(215, 215, 215, 0.25);
}

.move-box {
  -webkit-transition: top ease 0.5s;
  transition: top ease 0.5s;
  position: relative;
  top: 0;
}
.move-box:hover {
  top: -6px;
}

.browse-icon {
  background: #FFFFFF;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.browse-content h6, .browse-content .h6 {
  color: #2E2E2E;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;
}
.browse-content p {
  color: #5F5F5F;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}

.what-about-it {
  background: #FBFBFB;
  padding: 80px 0px 55px;
}

.about-it-item {
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  box-shadow: 0px 4px 24px rgba(215, 215, 215, 0.25);
  border-radius: 9px;
  padding: 30px;
  width: 100%;
  margin-bottom: 25px;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.about-it-item:hover {
  box-shadow: 0px 15px 30px rgba(215, 215, 215, 0.5);
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.about-it-icon {
  background: linear-gradient(270.05deg, #F8ECF8 -28.55%, #FFF3EA 112.03%);
  width: 70px;
  height: 70px;
  margin-bottom: 15px;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.about-great-content h4, .about-great-content .h4 {
  color: #000000;
  font-weight: 600;
  margin-bottom: 10px;
}
.about-great-content p {
  color: #515151;
  margin-bottom: 0px;
  font-size: 14px;
}

.feaure-for-developer {
  padding: 80px 0px 55px;
  background-image: url(../../assets/img/bg/bg-25.png), url(../../assets/img/bg/bg-25.png);
  background-repeat: no-repeat, no-repeat;
  background-position: right center, left center;
}

.develop-dollr img {
  width: auto;
}

.foot-profile-btn .btn-primary {
  border-radius: 11px;
  font-size: 15px;
  font-weight: 600;
  padding: 8px 15px;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.foot-profile-btn .btn-primary:hover {
  background-color: #13323C;
  border: 1px solid #13323C;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.join-marketplace {
  background: #FBFBFB;
  padding: 80px 0;
  background-image: url(../../assets/img/bg/bg-26.png);
  background-repeat: no-repeat, no-repeat;
  background-position: right center;
  background-size: 170px;
}

.popular-projects {
  padding: 80px 0px 55px;
}

.popular-group {
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  border-radius: 10px;
  padding: 15px;
}
.popular-group .blog-image {
  border-radius: 10px;
  margin-bottom: 20px;
}

.head-popular {
  margin-bottom: 25px;
}
.head-popular h4, .head-popular .h4 {
  color: #101828;
  font-weight: 600;
  font-size: 18px;
}
.head-popular p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #5F5F5F;
  font-size: 14px;
  margin-bottom: 0;
}

.popular-list-box {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.popular-list-box ul li {
  background: #FFF5F1;
  border-radius: 4px;
  font-size: 14px;
  color: #474747;
  font-weight: 500;
  padding: 6px 15px;
  min-width: 88px;
  margin-right: 10px;
  text-align: center;
}
.popular-list-box ul li:last-child {
  margin-right: 0px;
}
.popular-list-box h4, .popular-list-box .h4 {
  color: #86E53E;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}
.popular-list-box h4 span, .popular-list-box .h4 span {
  color: #5F5F5F;
  font-size: 15px;
  font-weight: 500;
  padding-left: 5px;
}

.popular-foot {
  color: #5F5F5F;
  font-weight: 500;
  margin-bottom: 0;
  font-size: 15px;
}

.popular-slider.developers-slider.owl-carousel .owl-nav button.owl-next,
.popular-slider.developers-slider.owl-carousel .owl-nav button.owl-prev {
  transform: none;
  background: #FFF5F1;
  border: 1px solid #EBEBEB;
  width: 44px;
  height: 44px;
  border-radius: 50px !important;
  box-shadow: none;
}

.popular-slider.developers-slider.owl-carousel .owl-nav button.owl-next:hover,
.popular-slider.developers-slider.owl-carousel .owl-nav button.owl-prev:hover {
  background: #86E53E;
  border: 1px solid #86E53E;
}

.popular-slider.developers-slider.owl-carousel .owl-nav button.owl-next i,
.popular-slider.developers-slider.owl-carousel .owl-nav button.owl-prev i {
  transform: none;
  color: #86E53E;
}

.popular-slider.developers-slider.owl-carousel .owl-nav button.owl-next:hover i,
.popular-slider.developers-slider.owl-carousel .owl-nav button.owl-prev:hover i {
  color: #fff;
}

.popular-slider.developers-slider .owl-nav {
  bottom: 100%;
  right: 18%;
  margin-bottom: 55px;
}

.achive-feature {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.achive-feature .feature-content p {
  margin-bottom: 0;
}
.achive-feature .feature-content h4, .achive-feature .feature-content .h4 {
  color: #fff;
  margin-bottom: 5px;
}
.achive-feature:hover .feature-content h4, .achive-feature:hover .feature-content .h4 {
  color: #101828;
}

.review-four {
  background: #FFF5F1;
  padding: 80px 0px 150px;
  position: relative;
  background-image: url(../../assets/img/bg/bg-28.png);
  background-repeat: no-repeat, no-repeat;
  background-position: left center;
}
.review-four .testimonial-thumb {
  padding: 10px 0;
}
.review-four .testimonial-thumb img {
  width: 58px;
  border-radius: 60px;
}
.review-four .slider-nav {
  width: 300px;
  margin: auto;
}
.review-four .slider-nav .slick-current .testimonial-thumb {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
}
.review-four .slider-nav .slick-current .testimonial-thumb img {
  border: 2px solid #86E53E;
  border-radius: 50px;
  padding: 2px;
}
.review-four .slick-list {
  padding: 0 !important;
}
.review-four .slick-prev {
  left: 0;
  z-index: 99;
  background: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: none;
  top: 50%;
}
.review-four .slick-prev:hover {
  background: #86E53E;
}
.review-four .slick-prev:before {
  content: "\f053";
  font-family: "Font Awesome 5 Free";
  font-size: 18px;
  font-weight: 900;
}
.review-four .slick-next {
  right: 0;
  background: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  top: 50%;
  display: none;
}
.review-four .slick-next:hover {
  background: #86E53E;
}
.review-four .slick-next:before {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-size: 18px;
  font-weight: 900;
}
.review-four .slick-slide {
  width: 70px;
}
.review-four .client-img {
  position: absolute;
  left: 50%;
  bottom: 70px;
  -webkit-transform: translate(-50%, 0) !important;
  -ms-transform: ttranslate(-50%, 0) !important;
  transform: translate(-50%, 0) !important;
}
.review-four .client-img img {
  margin: auto;
}
.review-four .review-passage {
  font-weight: 600;
}

.review-four .slick-prev:before,
.slick-next:before {
  color: #000;
}

.review-four .slick-prev:hover,
.review-four .slick-next:hover,
.review-four .swiper-button-prev:hover,
.review-four .swiper-button-next:hover {
  color: #fff;
  opacity: 1;
}

.review-four .slick-prev:hover:before,
.review-four .slick-prev:focus:before,
.review-four .slick-next:hover:before,
.review-four .slick-next:focus:before,
.review-four .swiper-button-prev:hover:before,
.review-four .swiper-button-next:hover:before {
  color: #fff;
  opacity: 1;
}

.review-four .slick-next:focus,
.review-four .slick-prev:focus {
  color: #000;
  background: #86E53E;
}

.review-four .slick-prev:before,
.review-four .slick-next:before {
  color: #000;
}

.say-name-blk {
  margin-bottom: 40px;
}
.say-name-blk h6, .say-name-blk .h6 {
  color: #353535;
  font-weight: 600;
}
.say-name-blk p {
  color: #515151;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}

.platform-location {
  background: #FBFBFB;
}

.blog-article {
  padding: 80px 0px 55px;
}

.blog-article-group {
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  box-shadow: 0px 4px 34px rgba(233, 233, 233, 0.25);
  border-radius: 10px;
  padding: 8px;
}
.blog-article-group .blog-image {
  border-radius: 10px;
  margin-bottom: 0;
  position: relative;
}
.blog-article-group .blog-image:before {
  content: "";
  background: linear-gradient(358.04deg, rgba(0, 0, 0, 0.85) 20.78%, rgba(0, 0, 0, 0) 84.46%);
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  z-index: 1;
}

.article-blog-content {
  position: absolute;
  bottom: 0px;
  z-index: 2;
  padding: 20px;
}
.article-blog-content span {
  color: #FFFFFF;
  font-size: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}
.article-blog-content h4, .article-blog-content .h4 {
  font-size: 18px;
  margin-bottom: 20px;
}
.article-blog-content h4:hover a, .article-blog-content .h4:hover a {
  color: #86E53E;
}
.article-blog-content h4 a, .article-blog-content .h4 a {
  color: #fff;
}
.article-blog-content p {
  color: #EBEBEB;
  font-size: 15px;
  margin-bottom: 0px;
  font-weight: 500;
}

.article-Production {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 9;
}
.article-Production span {
  background: #86E53E;
  border-radius: 10px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.top-company-four {
  padding-bottom: 55px;
}
.top-company-four .company-logos {
  border: 1px solid #EBEBEB;
  background: #fff;
  padding: 7px 0px;
  border-radius: 4px;
}

.next-great-job {
  background: linear-gradient(270.05deg, #F8ECF8 -28.55%, #FFF3EA 112.03%);
  padding: 80px 0px;
}
.next-great-job:before {
  content: "";
  position: absolute;
  width: 160px;
  height: 160px;
  background-image: url(../../assets/img/bg/bg-27.png);
  background-repeat: no-repeat, no-repeat;
  background-position: right center;
  right: 60px;
  background-size: 140px;
}
.next-great-job .job-next-blk h2, .next-great-job .job-next-blk .h2 {
  color: #2E2E2E;
  font-size: 32px;
  margin-bottom: 20px;
}
.next-great-job .job-next-blk p {
  color: #515151;
}

.trust-slider.developers-slider.owl-carousel .owl-nav button.owl-next,
.trust-slider.developers-slider.owl-carousel .owl-nav button.owl-prev {
  transform: none;
  background: #fff;
  border: 1px solid #EBEBEB;
  width: 44px;
  height: 44px;
  border-radius: 50px !important;
  box-shadow: none;
}

.trust-slider.developers-slider.owl-carousel .owl-nav button.owl-next:hover,
.trust-slider.developers-slider.owl-carousel .owl-nav button.owl-prev:hover {
  background: #86E53E;
  border: 1px solid #86E53E;
}

.trust-slider.developers-slider.owl-carousel .owl-nav button.owl-next i,
.trust-slider.developers-slider.owl-carousel .owl-nav button.owl-prev i {
  transform: none;
  color: #86E53E;
}

.trust-slider.developers-slider.owl-carousel .owl-nav button.owl-next:hover i,
.trust-slider.developers-slider.owl-carousel .owl-nav button.owl-prev:hover i {
  color: #fff;
}

.trust-slider.developers-slider .owl-nav {
  bottom: 100%;
  right: 0;
  margin-bottom: 33px;
}

.home-five.home-banner {
  background-image: url(../img/banner6.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 0px;
}
.home-five.home-banner:before {
  content: "";
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 86.88%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  width: 100%;
  height: 100%;
}
.home-five .banner-content h1, .home-five .banner-content .h1 {
  color: #FFF5F1;
}
.home-five .banner-content h5, .home-five .banner-content .h5 {
  color: #FFFFFF;
  margin-bottom: 0;
}
.home-five .banner-content p {
  color: #FFFFFF;
  margin-bottom: 40px;
}
.home-five .banner-content .sub-btn {
  height: 52px;
  min-width: 52px;
  border-radius: 10px !important;
  padding: 10px 12px;
}
.home-five .banner-content .sub-btn i {
  font-size: 25px;
}
.home-five .banner-content .form-control::placeholder {
  color: #9D9D9D;
}

.banner-blk-five {
  min-height: 650px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.banner-blk-five .rating {
  margin-bottom: 10px;
}
.banner-blk-five .rating .checked {
  color: #FDC232;
}
.banner-blk-five .home-count ul {
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.banner-blk-five .home-count ul li,
.banner-blk-five .home-count ul li .list-count {
  color: #FFFFFF;
}

.home-five .banner-content .form-inner,
.home-five .banner-content .input-group {
  border-radius: 4px;
}

.need-job {
  padding: 80px 0px 0px;
  background-image: url(../../assets/img/bg/bg-29.png);
  background-repeat: no-repeat, no-repeat;
  background-position: left 20%, top;
}

.section-header-five .header-title {
  margin-bottom: 5px;
  color: #2e2e2e;
}
.section-header-five p {
  color: #515151;
  margin-bottom: 0px;
  padding-bottom: 20px;
}
.section-header-five:before {
  content: "";
  width: 31px;
  height: 4px;
  background: #86E53E;
  bottom: 0;
  left: 49%;
  position: absolute;
  border-radius: 50px;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.section-header-five:after {
  content: "";
  width: 14px;
  height: 4px;
  background: #86E53E;
  bottom: 0;
  left: 52%;
  position: absolute;
  border-radius: 50px;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.need-job-group {
  padding-bottom: 55px;
  border-bottom: 1px solid #ebebeb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.need-job-group .about-it-item {
  background: #FFFFFF;
  border: 0;
  border-right: 1px solid #EBEBEB;
  box-shadow: none;
  border-radius: 0;
  padding: 0px 30px;
  width: 100%;
  margin-bottom: 25px;
}
.need-job-group .about-it-item:last-child {
  border-right: 0;
}
.need-job-group .about-it-item:hover {
  box-shadow: none;
}
.need-job-group .about-great-content h4, .need-job-group .about-great-content .h4 {
  color: #2e2e2e;
}
.need-job-group .about-great-content p {
  color: #353535;
}

.need-job-icon {
  margin-bottom: 20px;
}

.popular-projects-job {
  padding: 80px 0px;
  background-image: url(../../assets/img/bg/bg-30.png), url(../../assets/img/bg/bg-31.png);
  background-repeat: no-repeat, no-repeat;
  background-position: right top, left center;
}

.recent-categories {
  padding: 80px 0px;
  background: linear-gradient(113.89deg, #FFFCFB 51.94%, #FFDCD4 100.96%, #FFFFFF 100.97%);
  position: relative;
  z-index: 1;
}
.recent-categories:before {
  content: "";
  background-image: url(../../assets/img/bg/bg-32.png);
  background-repeat: no-repeat, no-repeat;
  background-position: left center;
  position: absolute;
  width: 170px;
  height: 220px;
  left: 0;
  top: 20%;
  z-index: -1;
  background-size: 145px;
}

.update-icon-end i {
  color: #9D9D9D;
}

.profesion-job {
  padding: 80px 0px;
  background-image: url(../../assets/img/bg/bg-34.png);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 145px;
}

.demand-post-job {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.demand-post-img {
  margin-right: 20px;
  flex-shrink: 0;
  width: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.demand-professional {
  flex-grow: 1;
}
.demand-professional h2, .demand-professional .h2 {
  color: #2E2E2E;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 15px;
}
.demand-professional p {
  color: #515151;
  font-weight: 500;
  margin-bottom: 20px;
}

.demand-content h6, .demand-content .h6 {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
.demand-content p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}

.market-place-join {
  padding: 0px 0px 80px;
}

.market-place-bg {
  background: #FBFBFB;
  padding-top: 45px;
}

.join-market-world {
  padding-left: 100px;
}
.join-market-world h2, .join-market-world .h2 {
  color: #101828;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 30px;
}
.join-market-world p {
  color: #515151;
  font-weight: 600;
  margin-bottom: 50px;
}

.join-platform-img:before {
  content: "";
  position: absolute;
  background-image: url(../../assets/img/bg-market.png);
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: right bottom;
}

.join-platform-img,
.join-platform-img img {
  position: relative;
}

.talent-developer {
  padding: 80px 0px;
  background: #FBFBFB;
}
.talent-developer:before {
  content: "";
  background-image: url(../../assets/img/bg/bg-33.png);
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 250px;
  width: 260px;
  height: 220px;
  position: absolute;
  right: 40px;
}

.freelance-widget {
  background-color: #fff;
  text-align: center;
  position: relative;
  margin-bottom: 25px;
  z-index: 1;
  border: 1px solid #EBEBEB;
  box-shadow: 0px 4px 48px rgba(160, 160, 160, 0.1);
  border-radius: 6px;
  padding: 10px;
}
.freelance-widget.talent-widget {
  background: #FFFFFF;
  box-shadow: 0px 4px 34px rgba(220, 220, 220, 0.25);
  border-radius: 10px;
  border: 0;
}
.freelance-widget .favourite.color-active {
  animation: size 0.4s;
  background: #86E53E;
  color: #fff;
}
.freelance-widget .rating {
  margin-bottom: 15px;
}
.freelance-widget:hover .freelancers-price {
  color: #159C5B;
}

.talent-widget .freelance-img img {
  margin: auto;
  width: 106px;
  height: 106px;
  border-radius: 50%;
  border: 0;
  padding: 0;
}
.talent-widget .freelance-tags {
  border-bottom: 1px solid #EBEBEB;
  padding-bottom: 10px;
}
.talent-widget .freelance-content {
  padding-bottom: 10px;
}
.talent-widget.freelance-widget .rating {
  margin-bottom: 15px;
}
.talent-widget .freelance-specific {
  margin-bottom: 5px;
}

.talent-foot-list ul {
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.talent-foot-list ul li {
  text-align: left;
  margin-right: 20px;
  color: #2E2E2E;
  font-size: 14px;
  font-weight: 500;
}
.talent-foot-list ul li:last-child {
  margin-right: 0px;
}
.talent-foot-list ul li span {
  display: block;
  color: #515151;
  font-weight: 400;
}

.compay-hire {
  padding: 80px 0px;
  background-image: url(../../assets/img/bg/bg-35.png);
  background-repeat: no-repeat;
  background-position: right center;
}

.compay-hire-blk h3, .compay-hire-blk .h3 {
  color: #2E2E2E;
  font-weight: 700;
  margin-bottom: 15px;
}
.compay-hire-blk p {
  font-weight: 500;
  margin-bottom: 25px;
}

.hire-company-list ul li {
  padding: 10px 30px;
  width: 180px;
  min-height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-evenly;
  -webkit-justify-content: space-evenly;
  -ms-flex-pack: space-evenly;
  border: 1px solid #EBEBEB;
}

.hire-company-list ul li:nth-child(1),
.hire-company-list ul li:nth-child(2),
.hire-company-list ul li:nth-child(3),
.hire-company-list ul li:nth-child(4),
.hire-company-list ul li:nth-child(5),
.hire-company-list ul li:nth-child(6),
.hire-company-list ul li:nth-child(7),
.hire-company-list ul li:nth-child(8),
.hire-company-list ul li:nth-child(9) {
  border-top: 0;
  border-left: 0;
}

.hire-company-list ul li:nth-child(3),
.hire-company-list ul li:nth-child(6),
.hire-company-list ul li:nth-child(9) {
  border-right: 0;
}

.hire-company-list ul li:nth-child(7),
.hire-company-list ul li:nth-child(8),
.hire-company-list ul li:nth-child(9) {
  border-bottom: 0;
}

.review-five {
  padding: 80px 0px;
  background: linear-gradient(113.89deg, #FFFCFB 51.94%, #FFDCD4 100.96%, #FFFFFF 100.97%);
  position: relative;
}
.review-five:before {
  content: "";
  background-image: url(../../assets/img/bg/bg-32.png);
  background-repeat: no-repeat, no-repeat;
  background-position: left center;
  position: absolute;
  width: 170px;
  height: 220px;
  left: 0;
  top: 20%;
  background-size: 145px;
}
.review-five .testimonial-all {
  margin-top: 30px;
  padding-bottom: 50px;
  position: relative;
}
.review-five .testimonial-thumb {
  padding: 20px 0;
}
.review-five .testimonial-thumb img {
  width: 60px;
  border-radius: 60px;
}
.review-five .slider-nav {
  width: 370px;
  margin-left: auto;
}
.review-five .slider-nav .slick-current .testimonial-thumb {
  transform: scale(1.4);
  -webkit-transform: scale(1.4);
  -ms-transform: scale(1.4);
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
}
.review-five .slick-list {
  padding: 0 !important;
}
.review-five .client-img {
  position: absolute;
  right: 0px;
  bottom: -30px;
}
.review-five .client-img img {
  margin: auto;
}
.review-five .slick-prev {
  left: 25%;
  z-index: 99;
  background: #fff;
  width: 44px;
  height: 44px;
  border-radius: 50px;
  bottom: 0px;
  top: inherit;
  border: 1px solid #EBEBEB;
}
.review-five .slick-prev:before {
  content: "\f053";
  font-family: "Font Awesome 5 Free";
  font-size: 18px;
  font-weight: 900;
}
.review-five .slick-next {
  right: 65%;
  background: #fff;
  width: 44px;
  height: 44px;
  border-radius: 50px;
  bottom: 0px;
  top: inherit;
  border: 1px solid #EBEBEB;
}
.review-five .slick-next:before {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-size: 18px;
  font-weight: 900;
}
.review-five .slick-next:before .testimonial-two .slick-next:hover {
  color: #fff;
}
.review-five .slick-slide {
  width: 70px;
}
.review-five .slick-center .say-client .say-client-name {
  display: block;
}

.testimonial-two-head {
  max-width: 100%;
  background: transparent;
  border-radius: 10px;
  position: relative;
}
.testimonial-two-head h3, .testimonial-two-head .h3 {
  margin: 0px 0px 10px;
  font-size: 24px;
  font-weight: 700;
  color: #2b2b2b;
}
.testimonial-two-head span {
  margin-bottom: 20px;
  color: #515151;
  font-weight: 500;
  display: block;
}
.testimonial-two-head p {
  font-weight: 500;
  line-height: 1.7;
  margin-bottom: 20px;
}

.img-reviews img {
  max-width: 193px;
  margin: auto;
  border-radius: 50%;
}

.review-quote img {
  position: absolute;
  max-width: 60px;
  top: -27px;
}

.slid-review-group {
  position: relative;
}

.review-five .slick-prev:before,
.review-five .slick-next:before {
  color: #000;
  color: #000;
}

.review-five .slick-prev:hover,
.review-five .slick-next:hover {
  background: #FF3115;
}

.review-five .slick-prev:hover,
.review-five .slick-next:hover,
.review-five .swiper-button-prev:hover,
.review-five .swiper-button-next:hover {
  color: #fff;
  opacity: 1;
}

.review-five .slick-prev:hover:before,
.review-five .slick-prev:focus:before,
.review-five .slick-next:hover:before,
.review-five .slick-next:focus:before,
.review-five .swiper-button-prev:hover:before,
.review-five .swiper-button-next:hover:before {
  color: #fff;
  opacity: 1;
}

.review-five .slick-next:focus,
.review-five .slick-prev:focus {
  color: #000;
  background: #FF3115;
}

.blog-tricks-five {
  padding: 80px 0px 230px;
}

.blog-trick-five .owl-dots {
  display: none;
}
.blog-trick-five.blog-slider.owl-carousel .owl-nav button.owl-prev {
  top: inherit;
  left: 48%;
}
.blog-trick-five.blog-slider.owl-carousel .owl-nav button.owl-next {
  top: inherit;
  right: 52%;
}

.blog-trick-five.blog-slider.owl-carousel .owl-nav button.owl-prev,
.blog-trick-five.blog-slider.owl-carousel .owl-nav button.owl-next {
  background: #FFF5F1;
}

.blog-trick-five.blog-slider.owl-carousel .owl-nav button.owl-prev:hover,
.blog-trick-five.blog-slider.owl-carousel .owl-nav button.owl-next:hover {
  background: #86E53E;
  color: #fff;
}

.blog-trick-read {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  padding-bottom: 15px;
}
.blog-trick-read a {
  font-size: 14px;
  font-weight: 500;
}

.great-opportunity {
  padding: 80px 0px;
  background: linear-gradient(113.89deg, #FFFCFB 51.94%, #FFDCD4 100.96%, #FFFFFF 100.97%);
  position: relative;
}

.great-find-job.job-next-blk h2, .great-find-job.job-next-blk .h2 {
  color: #2B2B2B;
}
.great-find-job.job-next-blk p {
  color: #515151;
}

.opportunity-img {
  position: absolute;
  bottom: 0;
  right: 0;
}

div#global-loader {
  background: #FFF5F1;
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  top: 0;
}

.whirly-loader {
  background: #fff;
  border: 4px solid #fff;
  border-radius: 50%;
  border-top: 4px solid #86E53E;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader-img {
  position: absolute;
  z-index: 9;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.btn-file {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 20px;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  overflow: hidden;
  padding: 0 0.75rem;
  position: relative;
  vertical-align: middle;
}

.incoming-btns {
  margin-top: 20px;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:after {
  clear: both;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-loading .slick-track {
  visibility: hidden;
}
.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}

[dir=rtl] .slick-slide {
  float: right;
}
[dir=rtl] .slick-prev {
  right: -25px;
  left: auto;
}
[dir=rtl] .slick-prev:before {
  content: "◀";
}
[dir=rtl] .slick-next {
  right: auto;
  left: -25px;
}
[dir=rtl] .slick-next:before {
  content: "▶";
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: -50px;
  display: block;
  width: 32px;
  height: 32px;
  padding: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 10px;
  cursor: pointer;
  border: none;
  outline: none;
  background: #13323C;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  background-color: #FF3115;
  color: #fff;
  opacity: 1;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  color: #fff;
  opacity: 1;
  filter: invert(1) brightness(200);
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-size: 18px;
  line-height: 1;
  opacity: 0.75;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: auto;
  z-index: 1;
  right: 52px;
}
.slick-prev:before {
  content: "<";
  display: inline-block;
  transform: rotate(-45deg);
}

.slick-next {
  right: 10px;
}
.slick-next:before {
  content: ">";
  transform: rotate(-45deg);
  display: inline-block;
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.account-page {
  background-color: #fff;
}
.account-page .footer {
  display: none;
}
.account-page .content {
  padding: 144px 0 60px;
  background: #fffcfc;
  border-top: 1px solid #e5e6e6;
}

.login-header {
  margin-bottom: 20px;
}
.login-header img {
  margin-bottom: 15px;
}
.login-header p {
  font-size: 16px;
  color: #17191A;
  margin-bottom: 20px;
  font-weight: 500;
}
.login-header h3, .login-header .h3 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 9px;
  color: #161c2d;
}
.login-header h3 a, .login-header .h3 a {
  color: #ff3115;
  float: right;
  font-size: 15px;
  margin-top: 2px;
}

.login-right .dont-have {
  color: #161c2d;
  font-weight: 500;
  margin-top: 30px;
  font-size: 16px;
}
.login-right .dont-have label {
  color: #161c2d;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 30px;
}
.login-right .dont-have a {
  color: #86E53E;
}

.login-btn {
  font-size: 18px;
  font-weight: bold;
  background: #86E53E;
  border: 1px solid #86E53E;
  border-radius: 5px;
  padding: 14px 10px;
}

.login-or {
  color: #d4d4d4;
  padding-bottom: 25px;
  padding-top: 25px;
  position: relative;
  text-align: center;
}
.login-or p {
  font-size: 16px;
  color: #161c2d;
  margin-bottom: 0;
}

.or-line {
  background-color: #e5e5e5;
  height: 1px;
  margin-bottom: 0;
  margin-top: 0;
  display: block;
}

.span-or {
  background-color: #fff;
  display: block;
  left: 50%;
  margin-left: -20px;
  position: absolute;
  text-align: center;
  top: -3px;
  width: 42px;
}

.forgot-link {
  color: #161c2d;
  font-weight: 500;
  margin-top: 30px;
  font-size: 16px;
  display: inline-block;
}

.btn-facebook {
  background: #3F76E4;
  border-radius: 165px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 12px;
}

.btn-google {
  background-color: #dd4b39;
  border-radius: 165px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 12px;
}

.btn-twitter {
  background: #29B2FF;
  border-radius: 165px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 12px;
}

.social-login {
  border-bottom: 1px solid #E9E9E9;
  padding-bottom: 30px;
}

.social-login .btn:hover, .social-login .btn:focus {
  color: #fff;
}

.empty-content {
  padding: 50px 0;
}
.empty-content img {
  margin-bottom: 30px;
}
.empty-content h2, .empty-content .h2 {
  text-transform: uppercase;
  color: #161c2d;
  margin-bottom: 30px;
  font-size: 24px;
}
.empty-content p {
  font-size: 14px;
  text-transform: uppercase;
  color: #161c2d;
  margin-bottom: 0;
  font-weight: 500;
}
.empty-content .btn-item {
  margin-top: 40px;
}

.title-detail .custom-file, .title-detail .custom-file-input {
  height: 48px;
}

.title-box {
  background: #fff;
  box-shadow: 0px 4px 14px rgba(215, 215, 215, 0.25);
  border-radius: 5px;
}

.title {
  border-bottom: 1px solid #F3F1F1;
  padding: 30px;
}
.title h3, .title .h3 {
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  color: #161c2d;
  margin-bottom: 0;
  position: relative;
  padding-bottom: 8px;
}
.title h3::before, .title .h3::before {
  height: 3px;
  content: "";
  position: absolute;
  width: 29px;
  background: #86E53E;
  bottom: 0;
  left: 0;
}
.title h3::after, .title .h3::after {
  height: 3px;
  content: "";
  position: absolute;
  width: 11px;
  background: #86E53E;
  bottom: 0;
  left: 32px;
}

.title-content {
  padding: 30px;
}
.title-content h3, .title-content .h3 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 15px;
  color: #161c2d;
}
.title-content p {
  font-size: 14px;
  color: #494949;
  padding-top: 12px;
}

.select-project .title-content {
  padding: 0 0 30px;
}

.next-btn {
  background: #86E53E;
  border-radius: 90px;
  border-color: #86E53E;
  font-weight: bold;
  font-size: 17px;
  padding: 13px 50px;
  color: #fff;
  text-transform: uppercase;
}
.next-btn:hover {
  color: #fff;
  background-color: #fb451d;
  border-color: #fb451d;
}

.cancel-btn {
  background: #13323C;
  border-radius: 90px;
  font-weight: bold;
  font-size: 17px;
  padding: 13px 50px;
  color: #fff;
  margin-right: 15px;
  text-transform: uppercase;
}
.cancel-btn:hover {
  color: #fff;
  background-color: #86E53E;
  border-color: #86E53E;
}

.technology {
  padding: 30px 0;
}
.technology .btn-item {
  margin: 25px 0;
}

.upload-document {
  position: relative;
  width: 125px;
}
.upload-document img {
  border-radius: 4px;
  height: 80px;
  width: auto;
}
.upload-document .btn-danger {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 17px;
  width: 28px;
  height: 28px;
  background: #F5365C;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.upload-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.upload-wrap .upload-document:not(:last-child) {
  margin-right: 25px;
}
.upload-wrap .upload-images + .upload-images {
  margin-left: 20px;
}

.add-links {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 46px;
  width: 46px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  background-color: #86E53E;
  color: #fff;
}
.add-links.more-link {
  width: auto;
  height: auto;
  background: transparent;
  color: #898989;
  display: inline-block;
}
.add-links.more-link:hover {
  color: #898989;
  opacity: 0.8;
}
.add-links:hover {
  color: #fff;
  opacity: 0.8;
}

.back {
  margin-top: 35px;
  color: #86E53E;
  padding-left: 20px;
}
.back p a {
  color: #86E53E;
  font-weight: bold;
  font-size: 18px;
}
.back p::before {
  content: "\f104";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  left: 0;
  margin-right: 5px;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  color: #86E53E;
  padding-left: 20px;
}

.faq {
  padding: 30px 0;
}
.faq .card {
  border: 1px solid #ffe6e1;
  box-shadow: 0px 4px 4px rgba(244, 244, 244, 0.25);
  border-radius: 5px;
}
.faq .card .card-header {
  border-bottom: 1px solid #ffe6e1;
}

#accordion h4, #accordion .h4 {
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  color: #86E53E;
  margin-bottom: 22px;
}
#accordion i {
  float: right;
}
#accordion a[aria-expanded=true] i {
  transform: rotate(90deg);
}
#accordion .card-header {
  font-size: 18px;
  color: #161c2d;
}
#accordion .card-body {
  font-size: 16px;
  color: #161c2d;
}

#accordion a[aria-expanded=true] .card-header, #accordion a[aria-expanded=true] .card-header i {
  color: #86E53E;
}

.basics-faq {
  margin-bottom: 40px;
}

.select2-container {
  z-index: 11111;
}
.select2-container .select2-selection--single {
  border: 1px solid #E7E8EA;
  height: 46px;
  outline: 0;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 30px;
  padding-left: 15px;
}

.select2-results__option {
  padding: 6px 15px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 44px;
  right: 7px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #353535 transparent transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  height: 0;
  left: 50%;
  margin-left: -10px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #353535;
  font-size: 16px;
  font-weight: normal;
  line-height: 44px;
  font-weight: 500;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #353535;
  border-width: 0 6px 6px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #161c2d;
  border: 0;
}
.select2-container--default .select2-selection--multiple {
  border: 1px solid #dcdcdc;
  min-height: 46px;
}

.select2-dropdown {
  border-color: #dcdcdc;
}

.result {
  background: #FFFDFD;
  margin: 0 0 20px 0;
}
.result .select2-container .select2-selection--single {
  border: 0;
  background-color: unset;
  height: 46px;
}
.result .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #86E53E;
  font-weight: 500;
  font-size: 14px;
}
.result .select2-container--default .select2-selection--single .select2-selection__rendered:focus {
  outline: 0;
  border: 0;
  -webkit-appearance: none;
}
.result.bg-white {
  background: #fff;
  border: 1px solid rgba(239, 239, 239, 0.42);
  box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
}
.result .sort-title {
  font-weight: 500;
  font-size: 14px;
  color: #161c2d;
}
.result h4, .result .h4 {
  font-weight: 500;
  font-size: 14px;
  color: #86E53E;
  margin-bottom: 0;
}
.result h4 span, .result .h4 span {
  font-weight: 700;
}

.select2-container:focus, .select2-container--below .select2-container--focus {
  border: 0;
  outline: 0;
  -webkit-appearance: none;
}

.search-filter {
  background: #fff;
  border: 1px solid rgba(239, 239, 239, 0.42);
  box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
  border-radius: 5px;
}
.search-filter .card-header {
  border: 1px solid rgba(239, 239, 239, 0.42);
  box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
  border-radius: 5px;
  align-items: center;
  -webkit-align-items: center;
}
.search-filter .card-header h2, .search-filter .card-header .h2 {
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
  color: #161c2d;
}
.search-filter .card-header a {
  font-weight: 600;
  font-size: 14px;
  color: #86E53E;
}

.search-filter .select2-container .select2-selection--single, .search-filter .form-control {
  border: 1px solid #ecf0f9;
  border-radius: 6px;
}

.breadcrumb-bar {
  background: url(../../assets/img/inner-banner.jpg);
  background-size: cover;
  padding-top: 84px;
  background-repeat: no-repeat;
  min-height: 304px;
}
.breadcrumb-bar .breadcrumb-title {
  font-weight: bold;
  font-size: 40px;
  color: #86E53E;
  margin-bottom: 15px;
}

.bread-crumb-bar {
  background-color: #FFF;
  padding-top: 84px;
}
.bread-crumb-bar .inner-banner {
  padding: 20px 0;
}
.bread-crumb-bar .page-breadcrumb ol li a img {
  margin-right: 10px;
}
.bread-crumb-bar .breadcrumb-list .page-breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  line-height: 5px;
  margin: 0px 19px 0;
  padding-right: 0;
}

.breadcrumb-list .page-breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  color: #22100D;
  font-size: 14px;
  content: "\f054";
  font-weight: 600;
  line-height: 1.5;
  font-family: "Font Awesome 5 Free";
  padding-right: 16px;
  margin: 0px 15px 0;
  background: none;
}
.breadcrumb-list .page-breadcrumb ol {
  display: flex;
}
.breadcrumb-list .page-breadcrumb ol li {
  text-transform: uppercase;
  font-size: 14px;
  color: #13323C;
  font-weight: 600;
}

.inner-content {
  margin-top: 40px;
}

.content {
  min-height: 200px;
  padding: 60px 0 30px;
  background-color: #fff;
}

.inner-banner {
  padding: 70px 0;
}

.page-breadcrumb ol {
  background-color: transparent;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  padding: 0;
  display: inline-flex;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
}
.page-breadcrumb ol li a {
  color: #161c2d;
  font-weight: 600;
}
.page-breadcrumb ol li.active {
  color: #161c2d;
}
.page-breadcrumb .breadcrumb a:hover {
  color: #86E53E;
}
.page-breadcrumb .breadcrumb-item + .breadcrumb-item {
  padding-left: 0;
}
.page-breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  color: #161c2d;
  font-size: 16px;
  content: "";
  width: 5px;
  height: 5px;
  background: red;
  border-radius: 20px;
  margin: 10px 15px 0;
  display: block;
  padding: 0;
}

.bread-crumb-bar .page-breadcrumb ol li a,
.bread-crumb-bar .breadcrumb-list .page-breadcrumb ol li,
.bread-crumb-bar .breadcrumb-list .page-breadcrumb ol {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sort-by {
  float: right;
}
.sort-by select {
  border: none;
  outline: none;
  width: 150px;
  padding: 0 20px;
  font-size: 15px;
  color: #53545b;
  font-weight: 500;
  height: 36px;
}

.sort-title {
  color: #fff;
  font-size: 14px;
  margin-right: 10px;
}

.sortby-fliter {
  display: inline-block;
  width: 130px;
}

.cal-icon {
  position: relative;
  width: 100%;
}
.cal-icon:after {
  color: #86E53E;
  content: "\f073";
  display: block;
  font-family: "Font Awesome 5 Free";
  font-size: 16px;
  font-weight: 400;
  margin: auto;
  position: absolute;
  right: 15px;
  top: 10px;
}

.custom_check {
  color: #55545b;
  display: inline-block;
  position: relative;
  font-size: 14px;
  font-size: 0.9375rem;
  padding-left: 30px;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.custom_check input:checked ~ .checkmark {
  background-color: #fff;
  border-color: #ffe6e1;
}
.custom_check input:checked ~ .checkmark:after {
  display: block;
}
.custom_check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #ecf0f9;
  background-color: #fff;
  border-radius: 3px;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.custom_check .checkmark::after {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  display: none;
  left: 4px;
  top: 0;
  color: #86E53E;
  font-size: 11px;
}

.custom_radio {
  color: #555;
  display: inline-flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  font-size: 0.9375rem;
  padding-left: 30px;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom_radio input {
  position: absolute;
  opacity: 0;
}
.custom_radio input:checked ~ .checkmark:after {
  opacity: 1;
}
.custom_radio .checkmark {
  position: absolute;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #ffe6e1;
  border-radius: 50%;
}
.custom_radio .checkmark:after {
  display: block;
  content: "";
  position: absolute;
  opacity: 0;
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #86E53E;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.radio_input .custom_radio + .custom_radio {
  margin-left: 15px;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Upload";
}

.custom-file-label {
  height: 48px;
  border: 1px solid #E7E8EA;
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  margin: 0.1rem;
  height: 41px;
  border-radius: 5px;
  padding: 10px 25px;
  border-left: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
}

.filter-widget {
  margin-bottom: 20px;
}
.filter-widget h4, .filter-widget .h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}
.filter-widget .custom_check {
  line-height: 18px;
}
.filter-widget .select2-container {
  z-index: unset;
}

.btn-search .btn {
  background-color: #13323C;
  border: 1px solid #13323C;
  border-radius: 5px;
  color: #fff;
  height: 46px;
  font-weight: 500;
  font-size: 16px;
}

.btn-search .btn:hover, .btn-search .btn:focus {
  background-color: #86E53E;
  border: 1px solid #86E53E;
}

.provider-widget {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.provider-widget .pro-name {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 3px;
}
.provider-widget .rating i {
  font-size: 14px;
}
.provider-widget .average-rating {
  font-size: 14px;
  font-weight: 500;
}

.pro-info-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.pro-info-left .rating {
  list-style: none;
  margin: 0 0 7px;
  padding: 0;
  width: 100%;
}

.pro-info-right {
  margin-left: auto;
  -ms-flex: 0 0 200px;
  flex: 0 0 200px;
  max-width: 200px;
}

.provider-img {
  -ms-flex: 0 0 150px;
  flex: 0 0 150px;
  margin-right: 20px;
  width: 150px;
  height: 154px;
  position: relative;
}
.provider-img img {
  border-radius: 5px;
}

.load-more {
  margin-bottom: 30px;
}
.load-more .btn {
  background-color: #ff3115;
  border-color: #ff3115;
}

.invited-freelancer-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}

.provider-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.provider-action a + a {
  margin-left: 8px;
}
.provider-action .fav-btn:hover {
  background-color: #fb1612;
  border-color: #fb1612;
  color: #fff;
}

.msg-btn:hover, .msg-btn:focus {
  background-color: #ff3115;
  border-color: #ff3115;
  color: #fff;
}

.call-btn:hover, .call-btn:focus {
  background-color: #ff3115;
  border-color: #ff3115;
  color: #fff;
}

.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active,
.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:hover,
.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:focus {
  border-bottom-width: 3px;
  color: #86E53E;
}

.widget {
  margin-bottom: 30px;
}

.widget-title {
  margin-bottom: 15px;
}

.before-circle {
  background-color: rgba(255, 49, 21, 0.2);
  border-radius: 50%;
  height: 12px;
  width: 12px;
  border: 2px solid #ff3115;
}

.awards-widget .experience-list > li:last-child p {
  margin-bottom: 0;
}

.service-list {
  margin-bottom: 30px;
}
.service-list:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
.service-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.service-list ul li {
  float: left;
  margin: 6px 0;
  padding-left: 25px;
  position: relative;
  width: 33%;
}
.service-list ul li::before {
  color: #ccc;
  content: "\f30b";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  left: 0;
  position: absolute;
}

.dropzone {
  background-color: #fbfbfb;
  border: 2px dashed rgba(0, 0, 0, 0.1);
  background: #FFFFFF;
  border: 1px dashed #8A2BE2;
  border-radius: 10px;
  min-height: 130px;
  text-align: center;
}
.dropzone .dz-preview.dz-error:hover .dz-error-message {
  display: none;
}
.dropzone p {
  font-size: 16px;
  color: #8A2BE2;
}
.dropzone span {
  display: none;
}

.profile {
  background: #fff;
  box-shadow: 0px 4px 14px rgba(215, 215, 215, 0.25);
  border-radius: 5px;
  margin: -130px 0 25px 0;
}
.profile .select2-container {
  z-index: unset;
}
.profile.profile-freelance {
  margin: 0px 0 25px 0;
}

.profile-image img {
  margin-bottom: 1.5rem;
}

.change-photo-btn {
  background-color: #FF3115;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 13px;
  font-weight: 600;
  margin: 0 auto;
  padding: 10px 15px;
  position: relative;
  transition: 0.3s;
  -moz-transition: 0.3s;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  text-align: center;
  width: 220px;
}
.change-photo-btn input.upload {
  bottom: 0;
  cursor: pointer;
  filter: alpha(opacity=0);
  left: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 220px;
}

.btn-icon {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  position: absolute;
  padding: 0;
  font-size: 10px;
  width: 20px;
  height: 20px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  right: 3px;
  top: 3px;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.upload-images {
  position: relative;
  width: 80px;
}
.upload-images img {
  border-radius: 4px;
  height: 80px;
  width: auto;
}

.add-more a {
  color: #ff3115;
}

.bootstrap-tagsinput {
  border-color: #E7E8EA;
  box-shadow: inherit;
  min-height: 46px;
  width: 100%;
  border-radius: 5px;
}
.bootstrap-tagsinput.focus {
  border-color: #C7C8CA;
}
.bootstrap-tagsinput .tag {
  background-color: #ff508b;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  margin-right: 2px;
  padding: 11px 15px;
  border-radius: 0;
}
.bootstrap-tagsinput .tag.badge-info {
  border-radius: 40px;
  margin-bottom: 0;
  border: 0;
}

.submit-section .submit-btn {
  padding: 12px 30px;
  font-weight: 600;
  font-size: 16px;
  min-width: 120px;
}
.submit-section .submit-btn + .submit-btn {
  margin-left: 15px;
}

.info-widget {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.payment-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 600;
  color: #161c2d;
  text-transform: capitalize;
}
.payment-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.payment-radio input:checked ~ .checkmark {
  border-color: #ff3115;
}
.payment-radio input:checked ~ .checkmark::after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}
.payment-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 19px;
  height: 19px;
  margin: 3px 0 0 0;
  border: 2px solid #ddd;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.payment-radio .checkmark::after {
  position: absolute;
  left: 3px;
  top: 3px;
  content: "";
  width: 9px;
  height: 9px;
  background-color: #ff3115;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0.1);
  -moz-transform: scale(0.1);
  -ms-transform: scale(0.1);
  -o-transform: scale(0.1);
  transform: scale(0.1);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.submit-btn-bottom {
  margin-bottom: 30px;
}

.btn-file {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 20px;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  overflow: hidden;
  padding: 0 0.75rem;
  position: relative;
  vertical-align: middle;
}
.btn-file input {
  cursor: pointer;
  filter: alpha(opacity=0);
  font-size: 23px;
  height: 100%;
  margin: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  cursor: pointer;
  filter: alpha(opacity=0);
  font-size: 23px;
  height: 100%;
  margin: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

#calendar-events {
  background-color: #fcfcfc;
}

.calendar-events {
  border: 1px solid transparent;
  cursor: move;
  padding: 10px 15px;
}
.calendar-events:hover {
  border-color: #e9e9e9;
  background-color: #fff;
}
.calendar-events i {
  margin-right: 8px;
}

.calendar {
  float: left;
  margin-bottom: 0;
}

.fc-toolbar.fc-header-toolbar {
  margin-bottom: 1.5rem;
}
.fc-toolbar h2, .fc-toolbar .h2 {
  font-size: 18px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  line-height: 30px;
  text-transform: uppercase;
}

.none-border .modal-footer {
  border-top: none;
}

.fc-day-grid-event .fc-time {
  font-family: "Roboto", sans-serif;
}

.fc-day {
  background: #fff;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0;
}

.fc th.fc-widget-header {
  background: #eeeeee;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0;
  text-transform: uppercase;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: #f3f3f3;
}

.fc-basic-view .fc-day-number,
.fc-basic-view .fc-week-number {
  padding: 2px 5px;
}

.fc-button {
  background: #f1f1f1;
  border: none;
  color: #797979;
  text-transform: capitalize;
  box-shadow: none !important;
  border-radius: 3px !important;
  margin: 0 3px !important;
  padding: 6px 12px !important;
  height: auto !important;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 16px;
}

.fc-state-hover {
  background: #f3f3f3;
}

.fc-state-highlight {
  background: #f0f0f0;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: #ff3115 !important;
  color: #fff !important;
  text-shadow: none !important;
}

.fc-cell-overlay {
  background: #f0f0f0;
}

.fc-unthemed .fc-today {
  background: #fff;
}

.fc-event {
  border-radius: 2px;
  border: none;
  color: #fff !important;
  cursor: move;
  font-size: 13px;
  margin: 1px 7px;
  padding: 5px 5px;
  text-align: center;
}

.fc-basic-view td.fc-week-number span {
  padding-right: 8px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}
.fc-basic-view td.fc-day-number {
  padding-right: 8px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}

.event-form .input-group .form-control {
  height: 40px;
}

.profile-sidebar {
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  margin-bottom: 30px;
  overflow: hidden;
}

.pro-widget-content {
  padding: 20px;
  text-align: center;
}

.profile-info-widget {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: left;
}
.profile-info-widget .booking-pro-img {
  margin-right: 15px;
}
.profile-info-widget .booking-pro-img img {
  border-radius: 4px;
  height: 90px;
  width: 90px;
  object-fit: cover;
}

.profile-det-info {
  overflow: hidden;
}
.profile-det-info h3, .profile-det-info .h3 {
  font-size: 16px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 12px;
}
.profile-det-info h4, .profile-det-info .h4 {
  font-weight: 500;
  font-size: 14px;
  color: #161c2d;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 12px;
}
.profile-det-info p {
  color: #696969;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 8px;
  margin-bottom: 12px;
}

.customer-details h5, .customer-details .h5 {
  color: #AAAAAA;
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 12px;
}
.customer-details h5 i, .customer-details .h5 i {
  width: 18px;
}
.customer-details .rating .average-rating {
  font-size: 9px;
  background-color: #FEBE42;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  width: 22px;
  height: 16px;
  line-height: 16px;
  display: inline-flex;
  display: -ms-inline-flexbox;
  margin-right: 5px;
  align-items: center;
  justify-content: center;
}

.customer-job {
  padding: 20px 0;
}
.customer-job i {
  color: #F5365C;
  font-size: 18px;
  margin-right: 15px;
}

.pat-widget-profile {
  background: #fff;
  border: 1px solid rgba(239, 239, 239, 0.42);
  box-sizing: border-box;
  box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
  border-radius: 5px;
}
.pat-widget-profile .pro-widget-content {
  padding: 0;
  margin-top: 0;
}
.pat-widget-profile .booking-date li {
  font-size: 0.875rem;
}
.pat-widget-profile .booking-date li span {
  font-size: 0.875rem;
}
.pat-widget-profile .card-body {
  padding: 35px 20px 20px;
}
.pat-widget-profile .card-footer {
  padding: 15px;
  background: #FBFBFB;
}

.card-top {
  position: relative;
}
.card-top::before {
  content: "";
  background-color: rgba(20, 20, 20, 0.7);
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.card-top .dev-user {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -80%);
  -webkit-transform: translate(-50%, -80%);
  -ms-transform: translate(-50%, -80%);
  display: flex;
  display: -webkit-flex;
}
.card-top .dev-user::before {
  background-color: #159C5B;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  content: "\f00c";
  font-weight: 600;
  font-family: "Font Awesome 5 Free";
  font-size: 13px;
  justify-content: center;
  align-items: center;
  color: #fff;
  display: inline-flex;
  display: -ms-inline-flexbox;
}
.card-top .dev-user img {
  margin: auto;
  width: 86px;
  height: 86px;
  border-radius: 30px;
}
.card-top .bg-none.dev-user::before {
  visibility: hidden;
}
.card-top .dev-text {
  position: absolute;
  top: 0;
  transform: none;
  text-align: center;
  padding: 15px;
  width: 100%;
  left: 0;
  right: 0;
}
.card-top .dev-text .left {
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  color: #fff;
}
.card-top .dev-text .right {
  color: #fff;
  font-weight: bold;
  background: #86E53E;
  border: 1px solid #86E53E;
  border-radius: 130px;
  font-size: 14px;
  width: 37px;
  height: 37px;
  line-height: 34px;
  padding: 0;
}
.card-top .dev-text .right.bg-grey {
  background: #EEEEEE;
  border: 1px solid #EEEEEE;
}
.card-top .dev-text a:hover .right.bg-grey {
  background: #86E53E;
  border: 1px solid #86E53E;
}

.left-bid {
  padding-top: 20px;
}

.vote {
  background: #EEEEEE;
  border-radius: 5px;
  font-weight: 600;
  font-size: 9px;
  color: #7D7D7D;
  padding: 5px 10px;
}

.pro-widget-profile {
  border: 1px solid rgba(239, 239, 239, 0.42);
  box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
  border-radius: 5px;
}
.pro-widget-profile .dev-user {
  transform: translate(-50%, -50%);
}
.pro-widget-profile .dev-text .left {
  text-align: left;
}
.pro-widget-profile .dev-text .left img {
  display: block;
  float: left;
  width: 66px;
  border-radius: 12px;
}
.pro-widget-profile .dev-text .left .left-content {
  margin-left: 75px;
}
.pro-widget-profile .dev-text .left .left-content img {
  width: auto;
}
.pro-widget-profile .customer-info {
  margin-top: 0;
}
.pro-widget-profile .customer-info ul {
  padding: 0;
}
.pro-widget-profile .slot p {
  font-size: 12px;
}
.pro-widget-profile .slot h5, .pro-widget-profile .slot .h5 {
  font-size: 13px;
  margin: 0;
}
.pro-widget-profile .badge-design {
  font-size: 12px;
  padding: 5px 15px;
  margin-bottom: 5px;
}
.pro-widget-profile .card-header {
  border-bottom: 1px solid #F8F8F8;
  padding: 1.5rem;
}
.pro-widget-profile .profile-info-widget {
  text-align: left;
}
.pro-widget-profile .pro-widget-content {
  padding: 0;
}

.left .left-img {
  position: absolute;
}
.left .left-img::before {
  background-color: #159C5B;
  position: absolute;
  right: 2px;
  bottom: 0;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  content: "\f00c";
  font-weight: 600;
  font-family: "Font Awesome 5 Free";
  font-size: 13px;
  justify-content: center;
  align-items: center;
  color: #fff;
  display: inline-flex;
  display: -ms-inline-flexbox;
}

.client.pro-widget-profile .dev-text .left .left-content {
  margin-left: 0;
}
.client.pro-widget-profile .card-img-top {
  border-radius: 5px 0 5px 0;
}

.left-content h6, .left-content .h6 {
  font-weight: 500;
  font-size: 12px;
  color: #fff;
}
.left-content h6 i, .left-content .h6 i {
  color: #159C5B;
}
.left-content h4, .left-content .h4 {
  font-weight: bold;
  font-size: 16px;
  color: #fff;
}
.left-content h4 a, .left-content .h4 a {
  color: #fff;
}
.left-content p {
  font-weight: 500;
  font-size: 11px;
  color: #fff;
  margin-bottom: 5px;
}

.dev-right {
  margin-left: 20px;
}
.dev-right p {
  font-weight: bold;
  font-size: 17px;
  color: #fff;
}
.dev-right h6, .dev-right .h6 {
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  margin-top: 5px;
}

.profile-box {
  padding: 30px;
}
.profile-box .bid-btn {
  font-size: 14px;
}

.profile-freelance .provider-img img {
  border-radius: 15px;
}

.bid-btn {
  background: #FF5E3B;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  white-space: nowrap;
}
.bid-btn:hover {
  color: #fff;
  background-color: #fb451d;
  border-color: #fb451d;
}

.star {
  color: #fff;
  font-weight: bold;
  background: #86E53E;
  border: 1px solid #86E53E;
  border-radius: 130px;
  font-size: 14px;
  width: 37px;
  height: 37px;
  line-height: 37px;
  padding: 0;
  text-align: center;
}

.bootstrap-tags {
  padding: 0 15px 15px;
  text-align: center;
  border-top: 1px solid #FFFBFB;
}

.badge-tech {
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #161c2d;
  background: rgba(19, 17, 53, 0.1);
  border-radius: 40px;
  margin-right: 4px;
}
.badge-tech:last-child {
  margin-right: 0;
}

.badge-design {
  font-weight: 500;
  font-size: 12px;
  color: #2e2e2e;
  background: #FFEEEA;
  border-radius: 60px;
  margin-right: 3px;
  display: inline-block;
  padding: 7px 10px;
}
.badge-design:last-child {
  margin-right: 0;
}
.badge-design:hover {
  background-color: #FFF0F0;
}

.slots {
  padding-bottom: 10px;
}

.slot p {
  font-size: 12px;
  color: #6e727d;
  margin-bottom: 3px;
  font-weight: normal;
}
.slot h5, .slot .h5 {
  font-size: 12px;
  font-weight: 500;
  color: #161c2d;
}
.slot h5 i, .slot .h5 i {
  color: #86E53E;
}

.profile-btn {
  background: #86E53E;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  color: #fff;
}
.profile-btn:hover {
  color: #fff;
  background-color: #fb451d;
  border-color: #fb451d;
}

.job-btn {
  background: #8553EE;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
}
.job-btn:hover {
  color: #fff;
  background-color: #7d46ef;
  border-color: #7d46ef;
}

.badge-skill {
  background: #E7E8EA;
  border-radius: 6px;
  font-weight: 500;
  font-size: 12px;
  color: #6e727d;
  padding: 10px;
  margin: 0 7px 7px 0;
}

.tags {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
}
.tags .badge-skills {
  width: 195px;
}
.tags li {
  float: left;
}

.badge-skills {
  background-color: #edeef3;
  border-radius: 6px;
  font-size: 15px;
  font-weight: normal;
  margin: 0 2px 7px 0;
  padding: 10px 15px;
  color: #55545b;
}
.badge-skills:hover {
  background-color: #FFF0F0;
}
.badge-skills:hover .tag-close {
  background-color: #86E53E;
  color: #fff;
}

.tag-close {
  width: 20px;
  height: 20px;
  display: inline-flex;
  background-color: #d3d4d9;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  font-size: 12px;
  cursor: pointer;
}

.profile-title {
  font-weight: 600;
  font-size: 30px;
  color: #161c2d;
}

.profile-position {
  font-size: 16px;
  color: #86E53E;
}

.prof-img, .camera-bg {
  position: relative;
}

.prof-img::before {
  background-color: #159C5B;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -23%);
  bottom: 0;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  content: "\f00c";
  font-weight: 600;
  font-family: "Font Awesome 5 Free";
  font-size: 13px;
  justify-content: center;
  align-items: center;
  color: #fff;
  display: inline-flex;
  display: -ms-inline-flexbox;
}

.camera-bg {
  background-color: #86E53E;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 30%);
  bottom: 0;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  content: "\f00c";
  content: "\f030";
  font-weight: 600;
  font-family: "Font Awesome 5 Free";
  font-size: 18px;
  justify-content: center;
  align-items: center;
  color: #fff;
  display: inline-flex;
  display: -ms-inline-flexbox;
}

.full-btn {
  background: #129BE8;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
}
.full-btn:hover {
  color: #fff;
  opacity: 0.8;
}

.profile-list ul {
  margin: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  border-top: 1px solid #E9E9E9;
}
.profile-list li {
  font-weight: 500;
  font-size: 16px;
  color: #161c2d;
  border-right: 1px solid #E9E9E9;
  text-align: center;
  margin: 0 auto;
  padding: 15px 5px;
  width: 16.6666666667%;
}
.profile-list li:last-child {
  border-right: 0;
}
.profile-list .proj-title {
  font-weight: 500;
  font-size: 16px;
  color: #161c2d;
}

.profile-preword {
  margin: 15px 0 0 0;
  padding-left: 0;
  list-style: none;
  display: inline-flex;
  display: -ms-inline-flexbox;
  flex-wrap: wrap;
}
.profile-preword li {
  font-weight: 500;
  font-size: 16px;
  color: #8D8D8D;
  margin-right: 20px;
  margin-bottom: 5px;
}
.profile-preword li img {
  box-shadow: 1px 0px 5px #aaaaaa;
  margin-right: 2px;
}
.profile-preword .rating .average-rating {
  font-size: 11px;
  background-color: #FEBE42;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  width: 22px;
  height: 16px;
  line-height: 16px;
  display: inline-flex;
  display: -ms-inline-flexbox;
  margin-right: 5px;
  align-items: center;
  justify-content: center;
}

.profile-inf {
  text-align: right;
}
.profile-inf p i.heart {
  color: #F5365C;
  font-size: 30px;
  margin-right: 10px;
}

.bottom-text {
  position: absolute;
  right: 0;
  bottom: 0;
}

.profile-right {
  padding-left: 0;
  list-style: none;
  display: inline-flex;
  display: -ms-inline-flexbox;
  flex-wrap: wrap;
}
.profile-right li {
  font-weight: 600;
  font-size: 12px;
  color: #161c2d;
  margin-right: 20px;
}
.profile-right li:last-child {
  margin-right: 0;
  padding-top: 0;
}

.amt-hr {
  font-weight: 500;
  font-size: 36px;
  color: #F5365C;
}
.amt-hr p {
  font-size: 16px;
  color: #161c2d;
}

.pro-post {
  background: #fff;
  box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.25);
  margin-bottom: 30px;
  border: 1px solid #e8e8e8;
}

.pro-title {
  font-weight: bold;
  font-size: 20px;
  position: relative;
  color: #161c2d;
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid #E6E6E6;
}
.pro-title::before {
  height: 3px;
  content: "";
  position: absolute;
  width: 29px;
  background: #86E53E;
  bottom: 0;
  left: 0;
}
.pro-title::after {
  height: 3px;
  content: "";
  position: absolute;
  width: 11px;
  background: #86E53E;
  bottom: 0;
  left: 32px;
}
.pro-title.without-border {
  border: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.pro-title.without-border:before,
.pro-title.without-border:after {
  display: none;
}

.pro-list {
  list-style: none;
  padding: 0;
}

.project-widget {
  background: #fff;
  box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
  margin-bottom: 30px;
}
.project-widget .select2-container {
  z-index: unset;
}
.project-widget .pro-image {
  border-radius: 5px 5px 0px 0px;
  border-radius: 5px 5px 0px 0px;
}
.project-widget .pro-detail {
  text-align: center;
  padding: 15px;
}
.project-widget .pro-name {
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  color: #161c2d;
  margin-bottom: 5px;
}
.project-widget .pro-designation {
  font-size: 12px;
  color: #636363;
  margin-bottom: 0;
}

.more-btn {
  background-color: #86E53E;
  border-radius: 150px;
  color: #fff;
  padding: 9px 16px;
  font-size: 12px;
  margin-right: 8px;
  font-weight: 600;
  color: #fff;
}
.more-btn:hover {
  color: #fff;
  opacity: 0.8;
}

.widget-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.widget-list ul li {
  float: left;
  margin: 6px 0;
  padding-left: 25px;
  position: relative;
}
.widget-list ul li::before {
  color: #86E53E;
  content: "\f30b";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  left: 0;
  position: absolute;
}
.widget-list ul li h4, .widget-list ul li .h4 {
  font-weight: 500;
  font-size: 16px;
  color: #161c2d;
}
.widget-list ul li h5, .widget-list ul li .h5 {
  font-weight: normal;
  font-size: 14px;
  color: #F38247;
  margin-bottom: 15px;
}
.widget-list ul li p {
  font-size: 14px;
  color: #000;
}

.bookmark {
  margin-bottom: 0;
}
.bookmark h5, .bookmark .h5 {
  font-weight: 500;
  font-size: 18px;
  color: #161c2d;
  margin-bottom: 15px;
}

.bookmark-btn {
  background-color: #FEBE42;
  border-radius: 6px;
  color: #fff;
  padding: 9px 16px;
  font-size: 18px;
  margin-right: 8px;
  font-weight: 600;
  color: #fff;
}
.bookmark-btn:hover {
  color: #fff;
  background-color: #fab530;
  border-color: #fab530;
}

.share-icon {
  display: inline-block;
  background-color: #13323C;
  border-radius: 6px;
  color: #fff;
  padding: 9px 16px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}
.share-icon:hover {
  color: #fff;
  opacity: 0.8;
}

.follow-btn {
  background-color: #8553EE;
  border-radius: 50px;
  color: #fff;
  padding: 9px 30px;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}
.follow-btn:hover {
  color: #fff;
  background-color: #7a40f3;
  border-color: #7a40f3;
}

.follow-posts {
  margin: 30px auto 0;
  padding: 10px 0;
  list-style: none;
  width: 76%;
}
.follow-posts li {
  border-right: 1px solid #E9E9E9;
  display: inline-block;
  padding: 0 20px;
}
.follow-posts li:last-child {
  border-right: 0;
}
.follow-posts li p {
  font-size: 16px;
  color: #161c2d;
  margin-bottom: 2px;
}
.follow-posts li h6, .follow-posts li .h6 {
  font-size: 20px;
  color: #161c2d;
  font-weight: 500;
}

.about-widget ul li p {
  font-size: 15px;
  color: #5c5c5c;
  margin-bottom: 0;
}
.about-widget ul li h6, .about-widget ul li .h6 {
  font-size: 15px;
  color: #000;
  font-weight: 600;
  margin-bottom: 3px;
}
.about-widget.profile-overview p {
  margin-bottom: 10px;
}

.feedback-form {
  background: #FEFEFE;
}

.proposal-widget {
  padding: 20px;
}
.proposal-widget p {
  margin-bottom: 0;
  padding: 10px 0 0 30px;
}
.proposal-widget .proposal-text {
  font-weight: 600;
  font-size: 16px;
}
.proposal-widget .custom_check {
  margin-bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.proposal-widget .custom_check .checkmark {
  top: auto;
}
.proposal-widget .about-author + .about-author {
  margin-top: 20px;
}
.proposal-widget .about-author-img {
  border-radius: 0;
  background-color: transparent;
}

.proposal-features .proposal-widget {
  margin-bottom: 20px;
}

.proposal-success {
  background: rgba(250, 255, 251, 0.3);
  border: 1px solid rgba(0, 190, 95, 0.3);
}
.proposal-success .proposal-text {
  color: #00BE5F;
}

.proposal-light {
  background: rgba(255, 248, 189, 0.3);
  border: 1px solid rgba(185, 166, 0, 0.3);
}
.proposal-light .proposal-text {
  color: #E2BD00;
}

.proposal-danger {
  background: rgba(241, 92, 61, 0.2);
  border: 1px solid rgba(241, 92, 61, 0.3);
}
.proposal-danger .proposal-text {
  color: #CA2500;
}

.proposal-pink {
  background: rgba(255, 248, 189, 0.3);
  border: 1px solid rgba(255, 248, 189, 0.3);
}

.proposal-greens {
  background: #FAFFFB;
  border: 1px solid #CFFAD8;
}

.activity-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.activity-list li {
  font-weight: 600;
  font-size: 16px;
  color: #4B4B4B;
}
.activity-list li + li {
  padding-top: 13px;
}
.activity-list li:last-child {
  padding-bottom: 0;
}
.activity-list li span {
  color: #000;
}

.tags .badge-design, .tags .badge-tech {
  font-size: 14px;
  padding: 10px 30px;
  margin-bottom: 5px;
}

.payment-detail {
  padding: 30px;
}
.payment-detail img {
  margin-bottom: 10px;
}
.payment-detail h2, .payment-detail .h2 {
  font-size: 32px;
}
.payment-detail p {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
}

.click-btn {
  font-weight: 500;
  border-radius: 3px;
  padding: 11px 35px;
  color: #fff;
  text-align: center;
  display: inline-block;
}
.click-btn:hover {
  color: #fff;
}

.transaction {
  padding: 35px;
  text-align: center;
  border-top: 1px solid #E7E7E7;
}
.transaction h5, .transaction .h5 {
  font-weight: 500;
  font-size: 20px;
  color: #161c2d;
  padding: 15px 0;
}

.billing {
  border: 0;
}
.billing .card-header {
  border-bottom: 1px solid #E7E7E7;
}
.billing .card-header p {
  font-size: 19px;
  color: #161c2d;
  margin-bottom: 0;
}

.payment-list h3, .payment-list .h3 {
  font-weight: 700;
  color: #161c2d;
  margin-bottom: 25px;
  font-size: 20px;
}
.payment-list label {
  font-weight: 600;
  font-size: 15px;
  color: #161c2d;
}

.btn-pad {
  margin: 25px 0;
}

.billing-method {
  padding: 20px;
}

.review-content {
  border-bottom: 1px solid #ececec;
  padding: 30px;
}
.review-content h4, .review-content .h4 {
  font-weight: 600;
  font-size: 17px;
  color: #161c2d;
}
.review-content p {
  font-size: 15px;
  margin: 12px 0px;
  font-weight: 500;
}
.review-content.no-padding {
  padding: 0 0 1.5rem 0;
}
.review-content.no-padding + .review-content.no-padding {
  padding-top: 1.5rem;
}
.review-content:last-child {
  padding-bottom: 0;
  border: 0;
}

.review-btn {
  background: #86E53E;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
}

.grey-btn {
  background: #D9D9D9;
}

.widget-profile {
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
}
.widget-profile .profile-info-widget {
  display: block;
  text-align: center;
}
.widget-profile .profile-info-widget .booking-pro-img {
  display: inline-block;
  margin: 0 0 15px;
  width: auto;
  padding: 8px;
  background-color: #f7f7f7;
  border-radius: 50%;
}
.widget-profile .profile-info-widget .booking-pro-img img {
  border-radius: 50%;
  height: 120px;
  width: 120px;
}
.widget-profile.pat-widget-profile .profile-info-widget .booking-pro-img {
  padding: 0;
}
.widget-profile.pat-widget-profile .profile-info-widget .booking-pro-img img {
  border-radius: 50%;
  height: 100px;
  width: 100px;
}

.info-details {
  list-style: none;
  margin: 0;
  padding: 0;
}
.info-details li {
  margin-bottom: 10px;
}
.info-details li .title {
  color: #161c2d;
  font-weight: 500;
}
.info-details li .text {
  color: #757575;
  display: block;
  font-size: 16px;
  overflow: hidden;
}
.info-details li:last-child {
  margin-bottom: 0;
}

.align-right {
  text-align: right;
}

.slider-labels {
  margin-top: 10px;
}
.slider-labels .caption {
  background: #FFEEEA;
  border-radius: 80px;
  padding: 3px 8px;
  text-align: center;
  margin: 0 auto;
  position: relative;
  top: 10px;
  font-weight: bold;
  font-size: 16px;
  color: #86E53E;
}
.slider-labels .caption::before {
  border: 5px solid #ffeeea;
  border-color: transparent transparent #ffeeea #ffeeea;
  box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
  content: "";
  left: 38px;
  position: absolute;
  top: 2px;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.noUi-target, .noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  direction: ltr;
  border-radius: 2px;
}

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 2px;
}

.noUi-handle {
  position: relative;
  z-index: 1;
  cursor: default;
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  box-sizing: content-box !important;
}
.noUi-handle:active {
  border: 8px solid #345DBB;
  border: 8px solid rgba(53, 93, 187, 0.38);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  left: -14px;
  top: -14px;
}

.noUi-stacking .noUi-handle {
  z-index: 10;
}

.noUi-state-tap .noUi-origin {
  -webkit-transition: left 0.3s, top 0.3s;
  -moz-transition: left 0.3s, top 0.3s;
  transition: left 0.3s, top 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

.noUi-base, .noUi-handle {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.noUi-horizontal {
  height: 4px;
}
.noUi-horizontal .noUi-handle {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  left: -7px;
  top: -7px;
  background-color: #86E53E;
  cursor: pointer;
}

.noUi-background {
  background: #EDEDED;
}

.noUi-connect {
  background: #86E53E;
  -webkit-transition: background 450ms;
  transition: background 450ms;
}

.noUi-draggable {
  cursor: w-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: n-resize;
}

[disabled].noUi-connect, [disabled] .noUi-connect {
  background: #B8B8B8;
}

[disabled].noUi-origin, [disabled] .noUi-handle {
  cursor: not-allowed;
}

.add-new-btn {
  background-color: #ff3115;
  border-radius: 30px;
  color: #fff;
  display: inline-block;
  font-weight: 500;
  margin-bottom: 20px;
  padding: 10px 20px;
}

.add-new-btn:focus, .add-new-btn:hover, .add-new-btn:active {
  background-color: #ff508b;
  color: #fff;
}

.customer-info {
  margin-top: 0;
}
.customer-info ul {
  padding: 0 0 10px;
  list-style: none;
  font-size: 0.875rem;
  margin: 0;
}
.customer-info ul li {
  position: relative;
  font-size: 0.875rem;
  font-weight: 600;
  color: #161c2d;
  text-transform: capitalize;
}
.customer-info ul li span {
  color: #757575;
  float: right;
  font-weight: 400;
}

.add-more-item {
  margin-bottom: 10px;
}
.add-more-item a {
  color: #ff3115;
  font-weight: 500;
}

.blog-image {
  overflow: hidden;
  margin-bottom: 30px;
}
.blog-image img {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  -moz-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -o-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}
.blog-image a:hover img {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  transform: scale(1.15);
}

.blog-image,
.blog-image > a,
.blog-image img {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
}

.blog-content {
  position: relative;
}
.blog-content p {
  font-size: 15px;
  margin: 0 0 20px;
  line-height: 1.7;
  font-weight: 500;
}
.blog-content p:last-child {
  margin-bottom: 0;
}

.blog-title {
  font-size: 22px;
  margin: 0 0 15px;
}
.blog-title a {
  color: #161c2d;
}
.blog-title a:hover {
  color: #ff3115;
}

.blog-read {
  margin-top: 15px;
}
.blog-read a {
  color: #86E53E;
  font-size: 15px;
  font-weight: 600;
}
.blog-read a:hover {
  color: #13323C;
}
.blog-read a i {
  font-size: 13px;
}

.read-more {
  display: inline-block;
  font-size: 16px;
  padding: 0;
  color: #86E53E;
  text-decoration: underline;
  font-weight: 500;
}

.blog-info {
  color: #606060;
  font-size: 15px;
  margin-bottom: 15px;
}
.blog-info a {
  color: #606060;
}
.blog-info a:hover {
  color: #ff3115;
}
.blog-info i {
  margin-right: 7px;
}

.entry-meta {
  color: #9D9D9D;
  font-size: 14px;
  list-style: none;
  margin-bottom: 15px;
  padding: 0;
}
.entry-meta li {
  display: inline-block;
  margin-right: 15px;
}
.entry-meta li:last-child {
  margin-right: 0;
}

.blog-pagination .pagination {
  margin-bottom: 30px;
}
.blog-pagination .pagination li a {
  padding: 12px 20px;
  font-weight: 600;
  color: #2E3842;
  margin-right: 10px;
  border-radius: inherit !important;
  border-color: #f0f0f0;
}
.blog-pagination .pagination li.disabled a {
  border-color: #f0f0f0;
}
.blog-pagination .pagination li.active a {
  background: #ff3115;
  border-color: transparent;
  color: #fff;
}

.post-left ul {
  margin: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.post-left ul li {
  margin-bottom: 5px;
  margin-right: 20px;
}
.post-left ul li:last-child {
  margin-right: 0;
}

.video {
  position: relative;
  padding-bottom: 56%;
  height: 0;
  overflow: hidden;
}

.video iframe,
.video object,
.video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.language-widget .latest-posts li {
  border-bottom: 0;
}

.latest-posts {
  margin: 0;
  padding: 0;
}
.latest-posts li {
  display: table;
  width: 100%;
  padding: 11px 0;
}
.latest-posts li:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.category-widget .latest-posts li {
  padding: 15px 30px;
}
.category-widget .latest-posts li:first-child {
  padding-top: 0;
}
.category-widget .latest-posts li:last-child {
  margin-bottom: 0;
  border-bottom: none;
}
.category-widget .category-link {
  list-style: none;
  padding: 0;
  margin: 0;
}
.category-widget .category-link li {
  margin-bottom: 17px;
}
.category-widget .category-link li a {
  color: #2E3842;
  font-size: 15px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  text-transform: capitalize;
  display: block;
  position: relative;
}
.category-widget .category-link li a:after {
  display: inline-block;
  position: absolute;
  right: 0;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #2E3842;
}
.category-widget .category-link li:last-child {
  margin-bottom: 0;
}
.category-widget .categories {
  list-style: none;
  margin: 0;
  padding: 0;
}
.category-widget .categories li {
  margin-bottom: 20px;
}
.category-widget .categories li:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.category-widget .categories li a {
  color: #2E3842;
  font-size: 15px;
  font-weight: 500;
}
.category-widget .categories li a span {
  float: right;
  color: #757575;
}
.category-widget .categories li a:hover {
  color: #ff3115;
}
.category-widget .categories > li > a > i {
  color: #73379a;
  font-size: 18px;
  margin-right: 10px;
}

.post-thumb {
  border-radius: 10px;
  width: 77px;
  float: left;
  overflow: hidden;
}
.post-thumb a img {
  border-radius: 10px;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  -moz-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -o-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}
.post-thumb a:hover img {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  transform: scale(1.15);
}

.b-0 {
  border: 0 !important;
}

.post-info {
  margin-left: 95px;
}
.post-info h4, .post-info .h4 {
  font-size: 16px;
  font-weight: bold;
  line-height: 25px;
  margin-bottom: 0;
}
.post-info h4 a, .post-info .h4 a {
  color: #161c2d;
}
.post-info h4 a:hover, .post-info .h4 a:hover {
  color: #ff3115;
}
.post-info p {
  color: #757575;
  font-size: 12px;
  margin: 0;
}
.post-info p i {
  color: #73379a;
  font-size: 14px;
  margin-right: 4px;
}
.post-info .posts-date {
  color: #6e727d;
  font-size: 14px;
}

.tags-widget .card-body {
  padding-bottom: 1rem;
}

.tag {
  display: inline-block;
  padding: 3px 20px;
  background-color: #E7E8EA;
  border-radius: 50px;
  font-size: 15px;
  color: #6e727d;
  margin: 0 10px 10px 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.tag:hover {
  background-color: #86E53E;
  border-color: #86E53E;
  color: #fff;
}

.comment-by {
  display: block;
  font-size: 14px;
  line-height: 21px;
  margin: 0 0 10px;
}

.comments-list .comment-block p {
  margin-bottom: 5px;
  color: #6e727d;
  line-height: 28px;
}

.blog-view .blog-title {
  font-size: 24px;
  font-weight: bold;
}
.blog-view .blog-info {
  border: 0;
  margin-bottom: 20px;
  padding: 0;
}

.social-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.social-links ul li {
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  display: inline-block;
  margin-right: 15px;
}
.social-links li a {
  display: block;
  color: #606060;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.social-links li a:hover {
  color: #86E53E;
}

.social-share {
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
}
.social-share > li {
  display: inline-block;
  float: left;
  margin-left: 10px;
  text-align: center;
}
.social-share > li:first-child {
  margin-left: 0;
}
.social-share > li > a {
  border: 1px solid #dfdfdf;
  color: #666;
  display: inline-block;
  font-size: 22px;
  height: 40px;
  line-height: 40px;
  width: 40px;
}
.social-share > li > a:hover {
  background-color: #73379a;
  color: #fff;
  border-color: #73379a;
}

.blog-reply > a {
  color: #999;
  font-size: 12px;
  font-weight: 500;
}

.blog-date {
  color: #999;
  font-size: 12px;
}

.blog-comments .comments-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}
.blog-comments .comments-list li {
  clear: both;
  padding-left: 80px;
}
.blog-comments .comments-list li .comment {
  margin-bottom: 40px;
}
.blog-comments .comments-list li .comment-author {
  left: 0;
  position: absolute;
}
.blog-comments .comments-list li img.avatar {
  height: 58px;
  width: 58px;
  border-radius: 58px;
}
.blog-comments .comment-btn {
  color: #606060;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
}

.blog-author-name {
  color: #161c2d;
  font-size: 18px;
  font-weight: bold;
  color: #161c2d;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 5px;
  font-weight: 700;
}

.new-comment label {
  font-weight: 500;
}

.comment-submit .btn {
  background-color: #ff508b;
  border-color: #ff508b;
  border-radius: 0;
  font-size: 18px;
  padding: 8px 26px;
  color: #fff;
}

.about-author {
  border-bottom: 1px solid #EAEAEA;
  padding: 30px;
}
.about-author:last-child {
  border-bottom: 0;
}

.about-author-img {
  background-color: #fff;
  height: 86px;
  overflow: hidden;
  position: absolute;
  width: 86px;
  border-radius: 50%;
}

.author-details {
  margin-left: 116px;
}
.author-details .blog-author-name {
  display: inline-block;
  margin-bottom: 10px;
}
.author-details h4, .author-details .h4 {
  font-weight: 500;
  font-size: 18px;
  color: #161c2d;
  margin-bottom: 6px;
}
.author-details p {
  font-size: 16px;
  color: #55545b;
}
.author-details h3, .author-details .h3 {
  font-weight: 500;
  font-size: 24px;
  color: #F5365C;
}
.author-details h5, .author-details .h5 {
  font-weight: normal;
  font-size: 14px;
  color: #F38247;
}
.author-details .rating {
  margin-bottom: 6px;
}

.widget-box {
  padding: 20px;
}

.widget-title-box {
  padding: 30px 30px 0;
}

.r-0 {
  right: 0 !important;
}

.follow-widget {
  padding: 30px;
  text-align: center;
  position: relative;
}

.custom-edit-btn {
  position: absolute;
  right: 30px;
  top: 30px;
}

.profile-group .input-group {
  background: #fff;
  border: 1px solid #E7E8EA;
  border-radius: 5px;
  padding: 2px;
}
.profile-group .input-group .form-control {
  border: transparent;
}
.profile-group .input-group .input-group-append {
  margin: 5px 5px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.profile-group .input-group .sub-btn {
  background: #86E53E;
  border-radius: 10px;
}

.attach-img {
  position: relative;
}

.attach-detail {
  position: absolute;
  bottom: 12px;
  left: 12px;
}
.attach-detail p {
  margin-bottom: 2px;
  font-size: 14px;
  color: #161c2d;
}
.attach-detail h6, .attach-detail .h6 {
  font-size: 14px;
  color: #A3A3A3;
  font-weight: normal;
  margin-bottom: 0;
}

.client-list {
  padding: 15px;
  text-align: center;
}
.client-list p {
  font-weight: 500;
  font-size: 16px;
  color: #5C5C5C;
  margin-bottom: 10px;
}
.client-list h6, .client-list .h6 {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 0;
}

.job-type {
  padding: 15px;
  text-align: center;
}
.job-type p {
  font-weight: 500;
  font-size: 14px;
  color: #4E4E4E;
  margin-bottom: 10px;
}
.job-type h6, .job-type .h6 {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 0;
  color: #161c2d;
}

.exp-detail {
  margin-bottom: 30px;
}
.exp-detail img {
  float: left;
}
.exp-detail p {
  font-weight: 500;
  font-size: 14px;
  color: #4E4E4E;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 16px;
  color: #161c2d;
}
.exp-detail .exp-info {
  margin-left: 40px;
}
.exp-detail .exp-info h5, .exp-detail .exp-info .h5 {
  margin-bottom: 0;
}

.management-widget .profile-btn {
  padding: 12px 0;
  font-size: 18px;
  text-transform: uppercase;
  margin-top: 20px;
}

.sub-title {
  font-weight: 500;
  font-size: 14px;
  color: #86E53E;
}

.budget-info {
  margin-bottom: 20px;
}

.list-details {
  display: flex;
  display: -ms-flexbox;
  justify-content: space-between;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}

.white-circle {
  background: #fff;
  border-radius: 110px;
  width: 25px;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  color: #86E53E;
}

.btn-view-outline {
  color: #86E53E;
  border-color: #86E53E;
  font-size: 14px;
}
.btn-view-outline:hover {
  color: #fff;
  background-color: #86E53E;
  border-color: #86E53E;
}

.w-80 {
  max-width: 80%;
}

.bg-none {
  background: transparent;
  border: 0;
}

.square-box {
  width: 34px;
  height: 34px;
  line-height: 34px;
  border: 1px solid;
  background: #F8F8F8;
  border: 1px solid #FFE6E0;
  text-align: center;
  border-radius: 5px;
}

.reply-group .input-group input {
  background: #F8F8F8;
  border: 0;
}
.reply-group .sub-btn {
  padding: 8px 40px;
  border: 0;
  border-radius: 0;
}

.profile-widget {
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  margin-bottom: 30px;
  position: relative;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  padding: 15px;
}
.profile-widget .rating {
  color: #757575;
  font-size: 14px;
  margin-bottom: 15px;
}
.profile-widget .fav-btn {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #fff;
  width: 30px;
  height: 30px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border-radius: 3px;
  color: #2E3842;
  -webkit-transform: translate3d(100%, 0, 0);
  -ms-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  opacity: 0;
  visibility: hidden;
  z-index: 99;
}
.profile-widget .fav-btn:hover {
  background-color: #fb1612;
  color: #fff;
}
.profile-widget:hover .fav-btn {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.profile-widget .pro-content .title a {
  display: inline-block;
}
.profile-widget .verified {
  color: #28a745;
  margin-left: 3px;
}
.profile-widget p.speciality {
  font-size: 13px;
  color: #757575;
  margin-bottom: 5px;
  min-height: 40px;
}

.pro-img {
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 4px;
}
.pro-img img {
  border-radius: 4px;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  -moz-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -o-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  width: 100%;
}
.pro-img:hover img {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  transform: scale(1.15);
}

.pro-content {
  padding: 15px 0 0;
}
.pro-content p {
  font-size: 15px;
  font-weight: 500;
}
.pro-content p:last-child {
  margin-bottom: 0;
}
.pro-content .title {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 5px;
}
.pro-content .book-btn {
  background-color: #115241;
  border: 2px solid #115241;
  color: #fff;
  font-size: 13px;
  text-align: center;
  display: block;
  font-weight: 500;
  padding: 6px;
  border-radius: 0.25rem;
}

.available-info {
  font-size: 13px;
  color: #757575;
  font-weight: 400;
  list-style: none;
  padding: 0;
  margin-bottom: 15px;
}
.available-info li + li {
  margin-top: 5px;
}
.available-info li i {
  width: 22px;
}

.row.row-sm {
  margin-left: -3px;
  margin-right: -3px;
}
.row.row-sm > div {
  padding-left: 3px;
  padding-right: 3px;
}

.view-btn {
  color: #115241;
  font-size: 13px;
  border: 2px solid #115241;
  text-align: center;
  display: block;
  font-weight: 500;
  padding: 6px;
}

.view-btn:hover, .view-btn:focus {
  background-color: #115241;
  color: #fff;
}

.book-btn:hover, .book-btn:focus {
  background-color: #0d5341;
  border-color: #115241;
  color: #fff;
}

.view-icons {
  display: inline-flex;
  display: -ms-inline-flexbox;
  float: right;
}
.view-icons a {
  align-items: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #161c2d;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  font-size: 20px;
  justify-content: center;
  padding: 4px 10px;
  text-align: center;
  margin-left: 10px;
  width: 44px;
  height: 44px;
}
.view-icons a:hover {
  border-color: #ff3115;
  color: #ff3115;
}
.view-icons a.active {
  background-color: #ff3115;
  border-color: #ff3115;
  color: #fff;
}

.terms-text {
  margin-bottom: 20px;
}
.terms-text h3, .terms-text .h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
  color: #86E53E;
  margin-bottom: 25px;
}
.terms-text h4, .terms-text .h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #000;
}
.terms-text p {
  color: #000;
  display: inline-block;
  font-size: 15px;
  line-height: 30px;
}
.terms-text ul {
  padding-left: 20px;
  margin-bottom: 25px;
}
.terms-text ul li {
  padding-left: 5px;
  color: #000;
}
.terms-text ul li + li {
  margin-top: 10px;
}
.terms-text ul li::marker {
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  left: 0;
  margin-right: 5px;
  position: absolute;
  top: 0;
  color: #86E53E;
}

.list-widget {
  box-shadow: 0px 2px 4px rgba(189, 189, 189, 0.25);
}
.list-widget .card-body {
  padding: 0;
}

.list-group-item {
  border: 1px solid #ffe6e1;
}
.list-group-item a {
  font-size: 16px;
  color: #161c2d;
}
.list-group-item a:hover {
  color: #86E53E;
}
.list-group-item ul li {
  padding: 0.75rem 0;
}
.list-group-item ul li::marker {
  content: "";
}
.list-group-item ul li:last-child {
  padding: 0;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #86E53E;
  border-color: #86E53E;
}

.web-project h4, .web-project .h4 {
  font-weight: 500;
  font-size: 22px;
  color: #86E53E;
}

.stone-btn {
  font-size: 16px;
  font-weight: bold;
  background: #86E53E;
  color: #fff;
  border-radius: 30px;
  padding: 18px 25px;
}

.nav-tabs-bottom .nav-link {
  padding: 1rem 1rem;
}

.quick-title {
  margin-bottom: 30px;
}

.btn-group button, .btn-group .btn {
  border-radius: 130px;
}

.in-btn {
  background: #86E53E;
  border: 1px solid transparent;
  padding: 10px 15px;
}

.out-btn {
  background: #FAFAFA;
  color: #161c2d;
  border: 1px solid transparent;
  padding: 10px 15px;
}

.btn-cancel {
  background: #86E53E;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  padding: 12px 20px;
  color: #fff;
}

.sort-show .select2-container .select2-selection--single {
  background: #fff;
  border: 1px solid #FFF5F3;
  box-shadow: 0px 4px 14px rgba(239, 239, 239, 0.35);
}
.sort-show .sort-title {
  color: #161c2d;
}

.sec-line {
  position: relative;
  display: inline-flex;
}
.sec-line::before {
  height: 3px;
  content: "";
  position: absolute;
  width: 29px;
  background: #FF3F00;
  bottom: 0;
  left: 0;
}
.sec-line::after {
  height: 3px;
  content: "";
  position: absolute;
  width: 11px;
  background: #FF3F00;
  bottom: 0;
  left: 32px;
}

.stats-box {
  background: #FBFCFF;
  border-radius: 5px;
  padding: 25px;
  height: 100%;
}

.stats-content {
  margin-top: 25px;
  display: -ms-flexbox;
  display: flex;
}

.stats-detail {
  background: #fff;
  box-shadow: 0px 4px 4px rgba(236, 236, 236, 0.25);
  border-radius: 5px;
  padding: 30px;
  text-align: center;
  width: 50%;
  margin: 0 5px;
}
.stats-detail h3, .stats-detail .h3 {
  color: #86E53E;
  font-family: Roboto;
  font-weight: 500;
  font-size: 34px;
}
.stats-detail p {
  font-family: Roboto;
  font-weight: 500;
}

.table-borderless.table tbody tr {
  border-bottom: 0;
}
.table-borderless.table tbody tr td {
  font-weight: 500;
  font-size: 22px;
  padding: 20px;
}

.grey-text {
  color: #8C8C8C;
  padding: 10px 20px;
}

.milestone-payment, .profit-transactions, .quick-stat {
  margin-bottom: 35px;
}

.position-title .sort-by {
  padding-left: 15px;
}

.finance-title .sort-show {
  float: right;
}
.finance-title .sort-show .sort-title {
  color: #86E53E;
}

.bg-bluelight {
  background: #F5F9FF;
}

.bg-light {
  background: #FCFCFC;
}

.text-rbold {
  font-weight: bold;
  font-size: 24px;
  color: #86E53E;
}

.transaction-position {
  background: #FBFCFF;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 30px;
}
.transaction-position h3, .transaction-position .h3 {
  font-size: 22px;
}

.transaction-payment {
  box-shadow: 0px 4px 14px rgba(206, 206, 206, 0.25);
  margin-bottom: 50px;
}
.transaction-payment .transaction-table {
  box-shadow: none;
}
.transaction-payment .tab-content {
  padding-top: 0;
}
.transaction-payment ul.nav-tabs-solid {
  padding: 10px;
}

.transaction-table {
  box-shadow: 0px 4px 14px rgba(206, 206, 206, 0.25);
}
.transaction-table .main-head {
  background: #FBFCFF;
}
.transaction-table .main-head .sort-show {
  padding-left: 15px;
}
.transaction-table .head {
  background: #F5F9FF;
}
.transaction-table .head-title {
  color: #86E53E;
  font-weight: 600;
  font-size: 24px;
}
.transaction-table .sort-title {
  font-size: 15px;
  font-weight: 500;
}
.transaction-table .table td {
  font-weight: 500;
}

.transaction-table .main-head th, .transaction-table .main-head td {
  border: 0;
}

.export-item {
  font-weight: 500;
  font-size: 17px;
  color: #86E53E;
}

.pink-btn {
  background: #FFF8F8;
  padding: 5px 10px;
}

.green-btn {
  background: #F0FFF3;
  padding: 5px 10px;
}

.dol-btn {
  background: #F8F8F8;
  border-radius: 5px;
  padding: 10px 30px;
}

.wallet, .payment-process {
  padding: 30px;
  background: #fff;
  box-shadow: 0px 4px 14px rgba(198, 198, 198, 0.25);
  border-radius: 0px 0px 10px 10px;
}

.payment-process .select2-container {
  z-index: unset;
}
.payment-process ul {
  padding: 0;
  margin: 0;
}
.payment-process ul li {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  padding: 12px 0;
  align-items: center;
}
.payment-process ul li:last-child {
  font-size: 18px;
  padding-bottom: 0;
  font-weight: 600;
}
.payment-process .sortby-fliter {
  width: 160px;
}
.payment-process .input-group.dep-amt {
  width: 160px;
}
.payment-process .input-group.dep-amt .form-control {
  border-color: #e9ecef;
  border-radius: 0;
  text-align: right;
}
.payment-process .input-group.dep-amt .input-group-text {
  border-radius: 0;
  padding: 10px 15px;
}
.payment-process p {
  padding-top: 20px;
  font-size: 14px;
  color: #777777;
}

.wallet-detail {
  background: #fff;
  box-shadow: 0px 4px 14px rgba(198, 198, 198, 0.25);
  border-radius: 0px 0px 10px 10px;
}

.wallet-title {
  padding: 25px;
}

.wallet-bal {
  background: #86E53E;
  padding: 25px;
  display: flex;
  align-items: center;
}
.wallet-bal p {
  color: #FFF;
  margin-bottom: 10px;
}
.wallet-bal h3, .wallet-bal .h3 {
  color: #FFF;
  font-size: 22px;
}

.dol-circle {
  width: 80px;
  height: 80px;
  background: #FF4117;
  border-radius: 50%;
  text-align: center;
  line-height: 80px;
  padding: 0;
  font-size: 38px;
  color: #FFF;
  display: block;
  float: left;
  margin-right: 30px;
}

.wallet-content {
  display: flex;
  flex-wrap: wrap;
}

.wallet-pay {
  width: 50%;
  text-align: center;
  padding: 25px;
}
.wallet-pay:first-child {
  border-right: 1px solid #EAEAEA;
}
.wallet-pay p {
  margin-bottom: 5px;
}
.wallet-pay h3, .wallet-pay .h3 {
  font-weight: 600;
  font-size: 22px;
}

.pay-btn {
  background: #86E53E;
  border-radius: 5px;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  padding: 12px 0;
}
.pay-btn:hover {
  color: #fff;
  opacity: 0.8;
}

.tot-border {
  border-bottom: 1px solid #ebebeb;
}

.card-list {
  margin: 0;
  padding: 0;
}
.card-list li {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  padding: 10px 0;
  align-items: center;
}

.column-5 {
  width: 20%;
}

.price-features {
  list-style-type: none;
  border: 1px solid #eee;
  margin: 0;
  padding: 0;
  background: #FDFFFE;
  border: 1px solid #F0EAEA;
  box-shadow: 0px 4px 14px rgba(198, 198, 198, 0.25);
  border-radius: 10px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin: 30px 5px 0 5px;
}
.price-features li {
  padding: 15px;
  text-align: center;
  color: #646464;
  font-weight: 500;
  font-size: 16px;
}
.price-features .header-price h3, .price-features .header-price .h3 {
  font-weight: 500;
  font-size: 30px;
  color: #86E53E;
}
.price-features .header-price p {
  font-size: 14px;
  margin-bottom: 0;
}
.price-features .yr-price {
  background: #FBFBFB;
}
.price-features .yr-price h3, .price-features .yr-price .h3 {
  font-weight: 500;
  font-size: 26px;
}
.price-features .yr-price p {
  font-size: 14px;
  margin-bottom: 0;
}
.price-features.plus-price .yr-price {
  background: transparent;
}
.price-features .subheader-price p {
  margin-bottom: 0;
  font-weight: 500;
}

.plus-price {
  background: #fffcfc;
  border: 1px solid #86E53E;
  margin-top: 0;
}
.plus-price .plan-btn {
  background: linear-gradient(0deg, #86E53E, #86E53E), #00A171;
}

.tab-btn {
  display: inline-flex;
}
.tab-btn .in-btn a {
  color: #fff;
}
.tab-btn .out-btn a {
  color: #161c2d;
}

.plan-btn {
  background: #13323C;
  border-radius: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #fff;
}
.plan-btn:hover {
  color: #fff;
  background: #fb451d;
}

.change-btn {
  background: linear-gradient(113.98deg, #86E53E 19.08%, #86E53E 68.11%), #86E53E;
  border-radius: 10px;
  font-weight: 600;
  font-size: 20px;
  color: #fff;
}

.plan-detail {
  background: #fff;
  box-shadow: 0px 4px 24px 10px rgba(231, 231, 231, 0.3);
  border-radius: 15px;
}

.bt-0 {
  border: 0;
}

.plan-left h2, .plan-left .h2 {
  color: #86E53E;
}

.plan-right {
  text-align: right;
}

.plan-body {
  padding: 30px;
}

.transaction-table .table td, .transaction-table .table th {
  border-top: 0;
}

.myprofile-widget {
  border: 1px solid #E8E8E8;
  box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.25);
  height: 100%;
}

.myprofile-header {
  background: #86E53E;
  padding: 20px;
}
.myprofile-header h2, .myprofile-header .h2 {
  color: #fff;
}
.myprofile-header p {
  color: #fff;
  margin-bottom: 12px;
}

.member-btn {
  background: #fff;
  border-radius: 50px;
  font-weight: 500;
  font-size: 16px;
  color: #86E53E;
}

.myprofile-body {
  background: #fff;
  padding: 20px;
}

.prog-card {
  background-color: #F4F4F4;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.prog-acc {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.prog-acc h5, .prog-acc .h5 {
  font-weight: 500;
  font-size: 14px;
  color: #A8A8A8;
}

.icon-circle {
  background: #86E53E;
  width: 29px;
  height: 29px;
  display: inline-block;
  line-height: 29px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
}

.icon-grey {
  background: #F4F4FA;
  width: 32px;
  height: 32px;
  display: inline-block;
  line-height: 32px;
  text-align: center;
  border-radius: 50%;
  color: #C8C8D8;
  font-size: 18px;
}

.progress-bar {
  background: #159C5B;
  width: 25%;
}

.progress-info {
  width: 20%;
}
.progress-info h5, .progress-info .h5 {
  color: #343434;
  font-size: 15px;
  font-weight: 500;
}
.progress-info p {
  margin-bottom: 0;
  margin-left: 10px;
}

.fund-btn {
  background: #13323C;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
}
.fund-btn:hover {
  color: #fff;
}

.pro-head {
  border-bottom: 1px solid #F8F8F8;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pro-head h2, .pro-head .h2 {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 0;
}

.pro-body {
  padding: 20px;
}

.news-feature {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
}
.news-feature img {
  margin-right: 15px;
}
.news-feature p {
  font-size: 14px;
  margin-bottom: 0;
}
.news-feature h5, .news-feature .h5 {
  font-weight: 600;
  font-size: 14px;
}

.table-budget {
  font-weight: 500;
  font-size: 14px;
  color: #161c2d;
}

.text-expiry {
  color: #161c2d;
  font-weight: 600;
  font-size: 12px;
}

.detail-text {
  font-size: 15px;
  color: #161c2d;
}

.table-job .table td {
  font-weight: 500;
  font-size: 14px;
  color: #5F5F5F;
}

.dev-feature {
  padding: 20px;
}
.dev-feature h4, .dev-feature .h4 {
  font-weight: 500;
  color: #86E53E;
}
.dev-feature p {
  font-size: 14px;
  margin-bottom: 10px;
}
.dev-feature h5, .dev-feature .h5 {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 15px;
  color: #808080;
}

.dev-feature:not(:last-child), .news-feature:not(:last-child) {
  border-bottom: 1px solid #F3F3F3;
}

.bgg-yellow {
  background: #FF7A03;
  color: #fff;
  border-radius: 50%;
  width: 17px;
  line-height: 17px;
  padding: 0;
  height: 17px;
}

.filter-form {
  background: #fff;
  box-shadow: 0px 4px 24px rgba(200, 200, 200, 0.25);
  padding: 30px;
  margin-bottom: 40px;
}

.rate-box {
  background: #86E53E;
  border-radius: 0px 0px 30px 30px;
  width: 54px;
  height: 66px;
  line-height: 66px;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  color: #fff;
}

.bg-black {
  background: #13323C;
  border: 1px solid #13323C;
}

.setting-tab .nav-link {
  padding: 15px 30px;
  font-weight: 600;
  font-size: 16px;
  color: #86E53E;
}
.setting-tab .nav-link.active {
  background: #86E53E;
  border-radius: 80px 0px 0px 80px;
  color: #fff;
}

.setting-content {
  padding-top: 0;
  margin-bottom: 30px;
}
.setting-content .pro-card {
  background: #fff;
  box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
  border-radius: 5px;
  border: 0;
}
.setting-content .pro-pad {
  padding: 20px;
}
.setting-content .pro-head h2, .setting-content .pro-head .h2 {
  font-size: 28px;
}

.pay-tab .nav-link {
  background: #FFF8F7;
  padding: 35px;
  color: #161c2d;
}

.chat-page .content {
  padding: 110px 0 0 0;
}
.chat-page .footer {
  display: none;
}
.chat-page footer {
  display: none;
}

.chat-window {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
}
.chat-window .chat-scroll {
  min-height: calc(100vh - 328px);
  max-height: calc(100vh - 328px);
  overflow-y: auto;
}

.chat-cont-left {
  border-right: 1px solid #f0f0f0;
  -ms-flex: 0 0 34%;
  flex: 0 0 34%;
  left: 0;
  margin-right: 1%;
  max-width: 35%;
  position: relative;
  z-index: 4;
  background: #fff;
  box-shadow: 0px 4px 14px rgba(198, 198, 198, 0.25);
  border-radius: 0px 0px 10px 10px;
}
.chat-cont-left .chat-header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
  color: #161c2d;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 72px;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  padding: 0 15px;
}
.chat-cont-left .chat-header span {
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
}
.chat-cont-left .chat-header .chat-compose {
  color: #8a8a8a;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.chat-cont-left .chat-search {
  background-color: #fff;
  width: 100%;
}
.chat-cont-left .chat-search .input-group {
  width: 100%;
}
.chat-cont-left .chat-search .input-group .form-control {
  background-color: #fff;
  border-radius: 50px;
  padding-left: 36px;
}
.chat-cont-left .chat-search .input-group .form-control:focus {
  border-color: #ccc;
  box-shadow: none;
}
.chat-cont-left .chat-search .input-group .input-group-prepend {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  bottom: 0;
  color: #666;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  right: 15px;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 4;
}
.chat-cont-left .chat-users-list {
  background-color: #fff;
}
.chat-cont-left .chat-users-list a.media {
  background: #fff;
  box-shadow: 0px 4px 4px #f5f8ff;
  margin: 10px;
  padding: 10px 15px;
  transition: all 0.2s ease 0s;
}
.chat-cont-left .chat-users-list a.media:last-child {
  border-bottom: 0;
}
.chat-cont-left .chat-users-list a.media .media-img-wrap {
  margin-right: 15px;
  position: relative;
}
.chat-cont-left .chat-users-list a.media .media-img-wrap .avatar {
  height: 45px;
  width: 45px;
}
.chat-cont-left .chat-users-list a.media .media-img-wrap .status {
  bottom: 7px;
  height: 10px;
  right: 4px;
  position: absolute;
  width: 10px;
  border: 2px solid #fff;
}
.chat-cont-left .chat-users-list a.media .media-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name {
  color: #86E53E;
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
}
.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
  color: #232323;
  font-size: 12px;
  line-height: 24px;
}
.chat-cont-left .chat-users-list a.media .media-body > div:last-child {
  text-align: right;
}
.chat-cont-left .chat-users-list a.media .media-body > div:last-child .last-chat-time {
  color: #8a8a8a;
  font-size: 13px;
}
.chat-cont-left .chat-users-list a.media:hover {
  background-color: #f5f5f6;
}
.chat-cont-left .chat-users-list a.media.read-chat .media-body > div:last-child .last-chat-time {
  color: #8a8a8a;
}
.chat-cont-left .chat-users-list a.media.active {
  background-color: #f5f5f6;
}

.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name,
.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat-cont-right {
  -ms-flex: 0 0 65%;
  flex: 0 0 65%;
  max-width: 65%;
  background: #fff;
  box-shadow: 0px 4px 14px rgba(198, 198, 198, 0.25);
  border-radius: 0px 0px 10px 10px;
  padding: 10px;
}
.chat-cont-right .chat-header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  border: 1px solid #F4EEFF;
  box-shadow: 0px 4px 4px #F5F8FF;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 72px;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  padding: 0 15px;
}
.chat-cont-right .chat-header .back-user-list {
  display: none;
  margin-right: 5px;
  margin-left: -7px;
}
.chat-cont-right .chat-header .media {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.chat-cont-right .chat-header .media .media-img-wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 15px;
}
.chat-cont-right .chat-header .media .media-img-wrap .avatar {
  height: 50px;
  width: 50px;
}
.chat-cont-right .chat-header .media .media-img-wrap .status {
  border: 2px solid #fff;
  bottom: 0;
  height: 10px;
  position: absolute;
  right: 3px;
  width: 10px;
}
.chat-cont-right .chat-header .media .media-body .user-name {
  color: #161c2d;
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
}
.chat-cont-right .chat-header .media .media-body .user-status {
  color: #666;
  font-size: 14px;
}
.chat-cont-right .chat-header .chat-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.chat-cont-right .chat-header .chat-options > a {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  color: #8a8a8a;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 30px;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin-left: 10px;
  width: 30px;
}
.chat-cont-right .chat-body {
  background-color: #fff;
}
.chat-cont-right .chat-body ul.list-unstyled {
  margin: 0 auto;
  padding: 15px;
  width: 100%;
}
.chat-cont-right .chat-body .media .avatar {
  height: 30px;
  width: 30px;
  bottom: 0;
  position: absolute;
}
.chat-cont-right .chat-body .media .media-body {
  margin-left: 45px;
}
.chat-cont-right .chat-body .media .media-body .msg-box > div {
  padding: 10px 15px;
  border-radius: 15px;
  display: inline-block;
  position: relative;
}
.chat-cont-right .chat-body .media .media-body .msg-box > div p {
  color: #fff;
  margin-bottom: 0;
  font-size: 15px;
}
.chat-cont-right .chat-body .media .media-body .msg-box + .msg-box {
  margin-top: 5px;
}
.chat-cont-right .chat-body .media.received {
  margin-bottom: 20px;
  position: relative;
}
.chat-cont-right .chat-body .media.received .media-body .msg-box {
  position: relative;
}
.chat-cont-right .chat-body .media.received .media-body .msg-box > div {
  background-color: #86E53E;
}
.chat-cont-right .chat-body .media.received .media-body .msg-box > div .chat-time {
  color: #f6f6f6;
}
.chat-cont-right .chat-body .media.received .media-body .msg-box:first-child:before {
  border-bottom: 15px solid #86E53E;
  border-right: 15px solid #86E53E;
  border-top: 15px solid transparent;
  border-left: 15px solid transparent;
  content: "";
  height: 0;
  left: -10px;
  position: absolute;
  right: auto;
  bottom: 12px;
  width: 0;
}
.chat-cont-right .chat-body .media:last-child {
  margin-bottom: 0;
}
.chat-cont-right .chat-body .media.sent {
  margin-bottom: 20px;
  position: relative;
}
.chat-cont-right .chat-body .media.sent .avatar {
  height: 30px;
  width: 30px;
  bottom: 0;
  right: 0;
  position: absolute;
}
.chat-cont-right .chat-body .media.sent .media-body {
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  margin-left: 0;
  margin-right: 45px;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box {
  padding-left: 50px;
  position: relative;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div {
  background-color: #E8EFFF;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div p {
  color: #232323;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-time {
  color: #919191;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-info {
  flex-direction: row-reverse;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-info li {
  padding-left: 16px;
  padding-right: 0;
  position: relative;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-attachments {
  flex-direction: row-reverse;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div:hover .chat-msg-actions {
  opacity: 1;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box h6, .chat-cont-right .chat-body .media.sent .media-body .msg-box .h6 {
  text-align: right;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box:first-child:before {
  border-bottom: 15px solid #e8efff;
  border-left: 15px solid #e8efff;
  border-top: 15px solid transparent;
  border-right: 15px solid transparent;
  content: "";
  height: 0;
  left: auto;
  position: absolute;
  right: -10px;
  bottom: 12px;
  width: 0;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box .chat-msg-info li:not(:last-child)::after {
  right: auto;
  left: 8px;
  transform: translate(-50%, -50%);
  background: #aaa;
}
.chat-cont-right .chat-body .chat-date {
  font-size: 14px;
  margin: 1.875rem 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-transform: capitalize;
}
.chat-cont-right .chat-body .chat-date:before {
  background-color: #e0e3e4;
  content: "";
  height: 1px;
  margin-right: 28px;
  position: absolute;
  right: 50%;
  top: 50%;
  width: 100%;
}
.chat-cont-right .chat-body .chat-date:after {
  background-color: #e0e3e4;
  content: "";
  height: 1px;
  left: 50%;
  margin-left: 28px;
  position: absolute;
  top: 50%;
  width: 100%;
}
.chat-cont-right .chat-footer {
  background-color: #fff;
  border-top: 1px solid #f0f0f0;
  padding: 10px 0px;
  position: relative;
}
.chat-cont-right .chat-footer .input-group {
  width: 100%;
  background-color: #f5f5f6;
  border: none;
  border-radius: 50px;
  padding: 5px;
}
.chat-cont-right .chat-footer .input-group .form-control {
  background-color: #f5f5f6;
  border: none;
  border-radius: 50px;
}
.chat-cont-right .chat-footer .input-group .form-control:focus {
  background-color: #f5f5f6;
  border: none;
  box-shadow: none;
}
.chat-cont-right .chat-footer .input-group .btn.msg-send-btn {
  background-color: #86E53E;
  border-color: #86E53E;
  border-radius: 48px;
  width: 48px;
  height: 48px;
  color: #fff;
  font-size: 26px;
  font-weight: 600;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
}

.chat-cont-right .chat-footer .input-group .btn,
.chat-cont-right .chat-footer .input-group .btn {
  background-color: transparent;
  border: none;
  color: #9f9f9f;
}

.msg-typing {
  width: auto;
  height: 24px;
  padding-top: 8px;
}
.msg-typing span {
  height: 8px;
  width: 8px;
  float: left;
  margin: 0 1px;
  background-color: #a0a0a0;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}
.msg-typing span:nth-of-type(1) {
  animation: 1s blink infinite 0.33333s;
}
.msg-typing span:nth-of-type(2) {
  animation: 1s blink infinite 0.66666s;
}
.msg-typing span:nth-of-type(3) {
  animation: 1s blink infinite 0.99999s;
}

.chat-cont-left .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #ff5837;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.chat-cont-right .chat-body .media.received .media-body .msg-box h6, .chat-cont-right .chat-body .media.received .media-body .msg-box .h6, .chat-cont-right .chat-body .media.sent .media-body .msg-box h6, .chat-cont-right .chat-body .media.sent .media-body .msg-box .h6 {
  font-size: 10px;
  margin-bottom: 0;
  color: #86E53E;
}

.chat-msg-info {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  clear: both;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 5px 0 0;
}
.chat-msg-info li {
  font-size: 13px;
  padding-right: 16px;
  position: relative;
}
.chat-msg-info li:not(:last-child):after {
  position: absolute;
  right: 8px;
  top: 50%;
  content: "";
  height: 4px;
  width: 4px;
  background: #d2dde9;
  border-radius: 50%;
  transform: translate(50%, -50%);
}
.chat-msg-info li a {
  color: #777;
}
.chat-msg-info li a:hover {
  color: #2c80ff;
}

.chat-seen i {
  color: #00d285;
  font-size: 16px;
}

.chat-msg-attachments {
  padding: 4px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: 0 -1px;
}
.chat-msg-attachments > div {
  margin: 0 1px;
}

.chat-attachment {
  position: relative;
  max-width: 130px;
  overflow: hidden;
  border-radius: 0.25rem;
}
.chat-attachment img {
  max-width: 100%;
}
.chat-attachment:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  content: "";
  opacity: 0.4;
  transition: all 0.4s;
}
.chat-attachment:hover:before {
  opacity: 0.6;
}
.chat-attachment:hover .chat-attach-caption {
  opacity: 0;
}
.chat-attachment:hover .chat-attach-download {
  opacity: 1;
}

.chat-attach-caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  padding: 7px 15px;
  font-size: 13px;
  opacity: 1;
  transition: all 0.4s;
}

.chat-attach-download {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.4s;
  color: #fff;
  width: 32px;
  line-height: 32px;
  background: rgba(255, 255, 255, 0.2);
  text-align: center;
}
.chat-attach-download:hover {
  color: #495463;
  background: #fff;
}

.chat-attachment-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: -5px;
}
.chat-attachment-list li {
  width: 33.33%;
  padding: 5px;
}

.chat-attachment-item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 5px solid rgba(230, 239, 251, 0.5);
  height: 100%;
  min-height: 60px;
  text-align: center;
  font-size: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.chat-msg-actions {
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: all 0.4s;
  z-index: 2;
}
.chat-msg-actions > a {
  padding: 0 10px;
  color: #495463;
  font-size: 24px;
}
.chat-msg-actions > a:hover {
  color: #2c80ff;
}

.call-box .call-wrapper {
  height: auto;
  text-align: center;
}
.call-box .call-wrapper .call-avatar {
  margin-bottom: 30px;
  cursor: pointer;
  animation: ripple 2s infinite;
}
.call-box .call-wrapper .call-user {
  margin-bottom: 30px;
}
.call-box .call-wrapper .call-user span {
  display: block;
  font-weight: 500;
  text-align: center;
}
.call-box .call-wrapper .call-user h4, .call-box .call-wrapper .call-user .h4 {
  font-size: 24px;
}
.call-box .call-wrapper .call-items {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.call-box .call-wrapper .call-items .call-item {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid transparent;
  border-radius: 100%;
  color: #fff;
  line-height: 0;
  margin: 0 5px;
  padding: 15px;
}
.call-box .call-wrapper .call-items .call-item:hover {
  opacity: 0.9;
}
.call-box .call-wrapper .call-items .call-item:first-child {
  margin-top: -30px;
}
.call-box .call-wrapper .call-items .call-item:last-child {
  margin-top: -30px;
}
.call-box .call-wrapper .call-items .call-item.call-end {
  padding: 20px;
  margin: 30px 20px 0;
  background: #f06060;
  border: 1px solid #f06060;
  color: #fff;
  line-height: 0;
  border-radius: 100%;
}
.call-box .call-wrapper .call-items .call-item.call-start {
  padding: 20px;
  margin: 30px 20px 0;
  background: #55ce63;
  border: 1px solid #55ce63;
  color: #fff;
  line-height: 0;
  border-radius: 100%;
}
.call-box.incoming-box .call-wrapper .call-items .call-item.call-start {
  margin: 0 10px;
}
.call-box.incoming-box .call-wrapper .call-items .call-item.call-end {
  margin: 0 10px;
}
.call-box .call-avatar {
  border-radius: 100%;
  height: 140px;
  max-width: 140px;
  min-width: 140px;
  position: relative;
  width: 100%;
  border: 10px solid #fafafa;
}
.call-box .btn {
  background: rgba(0, 0, 0, 0);
  transition: all 0.3s ease 0s;
}

.call-wrapper {
  position: relative;
  height: calc(100vh - 145px);
}

.call-page .footer {
  display: none;
}

.dropdown-action .dropdown-toggle::after {
  display: none;
}

.call-modal .modal-body {
  padding: 40px;
}
.call-modal .modal-content {
  border: 0;
  border-radius: 10px;
}

.pro-edit {
  background: #F9F9F9;
  height: 560px;
  overflow-y: hidden;
  margin-top: 20px;
  padding: 20px;
}

.profile-update-btn, .profile-cancel-btn {
  font-size: 15px;
  font-weight: 600;
  padding: 12px 25px;
  border-radius: 4px;
  margin-top: 10px;
}

.profile-cancel-btn {
  background: #DEDEDE;
  color: #161c2d;
}

.edit-sub-title {
  background: #F8F8F8;
  padding: 10px;
  border-radius: 50%;
  color: #161c2d;
}

.profile-edit-form .form-control, .profile-edit-form .select2-container .select2-selection--single {
  background: #fff;
  border: 1px solid rgba(239, 239, 239, 0.42);
  box-sizing: border-box;
  box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
  border-radius: 5px;
}

.form-check-label {
  font-size: 14px;
  color: #616161;
}

.submit-profile-sec a {
  padding: 8px 20px;
}

.input-group-text {
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
}

.pro-new, .pro-new1, .pro-new2, .pro-new3 {
  display: none;
}

.pro-text, .pro-text1, .pro-text2, .pro-text3 {
  display: block;
  line-height: 30px;
}

.dropdown.profile-action {
  position: absolute;
  right: 5px;
  text-align: right;
  top: 10px;
}

.profile-action .dropdown-toggle::after {
  display: none;
}
.profile-action .dropdown-menu {
  background: #fff;
  border: 1px solid #FFE6E0;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(165, 165, 165, 0.25);
  color: #86E53E;
}
.profile-action .dropdown-menu .dropdown-item {
  color: #86E53E;
}

.add-skills .modal-header {
  background: #FFF8F8;
  padding: 25px;
  margin: 30px;
  text-align: center;
  border-bottom: none;
  border-radius: 5px 5px 0px 0px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.add-skills .modal-title {
  font-size: 24px;
  font-weight: 700;
}
.add-skills .list-group-item {
  border: 0px solid #ffe6e1;
}
.add-skills .menu-arrow {
  -webkit-transition: -webkit-transform 0.15s;
  -o-transition: -o-transform 0.15s;
  transition: transform 0.15s;
  position: absolute;
  right: 15px;
  display: inline-block;
  font-family: "FontAwesome";
  text-rendering: auto;
  line-height: 40px;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  line-height: 18px;
  top: 11px;
}
.add-skills .menu-arrow:before {
  content: "\f105";
}
.add-skills li a.subdrop .menu-arrow {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.add-skills .card {
  background: #fff;
  border: 1px solid rgba(239, 239, 239, 0.42);
  box-sizing: border-box;
  box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
  border-radius: 5px;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 1;
  margin-bottom: 5rem;
}
.hr-text:before {
  content: "";
  background: #E9E9E9;
  position: absolute;
  left: 0;
  top: 30px;
  width: 100%;
  height: 1px;
}
.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  padding: 0 0.5em;
  color: #fff;
  background-color: #86E53E;
  width: 66px;
  height: 66px;
  border-radius: 50%;
  line-height: 66px;
}

.skill-list-group {
  padding: 20px;
}

.f-7 {
  font-size: 7px;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  z-index: 2;
  color: #86E53E;
  background-color: #FFF7F7;
  border-color: #FFF7F7;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  background-color: #86E53E;
  border-color: #86E53E;
}

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #86E53E;
  border-color: #86E53E;
}

.f-20 {
  font-size: 20px;
}

.project-details-tab .nav-tabs {
  border-bottom: 1px solid #86E53E;
  background: #86E53E;
}
.project-details-tab .nav-tabs > li > a {
  color: #fff;
  font-weight: normal;
}
.project-details-tab .profile-action {
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: #fff;
  text-align: center;
  border-radius: 50%;
  right: 20px;
  top: 14px;
}

.project-details-tab .nav-tabs .nav-item.show .nav-link, .project-details-tab .nav-tabs .nav-link.active {
  color: #fff;
}

.project-details-tab .nav-tabs.nav-tabs-bottom > li > a.active, .project-details-tab .nav-tabs.nav-tabs-bottom > li > a.active:hover, .project-details-tab .nav-tabs.nav-tabs-bottom > li > a.active:focus {
  border-bottom-width: 5px;
  border-color: transparent;
  border-bottom-color: #fff;
}

.project-details-tab .nav-tabs .nav-link:focus, .project-details-tab .nav-tabs .nav-link:hover {
  background-color: #86E53E;
  border-color: transparent;
  color: #fff;
  border-bottom-width: 5px;
  border-bottom-color: #fff;
}

.btn-purple {
  background: #1E1A60;
  border-radius: 5px;
  color: #fff;
}

.project-details-table .select2-container .select2-selection--single {
  height: 40px;
  line-height: 40px;
}
.project-details-table .select2-container--default .select2-selection--single .select2-selection__arrow b {
  top: 40%;
}

.task-btn {
  align-items: center;
  border: 3px dashed #dedede;
  border-radius: 0;
  color: #4d525b;
  cursor: pointer;
  display: flex;
  height: 96px;
  justify-content: center;
  margin-bottom: 20px;
}

.task-form {
  background: #FAFAFA;
  margin-top: 40px;
  padding: 30px;
}

.close-task {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #c7c5c5;
}

.action-task span {
  margin-right: 15px;
}

.settings-widget {
  background: #fff;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.25);
  margin-bottom: 30px;
}

.settings-header {
  background: #86E53E;
  padding: 20px;
}
.settings-header p {
  color: #fff;
}

.settings-header h3, .settings-header .h3, .settings-header h3 a, .settings-header .h3 a {
  color: #fff;
  font-size: 22px;
}

.settings-menu ul {
  display: block;
  border: 0;
  padding: 20px;
  margin: 0;
  list-style: none;
}
.settings-menu ul li + li {
  margin-top: 25px;
}
.settings-menu ul li a {
  color: #161c2d;
  font-size: 15px;
  font-weight: 600;
  padding: 0;
  border: 0 !important;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  line-height: normal;
}
.settings-menu ul li a.active {
  color: #86E53E !important;
  border: 0;
}
.settings-menu ul li a i {
  margin-right: 8px;
  font-size: 20px;
}

.settings-menu ul li a:hover, .settings-menu ul li a:focus {
  color: #86E53E;
  background-color: transparent !important;
  border: 0 !important;
}

.dash-widget {
  background: #fff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 4px 4px rgba(200, 200, 200, 0.25);
  margin-bottom: 30px;
}

.dash-info {
  padding: 20px;
  border-bottom: 1px solid #f0f0f0;
}

.dash-widget-info {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
  color: #161c2d;
}

.dash-widget-count {
  font-weight: bold;
  font-size: 28px;
  color: #86E53E;
  line-height: 1.2;
}

.dash-widget-more {
  padding: 20px;
}
.dash-widget-more a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 500;
}

.plan-details h4, .plan-details .h4 {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 0;
  color: #86E53E;
}
.plan-details h3, .plan-details .h3 {
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 1px;
  color: #86E53E;
}
.plan-details .expiry {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 25px;
}

.yr-amt {
  font-size: 15px;
  margin-bottom: 25px;
}

.yr-duration {
  font-size: 14px;
  margin-bottom: 25px;
}

.btn-plan {
  border-radius: 10px;
}

.plan-feature ul {
  list-style: none;
  margin: 0;
  padding: 0;
  outline: none;
}
.plan-feature ul li {
  margin-bottom: 23px;
  position: relative;
  font-size: 14px;
  padding-left: 25px;
}
.plan-feature ul li:last-child {
  margin-bottom: 0;
}
.plan-feature ul li::before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  color: #4B9F18;
  left: 0;
  margin-right: 5px;
  position: absolute;
  top: 0;
}
.plan-feature a {
  font-weight: 600;
  font-size: 14px;
  color: #86E53E;
}

.on-project {
  border-bottom: 1px solid #F7F7F7;
  padding: 20px;
}
.on-project h5, .on-project .h5 {
  font-weight: 500;
  font-size: 16px;
  color: #86E53E;
  margin-bottom: 5px;
}
.on-project p {
  font-weight: 500;
  font-size: 12px;
  color: #948E8E;
  margin-bottom: 10px;
}

.pro-info .list-details {
  margin-bottom: 0;
}
.pro-info .slot p {
  font-weight: 500;
  font-size: 12px;
  color: #161c2d;
  margin-bottom: 5px;
}
.pro-info .slot h5, .pro-info .slot .h5 {
  font-weight: 600;
  font-size: 13px;
  color: #838383;
}

.verify-box {
  border: 1px solid #86E53E;
  border-radius: 2px;
  padding: 25px;
}
.verify-box img {
  margin-bottom: 20px;
}
.verify-box h5, .verify-box .h5 {
  font-size: 20px;
  margin-bottom: 20px;
}
.verify-box a {
  font-weight: 600;
  color: #86E53E;
  text-decoration: underline;
}

.back-text {
  text-align: right;
  margin-bottom: 20px;
  padding-right: 25px;
}

.back-btn {
  padding: 5px 20px;
  border-radius: 50px;
  font-weight: bold;
  font-size: 14px;
}

.trash-icon {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 30px;
  width: 30px;
  display: -webkit-box;
  border-radius: 50%;
  background: #F5365C;
  color: #fff;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.trash-icon:hover {
  color: #fff;
  opacity: 0.8;
}

.avatar-medium {
  border-radius: 5px;
  width: 76px;
  height: 46px;
}

.pro-pic {
  margin-bottom: 0;
}
.pro-pic p {
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 0;
}

.light-pink-text {
  font-size: 12px;
  color: #F38247;
  padding-top: 10px;
}

.btn-list {
  background: rgba(255, 91, 55, 0.1);
  padding: 20px;
  border-radius: 5px;
}

.proedit-widget {
  background: #fff;
  border: 1px solid #D6D6D6;
  box-shadow: 0px 4px 14px 10px rgba(241, 241, 241, 0.3);
  border-radius: 5px;
  padding: 15px;
}
.proedit-widget .btn {
  height: 50px;
}

.proedit-header {
  padding-bottom: 40px;
}
.proedit-header h4, .proedit-header .h4 {
  font-weight: 500;
  font-size: 20px;
}

.proedit-body {
  list-style: none;
  padding: 0;
  margin: 0;
}
.proedit-body li {
  padding-bottom: 40px;
  display: -ms-flexbox;
  display: flex;
}
.proedit-body li span {
  color: #86E53E;
  margin-right: 12px;
}

.small-text {
  font-size: 12px;
  color: #86E53E;
  margin-bottom: 0;
}

.del-modal i {
  color: #DFDFDF;
  margin-bottom: 35px;
}

.black-btn {
  background: #13323C;
  border: 1px solid #13323C;
}

.portfolio-img {
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 0;
}
.portfolio-img .portfolio-live {
  transition: 0.5s ease;
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  visibility: hidden;
  z-index: 99;
  color: #fff;
}
.portfolio-img .portfolio-content {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  position: absolute;
}
.portfolio-img:hover .portfolio-live {
  opacity: 1;
  visibility: visible;
  background: rgba(255, 91, 55, 0.7);
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.port-icon {
  width: 34px;
  height: 34px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  background: #fff;
  border-radius: 50%;
  color: #86E53E;
}

.portfolio-detail {
  text-align: center;
  padding: 30px;
}
.portfolio-detail .pro-name {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
  text-transform: none;
}

.hire-select .select2-container .select2-selection--single {
  background: #86E53E;
  color: #fff;
  border-color: #86E53E;
}
.hire-select .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #fff;
}
.hire-select .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #fff transparent transparent;
}

.br-0 {
  border-radius: 0 !important;
}

.port-title h3, .port-title .h3 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 35px;
}

.projects-list h2, .projects-list .h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.prolist-details {
  display: flex;
  list-style: none;
  margin: 0;
  flex-wrap: wrap;
  display: flex;
  display: -ms-flexbox;
  padding: 0;
}
.prolist-details h5, .prolist-details .h5 {
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 5px;
  color: #6e727d;
  font-weight: 500;
}
.prolist-details p {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}
.prolist-details li:not(:last-child) {
  margin-right: 40px;
}
.prolist-details .price {
  font-size: 23px;
  color: #F5365C;
  font-weight: 600;
  margin-bottom: 0;
}

.pro-overview h4, .pro-overview .h4 {
  font-size: 18px;
}
.pro-overview p {
  font-size: 15px;
  font-weight: 500;
}

.red-text {
  color: #FF0000;
}

.hire-box h6, .hire-box .h6 {
  font-weight: bold;
  font-size: 14px;
  color: #86E53E;
}
.hire-box p {
  font-weight: 600;
  font-size: 14px;
}

.btn-chat {
  font-weight: 500;
  font-size: 12px;
  color: #86E53E;
  background: #fff;
  border: 1px solid #D8D8D8;
  border-radius: 70px;
}

.bg-success-dark {
  background-color: #4B9F18;
  border: 1px solid #4B9F18;
  padding: 10px 15px;
  color: #fff;
  width: 80px;
  border-radius: 10px;
}

.bg-grey-dark {
  background-color: #808080;
  border: 1px solid #808080;
  padding: 10px 15px;
  color: #fff;
  width: 80px;
  border-radius: 10px;
}

.bg-pink-dark {
  background: #F5365C;
  border-radius: 5px;
  padding: 10px 20px;
  color: #fff;
  border-radius: 10px;
}

.bg-grey-light {
  background: #DCDCDC;
  border-radius: 5px;
  padding: 10px 20px;
  color: #161c2d;
  border-radius: 10px;
}

.bg-outline-red {
  border: 1px solid #86E53E;
  border-radius: 20px;
  padding: 10px 15px;
  font-size: 14px;
  color: #86E53E;
}

.bg-outline-green {
  border: 1px solid #4B9F18;
  border-radius: 20px;
  padding: 10px 15px;
  font-size: 14px;
  color: #4B9F18;
}

.table-box {
  background: #fff;
  border: 1px solid #E7E7E7;
}
.table-box div.dataTables_wrapper div.dataTables_info {
  padding-left: 15px;
  padding-bottom: 15px;
}
.table-box div.dataTables_wrapper div.dataTables_paginate {
  padding-right: 15px;
  padding-bottom: 15px;
}

.project-table h4, .project-table .h4 {
  font-weight: 600;
  font-size: 16px;
  color: #86E53E;
  margin-bottom: 20px;
}
.project-table h3, .project-table .h3 {
  font-weight: 500;
  font-size: 24px;
  color: #161c2d;
  margin: 20px 20px 0;
}

.read-text {
  font-weight: 500;
  font-size: 14px;
  color: #86E53E;
}

.br-30 {
  border-radius: 30px;
}

.file-circle {
  width: 33px;
  height: 33px;
  background: #FDF4F3;
  color: #86E53E;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.dataTables_wrapper .dataTables_filter, .dataTables_wrapper .dataTables_length {
  padding: 20px 20px 5px;
}

.dataTables_wrapper .dataTables_length select, .dataTables_wrapper .dataTables_filter input {
  border: 1px solid #E7E7E7;
  appearance: auto;
  -webkit-appearance: auto;
  -moz-appearance: auto;
}

.reply-box {
  padding: 20px;
  margin-bottom: 20px;
}
.reply-box h4, .reply-box .h4 {
  margin-bottom: 0;
  color: #fff;
}

.dashboard-sec .pro-box {
  margin-bottom: 30px;
}
.dashboard-sec .card-title {
  font-weight: 500;
}

.text-violet {
  color: #7B46BE;
}

.text-pink {
  color: #FA6CA4;
}

.text-yellow {
  color: #FACD3A;
}

.text-blue {
  color: #24C0DC;
}

.static-list {
  list-style: none;
  margin: 0;
}
.static-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.static-list li:last-child {
  margin-bottom: 0;
}

.earn-feature {
  border: 1px solid #F7F7F7;
  padding: 20px;
}
.earn-feature .price {
  font-weight: 600;
  font-size: 24px;
  color: #86E53E;
  margin-left: auto;
}

.earn-info p {
  font-size: 16px;
  margin-bottom: 12px;
  font-weight: 600;
}
.earn-info .date {
  font-size: 14px;
  font-weight: 500;
}

.earn-img {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.earn-img span {
  font-weight: 600;
  font-size: 14px;
}
.earn-img span img {
  margin-right: 15px;
}

.pro-box {
  background: #fff;
  box-shadow: 0px 4px 24px 10px rgba(231, 231, 231, 0.3);
  border-radius: 5px;
}

.action {
  align-items: center;
  display: inline-flex;
}

.rangslider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  background: #ffe6e1;
  border-radius: 5px;
  outline: none;
  overflow: hidden;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}
.rangslider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 62px;
  height: 18px;
  background: #fff;
  border-radius: 5px;
  box-shadow: -100vw 0 0 100vw #86E53E, 0px 4px 24px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.rangslider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04AA6D;
  cursor: pointer;
}

.paginations {
  padding: 0;
  margin-bottom: 0;
}
.paginations li {
  list-style: none;
  display: inline-block;
  padding-right: 20px;
  list-style: none;
  display: inline-block;
  padding-right: 0;
}
.paginations li a {
  font-weight: 600;
  font-size: 14px;
  color: #161c2d;
  font-weight: 200;
  font-size: 16px;
  color: #6c757d;
  padding: 0.375rem 0.75rem;
}
.paginations li a.active {
  background: #86E53E;
  color: #fff;
  padding: 5px 11px;
  border-radius: 50%;
  display: inline-block;
  background: #86E53E;
  color: #fff;
  padding: 0.375rem 0.75rem;
  border-radius: 0;
}
.paginations li:hover a {
  color: #86E53E;
  color: #86E53E;
}
.paginations li:hover a.active {
  color: #fff;
  color: #fff;
}
.paginations li:last-child {
  padding-right: 0;
}
.paginations.freelancer {
  text-align: right;
}
.paginations.freelancer li:last-child {
  padding-right: 0;
}

.guide-account {
  border: 1px solid #86E53E;
  border-radius: 2px;
  padding: 30px 20px;
  position: relative;
  margin-bottom: 1.875rem;
  width: 100%;
}

.account-verify img {
  width: 65px;
}
.account-verify h2, .account-verify .h2 {
  font-size: 20px;
  margin: 15px 0;
}
.account-verify p {
  color: #797979;
}

.verify-link {
  color: #86E53E;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 4px solid #86E53E;
}

.projects-card {
  background: #fff;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.25);
}
.projects-card .card-body {
  height: 100%;
}

.project-info {
  width: 50%;
  display: block;
  padding-right: 20px;
}
.project-info span {
  font-weight: 500;
  font-size: 13px;
  color: #86E53E;
  display: inline-block;
  margin-bottom: 5px;
}
.project-info h2, .project-info .h2 {
  font-weight: 700;
  margin: 0 0 10px;
  font-size: 16px;
}
.project-info .customer-info ul {
  padding: 0;
}
.project-info .customer-info ul li h5, .project-info .customer-info ul li .h5 {
  margin: 0;
  display: flex;
  align-items: center;
}
.project-info .customer-info ul li h5 img, .project-info .customer-info ul li .h5 img {
  margin-right: 3px;
}
.project-info.project {
  width: 45%;
}

.projects-btn {
  font-size: 12px;
  font-weight: 600;
  background: #86E53E;
  border-radius: 3px;
  padding: 8px 10px;
  color: #fff;
  text-align: center;
  width: 140px;
  display: block;
  margin: 0 auto 10px;
}
.projects-btn:hover {
  background-color: #13323C;
  color: #fff;
}
.projects-btn.project {
  margin: 0 0 10px;
}

.projects-action {
  width: 50%;
  padding-left: 20px;
}
.projects-action .projects-btn ~ .projects-btn {
  margin: 0 auto 0;
}
.projects-action.single-btn {
  width: 100%;
}
.projects-action.project {
  width: 35%;
  padding-left: 20px;
}

.projects-details {
  display: flex;
  width: 100%;
}

.projects-amount {
  width: 50%;
  display: block;
  text-align: center;
}
.projects-amount p {
  font-weight: 500;
  font-size: 14px;
  color: #161c2d;
  margin-bottom: 0.25rem;
}
.projects-amount h3, .projects-amount .h3 {
  font-size: 15px;
  font-weight: 600;
}
.projects-amount h5, .projects-amount .h5 {
  font-weight: 600;
  font-size: 13px;
  color: #86E53E;
  margin-bottom: 0;
}
.projects-amount.proposals {
  width: 100%;
}

.prj-proposal-count {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.prj-proposal-count span {
  color: #86E53E;
  font-weight: 600;
  font-size: 34px;
  line-height: 1.2;
}
.prj-proposal-count h3, .prj-proposal-count .h3 {
  font-size: 16px;
}
.prj-proposal-count.hired h3, .prj-proposal-count.hired .h3 {
  font-weight: 600;
  font-size: 14px;
  color: #86E53E;
  margin-bottom: 12px;
}
.prj-proposal-count.hired img {
  height: 38px;
  width: 38px;
  border-radius: 50%;
  margin-bottom: 8px;
  object-fit: cover;
}
.prj-proposal-count.hired p {
  font-weight: 500;
  font-size: 14px;
}

.my-projects-list {
  margin-bottom: 30px;
}

.content-divider {
  border-right: 1px solid #F5F5F5;
  height: 96px;
}

.content-divider-1 {
  border-right: 1px solid #EAEAEA;
  height: 96px;
}

.hired-detail {
  font-size: 13px;
  color: #000;
  font-size: 12px;
  color: #6e727d;
  font-weight: 700;
  margin-bottom: 0;
}

.project-hire-info {
  display: flex;
  width: 50%;
  align-items: center;
}
.project-hire-info.project {
  width: 55%;
}

.page-title h3, .page-title .h3 {
  font-size: 25px;
  margin-bottom: 1.5rem !important;
}

.page-subtitle {
  font-size: 20px;
  color: #161c2d;
  margin-bottom: 1rem;
}

.proposal-card {
  border: 1px solid #E8E8E8;
  box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.25);
  padding: 12px;
}

.project-proposals {
  background: #FFF5F3;
  border: 1px solid rgba(255, 91, 55, 0.1);
  padding: 12px;
  margin-bottom: 15px;
}
.project-proposals.freelancer {
  margin-bottom: 0;
  padding: 18px;
}

.proposer-img img {
  height: 54px;
  width: 54px;
  border-radius: 50%;
}

.proposer-info {
  display: flex;
  align-items: center;
  width: 60%;
}
.proposer-info.project {
  width: 65%;
  padding-left: 25px;
}

.proposer-detail {
  margin-left: 15px;
  list-style: none;
  padding: 0;
}
.proposer-detail li {
  display: inline-block;
  color: #808080;
  font-size: 12px;
  border-right: 1px solid #ADADAD;
  padding-right: 12px;
  padding-left: 10px;
}
.proposer-detail li:first-child {
  padding-left: 0;
}
.proposer-detail li:last-child {
  padding-right: 0;
  border-right: 0;
}
.proposer-detail h4, .proposer-detail .h4 {
  font-weight: 500;
  font-size: 16px;
  color: #000;
  margin-bottom: 0.25rem;
}

.proposal-details {
  margin-bottom: 0;
}

.font-semibold {
  font-weight: 500;
}

.proposals-info {
  display: flex;
  margin-bottom: 15px;
}

.proposer-bid-info {
  display: flex;
  align-items: center;
  width: 40%;
}

.proposer-confirm {
  width: 50%;
}
.proposer-confirm .projects-btn {
  margin: 0 0 0 auto;
}

.proposer-bid {
  width: 50%;
}
.proposer-bid h3, .proposer-bid .h3 {
  font-size: 20px;
  color: #161c2d;
  margin-bottom: 0.25rem;
}
.proposer-bid h5, .proposer-bid .h5 {
  font-size: 13px;
  color: #86E53E;
  margin-bottom: 0;
}

.description-proposal {
  padding: 12px;
  background: #fff;
}
.description-proposal p {
  margin-bottom: 0;
  font-size: 14px;
}

.font-bold {
  font-weight: 600;
}

.warning-card {
  padding: 12px;
  background: rgba(255, 0, 0, 0.1);
  color: #FF0000;
  margin-bottom: 30px;
}
.warning-card p {
  color: #FF0000;
  font-size: 14px;
  margin-bottom: 0;
}

.completed-badge {
  font-size: 14px;
  color: #4B9F18;
  font-weight: 700;
}

.cancelled-badge {
  font-weight: 600;
  font-size: 15px;
  color: #FF0000;
}

.projects-delete-details {
  padding: 20px;
  background: rgba(255, 0, 0, 0.04);
  display: flex;
  box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.25);
  border: 1px solid #e8e8e8;
}
.projects-delete-details .content-divider {
  border-right: 1px solid rgba(255, 0, 0, 0.1);
}

.profile-edit-btn {
  background: #fff;
  font-weight: 500;
  font-size: 16px;
  color: #86E53E;
  border: 1px solid #86E53E;
  border-radius: 2px;
}
.profile-edit-btn:hover {
  background: #86E53E;
  color: #FFF;
  border: 1px solid #86E53E;
}

.my-projects-view .pro-post {
  margin-bottom: 0;
}

.pro-pos .select2-container {
  z-index: unset;
}

.package-detail {
  background: #fff;
  border: 1px solid #FEFEFE;
  box-shadow: 0px 4px 24px rgba(199, 199, 199, 0.25);
  border-radius: 5px;
  padding: 25px;
  margin-bottom: 25px;
}
.package-detail h4, .package-detail .h4 {
  font-weight: 600;
  font-size: 18px;
}
.package-detail p {
  font-size: 12px;
}
.package-detail .package-price {
  font-weight: 600;
  font-size: 25px;
  color: #86E53E;
  margin-bottom: 0;
}

.package-feature ul {
  list-style: none;
  margin: 25px 0;
  padding: 0;
  outline: none;
}
.package-feature ul li {
  margin-bottom: 14px;
  position: relative;
  font-size: 15px;
  font-weight: 600;
  padding-left: 25px;
}
.package-feature ul li:last-child {
  margin-bottom: 0;
}
.package-feature ul li.non-check::before {
  content: "\f057";
  color: #F5365C;
}
.package-feature ul li::before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  color: #4B9F18;
  left: 0;
  position: absolute;
  top: 0;
}

.price-btn {
  background: #13323C;
  border: 1px solid #13323C;
  border-radius: 5px;
  font-weight: 500;
  color: #fff;
  padding: 10px 20px;
}

.member-detail h4, .member-detail .h4 {
  font-weight: 600;
  font-size: 22px;
  color: #86E53E;
}
.member-detail h3, .member-detail .h3 {
  font-weight: 600;
  font-size: 22px;
  color: #86E53E;
  margin-bottom: 2px;
}
.member-detail .expiry-on {
  font-size: 15px;
}
.member-detail .expiry {
  font-weight: 500;
  font-size: 16px;
}

.member-plan {
  margin-bottom: 25px;
}

.freelance-title {
  margin-bottom: 40px;
}
.freelance-title h3, .freelance-title .h3 {
  font-weight: 600;
  font-size: 26px;
}
.freelance-title p {
  font-size: 16px;
  color: #757575;
}

.change-plan {
  float: right;
}

.fav .filled, .fav i {
  color: #F5365C;
}

.fav i:hover {
  color: #F5365C;
  font-weight: 700;
}

.tab-imgcircle::before {
  background-color: #159C5B;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  content: "\f00c";
  font-weight: 600;
  font-family: "Font Awesome 5 Free";
  font-size: 9px;
  justify-content: center;
  align-items: center;
  color: #fff;
  display: inline-flex;
  display: -ms-inline-flexbox;
}

.freelancer-proposals {
  background: #fff;
  box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 13px;
}

.proposals-title {
  font-weight: 700;
  font-size: 18px;
  color: #161c2d;
  margin-bottom: 1rem;
  line-height: 1.3;
}

.proposals-detail {
  display: block;
  width: 50%;
}

.proposer-content {
  display: block;
  width: 50%;
  display: block;
  padding-right: 20px;
}

.info-btn {
  background: #fff;
  border: 1px solid #D8D8D8;
  border-radius: 70px;
  font-weight: 500;
  font-size: 12px;
  color: #ADADAD;
  padding: 3px 35px;
}

.proposal-img {
  display: block;
  margin-right: 60px;
}
.proposal-img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.proposal-img h4, .proposal-img .h4 {
  font-weight: 600;
  font-size: 14px;
  color: #161c2d;
  margin: 0.5rem 0;
}

.proposal-client {
  margin-right: 60px;
}

.proposals-content {
  display: flex;
}

.proposal-delete {
  font-weight: 500;
  font-size: 12px;
  color: #F5365C;
}

.title-info {
  font-weight: 500;
  font-size: 15px;
  color: #161c2d;
  margin-bottom: 0.25rem;
}

.client-price {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0.25rem;
  color: #F5365C;
}

.price-type {
  font-size: 16px;
  color: #161c2d;
}

.proposal-type h3, .proposal-type .h3 {
  font-weight: 600;
  font-size: 16px;
  color: #F5365C;
  margin-bottom: 0;
}

.amnt-type {
  font-size: 16px;
  color: #161c2d;
  margin-bottom: 0;
  padding-top: 5px;
}

.hired-date {
  font-weight: 500;
  font-size: 13px;
  color: #161c2d;
  margin-bottom: 0;
}

.projects-cancelled-card {
  background: rgba(255, 0, 0, 0.07);
}

#more {
  display: none;
}

.readmore:hover {
  cursor: pointer;
}

ul {
  padding: 0;
}

.modal-title {
  font-weight: 600;
  font-size: 24px;
  color: #161c2d;
}

.rating-text {
  font-weight: 500;
  font-size: 16px;
  color: #161c2d;
}

.modal-close a i {
  color: #86E53E;
  font-size: 20px;
}

.update-btn {
  padding: 10px 20px;
  font-weight: bold;
  font-size: 15px;
  border-radius: 10px;
  min-width: 120px;
  text-transform: uppercase;
}

.rating-btn {
  font-weight: 500;
  font-size: 14px;
  color: #161c2d;
}

.bids-card {
  border-bottom: 1px solid #D9D9D9;
  padding: 20px 0;
}
.bids-card:last-child {
  border: 0;
  padding-bottom: 0;
}

.author-img-wrap img {
  border-radius: 20px;
}

.author-detail p {
  margin-top: 15px;
}

.proposal-amnt h3, .proposal-amnt .h3 {
  font-weight: 500;
  font-size: 24px;
  color: #F5365C;
}

.average-bids {
  background: #FFF6F4;
  padding: 15px 20px;
}
.average-bids p {
  margin-bottom: 0;
  color: #000;
  font-size: 16px;
}

.text-highlight {
  font-weight: 700;
  color: #86E53E;
}

.pro-btn {
  background: #86E53E;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  padding: 15px 30px;
  margin: auto;
}
.pro-btn:hover {
  color: #fff;
  background: #fb451d;
}

.proposal-btns {
  padding: 20px;
  background: #FFF9F9;
  display: flex;
  display: -webkit-flex;
  border-radius: 5px;
}

.custom-breadcrumb {
  padding: 50px 0 160px;
}

.profile-cmpny {
  font-weight: 500;
  font-size: 24px;
  color: #86E53E;
}

.bck-btn {
  font-weight: 500;
  font-size: 20px;
  color: #fff;
  background: #86E53E;
  border-radius: 5px;
  padding: 10px 15px;
  float: right;
}

.note-editor.card {
  padding: 0.5rem;
}
.note-editor.note-frame {
  border: 1px solid #E7E8EA;
}
.note-editor.note-frame .note-status-output {
  display: none;
}

.note-toolbar .btn-group button, .note-toolbar .btn-group .btn {
  border-radius: 0;
}

.note-toolbar {
  z-index: 2;
}

.map {
  width: 100%;
  height: 314px;
}

.table-width {
  width: 35%;
}

.invoice-item .invoice-logo {
  margin-bottom: 30px;
}
.invoice-item .invoice-logo img {
  width: auto;
  max-height: 52px;
}
.invoice-item .invoice-text {
  padding-top: 42px;
  padding-bottom: 36px;
}
.invoice-item .invoice-text h2, .invoice-item .invoice-text .h2 {
  color: #161c2d;
  font-size: 36px;
  font-weight: 600;
  font-weight: 400;
}
.invoice-item .invoice-details {
  text-align: right;
  font-weight: 500;
}
.invoice-item .invoice-details strong {
  color: #161c2d;
}
.invoice-item .invoice-details-two {
  text-align: left;
}
.invoice-item .customer-text {
  font-size: 18px;
  color: #161c2d;
  font-weight: 600;
  margin-bottom: 8px;
  display: block;
}

.invoice-info {
  margin-bottom: 30px;
}
.invoice-info p {
  margin-bottom: 0;
}
.invoice-info.invoice-info2 {
  text-align: right;
}
.invoice-info h5, .invoice-info .h5 {
  font-size: 16px;
  font-weight: 500;
}

.invoice-table tr th,
.invoice-table tr td,
.invoice-table-two tr th,
.invoice-table-two tr td {
  color: #161c2d;
  font-weight: 600;
  padding: 10px 20px;
  line-height: inherit;
}

.invoice-table tr td,
.invoice-table-two tr td {
  font-weight: 500;
}

.invoice-table-two {
  margin-bottom: 0;
}
.invoice-table-two tr td {
  text-align: right;
}

.invoice-table-two tr th,
.invoice-table-two tr td {
  border-top: 0;
}

.other-info {
  margin-top: 10px;
}

.footer {
  background-image: url(../img/bg/bg-18.png);
  background-position: top;
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
  overflow: hidden;
  background: #fdfdfd;
}
.footer .footer-widget .footer-about-content p {
  color: #E5E5E5;
}
.footer .footer-widget.footer-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  outline: none;
}
.footer .footer-menu ul li {
  margin-bottom: 10px;
}
.footer .footer-menu ul li:last-child {
  margin-bottom: 0;
}
.footer .footer-menu ul li a {
  color: #353535;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}
.footer .footer-menu ul li a:hover {
  color: #FF3115;
}
.footer .banner-content .form-inner {
  border-radius: 50px;
  border: 0;
  height: 50px;
  margin-bottom: 35px;
}
.footer .banner-content .form-control {
  background: #fff;
  border-radius: 50px;
  height: 50px;
  margin-right: 3px;
}
.footer .banner-content .form-control::placeholder {
  font-size: 14px;
  color: #9D9D9D;
}
.footer .banner-content .sub-btn {
  min-width: 72px;
  height: 38px;
  margin: 6px;
  background: #101828;
  border: 1px solid #101828;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.footer .banner-content .sub-btn:hover {
  background: #86E53E;
  border: 1px solid #86E53E;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.footer .form-control {
  line-height: 40px;
  border-right-color: transparent;
}
.footer .input-group .input-group-append {
  padding: 3px;
  background: #fff;
}
.footer .input-group .input-group-append .btn {
  border-radius: 0;
  padding: 10px 30px;
}
.footer.footer-two {
  background: #161515;
}
.footer.footer-two .footer-menu ul li a {
  color: #FFF5F1;
}
.footer.footer-two .footer-menu ul li a:hover {
  color: #86E53E;
}
.footer.footer-two .banner-content .form-inner {
  box-shadow: none;
  border-radius: 8px;
  height: 50px;
  padding: 0;
}
.footer.footer-two .banner-content .sub-btn {
  height: 50px;
  margin: 0;
  background: #86E53E;
  border: 1px solid #86E53E;
}
.footer.footer-two .banner-content .sub-btn:hover {
  background: #13323C;
  border: 1px solid #13323C;
}
.footer.footer-three {
  background: #FFF5F1;
}
.footer.footer-three .banner-content .sub-btn {
  background: transparent;
  border: 0;
  color: #86E53E;
  padding: 10px 12px;
}
.footer.footer-three .banner-content .sub-btn:hover {
  color: #2e2e2e;
}
.footer.footer-three .copyright-text p {
  color: #353535;
}
.footer.footer-four .footer-menu ul li a {
  color: #FFF5F1;
}
.footer.footer-four .footer-menu .social-icon ul li {
  margin-bottom: 0;
}
.footer.footer-four .banner-content .form-control {
  border-radius: 4px;
  height: 48px;
}
.footer.footer-four .banner-content .sub-btn {
  height: 38px;
  border-radius: 4px !important;
}
.footer.footer-five {
  background: #101010;
  background-image: url(../../assets/img/bg/bg-36.png);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 200px;
}
.footer.footer-five .footer-menu ul li a {
  color: #FFF5F1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.footer.footer-five .footer-menu ul li a i {
  font-size: 6px;
  color: #474747;
  margin-right: 10px;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.footer.footer-five .banner-content .sub-btn {
  background: #86E53E;
  border: 1px solid #86E53E;
  border-radius: 0px 6px 6px 0px !important;
  height: 48px;
  margin: 0px;
}
.footer.footer-five .banner-content .sub-btn:hover {
  background: #13323C;
  border: 1px solid #13323C;
}

.footer-user {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  top: 75px;
}

.footer-top {
  padding: 60px 0;
}

.footer-contact p {
  color: #E5E5E5;
}

.footer-title {
  color: #101828;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
  padding-bottom: 10px;
}

h2.footer-title:after, .footer-title.h2:after {
  content: "";
  width: 35px;
  height: 2px;
  background: #86E53E;
  bottom: 0;
  left: 0px;
  right: 0;
  bottom: 0;
  position: absolute;
  border-radius: 50px;
}
h2.footer-title:before, .footer-title.h2:before {
  content: "";
  width: 62px;
  height: 2px;
  background: #D9D9D9;
  bottom: 0;
  left: 0px;
  right: 0;
  bottom: 0;
  position: absolute;
  border-radius: 50px;
}

.footer-menu p {
  color: #515151;
  font-weight: 500;
  font-size: 14px;
}
.footer-menu .applicate-mobile-blk h6, .footer-menu .applicate-mobile-blk .h6 {
  color: #2B2B2B;
  font-weight: 600;
}

.footer .social-icon ul li .fa-facebook-f:hover,
.footer .social-icon ul li .fa-instagram:hover,
.footer .social-icon ul li .fa-twitter:hover {
  opacity: 0.7;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.footer-three .social-icon ul li .fa-facebook-f,
.footer-three .social-icon ul li .fa-linkedin-in,
.footer-three .social-icon ul li .fa-twitter,
.footer-four .social-icon ul li .fa-facebook-f,
.footer-four .social-icon ul li .fa-instagram,
.footer-four .social-icon ul li .fa-twitter,
.footer-five .social-icon ul li .fa-facebook-f,
.footer-five .social-icon ul li .fa-linkedin-in,
.footer-five .social-icon ul li .fa-twitter {
  background: transparent;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.footer-three .social-icon ul li .fa-facebook-f:hover,
.footer-three .social-icon ul li .fa-linkedin-in:hover,
.footer-three .social-icon ul li .fa-twitter:hover {
  background: #5F5F5F;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.footer-five .social-icon ul li .fa-facebook-f:hover,
.footer-five .social-icon ul li .fa-linkedin-in:hover,
.footer-five .social-icon ul li .fa-twitter:hover {
  background: #fff;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.social-icon ul li .icon:hover, .social-icon ul li .icon:focus {
  color: #fff;
}

.footer-newsletter input {
  box-shadow: 0px 4px 4px rgba(194, 194, 194, 0.25);
  border-radius: 200px;
  height: 43px;
  width: 100%;
  padding: 6px 15px;
  border: 0;
}
.footer-newsletter input:focus {
  outline: none;
}

.btn-newsletter {
  background: #FF3115;
  border-radius: 200px;
  position: absolute;
  height: 43px;
  right: 0;
  width: 80px;
}

.footer-contact-info p {
  color: #fff;
  font-size: 16px;
  font-weight: 800;
}
.footer-contact-info .social-icon ul {
  padding: 0;
  margin-top: 10px;
}
.footer-contact-info .social-icon ul li .icon {
  width: 26px;
  height: 26px;
  font-size: 12px;
}

.copyright {
  padding: 30px 0;
  border-top: 1px solid #EBEBEB;
}

.copyright-text p, .copyright-text p a {
  color: #9D9D9D;
  font-size: 14px;
}

.footer-bottom-nav {
  justify-content: end;
  -webkit-justify-content: end;
  -ms-flex-pack: end;
  margin-bottom: 5px;
}
.footer-bottom-nav li a {
  color: #515151;
  font-weight: 500;
  font-size: 14px;
}
.footer-bottom-nav li a:hover {
  color: #86E53E !important;
}
.footer-bottom-nav li + li {
  margin-left: 10px;
}

.footer-two .footer-bottom-nav li + li {
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid #2B2B2B;
}
.footer-two .footer-title {
  margin-bottom: 10px;
}
.footer-two .banner-content .sub-btn {
  background: #86E53E;
  border-radius: 0px 5px 5px 0px !important;
}

.center-text {
  text-align: center;
}

.copyright-text p a:hover {
  color: #FF3115;
}

.footer-address .off-address + .off-address {
  margin-top: 20px;
}
.footer-address .off-address p {
  margin-bottom: 0;
}

.footer-two .footer-title,
.footer-two .footer-menu p {
  color: #fff;
}

.footer-two h2.footer-title:before, .footer-two .footer-title.h2:before,
.footer-two h2.footer-title:after,
.footer-two .footer-title.h2:after {
  display: none;
}

.footer-two .footer-bottom-nav li a,
.footer-two .copyright-text p {
  color: #fff;
}

.footer.footer-two .banner-content .form-control,
.footer.footer-two .banner-content .input-group {
  height: 50px;
}

.footer-top-blk {
  border-bottom: 1px solid #EBEBEB;
  padding: 25px 0px;
}

.footer-social-group .footer-bottom-nav {
  justify-content: start;
  -webkit-justify-content: start;
  -ms-flex-pack: start;
}
.footer-social-group .footer-bottom-nav li a {
  color: #353535;
}
.footer-social-group .social-icon {
  justify-content: end;
  -webkit-justify-content: end;
  -ms-flex-pack: end;
}
.footer-social-group .social-icon ul li a {
  background: transparent;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.footer-social-group .social-icon ul li a:hover {
  background: #5F5F5F;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.footer-social-group .social-icon ul li a:hover i {
  color: #fff;
}
.footer-social-group .social-icon ul li i {
  color: #000;
}

.footer-three h2.footer-title:before, .footer-three .footer-title.h2:before,
.footer-three h2.footer-title:after,
.footer-three .footer-title.h2:after {
  display: none;
}

.footer-three .footer-title {
  margin-bottom: 20px;
  padding-bottom: 0px;
  color: #2B2B2B;
  font-size: 16px;
}

.footer.footer-three .banner-content .form-inner,
.footer.footer-three .banner-content .input-group {
  border-radius: 4px;
}

.footer-four {
  background: #161515;
  background-image: url(../../assets/img/bg/bg-37.png);
  background-repeat: no-repeat, no-repeat;
  background-position: right center;
  background-size: 170px;
}
.footer-four .footer-title {
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
}
.footer-four .footer-menu .applicate-mobile-blk h6, .footer-four .footer-menu .applicate-mobile-blk .h6 {
  color: #FFFFFF;
}
.footer-four .footer-menu .applicate-mobile-blk p {
  color: #FFF5F1;
  font-size: 14px;
}
.footer-four .footer-menu .free-customer p {
  color: #5F5F5F;
  font-size: 15px;
  font-weight: 500;
}
.footer-four .footer-menu .social-icon p {
  color: #FFF5F1;
}
.footer-four .applicate-mobile-blk {
  margin-top: 50px;
}
.footer-four .social-icon {
  margin-top: 50px;
}
.footer-four .social-icon ul li a {
  width: auto;
}
.footer-four .copyright-text p {
  color: #FFF5F1;
}
.footer-four .copyright {
  padding: 30px 0;
  border-top: 1px solid #2E2E2E;
}

.footer-four h2.footer-title:before, .footer-four .footer-title.h2:before,
.footer-four h2.footer-title:after,
.footer-four .footer-title.h2:after {
  display: none;
}

.footer.footer-four .footer-menu ul li a:hover i,
.footer.footer-four .footer-menu ul li a:hover {
  color: #86E53E;
}

.subscribe-four h4, .subscribe-four .h4 {
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
}

.footer.footer-four .banner-content .form-inner,
.footer.footer-four .banner-content .input-group {
  border-radius: 4px;
  padding: 0;
  max-width: 600px;
}

.play-app img {
  max-width: 142px;
}

.free-customer {
  margin-bottom: 10px;
}
.free-customer p {
  color: #5F5F5F;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}
.free-customer h6, .free-customer .h6 {
  color: #FFFFFF;
  font-weight: 600;
  font-size: 16px;
  font-size: 16px;
  margin-bottom: 0;
}

.footer-four .social-icon ul li:nth-child(1) a,
.footer-four .social-icon ul li:nth-child(2) a,
.footer-four .social-icon ul li:nth-child(3) a {
  background: transparent;
}

.footer-five .footer-social-group .footer-bottom-nav li a,
.footer-five .social-icon span,
.footer-five .footer-social-group .social-icon ul li i,
.footer-five .footer-menu p {
  color: #FFF5F1;
}

.footer-five .footer-social-group .social-icon ul li a:hover {
  background: #EBEBEB;
}
.footer-five .footer-social-group .social-icon ul li a:hover i {
  color: #515151;
}
.footer-five .footer-title {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
}
.footer-five .copyright-text p {
  color: #FFF5F1;
  font-size: 14px;
}
.footer-five .copyright {
  border-top: 1px solid #2E2E2E;
}
.footer-five .footer-top-blk {
  border-bottom: 1px solid #2E2E2E;
}

.footer-five .footer-title,
.footer-five .footer-menu .applicate-mobile-blk h6,
.footer-five .footer-menu .applicate-mobile-blk .h6 {
  color: #fff;
}

.footer.footer-five .footer-menu ul li a:hover,
.footer.footer-five .footer-menu ul li a:hover i {
  color: #86E53E;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.play-icon-store {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.footer-five h2.footer-title:before, .footer-five .footer-title.h2:before,
.footer-five h2.footer-title:after,
.footer-five .footer-title.h2:after {
  display: none;
}

.footer.footer-five .banner-content .form-inner,
.footer.footer-five .banner-content .input-group {
  border-radius: 4px;
}

.transaction-shortby .sort-show {
  padding-left: 10px;
}

.freelance-img {
  position: relative;
  width: 106px;
  height: 106px;
  margin: 0 auto 15px;
}
.freelance-img img {
  margin: auto;
  width: 106px;
  height: 106px;
  border-radius: 50%;
  border: 1px solid #86E53E;
  padding: 5px;
}
.freelance-img .verified {
  background-color: #fff;
  color: #159C5B;
  font-size: 18px;
  position: absolute;
  right: 5px;
  bottom: 5px;
  border-radius: 100%;
  z-index: 2;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.freelance-info h3, .freelance-info .h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 2px;
}
.freelance-info h3 a, .freelance-info .h3 a {
  color: #353535;
}
.freelance-info h3 a:hover, .freelance-info .h3 a:hover {
  color: #86E53E;
}

.freelance-specific {
  font-size: 14px;
  font-weight: 500;
  color: #515151;
  margin-bottom: 5px;
}

.freelance-location {
  color: #515151;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 500;
}

.favourite {
  color: #9D9D9D;
  font-weight: bold;
  background: #fafafa;
  border: 1px solid #EBEBEB;
  border-radius: 50px;
  font-size: 12px;
  width: 30px;
  height: 30px;
  padding: 0;
  display: inline-flex;
  position: absolute;
  right: 15px;
  top: 15px;
  justify-content: center;
  align-items: center;
}
.favourite:hover {
  color: #fff;
  background: #86E53E;
  border: 1px solid #86E53E;
}

.favourited {
  color: #fff;
  background: #86E53E;
  border: 1px solid #86E53E;
}

.cart-hover {
  text-align: center;
}

.btn-cart {
  width: 50%;
  display: inline-block;
  border-radius: 55px;
  border-color: transparent;
  text-transform: capitalize;
  background: #86E53E;
  color: #fff;
  padding: 10px 15px;
  font-weight: 700;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  font-size: 14px;
}

.btn-cart:hover,
.btn-cart:focus {
  background: #13323C;
  color: #fff;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.freelance-content {
  padding: 20px 0px;
}

.sort-tab {
  background-color: #edeef3;
  padding: 15px 20px;
  border-radius: 6px;
  margin-bottom: 22px;
}

.freelance-view h4, .freelance-view .h4 {
  font-size: 15px;
  font-weight: 500;
  color: #53545b;
  display: inline-block;
  margin-bottom: 0;
}

.freelance-tags {
  margin-bottom: 20px;
}

.freelancers-price {
  font-size: 16px;
  font-weight: 600;
  color: #101828;
}

.freelance-blk-location {
  border-top: 1px solid #EBEBEB;
  padding-top: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.widget-author {
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: center;
  position: relative;
  background-color: #fff;
  box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
  border: 1px solid rgba(239, 239, 239, 0.42);
  text-align: center;
  position: relative;
  margin-bottom: 30px;
}
.widget-author .cover-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.widget-author .cover-img img {
  border-radius: 8px 8px 0 0;
  width: 100%;
  height: 100px;
}
.widget-author .profile-img {
  box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
  padding: 15px;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin: 0 auto 15px;
}
.widget-author .profile-img a {
  border-radius: 50%;
  position: relative;
  display: inline-block;
}
.widget-author .profile-img a img {
  width: 100%;
}
.widget-author .author-name {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
}
.widget-author .author-name a {
  color: #161c2d;
}
.widget-author .author-location {
  font-size: 14px;
  color: #86E53E;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 500;
}
.widget-author .dev-text {
  position: relative;
}
.widget-author .dev-text .left img {
  display: block;
  float: left;
  width: 66px;
  border-radius: 12px;
}
.widget-author .dev-text .left .left-content {
  margin-left: 75px;
}
.widget-author .left-content h6, .widget-author .left-content .h6 {
  color: #000;
}
.widget-author .left-content h4, .widget-author .left-content .h4 {
  color: #000;
}
.widget-author .left-content h4 a, .widget-author .left-content .h4 a {
  color: #000;
}
.widget-author.pro-post {
  box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.25);
  border: 1px solid #e8e8e8;
  border-radius: 0;
}

.counter-stats {
  margin-top: 25px;
}
.counter-stats ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  list-style: none;
  padding: 0;
}
.counter-stats ul li {
  padding-left: 18px;
  padding-right: 18px;
  position: relative;
  width: 33%;
}
.counter-stats ul li h3, .counter-stats ul li .h3 {
  color: #51595e;
  font-weight: 700;
  margin-bottom: 3px;
  font-size: 16px;
}
.counter-stats ul li h5, .counter-stats ul li .h5 {
  color: #6e727d;
  text-transform: capitalize;
  font-size: 14px;
  margin-bottom: 0;
}
.counter-stats ul li + li::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1px;
  height: 18px;
  background-color: #ecf0f9;
}

.jobtype {
  color: #159C5B;
}

.developers-slider .owl-nav {
  position: absolute;
  overflow: hidden;
  bottom: 100%;
  right: -5px;
  margin-bottom: 50px;
}

.developers-slider.owl-carousel .owl-nav button.owl-next, .developers-slider.owl-carousel .owl-nav button.owl-prev, .developers-slider.owl-carousel button.owl-dot {
  display: block;
  float: left;
  width: 32px;
  height: 32px;
  font-size: 13px;
  color: #fff;
  border: 0;
  background: #13323C;
  text-align: center;
  -webkit-transition: color 0.7s ease, background-color 0.7s ease, border-color 0.7s ease;
  transition: color 0.7s ease, background-color 0.7s ease, border-color 0.7s ease;
  box-shadow: 0px 1px 4px rgba(183, 183, 183, 0.25);
  border-radius: 10px !important;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.developers-slider.owl-carousel .owl-nav button.owl-next i, .developers-slider.owl-carousel .owl-nav button.owl-prev i, .developers-slider.owl-carousel button.owl-dot i {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.popular-slider .page-prev .owl-nav .owl-prev {
  transform: none;
  background: #FFF5F1;
  border: 1px solid #EBEBEB;
  width: 44px;
  height: 44px;
  border-radius: 50px !important;
  box-shadow: none;
}

.popular-slider .page-prev .owl-nav .owl-prev i {
  color: #86E53E;
}

.popular-slider.owl-carousel.owl-nav button.owl-prev:hover {
  background: #86E53E !important;
  border: 1px solid #86E53E;
}

.popular-slider.owl-carousel.owl-nav button.owl-prev i {
  color: #86E53E;
  transform: none;
}

.testimonial-section .owl-nav {
  position: absolute;
  overflow: hidden;
  bottom: 100%;
  right: -5px;
  margin-bottom: 50px;
}

.testimonial-section .owl-carousel .owl-nav .owl-next {
  display: block;
  float: left;
  width: 32px;
  height: 32px;
  font-size: 13px;
  color: #fff;
  border: 0;
  background: #13323C;
  text-align: center;
  -webkit-transition: color 0.7s ease, background-color 0.7s ease, border-color 0.7s ease;
  transition: color 0.7s ease, background-color 0.7s ease, border-color 0.7s ease;
  box-shadow: 0px 1px 4px rgba(183, 183, 183, 0.25);
  border-radius: 10px !important;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.testimonial-section .owl-carousel .owl-nav .owl-prev {
  display: block;
  float: left;
  width: 32px;
  height: 32px;
  font-size: 13px;
  color: #fff;
  border: 0;
  background: #13323C;
  text-align: center;
  -webkit-transition: color 0.7s ease, background-color 0.7s ease, border-color 0.7s ease;
  transition: color 0.7s ease, background-color 0.7s ease, border-color 0.7s ease;
  box-shadow: 0px 1px 4px rgba(183, 183, 183, 0.25);
  border-radius: 10px !important;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.testimonial-section .owl-carousel .owl-nav .owl-next i {
  transform: rotate(-45deg);
}

.testimonial-section .owl-carousel .owl-nav .owl-prev i {
  transform: rotate(-45deg);
}

.owl-carousel .trend-slider .owl-nav {
  position: absolute;
  margin: 0;
  right: 0;
  top: -97px;
  min-width: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}

.owl-carousel .trend-slider .owl-prev {
  position: absolute;
  margin: 0;
  right: 0;
  top: -97px;
  min-width: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}

.owl-carousel .trend-slider .owl-nav .owl-prev {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.list-pagination {
  margin-bottom: 0;
  float: right;
  border: 1px solid #dee2e6;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pro-status {
  border-top: 1px solid #F5F5F5;
  padding-top: 20px;
  margin-top: 20px;
}

.pro-list-view .project-info {
  width: 70%;
}
.pro-list-view .project-info h2, .pro-list-view .project-info .h2 {
  font-size: 22px;
  line-height: 1.3;
}
.pro-list-view .slot p {
  font-size: 15px;
}
.pro-list-view .slot h5, .pro-list-view .slot .h5 {
  font-size: 18px;
}
.pro-list-view .projects-amount h3, .pro-list-view .projects-amount .h3 {
  font-size: 23px;
  font-weight: bold;
  color: #F5365C;
}
.pro-list-view .projects-amount h5, .pro-list-view .projects-amount .h5 {
  color: #6e727d;
}

.off-address a {
  color: #fff;
}

body.chat-page {
  overflow-y: hidden;
}

.invoice-id {
  color: #FF3115;
}

.company-profile h4, .company-profile .h4 {
  font-size: 16px;
  color: #000;
  text-transform: uppercase;
}
.company-profile .widget-box {
  padding: 0px;
}
.company-profile .widget-box .latest-posts li {
  padding: 13px 30px 13px;
}
.company-profile .widget-box .latest-posts li span {
  color: #86E53E;
}

.profile-head {
  padding: 30px 30px 6px 30px;
}
.profile-head .pro-title {
  border: 0;
}

.profile-overview .latest-posts li {
  border-bottom: 1px solid #EDEDED;
  padding: 13px 0;
}
.profile-overview .latest-posts li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.profile-overview .latest-posts li:last-child h6, .profile-overview .latest-posts li:last-child .h6 {
  margin-bottom: 0;
}

.profile-overview,
.working-days,
.location-widget,
.social-widget {
  border-radius: 5px;
}

.map-location {
  padding: 30px;
  padding-top: 0;
}
.map-location iframe {
  width: 100%;
  height: 200px;
}

.contact-btn {
  margin-top: 0px;
  padding: 30px;
}
.contact-btn .btn-primary {
  width: 100%;
  padding: 11px 15px;
  font-size: 20px;
  background: #8553EE;
  border: 0;
  border-radius: 50px;
}
.contact-btn .btn-primary i {
  margin-right: 12px;
}

.working-days .latest-posts li {
  color: #6D7E8F;
  font-size: 14px;
  font-weight: 400;
  border-bottom: 1px solid #EDEDED;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.working-days .latest-posts li p {
  margin-bottom: 0;
}
.working-days .latest-posts li h6, .working-days .latest-posts li .h6 {
  margin-left: auto;
  color: #13323C;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.social-link-profile {
  display: flex;
  padding: 30px;
  padding-top: 10px;
  margin: 0;
}
.social-link-profile li {
  list-style: none;
  margin-right: 15px;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.social-link-profile li:hover {
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.15);
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.social-link-profile li:last-child {
  margin-right: 0px;
}
.social-link-profile li a i {
  width: 50px;
  height: 50px;
  border: 1px solid #86E53E;
  border-radius: 5px;
  font-size: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.social-link-profile li:nth-child(1) a i {
  color: #1877F2;
}
.social-link-profile li:nth-child(2) a i {
  color: #1DA1F2;
}
.social-link-profile li:nth-child(3) a i {
  color: #F5365C;
}
.social-link-profile li:nth-child(4) a i {
  color: #0A66C2;
}
.social-link-profile li:nth-child(5) a i {
  color: #229ED9;
}

.location-widget .pro-title {
  margin-bottom: 40px;
}

.abouts-view {
  border: 1px solid #E8E8E8;
  box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
  border-radius: 5px;
  margin-bottom: 27px;
}
.abouts-view li {
  margin-right: 10px;
}
.abouts-view li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}
.abouts-view li a img {
  margin-right: 10px;
}
.abouts-view li:last-child {
  margin-right: 0px;
}
.abouts-view ul li .active-about {
  background-color: rgba(255, 91, 55, 0.15);
  border-radius: 5px;
}
.abouts-view ul li .active-about:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  background: #86E53E;
  top: -10px;
  left: 0;
  width: 100%;
  border-radius: 10px;
}
.abouts-view .nav-tabs.nav-tabs-solid {
  padding: 20px;
  padding-top: 28px;
}

.abouts-view .nav-justified .nav-item,
.abouts-view .nav-justified > .nav-link {
  flex-basis: inherit;
  flex-grow: inherit;
}

.company-post {
  border: 1px solid #E8E8E8;
  box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
  border-radius: 5px;
}
.company-post .pro-title {
  color: #000;
  margin-bottom: 38px;
  border-bottom: 0;
}
.company-post .list-pagination {
  margin: 0;
}
.company-post .project-company {
  margin-bottom: 30px;
}
.company-post .project-company .project-info span {
  font-weight: 600;
}

.profile-baner {
  background: url(../../assets/img/bg/bg-13.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 60px 0px;
}

.profile-img img {
  border-radius: 10px;
}

.header-bg {
  background: #fff;
}

.profile-main h2, .profile-main .h2 {
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 22px;
}
.profile-main h2 i, .profile-main .h2 i {
  color: #159C5B;
  background: #fff;
  border-radius: 50px;
  font-size: 16px;
}
.profile-main p {
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: 400;
}
.profile-main .rating .average-rating {
  font-weight: 400;
}
.profile-main .rating i.filled {
  color: #febe42;
}

.about-list {
  margin-bottom: 8px;
}
.about-list ul {
  list-style: none;
  display: flex;
  margin-bottom: 0;
}
.about-list ul li {
  padding: 0px 16px;
  border-right: 1px solid #FFFFFF;
  font-size: 12px;
  font-weight: 400;
}
.about-list ul li i {
  margin-right: 7px;
}
.about-list ul li:last-child {
  border-right: 0;
}
.about-list ul li:first-child {
  padding-left: 0;
}
.about-list ul li:first-child img {
  border-radius: 50px;
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

.about-list ul li,
.profile-main p {
  color: #fff;
}

.profile-main .rating span,
.profile-main .rating i {
  color: #fff;
}

.project-company .slot p,
.project-company .slot h5,
.project-company .slot .h5,
.project-company .project-info span {
  font-size: 14px;
}

.project-company {
  margin-bottom: 15px;
  box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
  border-radius: 5px;
}
.project-company .project-info .customer-info ul li h5 img, .project-company .project-info .customer-info ul li .h5 img {
  margin-right: 5px;
}
.project-company .projects-action .hired-detail {
  color: #86E53E;
  font-size: 13px;
}
.project-company .projects-action .hired-detail span {
  color: #000;
}
.project-company .projects-action .projects-btn {
  font-size: 14px;
}
.project-company .projects-action .projects-btn:nth-child(2) {
  margin-bottom: 11px;
}

.project-company .project-info span,
.project-company .project-info h2,
.project-company .project-info .h2 {
  margin-bottom: 15px;
}

.project-company .project-info h2, .project-company .project-info .h2,
.project-company .projects-amount h3,
.project-company .projects-amount .h3 {
  color: #000;
  font-size: 14px;
}

.company-gallery .project-widget {
  position: relative;
  margin-bottom: 25px;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.company-gallery .project-widget .pro-image a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  opacity: 0;
  background: linear-gradient(0deg, rgba(133, 83, 238, 0.87), rgba(133, 83, 238, 0.87));
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.company-gallery .project-widget .pro-image a:hover:before {
  opacity: 1;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.company-gallery .project-widget:hover .view-gallery i {
  display: block;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.company-gallery .pro-image img {
  width: 100%;
}

.view-gallery i {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  display: none;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.review-heading {
  padding: 25px;
}

.company-gallery .more-btn,
.company-review .more-btn {
  background: #F5365C;
  border-radius: 5px;
  min-width: 135px;
  padding: 9px 15px;
  font-size: 14px;
  margin-top: 20px;
}

.company-gallery .more-btn img,
.company-review .more-btn img {
  margin-right: 10px;
}

.company-review .review-content {
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;
}
.company-review .review-content.no-padding + .review-content.no-padding {
  padding-top: 15px;
}
.company-review .review-content h4, .company-review .review-content .h4 {
  font-size: 16px;
  color: #444444;
}
.company-review p {
  font-size: 14px;
}

.tab-reviews {
  margin-top: 15px;
}
.tab-reviews .review-img {
  width: 40px;
}
.tab-reviews .review-img img {
  border-radius: 50px;
}
.tab-reviews .review-info {
  margin: 0px 20px;
}
.tab-reviews .review-info h3 a, .tab-reviews .review-info .h3 a {
  font-size: 14px;
  color: #000;
  margin-bottom: 3px;
  font-weight: 600;
}
.tab-reviews .review-info h5, .tab-reviews .review-info .h5 {
  font-size: 11px;
  color: #646464;
  margin-bottom: 0;
  font-weight: 400;
}
.tab-reviews .rating i {
  color: #6E727D;
}
.tab-reviews .rating i.filled {
  color: #febe42;
}
.tab-reviews .rating .average-rating {
  font-weight: 400;
  margin-left: 10px;
}

.post-comment label {
  color: #343434;
  font-size: 16px;
}
.post-comment .form-control {
  border-color: #FFE6E1;
}

.post-btn .more-btn {
  background: #86E53E;
  border-radius: 5px;
  font-size: 18px;
  min-width: 160px;
  padding: 12px 15px;
}

.abouts-detail .pro-title {
  margin-bottom: 29px;
  font-size: 18px;
  text-transform: uppercase;
}
.abouts-detail .pro-content {
  padding: 0;
}

.developer-view .pro-title,
.develop-view .pro-title {
  border: 0;
  text-transform: uppercase;
  color: #000;
  font-size: 16px;
}

.develop-view .widget-box {
  padding: 20px;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
  border-radius: 5px;
}
.develop-view .abouts-view .nav-link {
  padding: 8px;
}
.develop-view .provider-tabs.abouts-view .nav-tabs.nav-tabs-solid li p {
  margin: 0;
}

.developer-profile-img {
  position: relative;
  margin-bottom: 25px;
}

.developer-view .progress-bar {
  background: #3398FB;
}
.developer-view .bg-success {
  background: #5BB054 !important;
}
.developer-view .follow-widget {
  text-align: inherit;
}
.developer-view .follow-widget .follow-btn {
  padding: 10px 44px;
}
.developer-view .widget-box {
  padding: 20px;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
  border-radius: 5px;
}
.developer-view .follow-posts {
  margin: 0;
  width: 100%;
  margin-bottom: 20px;
  box-shadow: 0px 4px 14px rgba(215, 215, 215, 0.25);
  border-radius: 5px;
  border: 0;
}
.developer-view .follow-posts li:first-child {
  width: 50%;
}

.img-profile img {
  border-radius: 50%;
  position: absolute;
  top: 36px;
  right: 43px;
  width: 150px;
  height: 150px;
  border: 4px solid #E0E0E0;
}

.about-field table td {
  padding: 20px 0 20px;
  font-size: 15px;
}
.about-field .table tr td:first-child {
  padding-left: 0px;
  color: #5C5C5C;
}
.about-field .table tr:last-child td {
  padding-bottom: 0;
}
.about-field .pro-title {
  margin-bottom: 15px;
  font-size: 16px;
  color: #000;
}

.developer-box h2, .developer-box .h2 {
  font-size: 26px;
}
.developer-box h3 i, .developer-box .h3 i {
  color: #159C5B;
  font-size: 24px;
}
.developer-box p {
  font-size: 16px;
  color: #000000;
}
.developer-box p span {
  background: #129BE8;
  padding: 7px 17px;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
}

.developer-box h3, .developer-box .h3,
.developer-box h3 a,
.developer-box .h3 a {
  font-size: 24px;
  font-weight: 500;
  color: #86E53E;
}

.head-develop h2, .head-develop .h2 {
  font-size: 28px;
  margin-bottom: 15px;
  color: #000;
}
.head-develop h2 i, .head-develop .h2 i {
  color: #159C5B;
  font-size: 28px;
  margin-left: 10px;
}
.head-develop p span {
  margin-left: 11px;
}
.head-develop .rating i.filled {
  width: 20px;
  height: 20px;
}
.head-develop .develope-list-rate li {
  font-size: 16px;
  font-weight: 500;
}

.develope-list-rate {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 27px;
}
.develope-list-rate li {
  padding: 0px 20px;
  list-style: none;
  border-right: 1px solid #000000;
  color: #000000;
}
.develope-list-rate li:last-child {
  border: 0;
}
.develope-list-rate li:last-child img {
  margin-right: 8px;
}
.develope-list-rate li:first-child {
  padding-left: 0;
}
.develope-list-rate .full-time {
  background: #129BE8;
  padding: 4px 15px;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
  margin-left: 20px;
  min-width: 87px;
}
.develope-list-rate .rating .average-rating {
  background: #FEBE42;
  padding: 5px 5px;
  font-size: 11px;
  color: #000;
  border-radius: 5px;
  margin-right: 10px;
}
.develope-list-rate .emp-edit {
  margin-left: auto;
}

.project-box-view h2, .project-box-view .h2 {
  color: #373737;
  margin-bottom: 30px;
}
.project-box-view h2 i, .project-box-view .h2 i {
  margin-left: 5px;
}
.project-box-view h3, .project-box-view .h3 {
  margin-bottom: 17px;
}
.project-box-view .develope-list-rate li {
  color: #13323C;
  font-size: 14px;
  font-weight: 500;
}
.project-box-view .develope-list-rate .full-time {
  color: #fff;
}

.project-details-view .pro-post {
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.project-details-view .pro-post:hover {
  box-shadow: 0px 6px 6px rgba(204, 204, 204, 0.4);
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.project-details-view .pro-post-head p {
  color: #4E4E4E;
  font-size: 14px;
}
.project-details-view .pro-post-head h6, .project-details-view .pro-post-head .h6 {
  color: #13323C;
  font-size: 17px;
  font-weight: 600;
}
.project-details-view .job-type {
  text-align: left;
  width: 100%;
  margin-bottom: 30px;
  border: 1px solid #efefef;
  border-radius: 5px;
}
.project-details-view .widget-box {
  box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
  border-radius: 5px;
  border: 0;
  padding: 15px;
}
.project-details-view .widget-box .badge-design {
  background: rgba(255, 91, 55, 0.1);
  color: #86E53E;
  padding: 10px;
  margin-right: 15px;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.project-details-view .widget-box .badge-design:hover {
  background: rgba(255, 91, 55, 0.3);
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.proposal-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 22px;
}
.proposal-box .favour-border {
  border: 1px solid #F5365C;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.proposal-box .favour-border:hover {
  background-color: #86E53E;
  border-color: #86E53E;
  color: #fff;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.project-rate li i {
  font-weight: 500;
  margin-right: 10px;
}

.proposal-value h4, .proposal-value .h4 {
  font-size: 25px;
  color: #86E53E;
  font-weight: 600;
  margin-bottom: 0;
}
.proposal-value span {
  font-size: 16px;
}

.favourites-btn.btn-primary,
.proposal-btn.btn-primary {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 47px;
  font-size: 16px;
}

.favourites-btn.btn-primary {
  background: transparent;
  color: #F5365C;
  margin: 0px 15px;
  min-width: 174px;
}
.favourites-btn.btn-primary i {
  margin-left: 7px;
}

.proposal-btn.btn-primary {
  background: #86E53E;
  min-width: 194px;
}
.proposal-btn.btn-primary:hover {
  background: #86E53E;
}
.proposal-btn.btn-primary i {
  margin-left: 4px;
}

.feed-back-detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
}
.feed-back-detail li {
  list-style: none;
  padding: 0px 10px;
  border-right: 1px solid #13323C;
  font-size: 15px;
  color: #5C5C5C;
  font-weight: 500;
}
.feed-back-detail li span {
  padding-left: 10px;
  color: #F15C3D;
}
.feed-back-detail li:last-child {
  border: 0;
}

.feed-back-detail li:first-child,
.watch-list li:first-child {
  padding-left: 0;
}

.feed-back-detail li img,
.watch-list li img {
  margin-right: 10px;
}

.watch-list {
  margin: 22px 0px 30px;
}
.watch-list li {
  padding: 0px 10px;
  list-style: none;
  text-transform: uppercase;
  color: #000;
  font-size: 14px;
  font-weight: 600;
}

.experiance-list {
  background: #F9F9F9;
  border-radius: 20px;
  padding: 20px;
}
.experiance-list h4, .experiance-list .h4 {
  font-size: 16px;
  color: #292929;
  font-weight: 500;
  margin-bottom: 15px;
}
.experiance-list h5, .experiance-list .h5 {
  color: #F38247;
  margin-bottom: 5px;
}
.experiance-list p {
  color: #000000;
}

.experiance-list h5, .experiance-list .h5,
.experiance-list p {
  font-size: 14px;
}

.experiance-logo {
  background: #86E53E;
  border-radius: 10px;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}
.experiance-logo.logo-bg {
  background: #F5365C;
}

.develop-feedback {
  border: 1px solid #E8E8E8;
  box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
  border-radius: 5px;
}
.develop-feedback .author-details h6, .develop-feedback .author-details .h6 {
  color: #F38247;
  font-size: 14px;
}
.develop-feedback .author-details .blog-author-name {
  font-size: 14px;
}
.develop-feedback .author-details p {
  font-size: 14px;
  color: #000;
}

.feedback-view {
  margin-left: auto;
  color: #86E53E;
  font-size: 14px;
  font-weight: 500;
}

.project-gallery .project-widget {
  position: relative;
}
.project-gallery .project-widget .pro-image a:before {
  content: "";
  position: unset !important;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  opacity: 0;
  background: linear-gradient(0deg, rgba(251, 84, 0, 0.79), rgba(251, 84, 0, 0.79));
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.project-gallery .project-widget .pro-image a:hover:before {
  opacity: 1;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.project-gallery .project-widget:hover .project-footer {
  opacity: 1;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.project-gallery .pro-image img {
  width: 100%;
  border-radius: 5px;
}
.project-gallery .more-btn {
  min-width: 135px;
  padding: 10px 15px;
  font-size: 15px;
}

.view-image {
  margin-left: auto;
}
.view-image i {
  width: 28px;
  height: 28px;
  background: #fff;
  color: #86E53E;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.project-footer .pro-name,
.project-footer .pro-designation {
  color: #fff;
}

.project-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.47);
  border-radius: 0 0 5px 5px;
  padding: 10px;
  opacity: 0;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.project-footer .pro-name {
  font-size: 13px;
  margin-bottom: 0;
}
.project-footer .pro-designation {
  font-size: 12px;
}
.project-footer .pro-detail {
  padding: 0;
  text-align: left;
}

.technical-skill .tags .badge-skills {
  width: auto;
  cursor: pointer;
}
.technical-skill .badge-skills {
  background: rgba(255, 91, 55, 0.1);
  border-radius: 40px;
  color: #86E53E;
  font-size: 15px;
  font-weight: 500;
  padding: 10px 15px;
  margin-bottom: 0;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.technical-skill .badge-skills:hover {
  background: rgba(255, 91, 55, 0.3);
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.develop-social-link .profile-head {
  padding: 0;
}
.develop-social-link .social-link-profile {
  padding: 0;
}
.develop-social-link .social-link-profile li a i {
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.develop-social-link .social-link-profile li a i:hover {
  background: #13323C;
  border: 1px solid #13323C;
  color: #fff;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.develop-social-link .pro-title {
  margin-bottom: 34px;
}

.link-box {
  border-radius: 5px;
}
.link-box .latest-posts li {
  border-bottom: 1px solid #EDEDED;
  padding: 15px 20px;
}
.link-box .latest-posts li a {
  font-size: 14px;
  color: #6D7E8F;
}
.link-box .latest-posts li:last-child {
  padding-bottom: 5px;
}
.link-box .widget-title-box {
  padding: 20px 20px 0;
}

.profile-link .input-group .form-control {
  color: #868686;
  font-size: 14px;
}

.develop-list-select .list-grid {
  margin-right: 21px;
}

.develop-list-pro {
  margin-left: auto;
  text-align: right;
}
.develop-list-pro .freelancers-price {
  margin: 19px 0px;
}
.develop-list-pro .btn-cart {
  min-width: 164px;
  padding: 8px 15px;
}
.develop-list-pro .favourite {
  position: relative;
  top: inherit;
  right: inherit;
}

.about-list-author .about-author-img {
  overflow: inherit;
}
.about-list-author .about-author-img img {
  border-radius: 50%;
}
.about-list-author .verified {
  background-color: #fff;
  color: #159C5B;
  font-size: 18px;
  position: absolute;
  right: 0px;
  bottom: 5px;
  border-radius: 100%;
  z-index: 2;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.list-develop-group {
  margin-bottom: 20px;
  box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
}
.list-develop-group .freelance-location {
  margin-bottom: 0px;
}

.wallet-group {
  background: #EDF8FF;
  border-radius: 5px;
  padding: 20px;
  width: 100%;
}

.wallet-history .table .thead-pink th {
  color: #13323C;
  font-size: 15px;
}
.wallet-history .table tr td {
  color: #13323C;
  font-size: 14px;
}
.wallet-history .table tr td span {
  padding: 0;
  color: #646464;
  font-weight: 400;
  font-size: 11px;
}
.wallet-history .table tr td:last-child {
  color: #4AA44D;
}
.wallet-history .table tr td:nth-child(4) i {
  color: #4AA44D;
}

.wallet-btn .btn-primary {
  min-width: 131px;
  padding: 8px 15px;
  font-size: 12px;
  border-radius: 130px;
  font-weight: 600;
}

.wallet-img {
  width: 45px;
  height: 45px;
  margin-right: 15px;
  background: #129BE8;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.wallet-rupee .profile-group .input-group {
  border: 1px solid #FFE6E1;
  border-radius: 5px;
  padding: 0;
}
.wallet-rupee .dol-btn {
  background: #EDEDED;
  border-radius: 5px;
  padding: 0;
  width: 64px;
  height: 64px;
}
.wallet-rupee .badge-skills {
  padding: 10px 37px;
  margin-right: 10px;
  font-size: 14px;
  color: #6E727D;
}
.wallet-rupee .form-control {
  border-color: #ffe6e1;
  height: 64px;
}
.wallet-rupee label {
  font-weight: 400;
  color: #343434;
}
.wallet-rupee .custom_radio .checkmark {
  height: 25px;
  width: 25px;
}
.wallet-rupee .custom_radio .checkmark:after {
  top: 5px;
  left: 5px;
  width: 13px;
  height: 13px;
}
.wallet-rupee .bootstrap-tags {
  padding: 0;
  margin-bottom: 15px;
}

.wallet-rupee .form-group,
.wallet-rupee .custom_radio {
  margin-bottom: 15px;
}

.balance-total h3, .balance-total .h3 {
  font-size: 16px;
  color: #13323C;
  font-weight: 400;
  margin-bottom: 5px;
}
.balance-total h2, .balance-total .h2 {
  font-size: 18px;
  color: #129BE8;
  font-weight: 600;
  margin-bottom: 0;
}

.total-credit {
  background: #E4FFEA;
}
.total-credit .wallet-img {
  background: #159C5B;
}
.total-credit .balance-total h2, .total-credit .balance-total .h2 {
  color: #159C5B;
}

.total-depit {
  background: #FFF0F3;
}
.total-depit .wallet-img {
  background: #FF2C00;
}
.total-depit .balance-total h2, .total-depit .balance-total .h2 {
  color: #FF2C00;
}

.wallet-list {
  padding: 0;
  box-shadow: none;
  background: transparent;
}
.wallet-list .dol-btn {
  color: #000;
  background: #EDEDED;
  border: 1px solid #EDEDED;
}

#wallet .modal-header {
  border-bottom: 0;
  padding: 0;
}
#wallet .modal-header span {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 9;
}

.wallet-head h2, .wallet-head .h2 {
  font-size: 24px;
  margin: 23px 0px 40px;
}

.freelance-box {
  padding: 25px;
}
.freelance-box .freelance-tags span {
  margin-bottom: 10px;
}

.book-mark .freelance-img,
.book-mark .freelance-img img {
  width: 86px;
  height: 86px;
}

.book-mark {
  padding-bottom: 0px;
}
.book-mark .freelance-info h3, .book-mark .freelance-info .h3 {
  font-size: 16px;
  font-weight: 500;
}
.book-mark .freelance-tags span {
  font-size: 10px;
}
.book-mark .freelance-img .verified {
  width: 16px;
  height: 16px;
  border: 1px solid #FFFFFF;
  border-radius: 50%;
}
.book-mark .freelance-img .verified i {
  font-size: 16px;
}
.book-mark .favourite {
  width: 27px;
  height: 27px;
}
.book-mark .freelance-specific {
  margin: 5px 0px;
}
.book-mark .freelance-location {
  margin-bottom: 6px;
}
.book-mark .freelance-content {
  padding: 15px;
}
.book-mark .freelance-widget {
  border: 1px solid #EFEFEF;
  box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
  margin-bottom: 25px;
}
.book-mark .rating i {
  color: #6E727D;
}
.book-mark .rating i.filled {
  color: #febe42;
}
.book-mark .rating .average-rating {
  font-weight: 400;
}
.book-mark .badge-design {
  background: #F6F6F6;
}
.book-mark .badge-design:hover {
  background: #FFF0F0;
}
.book-mark .btn-cart {
  padding: 6px 15px;
}
.book-mark .list-pagination {
  margin-top: 20px;
}

.book-mark .freelance-specific,
.book-mark .freelance-location,
.book-mark .rating i,
.book-mark .rating .average-rating,
.book-mark .btn-cart {
  font-size: 12px;
}

.favour-book .freelance-info h3 a, .favour-book .freelance-info .h3 a {
  color: #000;
}
.favour-book .freelancers-price {
  color: #55545B;
}

.book-mark .freelance-info h3 a:hover, .book-mark .freelance-info .h3 a:hover,
.tab-reviews .review-info h3 a:hover,
.tab-reviews .review-info .h3 a:hover {
  color: #86E53E;
}

.list-grid {
  margin-left: auto;
  margin-bottom: 0;
}
.list-grid li {
  list-style: none;
}
.list-grid li:first-child {
  margin-right: 15px;
}
.list-grid li i {
  background: #FFFFFF;
  border: 1px solid #E9E9E9;
  width: 34px;
  height: 34px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.list-grid li .favour-active i {
  background: #86E53E;
  border: 1px solid #86E53E;
  color: #fff;
}

.list-book-mark .about-author {
  padding: 15px;
}
.list-book-mark .freelance-tags {
  margin-bottom: 0;
}
.list-book-mark .freelance-tags span {
  margin-bottom: 5px;
}
.list-book-mark .verified {
  width: 16px;
  height: 16px;
  border: 1px solid #FFFFFF;
  border-radius: 50%;
  right: 5px;
}
.list-book-mark .verified i {
  font-size: 16px;
}
.list-book-mark .author-details {
  margin-left: 108px;
}

.relavance-rate {
  margin-left: auto;
}
.relavance-rate .sort-by {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.relavance-rate .sort-by select {
  padding: 0 4px;
  max-width: 110px;
}
.relavance-rate .sort-by label {
  margin-bottom: 0;
  margin-right: 4px;
}

.relavance-result {
  margin-bottom: 20px;
}
.relavance-result h4, .relavance-result .h4 {
  font-size: 14px;
  color: #86E53E;
}

.bookmark-projects .counter-stats {
  margin-top: 0px;
}
.bookmark-projects .counter-stats ul li {
  padding-left: 0;
  padding-right: 0;
}
.bookmark-projects .counter-stats ul li h5, .bookmark-projects .counter-stats ul li .h5 {
  margin-bottom: 3px;
}
.bookmark-projects .freelance-content {
  padding: 10px;
}
.bookmark-projects .widget-author .profile-img {
  width: 86px;
  height: 86px;
  padding: 0;
  border: 1px solid #C4C4C4;
  background: transparent;
  margin-bottom: 5px;
}
.bookmark-projects .widget-author .author-location a {
  font-size: 16px;
  margin-bottom: 5px;
  text-transform: capitalize;
  color: #86E53E;
}
.bookmark-projects .freelance-info h3, .bookmark-projects .freelance-info .h3 {
  line-height: 1.3;
  margin-bottom: 5px;
}
.bookmark-projects .freelance-info h3 a, .bookmark-projects .freelance-info .h3 a {
  font-size: 12px;
  color: #6E727D;
  font-weight: 400;
}
.bookmark-projects .post-now i {
  font-weight: 400;
}
.bookmark-projects .freelance-tags {
  margin-bottom: 0px;
}
.bookmark-projects .freelance-tags span {
  margin-bottom: 5px;
}
.bookmark-projects .freelancers-price {
  margin: 15px 0px;
  color: #55545B;
}

.bookmark-projects .counter-stats ul li h3, .bookmark-projects .counter-stats ul li .h3,
.bookmark-projects .counter-stats ul li h5,
.bookmark-projects .counter-stats ul li .h5 {
  font-size: 10px;
}

.favourite-group .card-header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.post-job-icon {
  margin-left: auto;
  width: 34px;
  height: 34px;
  box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.blue-color {
  background: #3398FB;
}

.orange-color {
  background: #86E53E;
}

.pink-color {
  background: #F5365C;
}

.green-color {
  background: #5BB054;
}

.project-duration .job-type {
  margin-bottom: 25px;
}
.project-duration .pro-content {
  padding-top: 0;
}

.requirement-img {
  position: relative;
}

.file-name {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
.file-name h4, .file-name .h4 {
  color: #000;
}
.file-name h5, .file-name .h5 {
  color: #A3A3A3;
}

.file-name h4, .file-name .h4,
.file-name h5,
.file-name .h5 {
  font-size: 14px;
  font-weight: 400;
}

.project-details-view .pro-post .pro-title,
.project-client-view .pro-post .pro-title {
  border-bottom: 0;
  text-transform: uppercase;
  font-size: 17px;
}

.project-client-view .pro-post {
  border: 1px solid #E8E8E8;
  box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
  border-radius: 5px;
}
.project-client-view .widget-author .author-location a {
  font-size: 22px;
  color: #86E53E;
  margin-bottom: 5px;
  text-transform: capitalize;
  font-weight: 500;
}
.project-client-view .widget-author .profile-img {
  box-shadow: none;
  background-color: transparent;
  padding: 0;
  border-radius: 50%;
  border: 1px solid #C4C4C4;
  width: 86px;
  height: 86px;
  margin: 0 auto 15px;
}
.project-client-view .freelance-info h4, .project-client-view .freelance-info .h4 {
  color: #6E727D;
  font-size: 14px;
  font-weight: 500;
}
.project-client-view .rating i {
  color: #6E727D;
}
.project-client-view .rating i.filled {
  color: #febe42;
}
.project-client-view .rating .average-rating {
  font-weight: 400;
}
.project-client-view .rounded-pill {
  padding: 8px 20px;
  font-size: 18px;
}
.project-client-view .follow-details {
  margin: 19px 0px;
}

.client-about .pro-post {
  border: 0;
  box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
  border-radius: 5px;
}
.client-about .client-list {
  margin-bottom: 25px;
  width: 100%;
}

.link-project .pro-title {
  margin-bottom: 26px;
}

.pro-member h6, .pro-member .h6 {
  font-size: 15px;
  font-weight: 600;
}
.pro-member span {
  font-weight: 500;
}

.project-verified {
  position: absolute;
  bottom: 0;
  right: 5px;
}
.project-verified i {
  color: #00cc52;
  background: #fff;
  border-radius: 50%;
}

.btn-get {
  font-weight: 900;
  font-size: 24px;
  color: #FFF;
  text-transform: uppercase;
  min-width: 300px;
  border-radius: 50px;
  padding: 16px;
}

.multistep-form {
  background: #F9F9F9;
  border-radius: 10px;
}
.multistep-form .on-board {
  display: none;
  width: 100%;
}
.multistep-form .on-board .form-focus.focused .focus-label {
  z-index: 9;
}
.multistep-form .group-select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.multistep-form > .card-body {
  padding: 50px;
}
.multistep-form .acc-title {
  color: #3C00F2;
  margin-bottom: 40px;
  font-weight: 900;
  font-size: 36px;
  text-align: center;
}
.multistep-form .form-group {
  margin-bottom: 25px;
  width: 100%;
}
.multistep-form .form-control {
  height: 50px;
}
.multistep-form label {
  font-size: 16px !important;
  color: #343434 !important;
}

.workin-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.workin-check .form-check-input {
  border: 1px solid #FFE6E1;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}
.workin-check .form-check-input:focus {
  box-shadow: none;
}

.form-switch .form-check-input {
  cursor: pointer;
}

.on-board .form-focus.focused .focus-label {
  z-index: 99999;
}

.account-onborad {
  max-width: 700px;
  text-align: center;
  margin: 0 auto;
}
.account-onborad a {
  font-weight: 900;
  font-size: 24px;
  color: #8A2BE2;
  display: inline-block;
}
.onboard-head h2, .onboard-head .h2 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 8px;
}
.onboard-head h2 span, .onboard-head .h2 span {
  color: #8A2BE2;
}
.onboard-head p {
  font-size: 16px;
  color: #343434;
  font-weight: 500;
  margin-bottom: 40px;
}
.onboard-head a {
  padding: 21px;
  width: 200px;
  height: 200px;
}

.back-home img {
  margin-bottom: 40px;
}
.back-home .btn-primary {
  background: #86E53E;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  min-width: 177px;
}
.back-home h2, .back-home .h2 {
  font-size: 20px;
  color: #000000;
}
.back-home p {
  font-size: 16px;
  color: #343434;
  font-weight: 400;
  margin: 8px 0px 42px;
}

.multi-step {
  width: 40px;
  height: 40px;
  background: #E8E8E8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  color: #000000;
  font-weight: 700;
  font-size: 14px;
  margin-right: 15px;
  line-height: 0;
}

.multistep-progress {
  background: #FFFFFF;
  box-shadow: 0px 0px 50px rgba(132, 39, 225, 0.2);
  border-radius: 50px;
  padding: 10px 10px 0;
  margin: 0 auto 50px;
  max-width: 1046px;
  width: fit-content;
}

.progressbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: auto;
  margin-top: 0;
}
.progressbar li {
  display: flex;
  align-items: center;
  margin: 0;
  margin-right: 30px;
}
.progressbar li h5, .progressbar li .h5 {
  margin-bottom: 0;
  color: #343434;
  font-weight: 500;
  font-size: 15px;
}

.board-logo {
  width: 20%;
}

.progress-active .multi-step {
  background: #86E53E;
  color: #fff;
}

.progress-activated .multi-step {
  background: #86E53E;
  font-size: 0;
  color: #fff;
}
.progress-activated .multi-step:before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 12px;
}
.progress-activated h5, .progress-activated .h5 {
  color: #86E53E;
}

.progress-25 {
  display: block;
}

.progress-50, .progress-75, .progress-100 {
  display: none;
}

.progress-50 .progress-bar {
  background: #159C5B;
  width: 50%;
}

.progress-75 .progress-bar {
  background: #159C5B;
  width: 75%;
}

.progress-100 .progress-bar {
  background: #159C5B;
  width: 100%;
}

.circle-bar1 {
  height: 55px;
  margin-bottom: 10px;
}

.circle-graph1 {
  height: 55px;
}

.circle-bar > div {
  display: inline-block;
  position: relative;
  text-align: center;
}
.circle-bar > div p {
  left: 0;
  position: absolute;
  top: 50%;
  right: 0;
  text-align: center;
  margin: 0 auto;
  transform: translateY(-50%);
  font-weight: 500;
  font-size: 14px;
  color: #19D1AF;
}
.circle-bar > div canvas {
  width: 55px !important;
  height: 55px !important;
}

.circle-percent-0 p {
  color: #8B8E97 !important;
}

.btn-prev {
  border: 0;
  padding-left: 30px;
  font-weight: 900;
  font-size: 24px;
  background-position: left;
  margin-right: 30px;
}

.field-card {
  padding: 30px;
}

.acc-title-01 {
  display: block;
}

.acc-title-02, .acc-title-03, .acc-title-04, .acc-title-05 {
  display: none;
}

.account-wrap .header {
  box-shadow: none;
  background: transparent;
}
.account-wrap .page-wrapper {
  padding-top: 0;
  height: 100%;
}
.account-wrap .page-wrapper > .content {
  padding: 30px;
  height: 100%;
}
.account-wrap .acc-content {
  height: 100%;
  background: #F9F9F9;
  border-radius: 10px;
}

.board-screen .content {
  padding: 0;
}
.board-screen .multistep-progress {
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 0;
}
.board-screen .progressbar {
  margin-bottom: 0;
  max-width: 70%;
  margin-left: auto;
}
.board-screen .multistep-form {
  background: #fff;
}

.first-progress,
.second-progress {
  display: flex;
  align-items: center;
  height: 53px;
}

.select-account {
  padding: 80px 0px;
}
.select-account .pro-form-img .btn-primary {
  min-width: 191px;
  background: transparent;
  border: 1px solid #FFE6E1;
  color: #86E53E;
  padding: 12px 15px;
  font-size: 16px;
}
.select-account .prev_btn {
  min-width: 150px;
  padding: 10px 15px;
  font-size: 16px;
  text-transform: capitalize;
  background: rgba(20, 20, 20, 0.7);
  border-radius: 5px;
  color: #fff;
}
.select-account .btn-primary {
  min-width: 150px;
  padding: 9px 15px;
  font-size: 16px;
  text-transform: capitalize;
  background: #86E53E;
  border-radius: 5px;
  font-weight: 600;
}

.select-box {
  margin-bottom: 130px;
}
.select-box .frelance-img:first-child {
  margin-right: 30px;
}
.select-box input {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.select-box input:active + .employee-level {
  opacity: 0.9;
}
.select-box input:checked + .employee-level {
  border: 1px solid #159C5B;
}
.select-box .employee-level:first-child {
  margin-right: 25px;
}

.guardian-onboard-info {
  background: #FFFFFF;
  box-shadow: 0px 0px 15px rgba(215, 215, 215, 0.56);
  border-radius: 10px;
  max-width: 90%;
  margin: auto;
  margin-bottom: 40px;
  padding: 30px;
}
.guardian-onboard-info .form-control::placeholder {
  color: #888888;
}
.guardian-onboard-info label {
  color: #343434;
  font-size: 16px;
}
.guardian-onboard-info label span {
  color: #FF0000;
}

.personal-info {
  background: #FFFFFF;
  border: 1px solid #EDEDED;
  box-shadow: 0px 0px 15px rgba(215, 215, 215, 0.56);
  border-radius: 10px;
  max-width: 90%;
  margin: auto;
  margin-bottom: 40px;
  padding: 30px;
}
.personal-info .form-control {
  border-color: #FFE6E1;
}
.personal-info .form-control::placeholder {
  color: #888888;
}
.personal-info label {
  color: #343434;
  font-size: 16px;
}
.personal-info label span {
  color: #FF0000;
}

.space-info {
  padding-bottom: 5px;
}

.pro-form-img {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.pro-form-img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}
.pro-form-img .file-upload {
  margin: 0;
  min-width: 191px;
  text-align: center;
}
.pro-form-img label.file-upload.image-upbtn {
  background: transparent;
  color: #86E53E !important;
  border: 1px solid #FFE6E1;
}

.min-characters p {
  color: #888888;
  margin-bottom: 0;
  margin-top: 5px;
}
.min-characters .form-control {
  height: auto;
}

.select-level {
  width: 100%;
  color: #888888;
  position: relative;
  background-image: url(../../assets/img/icon/icon-18.svg);
  background-repeat: no-repeat, no-repeat;
  background-position: 95%;
}

.select-edu {
  background-position: 88%;
}

.new-addd {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
  min-height: 50px;
}
.new-addd .add-new {
  margin-bottom: 0;
  color: #159C5B;
  margin-left: 7px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}
.new-addd .add-new i {
  font-size: 12px;
  margin-right: 5px;
}
.new-addd .remove_row {
  color: #FF0000;
}

.remove_row {
  cursor: pointer;
}

.employe-cal-icon:after {
  color: rgba(20, 20, 20, 0.6980392157);
}

.other-info-list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.other-info-list ul li {
  width: 50px;
  height: 50px;
  background: #C4C4C4;
  border-radius: 50px;
  list-style: none;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  margin-right: 26px;
  cursor: pointer;
}
.other-info-list ul .active-hour {
  background: #86E53E;
}

.time-box .form-group {
  margin-right: 20px;
  max-width: 118px;
}

.check-hour {
  display: none;
}

.employee-level {
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 200px;
  height: 200px;
  border: 1px solid #FFE6E1;
  border-radius: 5px;
  margin-bottom: 0;
  font-weight: 600;
}
.employee-level span {
  color: #343434;
  font-size: 14px;
  font-weight: 600;
}
.employee-level img {
  margin-bottom: 20px;
}

.free-lance-img {
  margin-right: 25px;
}

.select-btn {
  padding: 80px 0px;
}

.work-check {
  margin-bottom: 0;
}
.work-check .form-check-input {
  width: 39px;
  height: 22px;
  position: absolute;
  top: 10px;
  right: 20px;
}
.work-check .form-check-input:checked {
  background-color: #86E53E;
  border-color: #86E53E;
}
.work-check .form-check-input:focus {
  box-shadow: none;
}

.blog-tricks .blog-slider .owl-carousel .owl-nav .owl-prev {
  background: #fff;
  color: #86E53E;
  width: 44px;
  height: 44px;
  font-size: 16px;
  border-radius: 50px;
  position: absolute;
  border: 1px solid #EBEBEB;
  top: 40%;
  left: -25px;
}

.blog-tricks .blog-slider .owl-carousel .owl-nav .owl-next {
  background: #fff;
  color: #86E53E;
  width: 44px;
  height: 44px;
  font-size: 16px;
  border-radius: 50px;
  position: absolute;
  border: 1px solid #EBEBEB;
  top: 40%;
  right: -25px;
}

.blog-tricks .blog-slider .owl-carousel .owl-nav .owl-next:hover {
  background: #86E53E;
  color: #fff;
}

.blog-trick .blog-slider .owl-carousel .owl-nav .owl-prev:hover {
  background: #86E53E;
  color: #fff;
}

.testimonial-section .owl-carousel .owl-nav button .owl-prev {
  display: block;
  float: left;
  width: 32px;
  height: 32px;
  font-size: 13px;
  color: #fff;
  background: #13323C;
  text-align: center;
  border-radius: 10px !important;
}

.trend-projects.owl-carousel .trend-slider.owl-dots {
  position: absolute;
  margin: 0;
  right: 0;
  min-width: 90px;
  top: -94px;
}

.trend-projects .owl-carousel .trend-slider .owl-nav {
  position: absolute;
  margin: 0;
  right: 0;
  top: -97;
  min-width: 90px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}

.trend-projects .owl-carousel .trend-slider .owl-nav button.owl-prev {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
}

.developers-slider .owl-carousel .owl-nav .owl-prev {
  display: block;
  float: left;
  width: 32px;
  height: 32px;
  font-size: 13px;
  color: #fff;
  border: 0;
  background: #13323C;
  text-align: center;
  -webkit-transition: color 0.7s ease, background-color 0.7s ease, border-color 0.7s ease;
  transition: color 0.7s ease, background-color 0.7s ease, border-color 0.7s ease;
  box-shadow: 0px 1px 4px rgba(183, 183, 183, 0.25);
  border-radius: 10px !important;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.developers-slider .owl-carousel .owl-nav .owl-next {
  display: block;
  float: left;
  width: 32px;
  height: 32px;
  font-size: 13px;
  color: #fff;
  border: 0;
  background: #13323C !important;
  text-align: center;
  -webkit-transition: color 0.7s ease, background-color 0.7s ease, border-color 0.7s ease;
  transition: color 0.7s ease, background-color 0.7s ease, border-color 0.7s ease;
  box-shadow: 0px 1px 4px rgba(183, 183, 183, 0.25);
  border-radius: 10px !important;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.developers-slider .owl-carousel .owl-nav .owl-prev i {
  transform: rotate(-45deg);
}

.developers-slider .owl-carousel .owl-nav .owl-next i {
  transform: rotate(-45deg);
}

.client-img .slick-prev {
  display: none !important;
}

.client-img .slick-next {
  display: none !important;
}

.blog-trick-five.blog-slider.owl-carousel.owl-nav button.owl-next {
  background: #FFF5F1;
}

.dashboard-page .content > .container-fluid, .dashboard-page .content > .container-sm, .dashboard-page .content > .container-md, .dashboard-page .content > .container-lg, .dashboard-page .content > .container-xl, .dashboard-page .content > .container-xxl {
  padding-left: 30px;
  padding-right: 30px;
}

.footer {
  padding: 30px;
}

@media only screen and (min-width: 768px) {
  .avatar-xxl {
    width: 8rem;
    height: 8rem;
  }
  .avatar-xxl .border {
    border-width: 4px !important;
  }
  .avatar-xxl .rounded {
    border-radius: 12px !important;
  }
  .avatar-xxl .avatar-title {
    font-size: 42px;
  }
  .avatar-xxl.avatar-away:before,
  .avatar-xxl.avatar-offline:before,
  .avatar-xxl.avatar-online:before {
    border-width: 4px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 1440px;
  }
  .main-nav > li {
    margin-right: 30px;
  }
  .main-nav > li:last-child {
    margin-right: 0;
  }
  .main-nav > li > a {
    padding: 30px 0;
  }
  .main-nav > li > a > i {
    font-size: 10px;
    margin-left: 2px;
  }
  .main-nav > li .submenu li:first-child a {
    border-top: 0;
  }
  .main-nav > li .submenu > li .submenu {
    left: 100%;
    top: 0;
    margin-top: 10px;
  }
  .main-nav > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu {
    visibility: visible;
    opacity: 1;
    margin-top: -1px;
    margin-right: 0;
  }
  .main-nav > li > i {
    color: #fff;
    line-height: 75px;
  }
  .main-nav > li > i:hover {
    cursor: pointer;
  }
  .main-nav li {
    display: block;
    position: relative;
  }
  .main-nav li > ul {
    background-color: #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    display: block;
    font-size: 14px;
    left: 0;
    margin: 0;
    min-width: 200px;
    opacity: 0;
    padding: 0;
    position: absolute;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    top: 100%;
    visibility: hidden;
    z-index: 1000;
  }
  .main-nav li.has-submenu:hover > .submenu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .main-nav .has-submenu.active .submenu li.active > a {
    color: #fff;
    background-color: #86E53E;
  }
  .main-nav > .has-submenu > .submenu > .has-submenu > .submenu::before {
    top: 20px;
    margin-left: -35px;
    box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.15);
    border-color: transparent #fff #fff transparent;
  }
  .main-nav > li .submenu li a:hover,
  .header-three .main-nav > li .submenu li a:hover {
    background-color: #86E53E;
    color: #fff;
    border-color: transparent;
  }
  .searchbar {
    line-height: 60px;
  }
  .header-navbar-rht .dropdown-toggle.show + .dropdown-menu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
@media only screen and (max-width: 1399px) {
  .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name,
  .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
    max-width: 180px;
  }
}
@media only screen and (max-width: 1199.98px) {
  .header-nav {
    padding-left: 20px;
    padding-right: 20px;
  }
  .header-navbar-rht li.lang-item {
    display: none;
  }
  .header-navbar-rht li a {
    font-size: 12px;
    align-items: center;
  }
  .header-navbar-rht .login-btn {
    padding: 8px 30px;
    font-size: 14px;
  }
  .main-nav li a {
    font-size: 13px;
  }
  .main-nav > li {
    margin-right: 15px;
  }
  .project-content h5, .project-content .h5 {
    font-size: 12px;
  }
  .work-content {
    width: 83%;
    padding: 70px 50px 50px 60px;
  }
  .work-content h2, .work-content .h2 {
    font-size: 26px;
  }
  .footer-title {
    font-size: 19px;
  }
  .footer .footer-menu ul li a {
    font-size: 15px;
  }
  .agency-content {
    padding: 35px;
  }
  .payment-detail {
    padding: 25px;
  }
  .payment-detail p {
    font-size: 18px;
  }
  .profile-list li {
    width: 33.3333333333%;
  }
  .btn-list {
    margin-bottom: 7px;
  }
  .profile-title {
    font-size: 30px;
  }
  .follow-posts {
    width: 240px;
  }
  .left-content h4, .left-content .h4 {
    font-size: 12px;
  }
  .bid-btn, .btn-view-outline {
    font-size: 11px;
  }
  .profile-right {
    display: inline-block;
  }
  .profile-right li {
    margin-right: 0;
  }
  .job-type h6, .job-type .h6 {
    font-size: 17px;
  }
  .amt-hr {
    font-size: 28px;
  }
  .bid-btn, .dev-right h6, .dev-right .h6 {
    font-size: 13px;
  }
  .dev-right p {
    font-size: 15px;
  }
  .job-category p {
    font-size: 14px;
  }
  .stats-detail {
    padding: 18px;
  }
  .stats-detail h3, .stats-detail .h3, .create-milestone h3, .create-milestone .h3 {
    font-size: 24px;
  }
  .table-borderless.table tbody tr td {
    font-size: 20px;
  }
  .dash-widget-info {
    font-size: 17px;
  }
  .proposals-info {
    display: block;
  }
  .proposals-detail {
    width: 100%;
  }
  .project-hire-info {
    width: 100%;
  }
  .project-hire-info.project {
    width: 100%;
  }
  .content-divider-1 {
    display: none;
  }
  .projects-details {
    display: block;
  }
  .project-info.project {
    width: 100%;
  }
  .content-divider {
    display: none;
  }
  .proposer-info.project {
    padding-left: 0;
  }
  .projects-action.project {
    padding-left: 0;
  }
  .need-project h2, .need-project .h2 {
    font-size: 38px;
  }
  .develope-project,
  .news-two,
  .get-discount,
  .most-hired,
  .projects-two {
    padding: 60px 0px;
  }
  .perfect-group h2, .perfect-group .h2 {
    font-size: 20px;
  }
  .img-perfect img {
    width: 100%;
  }
  .develop-bottom h5, .develop-bottom .h5 {
    padding: 16px 25px;
  }
  .board-logo {
    width: 10%;
  }
  .social-link-profile {
    flex-wrap: wrap;
  }
  .social-link-profile li {
    margin-bottom: 15px;
  }
  .develop-social-link.widget-box {
    margin-bottom: 5px;
  }
  .work:after {
    width: 35px;
    transform: inherit;
  }
  .banner-content h1, .banner-content .h1 {
    font-size: 38px;
  }
  .banner-content p {
    font-size: 18px;
  }
  .banner-content .form-control {
    height: 58px;
  }
  .banner-content .sub-btn {
    height: 40px;
    font-size: 14px;
  }
  .all-btn {
    padding: 10px 35px;
    font-size: 14px;
    padding: 10px 30px;
    min-width: 180px;
  }
  .job-content-blk h1, .job-content-blk .h1 {
    font-size: 28px;
  }
  .job-content-blk p {
    font-size: 16px;
  }
  .owl-carousel .owl-item .review-blog .review-img img {
    width: 60px;
  }
  .owl-carousel.trend-slider .owl-nav {
    display: none;
  }
  .home-two .market-place h3, .home-two .market-place .h3 {
    font-size: 20px;
  }
  .home-count ul li {
    font-size: 20px;
  }
  .home-count ul li span {
    font-size: 16px;
  }
  .platform-group h1, .platform-group .h1 {
    font-size: 24px;
  }
  .feature-project-list li {
    font-size: 15px;
  }
  .feature-content h4, .feature-content .h4 {
    font-size: 18px;
  }
  .about-it-content p {
    font-size: 15px;
  }
  .more-project .btn-primary {
    min-width: 183px;
    padding: 8px 15px;
    font-size: 14px;
  }
  .blog-slider.owl-carousel .owl-nav button.owl-prev {
    left: -5px;
  }
  .blog-slider.owl-carousel .owl-nav button.owl-next {
    right: -5px;
  }
  .blog-slider.owl-carousel .owl-nav button.owl-prev,
  .blog-slider.owl-carousel .owl-nav button.owl-next {
    width: 35px;
    height: 35px;
    font-size: 12px;
  }
  .great-about {
    padding: 80px 0px 410px;
  }
  .dot-slider .slick-list {
    height: 445px !important;
  }
  .dot-slider.slick-vertical .slick-slide {
    height: 445px;
  }
  .home-three .banner-content h1, .home-three .banner-content .h1 {
    font-size: 28px;
  }
  .home-three .slick-prev:before, .home-three .slick-next:before {
    width: 15px;
    height: 15px;
    background-size: 91%;
  }
  .home-three .slick-prev, .home-three .slick-next {
    width: 40px;
    height: 40px;
  }
  .header-four .header-navbar-rht li a.reg-btn {
    font-size: 14px;
  }
  .home-four .banner-content h5, .home-four .banner-content .h5 {
    font-size: 18px;
  }
  .home-four .banner-content h1, .home-four .banner-content .h1 {
    font-size: 36px;
  }
  .stacker-active {
    font-size: 13px;
    bottom: -2px;
    width: 16px;
    height: 16px;
  }
  .fullstacker-img {
    width: 70px;
    height: 70px;
  }
  .fullstacker-name h4, .fullstacker-name .h4 {
    font-size: 16px;
  }
  .complete-content h3, .complete-content .h3 {
    font-size: 18px;
  }
  .complete-icon {
    width: 45px;
    height: 45px;
  }
  .market-list-out li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .market-place-group h2, .market-place-group .h2 {
    font-size: 26px;
  }
  .market-place-btn .btn-primary,
  .market-place-btn .market-developer {
    font-size: 14px;
  }
  .popular-slider.developers-slider .owl-nav {
    bottom: 100%;
    right: 22%;
    margin-bottom: 55px;
  }
  .review-four {
    padding: 80px 0px 120px;
  }
  .market-place-btn.platform-btn .btn-primary {
    min-width: 180px;
  }
  .register-profesion .avatar-group .avatar {
    width: 38px;
    height: 38px;
    border: 2px solid #fff;
  }
  .header-five .header-navbar-rht li .reg-btn {
    padding: 8px 15px;
    font-size: 14px;
    min-width: 170px;
  }
  .banner-blk-five {
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
  }
  .home-five .banner-content .sub-btn {
    height: 42px;
    min-width: 42px;
    padding: 10px 6px;
    margin: 6px;
  }
  .home-five .banner-content .sub-btn i {
    font-size: 21px;
  }
  .need-job-group .about-great-content h4, .need-job-group .about-great-content .h4 {
    font-size: 18px;
  }
  .project-item .feature-foot p {
    font-size: 14px;
  }
  .demand-professional h2, .demand-professional .h2 {
    font-size: 24px;
  }
  .demand-post-img {
    margin-right: 20px;
  }
  .join-market-world {
    padding-left: 45px;
  }
  .join-market-world h2, .join-market-world .h2 {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .join-market-world p {
    margin-bottom: 30px;
  }
  .join-platform-img:before {
    background-size: 100%;
  }
  .compay-hire .compay-hire-blk {
    margin-bottom: 25px;
  }
  .img-reviews img {
    max-width: 170px;
  }
  .review-quote img {
    max-width: 45px;
  }
  .testimonial-two-head h3, .testimonial-two-head .h3 {
    font-size: 20px;
  }
  .testimonial-two-head p {
    font-size: 14px;
  }
  .grid-blog .blog-title {
    font-size: 18px;
  }
  .great-find-job.job-next-blk h2, .great-find-job.job-next-blk .h2 {
    font-size: 24px;
  }
  .job-next-blk p {
    font-size: 15px;
  }
  .blog-tricks-five {
    padding: 80px 0px 120px;
  }
  .opportunity-img {
    max-width: 500px;
  }
  .hire-company-list ul li {
    border: 1px solid #EBEBEB !important;
  }
  .talent-developer:before {
    display: none;
  }
  .whirly-loader {
    width: 80px;
    height: 80px;
  }
  .loader-img img {
    width: 40px;
  }
  .scroll-top {
    width: 40px;
    height: 40px;
  }
}
@media only screen and (max-width: 991.98px) {
  h1, .h1 {
    font-size: 32px;
  }
  h2, .h2 {
    font-size: 28px;
  }
  h3, .h3 {
    font-size: 20px;
  }
  h4, .h4 {
    font-size: 18px;
  }
  h5, .h5 {
    font-size: 16px;
  }
  h6, .h6 {
    font-size: 15px;
  }
  .banner-content h1, .banner-content .h1 {
    font-size: 30px;
    line-height: 39px;
  }
  .banner-content p {
    font-size: 19px;
  }
  .courses-btn, .get-btn {
    font-size: 15px;
    padding: 14px 28px;
  }
  .container {
    max-width: 100%;
  }
  .feature-item {
    padding: 30px 10px;
  }
  .feature .feature-content h3, .feature .feature-content .h3 {
    font-size: 26px;
  }
  .feature .feature-content p {
    font-size: 18px;
  }
  .myprofile-header h2, .myprofile-header .h2 {
    font-size: 20px;
  }
  .bg1, .bg2 {
    background-size: 150px;
  }
  .setting-tab .nav-link {
    padding: 15px 20px;
    font-size: 16px;
  }
  .setting-content .pro-head h2, .setting-content .pro-head .h2 {
    font-size: 22px;
  }
  .work-content {
    width: 90%;
    padding: 40px;
  }
  .work-content .long-arrow {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 22px;
  }
  .work-content h2, .work-content .h2 {
    font-size: 26px;
  }
  .work-content p, .section-header p {
    font-size: 16px;
  }
  .all-btn {
    padding: 12px 24px;
    font-size: 16px;
  }
  .review-blog {
    padding: 20px;
  }
  .review-blog .review-img img {
    width: 100px;
    height: 100px;
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }
  .review-blog .review-content p {
    font-size: 15px;
  }
  .review-info {
    flex-grow: 1;
  }
  .review-info h3, .review-info .h3 {
    font-size: 18px;
  }
  .review-bottom .review-img img {
    width: 50px;
    height: 50px;
  }
  .review-bottom .review-info h3, .review-bottom .review-info .h3 {
    font-size: 16px;
  }
  .news-content {
    margin-right: 0;
  }
  .news-content .entry-meta li {
    font-size: 14px;
  }
  .news-content .entry-meta li.name i {
    font-size: 16px;
  }
  .news-content h2, .news-content .h2 {
    font-size: 16px;
  }
  .news-content p {
    font-size: 15px;
  }
  .service-item {
    padding: 25px;
  }
  .service-content h5, .service-content .h5 {
    font-size: 17px;
  }
  .social-icon ul li a {
    font-size: 13px;
  }
  .news-blog {
    align-items: unset;
  }
  .news-blog .d-flex {
    display: block !important;
  }
  .expert, .about, .job-counter {
    padding: 50px 0;
  }
  .about .about-img {
    text-align: center;
  }
  .agency-content {
    width: 100%;
    padding: 20px;
  }
  .agency-content h2, .agency-content .h2 {
    font-size: 20px;
    margin-bottom: 8px;
    line-height: 24px;
  }
  .counter h2, .counter .h2 {
    font-size: 28px;
  }
  .counter h4, .counter .h4 {
    font-size: 18px;
  }
  .follow-posts {
    width: 280px;
  }
  .payment-detail h2, .payment-detail .h2 {
    font-size: 32px;
  }
  .profile-list li {
    width: 33.3333333333%;
  }
  .profile-title {
    font-size: 28px;
  }
  .profile-right, .profile-preword {
    display: block;
  }
  .profile-right li {
    margin-right: 0;
  }
  .btn-list {
    margin-bottom: 7px;
  }
  .payment-list label {
    font-size: 15px;
  }
  .click-btn {
    font-size: 16px;
    padding: 10px 35px;
  }
  .left-content h4, .left-content .h4 {
    font-size: 12px;
  }
  #accordion .card-header {
    font-size: 17px;
  }
  .footer-user {
    top: 92px;
  }
  .sidebar-overlay.opened {
    display: block;
  }
  .price-features {
    margin-top: 0;
  }
  .price-features .header-price h3, .price-features .header-price .h3 {
    font-size: 28px;
  }
  .column-5 {
    width: 33.3333333333%;
    margin-bottom: 20px;
  }
  .header.min-header {
    background-color: #fff4f0;
  }
  .main-nav {
    padding: 0;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .main-nav ul {
    background-color: #ffe7e1;
    display: none;
    list-style: none;
    margin: 0;
    padding-left: 0;
  }
  .main-nav > li {
    border-bottom: 1px solid #fddbd2;
    margin-left: 0;
    margin-right: 0;
  }
  .main-nav > li > a {
    line-height: 1.5;
    padding: 15px 20px !important;
    color: #263238 !important;
    font-size: 14px;
    font-weight: 500;
    background: transparent !important;
    border-radius: 0 !important;
  }
  .main-nav > li > a > i {
    float: right;
    margin-top: 5px;
  }
  .main-nav > li .submenu li a {
    border-top: 0;
    color: #263238;
    padding: 10px 15px 10px 35px;
    font-size: 14px;
  }
  .main-nav > li .submenu ul li a {
    padding: 10px 15px 10px 45px;
  }
  .main-nav > li .submenu > li.has-submenu > a::after {
    content: "\f078";
  }
  .main-nav > li.active > a:before {
    display: none;
  }
  .main-nav li + li {
    margin-left: 0;
  }
  .main-nav li a:hover {
    background: transparent;
    border-radius: 0;
    line-height: 1.5;
    margin: 0;
  }
  .main-nav .has-submenu.active .submenu li.active > a {
    color: #86E53E;
  }
  .main-nav .log-select {
    display: none;
  }
  .main-nav .has-submenu.active > a, .main-nav > li.active > a {
    color: #86E53E !important;
  }
  .login-left {
    display: none;
  }
  .searchbar {
    display: none;
  }
  .main-menu-wrapper {
    order: 3;
    width: 260px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1060;
    transform: translateX(-260px);
    transition: all 0.4s;
    -moz-transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    background-color: #fff;
  }
  .menu-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .navbar-header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  #mobile_btn {
    display: inline-block;
    line-height: 0;
  }
  .services, .projects, .subscribe, .developer, .review, .news {
    padding: 60px 0;
  }
  .section-text {
    margin-bottom: 40px;
  }
  .footer-top {
    padding: 60px 0 0 0;
  }
  .footer-widget {
    margin-bottom: 25px;
  }
  .transaction-table .head-title {
    font-size: 24px;
  }
  .change-btn, .plan-btn, .pay-btn {
    font-size: 16px;
  }
  .table-borderless.table tbody tr td {
    font-size: 18px;
  }
  .stone-btn {
    font-size: 15px;
    padding: 15px 25px;
  }
  .position-title h3, .position-title .h3 {
    margin-bottom: 10px !important;
  }
  .position-title .sort-by {
    float: left;
    padding: 0 15px 0 0;
  }
  .stats-content {
    display: block;
  }
  .stats-detail {
    width: 100%;
    margin-bottom: 10px;
  }
  .chat-cont-left, .chat-cont-right {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    transition: left 0.3s ease-in-out 0s, right 0.3s ease-in-out 0s;
    width: 100%;
  }
  .chat-cont-left {
    border-right: 0;
  }
  .chat-cont-right {
    position: absolute;
    right: -100%;
    top: 0;
    opacity: 0;
    visibility: hidden;
  }
  .chat-cont-right .chat-header {
    justify-content: start;
    -webkit-justify-content: start;
    -ms-flex-pack: start;
  }
  .chat-cont-right .chat-header .back-user-list {
    display: block;
  }
  .chat-cont-right .chat-header .chat-options {
    margin-left: auto;
  }
  .chat-window.chat-slide .chat-cont-left {
    left: -106%;
  }
  .chat-window.chat-slide .chat-cont-right {
    right: 0;
    opacity: 1;
    visibility: visible;
  }
  .chat-footer .avatar {
    display: none;
  }
  .plan-details h4, .plan-details .h4 {
    font-size: 24px;
  }
  .pro-head h2, .pro-head .h2 {
    font-size: 18px;
  }
  .earn-info p {
    font-size: 15px;
  }
  .breadcrumb-bar .breadcrumb-title {
    font-size: 28px;
  }
  .projects-details {
    display: block;
  }
  .project-info {
    width: 100%;
  }
  .content-divider {
    display: none;
  }
  .content-divider-1 {
    display: none;
  }
  .projects-amount {
    width: 50%;
    text-align: left;
  }
  .projects-action {
    width: 50%;
    padding-left: 0;
    text-align: right !important;
  }
  .project-hire-info {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  .bread-crumb-bar {
    padding-top: 64px;
  }
  .projects-btn {
    margin: 0 0 10px auto;
    margin: 0 0 10px auto;
    width: 120px;
  }
  .projects-btn.project {
    margin: 0 0 10px auto;
  }
  .prj-proposal-count {
    top: 0;
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .prj-proposal-count.hired {
    top: 0%;
    -ms-transform: translateY(0%);
    transform: translateY(0%);
  }
  .projects-card {
    margin-bottom: 15px;
  }
  .projects-list h2, .projects-list .h2 {
    font-size: 24px;
  }
  .banner-content .sub-btn, .banner-content .form-control, .banner-content .select2-container .select2-selection--single .select2-selection__rendered {
    font-size: 15px;
  }
  .bookmark {
    margin-bottom: 0;
  }
  .chat-page .content {
    padding-top: 80px;
  }
  .dashboard-page .content {
    padding-top: 120px;
  }
  .dashboard-page .content-page {
    padding-top: 60px;
  }
  .dashboard-page .content > .container-fluid, .dashboard-page .content > .container-sm, .dashboard-page .content > .container-md, .dashboard-page .content > .container-lg, .dashboard-page .content > .container-xl, .dashboard-page .content > .container-xxl,
  .header-nav {
    padding-left: 15px;
    padding-right: 15px;
  }
  .header-title {
    font-size: 24px;
  }
  .table-responsive {
    white-space: nowrap;
  }
  .project-company .projects-action .projects-btn {
    margin: 0 0 10px auto;
    margin: 0 0 10px auto;
  }
  .img-profile img {
    width: 100px;
    height: 100px;
  }
  .developer-box h2, .developer-box .h2 {
    font-size: 30px;
    margin-bottom: 30px;
  }
  .developer-box h2 i, .developer-box .h2 i {
    font-size: 28px;
  }
  .proposal-value h4, .proposal-value .h4 {
    font-size: 28px;
  }
  .favourites-btn.btn-primary,
  .proposal-btn.btn-primary {
    font-size: 16px;
    min-height: 50px;
    min-width: 160px;
  }
  .feed-back-detail li {
    font-size: 13px;
  }
  .experiance-list {
    margin-bottom: 25px;
  }
  .technical-skill .badge-skills {
    margin-bottom: 10px;
  }
  .board-screen .progressbar {
    max-width: 100%;
    margin-left: 0px;
  }
  .progressbar li h5, .progressbar li .h5,
  .progress-info h5,
  .progress-info .h5 {
    font-size: 14px;
  }
  .progress-info h5, .progress-info .h5 {
    margin-bottom: 0;
  }
  .progressbar {
    justify-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .multi-step {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    font-size: 12px;
  }
  .select-account {
    padding: 60px 0px;
  }
  .select-account .prev_btn {
    min-width: 135px;
    padding: 8px 15px;
    font-size: 16px;
    margin-right: 10px;
  }
  .select-account .btn-primary {
    min-width: 135px;
    padding: 7px 15px;
    font-size: 16px;
  }
  .select-account .personal-info .form-control {
    font-size: 13px;
  }
  .select-box {
    margin-bottom: 80px;
  }
  .multistep-form label {
    font-size: 15px !important;
    font-size: 14px !important;
  }
  .btn-prev {
    margin-right: 0;
  }
  .new-addd .add-new,
  .new-addd .remove_row {
    font-size: 12px;
  }
  .personal-info input::placeholder {
    font-size: 13px;
  }
  .wallet-group {
    margin-bottom: 15px;
  }
  .onboard-head {
    padding: 0px 15px;
  }
  .onboard-head p {
    font-size: 15px;
  }
  .select-level {
    background-image: url(../../assets/img/icon/icon-19.png);
    background-position: 95%;
  }
  .wallet-rupee .form-control {
    border-color: #ffe6e1;
    height: 50px;
  }
  .wallet-rupee .dol-btn {
    width: 50px;
    height: 50px;
  }
  .work:after {
    display: none;
  }
  .bg1:before {
    width: 20px;
    top: 20px;
    left: 20px;
    height: 20px;
  }
  .want-works .work-content {
    width: 100%;
    padding: 40px;
  }
  .review-img {
    flex-shrink: 0;
    margin-right: 0px;
  }
  .owl-carousel .owl-item .review-blog .review-img img {
    width: 60px;
  }
  .owl-carousel .owl-item .project-img img {
    width: 100%;
  }
  .owl-carousel .owl-item .hour-dollr img {
    width: inherit;
  }
  .register-job-blk {
    display: block;
  }
  .job-content-blk {
    margin-bottom: 15px;
  }
  .job-content-blk h1, .job-content-blk .h1 {
    font-size: 28px;
  }
  .home-two {
    min-height: 490px;
  }
  .home-two .banner-content h1, .home-two .banner-content .h1 {
    margin-bottom: 25px;
  }
  .home-count {
    margin-top: 35px;
  }
  .home-count ul li {
    font-size: 20px;
  }
  .home-count ul li + li {
    margin-left: 10px;
    padding-left: 10px;
  }
  .home-count ul li span {
    font-size: 15px;
  }
  .home-count ul li .list-count {
    font-size: 15px;
  }
  .skill-head .nav-pills .nav-link {
    font-size: 20px;
  }
  .btn-cart {
    font-size: 14px;
  }
  .app-version-blk h2, .app-version-blk .h2 {
    font-size: 22px;
  }
  .app-version-blk p {
    font-size: 15px;
  }
  .entry-meta li {
    margin-right: 0;
    font-size: 13px;
  }
  .grid-blog .blog-title {
    font-size: 18px;
  }
  .great-about {
    padding: 80px 0px 338px;
  }
  .header-two .main-nav > li:last-child {
    display: none;
  }
  .owl-carousel.trend-slider .owl-nav,
  .owl-carousel.trend-slider .owl-dots {
    min-width: 110px;
  }
  .dot-slider .slick-list,
  .dot-slider.slick-vertical .slick-slide {
    height: 334px !important;
  }
  .home-four {
    margin-top: 60px;
  }
  .home-four .banner-content h5, .home-four .banner-content .h5 {
    font-size: 16px;
  }
  .home-four .banner-content h1, .home-four .banner-content .h1 {
    font-size: 30px;
  }
  .market-place-group h2, .market-place-group .h2 {
    font-size: 24px;
  }
  .view-locations .btn-primary {
    min-width: 175px;
  }
  .popular-slider.developers-slider .owl-nav {
    right: 25%;
  }
  .platform-location .market-place-group {
    padding: 30px 0px;
  }
  .market-place-btn.platform-btn .btn-primary {
    min-width: 165px;
  }
  .next-great-job .job-next-blk h2, .next-great-job .job-next-blk .h2 {
    color: #2E2E2E;
    font-size: 24px;
    margin-bottom: 20px;
  }
  .job-next-blk p {
    font-size: 15px;
  }
  .banner-blk-five .banner-content .form-control {
    height: 54px;
  }
  .header-navbar-rht .login-btn {
    padding: 6px 25px;
    font-size: 14px;
  }
  .header-five .header-navbar-rht li .reg-btn {
    padding: 6px 15px;
    min-width: 160px;
  }
  .section-header-five:before {
    left: 48%;
  }
  .need-job-group {
    flex-wrap: wrap;
  }
  .need-job-group .about-it-item {
    width: 50%;
    border: 0;
  }
  .recent-pro-img {
    margin-bottom: 20px;
  }
  .recent-pro-img img {
    width: 100%;
  }
  .talent-foot-list ul {
    justify-content: space-evenly;
    -webkit-justify-content: space-evenly;
    -ms-flex-pack: space-evenly;
  }
  .img-reviews img {
    max-width: 130px;
  }
  .review-quote img {
    max-width: 40px;
  }
  .review-five .slick-prev,
  .review-five .slick-next {
    width: 35px;
    height: 35px;
  }
  .testimonial-two-head span {
    margin-bottom: 10px;
  }
  .review-five .slick-prev:before,
  .review-five .slick-next:before {
    font-size: 14px;
  }
  .hire-company-list ul li {
    width: 150px;
    min-height: 70px;
  }
  .hire-company-list ul li img {
    max-width: 80px;
  }
}
@media only screen and (max-width: 767.98px) {
  body {
    font-size: 14px;
  }
  h1, .h1 {
    font-size: 28px;
  }
  h2, .h2 {
    font-size: 26px;
  }
  h3, .h3 {
    font-size: 20px;
  }
  h4, .h4 {
    font-size: 18px;
  }
  h5, .h5 {
    font-size: 15px;
  }
  h6, .h6 {
    font-size: 16px;
  }
  .banner-content {
    padding: 80px 0;
  }
  .banner-content h1, .banner-content .h1 {
    font-size: 23px;
    line-height: 29px;
  }
  .banner-content p {
    font-size: 18px;
    line-height: 20px;
  }
  .banner-content.discount-box {
    padding: 0;
  }
  .banner-img {
    display: none;
  }
  .courses-btn, .get-btn {
    font-size: 14px;
    padding: 12px 24px;
  }
  .work-content {
    width: 90%;
  }
  .work-content .long-arrow {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
  }
  .work-content h2, .work-content .h2 {
    font-size: 25px;
  }
  .work-content p {
    font-size: 22px;
  }
  .bg1, .bg2 {
    background-size: 150px;
  }
  .services {
    padding: 60px 0;
  }
  .projects {
    padding: 60px 0;
  }
  .all-btn {
    padding: 12px 26px;
    font-size: 14px;
  }
  .news-blog {
    margin-bottom: 20px;
  }
  .client-img {
    margin-bottom: 20px;
  }
  .social-icon ul li a {
    font-size: 12px;
    margin-right: 1px;
    line-height: 18px;
  }
  .about-content h2, .about-content .h2 {
    font-size: 24px;
  }
  .about {
    padding: 40px 0;
  }
  .about .about-img {
    text-align: center;
  }
  .agency-content {
    width: 100%;
    padding: 33px;
  }
  .job-counter .counter {
    margin-bottom: 20px;
  }
  .counter h2, .counter .h2 {
    font-size: 26px;
  }
  .counter h4, .counter .h4 {
    font-size: 16px;
  }
  .expert {
    padding: 40px 0;
  }
  .profile-list .proj-title {
    font-size: 14px;
  }
  .profile-list li {
    width: 33.3333333333%;
    font-size: 15px;
    padding: 15px 0px;
  }
  .provider-widget {
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }
  .pro-info-right {
    margin-left: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .pro-info-left {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .profile-box .pro-info-right {
    margin-left: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .camera-bg {
    bottom: 0;
  }
  .bottom-text {
    position: relative;
  }
  .profile-title {
    font-size: 18px;
  }
  .profile-preword, .profile-right {
    text-align: center;
    display: block;
  }
  .profile-inf {
    text-align: center;
  }
  .provider-img {
    margin: 0 auto 20px;
  }
  .provider-action {
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
  }
  .submit-section .submit-btn {
    padding: 12px 22px;
    font-size: 13px;
  }
  .management-widget .profile-btn {
    padding: 12px 0;
    font-size: 14px;
  }
  .pro-title, .billing .card-header p, .payment-list label, .transaction h5, .transaction .h5 {
    font-size: 16px;
  }
  .bookmark-btn, .share-icon, .click-btn {
    font-size: 17px;
  }
  .payment-detail {
    padding: 20px;
  }
  .payment-detail h2, .payment-detail .h2 {
    font-size: 25px;
  }
  .payment-detail p {
    font-size: 15px;
  }
  #accordion .card-header {
    font-size: 16px;
  }
  .footer-top {
    padding: 50px 0 40px 0;
  }
  .footer-user {
    top: 0;
  }
  .job-category p {
    font-size: 15px;
  }
  .login-header h3, .login-header .h3 {
    font-size: 21px;
  }
  .login-btn {
    font-size: 17px;
  }
  .settings-widget {
    margin-bottom: 25px;
  }
  .earn-info {
    margin-bottom: 15px;
  }
  .column-5 {
    width: 50%;
  }
  .news-content .entry-meta li.name img {
    width: 30px;
    height: 30px;
  }
  .nav-tabs {
    border-bottom: 0;
    position: relative;
    background-color: #fff;
    padding: 5px 0;
    border: 1px solid #ddd;
    border-radius: 3px;
  }
  .nav-tabs.nav-justified {
    border-bottom: 1px solid #ddd;
  }
  .nav-tabs .nav-item {
    margin-bottom: 0;
  }
  .user-tabs .nav-tabs.nav-tabs-bottom > li > a.active, .user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:hover, .user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:focus {
    border-left-width: 2px;
    color: #ff508b;
  }
  .nav-tabs.nav-tabs-bottom > li > a.active, .nav-tabs.nav-tabs-bottom > li > a.active:hover, .nav-tabs.nav-tabs-bottom > li > a.active:focus {
    background-color: #f5f5f5;
  }
  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, .nav-tabs > li > a.active, .nav-tabs > li > a.active:hover, .nav-tabs > li > a.active:focus {
    border-color: transparent transparent transparent #ff508b !important;
    border-left-width: 2px;
  }
  .user-tabs .nav-tabs .nav-item, .provider-tabs .nav-tabs .nav-item {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  .copyright-text {
    padding-bottom: 5px;
  }
  .right-text, .center-text {
    text-align: left;
  }
  .left-content h4, .left-content .h4 {
    font-size: 13px;
  }
  .transaction-table .head-title {
    font-size: 22px;
  }
  .transaction-table .sortby-fliter {
    width: 110px;
  }
  .plan-top i {
    font-size: 30px;
  }
  .change-btn, .plan-btn {
    font-size: 18px;
  }
  .price-features .header-price h3, .price-features .header-price .h3 {
    font-size: 26px;
  }
  .price-features li {
    font-size: 15px;
  }
  .stone-btn {
    font-size: 14px;
    padding: 12px 14px;
  }
  .transaction-table .sort-title, .sort-title {
    font-size: 14px;
  }
  .wallet, .payment-process, .wallet-detail {
    margin-bottom: 0;
  }
  .profit-title {
    padding: 15px 20px;
  }
  .profit-title h3, .profit-title .h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .stats-detail {
    width: 50%;
  }
  .stats-content {
    display: flex;
  }
  .table-borderless.table tbody tr td, .portfolio-detail .pro-name {
    font-size: 16px;
  }
  .finance-statement {
    margin-bottom: 25px;
    height: auto;
  }
  .profit-right .sort-by {
    margin-top: 10px;
  }
  .user-tabs ul li + li {
    margin-left: 0;
  }
  .projects-list h2, .projects-list .h2, .page-title h3, .page-title .h3, .earn-feature .price, .freelance-title h3, .freelance-title .h3 {
    font-size: 22px;
  }
  .prolist-details p, .prolist-details h5, .prolist-details .h5 {
    font-size: 15px;
  }
  .project-table h3, .project-table .h3 {
    font-size: 20px;
  }
  .yr-amt, .freelance-title p {
    font-size: 15px;
  }
  .plan-details h3, .plan-details .h3, .member-detail h3, .member-detail .h3 {
    font-size: 25px;
  }
  .package-feature ul li {
    font-size: 14px;
  }
  .package-detail .package-price, .member-detail h4, .member-detail .h4 {
    font-size: 24px;
  }
  .port-title h3, .port-title .h3, .modal-title {
    font-size: 18px;
  }
  .modal label {
    font-size: 15px;
  }
  .header-navbar-rht li {
    margin-right: 20px;
  }
  .inner-banner {
    padding: 30px 0;
  }
  .transaction-shortby .sort-show {
    padding: 5px;
  }
  .projects-action .projects-btn ~ .projects-btn {
    margin: 10px 0 0;
  }
  .header-title {
    font-size: 20px;
  }
  .subscribe h3, .subscribe .h3 {
    font-size: 28px;
  }
  #mobile_btn {
    line-height: 0;
  }
  .discount-box .btn-primary {
    margin: 0;
  }
  .about-list ul {
    display: block;
  }
  .about-list ul li {
    padding: 0;
    border: 0;
    margin-bottom: 5px;
  }
  .profile-main h2, .profile-main .h2 {
    font-size: 20px;
  }
  .abouts-view ul li .active-about:before {
    display: none;
  }
  .profile-baner {
    padding: 40px 0px;
  }
  .provider-tabs.abouts-view .nav-tabs.nav-tabs-solid > li > a p {
    font-size: 15px;
  }
  .social-link-profile {
    flex-wrap: wrap;
    padding: 20px;
    padding-top: 10px;
    margin: 0;
  }
  .social-link-profile li {
    margin-bottom: 15px;
  }
  .social-link-profile li a i {
    width: 40px;
    height: 40px;
  }
  .develop-social-link.widget-box {
    padding-bottom: 5px;
  }
  .location-widget .pro-title {
    margin-bottom: 25px;
  }
  .abouts-detail .pro-content {
    padding: 5px 0 0;
  }
  .company-profile .pro-content {
    padding: 10px 0 0;
  }
  .company-profile .widget-box .latest-posts li {
    padding: 13px 20px 13px;
  }
  .project-company .projects-action .projects-btn {
    margin-bottom: 10px;
    margin: 10px 0 10px;
  }
  .company-gallery .pro-image img {
    width: 100%;
  }
  .company-post .pro-title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .company-post.widget-box {
    padding: 20px;
  }
  .tab-reviews {
    flex-wrap: wrap;
  }
  .tab-reviews .rating {
    margin-top: 15px;
  }
  .post-btn .more-btn {
    font-size: 20px;
    padding: 6px 15px;
    min-width: 160px;
  }
  .profile-head {
    padding: 20px 20px 6px 20px;
  }
  .map-location {
    padding: 0px 20px;
  }
  .contact-btn {
    margin-top: 0px;
    padding: 20px;
  }
  .contact-btn .btn-primary {
    padding: 8px 15px;
    font-size: 18px;
  }
  .company-review .review-content p {
    font-size: 14px;
  }
  .img-profile img {
    width: 60px;
    height: 60px;
    top: 16px;
    right: 25px;
    border: 2px solid #E0E0E0;
  }
  .developer-box h2, .developer-box .h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .developer-box h2 i, .developer-box .h2 i {
    font-size: 22px;
    margin-left: 0px;
  }
  .develope-list-rate {
    flex-wrap: wrap;
    display: block;
  }
  .develope-list-rate li {
    padding-left: 0;
    margin-bottom: 10px;
    border: 0;
  }
  .list-book-mark .verified {
    width: 12px;
    height: 12px;
    right: 0;
  }
  .list-book-mark .verified i {
    font-size: 12px;
  }
  .project-rate {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .project-details-view .pro-post-head h6, .project-details-view .pro-post-head .h6 {
    font-size: 16px;
  }
  .project-details-view .job-type,
  .requirement-img {
    margin-bottom: 15px;
  }
  .wallet-rupee .modal-body {
    padding: 20px;
  }
  .wallet-rupee .dol-btn {
    width: 58px;
    height: 48px;
  }
  .wallet-rupee .form-control {
    height: 48px;
  }
  .wallet-rupee .badge-skills {
    padding: 8px 28px;
  }
  .wallet-rupee .custom_radio .checkmark {
    height: 20px;
    width: 20px;
  }
  .wallet-rupee .custom_radio .checkmark:after {
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
  }
  .proposal-value {
    font-size: 14px;
    margin-bottom: 0px;
  }
  .proposal-value h4, .proposal-value .h4 {
    font-size: 18px;
  }
  .proposal-value span {
    font-size: 12px;
  }
  .feed-back-detail {
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .feed-back-detail li {
    margin-bottom: 10px;
    font-size: 13px;
  }
  .watch-list li {
    font-size: 12px;
  }
  .favourites-btn.btn-primary {
    min-width: 80px;
    margin: 0px 10px;
  }
  .proposal-box {
    flex-wrap: wrap;
  }
  .proposal-btn.btn-primary {
    min-width: 120px;
  }
  .favourites-btn.btn-primary,
  .proposal-btn.btn-primary {
    min-height: 38px;
    font-size: 12px;
  }
  .technical-skill .badge-skills {
    margin-bottom: 10px;
  }
  .board-screen .progressbar {
    max-width: 100%;
    margin: 10px 0px;
  }
  .board-screen .multistep-progress {
    height: auto;
    text-align: center;
  }
  .multi-step {
    width: 30px;
    height: 30px;
  }
  .progressbar {
    justify-content: space-evenly;
  }
  .progressbar li {
    margin-bottom: 10px;
  }
  .progressbar li h5, .progressbar li .h5 {
    font-size: 14px;
  }
  .board-logo {
    margin: auto;
  }
  .progress-info {
    width: 100%;
  }
  .employee-level {
    width: 140px;
    height: 140px;
  }
  .employee-level img {
    margin-bottom: 6px;
  }
  .onboard-head {
    padding: 0px 15px;
  }
  .onboard-head a {
    width: 140px;
    height: 140px;
    padding: 15px;
  }
  .onboard-head h2, .onboard-head .h2 {
    font-size: 22px;
  }
  .onboard-head p {
    font-size: 14px;
  }
  .free-lance-img {
    margin-right: 15px;
  }
  .select-account {
    padding: 40px 0px;
  }
  .select-account .btn-primary {
    min-width: 110px;
    padding: 7px 15px;
    font-size: 15px;
  }
  .select-account .prev_btn {
    min-width: 110px;
    padding: 8px 15px;
    font-size: 15px;
    margin-right: 10px;
  }
  .select-account.select-btn {
    padding: 40px 0px 80px;
  }
  .select-account .personal-info .form-control {
    font-size: 12px;
  }
  .other-info-list ul li {
    width: 30px;
    height: 30px;
    background: #86E53E;
    border-radius: 50%;
    font-size: 10px;
    margin-right: 8px;
  }
  .first-progress, .second-progress {
    display: block;
    height: 100%;
  }
  .select-box {
    margin-bottom: 60px;
  }
  .multistep-form label {
    font-size: 14px !important;
  }
  .multistep-form .form-control {
    min-height: 40px;
    height: 40px;
  }
  .new-addd {
    margin-bottom: 10px;
  }
  .personal-info {
    margin-bottom: 40px;
    padding: 15px;
  }
  .personal-info input::placeholder {
    font-size: 12px;
  }
  .back-home .btn-primary {
    background: #86E53E;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    min-width: 160px;
  }
  .back-home p {
    font-size: 14px;
  }
  .back-home img {
    max-width: 230px;
  }
  .new-addd .add-new,
  .select-level {
    font-size: 12px;
  }
  .bread-crumb-bar {
    padding-top: 60px;
  }
  .bread-crumb-bar .breadcrumb-list .page-breadcrumb ol li,
  .bread-crumb-bar .breadcrumb-list .page-breadcrumb .breadcrumb-item + .breadcrumb-item:before {
    font-size: 12px;
  }
  .develop-list-select .list-grid {
    margin-left: 0;
  }
  .freelance-view {
    margin-bottom: 15px;
  }
  .list-develop-group .about-author,
  .list-develop-group .about-author .about-list-author {
    display: block !important;
  }
  .about-list-author .about-author-img {
    position: relative;
  }
  .about-list-author .author-details {
    margin-left: 0;
  }
  .book-mark .about-list-author .about-author-img {
    margin-bottom: 10px;
  }
  .develop-list-pro {
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 10px;
  }
  .develop-list-pro .btn-cart {
    min-width: auto;
  }
  .develop-list-pro .freelancers-price {
    margin: 0px 10px;
    font-size: 18px;
  }
  .wallet-group {
    margin-bottom: 15px;
  }
  .total-depit {
    margin-bottom: 0;
  }
  .balance-total h2, .balance-total .h2 {
    font-size: 18px;
  }
  .account-onborad.complte-board {
    padding: 10px;
  }
  .select-level {
    background-image: url(../../assets/img/icon/icon-19.png);
    background-position: 95%;
  }
  .bg1:before {
    content: "";
    position: absolute;
    width: 20px;
    top: 15px;
    left: 9px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
  }
  .want-works .work-content {
    width: 100%;
    padding: 40px;
  }
  .developers-slider .owl-nav {
    margin-bottom: 10px;
  }
  .register-job-blk {
    display: block;
  }
  .job-content-blk {
    margin-bottom: 15px;
  }
  .job-content-blk h1, .job-content-blk .h1 {
    font-size: 20px;
  }
  .job-content-blk p {
    font-size: 15px;
  }
  .footer .input-group {
    flex-wrap: inherit;
  }
  .footer .banner-content {
    padding: 0;
  }
  .footer .banner-content .sub-btn {
    flex: inherit;
    width: auto;
    line-height: 18px;
    padding: 6px 30px;
    height: 28px;
    line-height: 0;
  }
  .footer .banner-content .form-control {
    height: 35px;
    min-height: 35px;
  }
  .footer.footer-four .banner-content .sub-btn {
    min-width: 135px;
    font-size: 12px;
    padding: 5px 15px;
  }
  .footer.footer-four .footer-menu ul li a {
    font-size: 14px;
  }
  .footer-bottom-nav {
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
  }
  .copyright-text,
  .footer-bottom-logo {
    text-align: center;
  }
  .app-version-blk h2, .app-version-blk .h2 {
    font-size: 20px;
  }
  .app-version-blk p {
    font-size: 14px;
    margin-bottom: 20px;
  }
  .testimonial-section .owl-nav {
    margin-bottom: 10px;
  }
  .home-count ul li {
    margin-right: 10px;
    font-size: 20px;
  }
  .home-count ul li + li {
    margin-left: 0;
    padding-left: 0px;
    border: 0;
  }
  .home-count ul li span {
    font-size: 15px;
  }
  .platform:before {
    display: none;
  }
  .feature-project-list li {
    font-weight: 500;
    font-size: 14px;
  }
  .blog-slider.owl-carousel .owl-nav button.owl-prev,
  .blog-slider.owl-carousel .owl-nav button.owl-next {
    width: 30px;
    height: 30px;
    font-size: 11px;
    margin: 0;
  }
  .blog-slider.owl-carousel .owl-nav button.owl-prev {
    left: -10px;
  }
  .blog-slider.owl-carousel .owl-nav button.owl-next {
    top: 40%;
    right: -10px;
  }
  .about-position {
    position: relative;
    bottom: 0;
    padding: 80px 0px 55px;
  }
  .great-about {
    padding: 80px 0px 55px;
  }
  .about-it-blk {
    margin-bottom: 20px;
  }
  .home-three {
    background-image: url(../img/slider/slider-01.jpg);
  }
  .home-three .banner-kofe {
    position: relative;
    transform: unset;
    top: 0;
  }
  .home-three .banner-content {
    padding: 80px 0;
  }
  .home-three .banner-content h1, .home-three .banner-content .h1 {
    font-size: 26px;
  }
  .home-three .slick-prev, .home-three .slick-next,
  .dot-slider .slick-dots,
  .dot-slider.slick-dotted.slick-slider {
    display: none !important;
  }
  .section-header p {
    font-size: 14px;
  }
  .section-locate {
    flex-wrap: wrap;
  }
  .section-locate p {
    margin-bottom: 10px;
  }
  .different-categories {
    padding: 60px 0px 35px;
  }
  .best-marketplace,
  .trend-projects,
  .review-people,
  .top-skill {
    padding: 60px 0px;
  }
  .market-list-out li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .market-place-btn .btn-primary,
  .market-place-btn .market-developer {
    min-width: 130px;
    font-size: 14px;
  }
  .feature-count-head .header-title {
    text-align: center;
    font-size: 20px;
  }
  .owl-carousel.trend-slider .owl-nav {
    top: -36px;
    min-width: 160px;
  }
  .owl-carousel.trend-slider .owl-dots {
    top: -33px;
    min-width: 160px;
  }
  .review-love-list {
    display: block;
  }
  .review-profile-blk {
    margin-bottom: 10px;
  }
  .next-job-btn .btn-primary {
    font-size: 14px;
    min-width: 140px;
  }
  .footer-social-group .footer-bottom-nav,
  .footer-social-group .social-icon {
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
  }
  .market-place-group {
    margin-bottom: 20px;
    padding-bottom: 0px;
  }
  .market-place-group h2, .market-place-group .h2 {
    font-size: 22px;
    margin-bottom: 15px;
  }
  .market-place-group p {
    font-size: 14px;
  }
  .job-next-blk h2, .job-next-blk .h2 {
    font-size: 24px;
  }
  .fullstack-blk,
  .coplete-job {
    display: none;
  }
  .home-four .banner-content h1, .home-four .banner-content .h1 {
    font-size: 26px;
  }
  .popular-slider.developers-slider .owl-nav {
    right: 0;
    margin-bottom: 34px;
  }
  .popular-slider.developers-slider.owl-carousel .owl-nav button.owl-next,
  .popular-slider.developers-slider.owl-carousel .owl-nav button.owl-prev {
    width: 35px;
    height: 35px;
  }
  .popular-list-box {
    flex-wrap: wrap;
  }
  .popular-list-box ul {
    margin-bottom: 10px;
  }
  .popular-foot,
  .popular-list-box h4,
  .popular-list-box .h4 {
    font-size: 14px;
  }
  .head-popular h4, .head-popular .h4 {
    font-size: 16px;
  }
  .market-place-btn.platform-btn .btn-primary {
    min-width: 140px;
    margin-bottom: 15px;
  }
  .market-place-btn.bidding-btn .btn-primary {
    min-width: 126px;
  }
  .market-place-btn .btn-primary {
    padding: 8px 0px;
    font-size: 14px;
  }
  .market-place-btn.company-register-btn .market-project {
    min-width: 130px;
  }
  .market-place-btn.company-register-btn .project-post {
    min-width: 155px;
  }
  .article-blog-content h4, .article-blog-content .h4,
  .article-blog-content p {
    font-size: 15px;
  }
  .article-blog-content span {
    font-size: 14px;
  }
  .footer-four .free-customer h6, .footer-four .free-customer .h6 {
    font-size: 15px;
  }
  .register-profesion {
    position: relative;
    left: 0;
  }
  .markrt-place-img {
    margin-bottom: 20px;
  }
  .trust-slider.developers-slider.owl-carousel .owl-nav button.owl-next,
  .trust-slider.developers-slider.owl-carousel .owl-nav button.owl-prev {
    width: 38px;
    height: 38px;
  }
  .trust-slider.developers-slider .owl-nav {
    bottom: 58%;
    right: 0;
  }
  .need-job-group .about-it-item {
    width: 100%;
  }
  .demand-professional h2, .demand-professional .h2 {
    font-size: 20px;
  }
  .join-market-world {
    padding: 0px 15px 20px;
    width: 100%;
  }
  .join-market-world h2, .join-market-world .h2 {
    font-size: 20px;
  }
  .hire-company-list ul li {
    width: 145px;
  }
  .img-reviews {
    max-width: 170px;
    margin: auto;
  }
  .testimonial-two-head {
    margin-top: 20px;
  }
  .testimonial-two-head h3, .testimonial-two-head .h3 {
    font-size: 18px;
  }
  .review-five .slider-nav {
    width: 200px;
    margin: auto;
  }
  .review-five .testimonial-thumb img {
    width: 40px;
  }
  .review-five .slick-prev {
    left: 0;
  }
  .review-five .slick-next {
    right: 75%;
  }
  .blog-trick-five.blog-slider.owl-carousel .owl-nav button.owl-prev {
    left: 50%;
  }
  .great-find-job.job-next-blk h2, .great-find-job.job-next-blk .h2 {
    font-size: 20px;
  }
  .opportunity-img {
    display: none;
  }
}
.settings-widget {
  position: sticky;
  top: 100px;
}

@media only screen and (max-width: 575.98px) {
  h1, .h1 {
    font-size: 26px;
  }
  h2, .h2 {
    font-size: 22px;
  }
  h3, .h3 {
    font-size: 18px;
  }
  h4, .h4 {
    font-size: 16px;
  }
  h5, .h5 {
    font-size: 15px;
  }
  h6, .h6 {
    font-size: 12px;
  }
  .section-header {
    margin-bottom: 35px;
  }
  .section-header p {
    font-size: 14px;
    line-height: 24px;
  }
  .section-header h5, .section-header .h5 {
    font-size: 18px;
  }
  .developer .section-header, .review .section-header {
    margin-bottom: 55px;
  }
  .banner-content {
    padding: 50px 0;
  }
  .banner-content h1, .banner-content .h1 {
    font-size: 26px;
    margin: 10px 0;
  }
  .banner-content p {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  .banner-content .select2-container {
    border: 0;
  }
  .banner-content .select2-container .select2-selection--single .select2-selection__rendered {
    font-size: 15px;
  }
  .banner-content .form-inner {
    border-radius: 30px;
    padding: 8px;
  }
  .banner-content .form-control {
    width: 100%;
    font-size: 15px;
  }
  .banner-content .sub-btn {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    height: 45px;
    font-size: 14px;
    width: 100%;
  }
  .banner-content .rating .checked, .banner-content h5, .banner-content .h5 {
    font-size: 12px;
  }
  .feature .feature-content h3, .feature .feature-content .h3 {
    font-size: 22px;
  }
  .feature-item {
    margin-bottom: 25px;
  }
  .work-content .long-arrow {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 19px;
  }
  .work-content h2, .work-content .h2 {
    font-size: 22px;
  }
  .work-content p {
    font-size: 16px;
  }
  .services, .projects, .subscribe, .developer, .review, .news {
    padding: 40px 0;
  }
  .all-btn {
    padding: 10px 22px;
    font-size: 12px;
  }
  .services-item .content-part h3, .services-item .content-part .h3 {
    font-size: 16px;
  }
  .services-item .content-part h4, .services-item .content-part .h4 {
    font-size: 15px;
  }
  .services-item .img-part img {
    height: auto;
  }
  .courses-btn, .get-btn {
    font-size: 13px;
    padding: 10px 20px;
  }
  .btn-view {
    font-size: 16px;
  }
  .social-icon ul li a {
    font-size: 12px;
  }
  .breadcrumb-bar .breadcrumb-title {
    font-size: 25px;
  }
  .terms-text h4, .terms-text .h4 {
    font-size: 20px;
  }
  .review-blog {
    padding: 25px;
  }
  .review-blog .review-img img {
    width: 80px;
    height: 80px;
    margin-right: 9px;
  }
  .review-blog .review-content p {
    font-size: 15px;
  }
  .review-content h4, .review-content .h4 {
    font-size: 18px;
  }
  .header-nav {
    padding-left: 15px;
    padding-right: 15px;
  }
  .header-navbar-rht {
    display: none;
  }
  .main-nav li.login-link {
    display: block;
  }
  .navbar-header {
    width: 100%;
  }
  .navbar-brand.logo {
    width: 100%;
    text-align: center;
    margin-right: 0;
  }
  #mobile_btn {
    margin-right: 0;
  }
  .profile-detail .clg .icon {
    margin-right: 7px;
  }
  .review-bottom .review-img img {
    width: 50px;
    height: 50px;
    margin-right: 5px;
  }
  .review-bottom .review-info h3, .review-bottom .review-info .h3 {
    font-size: 16px;
  }
  .review-icon img {
    max-width: 42px;
  }
  .review-info h3, .review-info .h3 {
    font-size: 18px;
  }
  .review-info h5, .review-info .h5 {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .news .d-flex {
    display: block !important;
  }
  .news-content {
    margin-right: 0;
  }
  .news-content h2, .news-content .h2 {
    font-size: 16px;
  }
  .news-content p {
    font-size: 15px;
  }
  .client-img {
    margin-bottom: 30px;
  }
  .expert h2, .expert .h2 {
    font-size: 22px;
  }
  .blog-view .blog-title {
    font-size: 18px;
  }
  .blog-comments .comments-list li {
    padding: 10px 0 0 50px;
  }
  .blog-comments .comments-list li img.avatar {
    border-radius: 40px;
    height: 40px;
    width: 40px;
  }
  .profile-list li {
    width: 50%;
  }
  .profile-list .proj-title {
    font-size: 12px;
  }
  .pro-title, .billing .card-header p, .payment-list label, .transaction h5, .transaction .h5 {
    font-size: 15px;
  }
  .about-widget ul li p, .about-widget ul li h6, .about-widget ul li .h6, .latest-posts li a {
    font-size: 14px;
  }
  .left-content p, .pro-content .left-content p, .dev-right h6, .dev-right .h6 {
    font-size: 10px;
  }
  .management-widget .profile-btn {
    padding: 10px 0;
    font-size: 12px;
  }
  .follow-posts {
    width: 235px;
  }
  .follow-posts li {
    border-right: 0;
  }
  .client-list {
    padding: 10px 7px;
  }
  .client-list h6, .client-list .h6 {
    font-size: 20px;
  }
  .pro-content p {
    font-size: 14px;
  }
  .attach-detail p {
    font-size: 12px;
  }
  .about-author-img {
    width: 50px;
    height: 50px;
  }
  .author-details {
    margin-left: 65px;
  }
  .btn-list {
    margin-bottom: 7px;
  }
  .left .left-img::before {
    width: 15px;
    height: 15px;
    font-size: 9px;
  }
  .bookmark-btn, .share-icon, .click-btn {
    font-size: 15px;
  }
  .follow-btn {
    font-size: 15px;
  }
  .login-header h3, .login-header .h3 {
    font-size: 19px;
  }
  .login-btn {
    font-size: 16px;
  }
  .title h3, .title .h3 {
    font-size: 17px;
  }
  .next-btn, .cancel-btn {
    font-size: 13px;
    padding: 11px 50px;
  }
  .left-content h4, .left-content .h4 {
    font-size: 12px;
  }
  .submit-section .submit-btn {
    padding: 10px 20px;
    font-size: 12px;
  }
  .pro-widget-profile .dev-text .left.left-bid {
    margin-top: -20px;
  }
  .bid-btn {
    font-size: 12px;
  }
  .column-5 {
    width: 100%;
  }
  .footer-top {
    padding: 50px 0 7px 0;
    margin-top: 20px;
  }
  .position-title h3, .position-title .h3, .quick-title h3, .quick-title .h3 {
    margin-bottom: 10px !important;
  }
  .align-right {
    text-align: left;
  }
  .profit-title h3, .profit-title .h3, .stats-detail h3, .stats-detail .h3, .create-milestone h3, .create-milestone .h3, .pay-table .head-title {
    font-size: 22px;
  }
  .position-title .sort-by, .manage-tab .stone-btn {
    margin-bottom: 10px;
  }
  .manage-tab .btn-group {
    float: left;
  }
  .milestone-content .profile-group {
    width: auto;
  }
  .category-box .category-img {
    padding: 7px;
  }
  .chat-cont-left .nav-link {
    font-size: 10px;
    padding: 8px;
  }
  .plan-details a, .pro-info .list-details .slot {
    margin-bottom: 15px;
  }
  .banner-content .input-group-prepend, .drop-detail, .banner-content .input-group-append {
    width: 100%;
  }
  .proposals-content {
    display: block;
  }
  .proposal-img {
    margin-bottom: 15px;
  }
  .proposal-client {
    margin-bottom: 15px;
    margin-right: 0;
  }
  .title-info {
    font-size: 14px;
  }
  .client-price {
    font-size: 22px;
    font-size: 18px;
  }
  .project-hire-info {
    display: block;
  }
  .proposer-info.project {
    width: 100%;
  }
  .projects-action {
    text-align: left !important;
  }
  .projects-action.project {
    width: 100%;
    text-align: left !important;
  }
  .projects-btn {
    margin: 10px 0 0;
  }
  .projects-btn.project {
    margin: 10px 0;
  }
  .amnt-type {
    padding-top: 2px;
    font-size: 13px;
  }
  .proposer-detail li {
    padding-left: 0;
    border-right: 0;
  }
  .project-info h2, .project-info .h2 {
    font-size: 18px;
  }
  .card-body {
    padding: 0.85rem;
  }
  .completed-badge {
    margin-top: 10px;
  }
  .proposal-delete {
    padding-top: 10px;
  }
  .page-title h3, .page-title .h3 {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .card-top .dev-text {
    padding: 10px;
  }
}
@media only screen and (max-width: 479px) {
  .card-top .dev-text {
    padding: 5px;
  }
  .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name,
  .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
    max-width: 160px;
  }
  .section-features, .section-features, .section-specialities {
    background-color: #fff;
    padding: 30px 0;
  }
  .login-header h3, .login-header .h3 {
    text-align: center;
  }
  .login-header h3 a, .login-header .h3 a {
    color: #0de0fe;
    float: none;
    font-size: 15px;
    margin-top: 10px;
    text-align: center;
    display: block;
  }
  .user-tabs .nav-tabs .nav-item {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  .fc-header-toolbar .fc-left {
    margin-bottom: 10px;
  }
  .fc-header-toolbar .fc-right {
    margin-bottom: 10px;
  }
  .pro-widget-profile .dev-text .left img {
    width: 40px;
  }
  .pro-widget-profile .dev-text .left .left-content {
    margin-left: 45px;
  }
  .left-content h4, .left-content .h4 {
    font-size: 11px;
  }
  .proposal-widget .custom_check {
    align-items: baseline;
  }
  .prolist-details li:first-child {
    margin-bottom: 10px;
  }
  .submit-section .click-btn {
    margin-bottom: 5px;
  }
  .submit-section .submit-btn {
    min-width: 112px;
  }
  .submit-section .submit-btn + .submit-btn {
    margin-left: 8px;
  }
  .bid-btn {
    font-size: 10px;
  }
  .star {
    font-size: 10px;
    width: 25px;
    height: 25px;
    line-height: 25px;
  }
  .dev-right p {
    font-size: 13px;
  }
  .paginations li {
    padding-right: 15px;
  }
  .pro-head {
    display: block;
  }
  .testimonial-two .owl-item.active {
    width: 296px !important;
  }
}
.table_header {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 1.5rem 0;
}
.table_header .dataTables_length label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.table_header .dataTables_length select {
  width: auto;
  display: inline-block;
  margin: 0px 3px;
}
.table_header .dataTables_filter label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.table_header .dataTables_filter input {
  width: auto;
  display: inline-block;
  margin: 0px 3px;
}

.table_footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 1.5rem 1.5rem;
}
.table_footer .pagination_section {
  display: flex;
  justify-content: flex-end;
}

.table-scroll {
  overflow-x: auto;
}

.show-sub-menu {
  display: block !important;
  transition: all 0.5s;
}

.hide-sub-menu {
  display: none !important;
  transition: all 0.5s;
}

.sidebarsticky {
  position: sticky;
  top: 100px;
}

.top-review-slider .owl-nav .owl-prev {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 16px;
  background: #fff;
  color: #fff;
  border-radius: 50px;
  position: absolute;
}
.top-review-slider .owl-nav .owl-next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 16px;
  background: #fff;
  color: #fff;
  border-radius: 50px;
  position: absolute;
}

.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: #161c2d;
  color: #fff;
}

.mat-select-panel .mat-option.mat-selected:hover:not(.mat-option-multiple) {
  background: #161c2d;
}

.login-wrapper {
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  padding-top: 1.875rem;
}
.login-wrapper .loginbox {
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  margin: 1rem auto;
  max-width: 600px;
  width: 100%;
}
.login-wrapper .loginbox .login-left {
  align-items: center;
  background: linear-gradient(180deg, #8BC34A, #00bcd4);
  border-radius: 6px 0 0 6px;
  flex-direction: column;
  justify-content: center;
  padding: 80px;
  width: 600px;
  display: flex;
}
.login-wrapper .loginbox .login-right {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0.5rem 2rem;
  width: 600px;
}
.login-wrapper .loginbox .login-right .login-right-wrap {
  max-width: 100%;
  flex: 0 0 100%;
}
.login-wrapper .loginbox .login-right h1, .login-wrapper .loginbox .login-right .h1 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: center;
}

.login-body {
  display: flex;
  height: 100vh;
  min-height: 100vh;
}

.logo-dark {
  max-width: 180px;
  margin: auto;
  display: block;
}

.toggle-password {
  position: absolute;
  right: 17px;
  top: 50%;
  transform: translateY(-50%);
  color: #757575;
  cursor: pointer;
}

.toggle-password.fa-eye {
  margin-right: 1px;
}

.blog-slider .owl-carousel .owl-nav button.owl-prev {
  top: 40%;
  left: -25px;
}

.blog-slider .owl-carousel .owl-nav button.owl-next {
  top: 40%;
  left: -25px;
}

.blog-tricks.owl-carousel .owl-nav button.owl-prev {
  background: fff;
  color: #86E53E;
  border-radius: 50px;
  position: absolute;
  border: 1px solid #EBEBEB;
}

.table-scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  overflow-y: scroll;
  transition: all 0.5s;
}

.table-scroll::-webkit-scrollbar-thumb {
  border: 3px solid transparent;
  background: rgb(205, 202, 202);
  border-radius: 50px;
  background-clip: content-box;
  -webkit-background-clip: content-box;
}

.table-scroll::-webkit-scrollbar-thumb:hover {
  background: grey;
  background-clip: border-box;
  -webkit-background-clip: border-box;
}