
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$template-angular-primary: mat.define-palette(mat.$indigo-palette);
$template-angular-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$template-angular-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$template-angular-theme: mat.define-light-theme((
  color: (
    primary: $template-angular-primary,
    accent: $template-angular-accent,
    warn: $template-angular-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($template-angular-theme);

/* You can add global styles to this file, and also import other style files */
@import "bootstrap/scss/bootstrap";
@import "./assets/config/app";
@import "./assets/components/_toastr.css";

.table-scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  overflow-y: scroll;
  // background: #ffff;
  transition: all 0.5s;
}
.table-scroll::-webkit-scrollbar-thumb {
  border: 3px solid transparent;
  background: rgb(205, 202, 202);
  border-radius: 50px;
  background-clip: content-box;
  -webkit-background-clip: content-box;
}
.table-scroll::-webkit-scrollbar-thumb:hover {
  background: grey;
  background-clip: border-box;
  -webkit-background-clip: border-box;
}


