.table_header {
	display: flex;
	justify-content: space-between;
	padding: 1.5rem 1.5rem 0;
	.dataTables_length {
	  label {
		display: flex;
		align-items: center;
		justify-content: space-between;
	  }
	  select {
		width: auto;
		display: inline-block;
		margin: 0px 3px;
	  }
	}
	.dataTables_filter {
	  label {
		display: flex;
		align-items: center;
		justify-content: space-between;
	  }
	  input {
		width: auto;
		display: inline-block;
		margin: 0px 3px;
	  }
	}
  }
  .table_footer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0.4rem 1.5rem 1.5rem;
	.pagination_section {
	  display: flex;
	  justify-content: flex-end;
	}
  }
  .table-scroll {
	// max-height: calc(100vh - 350px);
	overflow-x: auto;
  }

  .show-sub-menu {
    display: block !important;
    transition: all 0.5s;
}

.hide-sub-menu {
    display: none !important;
    transition: all 0.5s;
}
.sidebarsticky {
	position: sticky;
	top: 100px;
}
.top-review-slider{
	.owl-nav{
		.owl-prev {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 50px;
			height: 50px;
			font-size: 16px;
			background: #fff;
			color: #fff;
			border-radius: 50px;
			position: absolute;
		}
		.owl-next {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 50px;
			height: 50px;
			font-size: 16px;
			background: #fff;
			color: #fff;
			border-radius: 50px;
			position: absolute;
		}
	}
}  
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
    background: #161c2d;
    color: #fff;
}
.mat-select-panel .mat-option.mat-selected:hover:not(.mat-option-multiple){
	background: #161c2d;
}
.login-wrapper {
	width: 100%;
	height: 100%;
	display: table-cell;
	vertical-align: middle;
	padding-top: 1.875rem;
	.loginbox {
		background-color:  #fff;
		border-radius: 20px;
		display: flex;
		margin: 1rem auto;
		max-width: 600px;
		width: 100%;
		.login-left {
			align-items: center;
			background: linear-gradient(180deg, #8BC34A, #00bcd4);
			border-radius: 6px 0 0 6px;
			flex-direction: column;
			justify-content: center;
			padding: 80px;
			width: 600px;
			display: flex;
		}
		.login-right {
			align-items: center;
			display: flex;
			justify-content: center;
			padding: 0.5rem 2rem;
			width: 600px;
			.login-right-wrap {
				max-width: 100%;
				flex: 0 0 100%;
			}
			h1 {
				font-size: 24px;
				font-weight: 500;
				margin-bottom: 5px;
				text-align: center;
			}
		}
	}
}
.login-body {
    display: flex;
    height: 100vh;
    min-height: 100vh;
}
.logo-dark {
    max-width: 180px;
    margin: auto;
    display: block;
}
.toggle-password {
	position: absolute;
	right: 17px;
	top: 50%;
	transform: translateY(-50%);
	color: #757575;
	cursor: pointer;
}
.toggle-password.fa-eye {
	margin-right: 1px;
}
.blog-slider .owl-carousel .owl-nav button.owl-prev{
	top:40%;
	left:-25px;
}
.blog-slider .owl-carousel .owl-nav button.owl-next{
	top:40%;
	left:-25px;
}
.blog-tricks.owl-carousel .owl-nav button.owl-prev{
 background: fff;
 color:#86E53E;
 border-radius: 50px;
 position:absolute;
 border:1px solid #EBEBEB;
}
