@font-face {
    font-family: 'Circular Std';
	font-style: normal;
	font-weight: 400;
    src: url('../fonts/CircularStd-Book.eot');
    src: url('../fonts/CircularStd-Book.eot?#iefix') format('embedded-opentype'),
         url('../fonts/CircularStd-Book.woff2') format('woff2'),
         url('../fonts/CircularStd-Book.woff') format('woff'),
         url('../fonts/CircularStd-Book.svg#Circular Std') format('svg');
}

@font-face {
    font-family: 'Circular Std';
	font-style: normal;
	font-weight: 500;
    src: url('../fonts/CircularStd-Medium.eot');
    src: url('../fonts/CircularStd-Medium.eot?#iefix') format('embedded-opentype'),
         url('../fonts/CircularStd-Medium.woff2') format('woff2'),
         url('../fonts/CircularStd-Medium.woff') format('woff'),
         url('../fonts/CircularStd-Medium.svg#Circular Std') format('svg');
}

@font-face {
    font-family: 'Circular Std';
	font-style: normal;
	font-weight: 700;
    src: url('../fonts/CircularStd-Bold.eot');
    src: url('../fonts/CircularStd-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/CircularStd-Bold.woff2') format('woff2'),
         url('../fonts/CircularStd-Bold.woff') format('woff'),
         url('../fonts/CircularStd-Bold.svg#Circular Std') format('svg');
}

@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: url(../fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
	src: local('Material Icons'),
	local('MaterialIcons-Regular'),
	url(../fonts/MaterialIcons-Regular.woff2) format('woff2'),
	url(../fonts/MaterialIcons-Regular.woff) format('woff'),
	url(../fonts/MaterialIcons-Regular.ttf) format('truetype');
}
.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: inherit;
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	vertical-align: middle;
	direction: ltr;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	font-feature-settings: 'liga';
}
html {
	height: 100%;
}
body {
	background-color: #ffffff;
	color: #353535;
	font-family: 'Encode Sans', sans-serif;
	font-size: 16px;
	height: 100%;
	overflow-x: hidden;
}
.bg-one {
	background-color: #fdfdfd;
}
.bg-one .container,
.bg-two .container,
.bg-three .container,
.bg-four .container,
.bg-five .container {
	max-width: 1200px;
}


h1, h2, h3, h4, h5, h6 {
	font-family: 'Encode Sans', sans-serif;
}
.h1, h1 {
	font-size: 32px;
	font-weight: bold;
}
.h2, h2 {
	font-size: 28px;
	font-weight: bold;
}
.h3, h3 {
	font-size: 24px;
	font-weight: 600;
}
.h4, h4 {
	font-size: 20px;
	font-weight: 600;
}
.h5, h5 {
	font-size: 18px;
	font-weight: 600;
}
.h6, h6 {
	font-size: 16px;
	font-weight: 500;
}

label {
	color: #2E2E2E;
	display: inline-block;
	margin-bottom: 0.5rem;
	font-weight: 500;
	font-size: 15px;
	&.file-upload {
		position: relative;
		overflow: hidden;
		color: #86E53E;
		font-size: 15px !important;
		border: 1px solid #ffbe9d;
		border-radius: 5px;
		padding: 12px 6px;
		&.image-btn {
			background: #fff;
			border: 1px solid #86E53E;
			border-radius: 2px;
			color: #86E53E;
			padding: 12px 15px;
			font-size: 16px;
			text-transform: uppercase;
			font-weight: 500;
			cursor: pointer;
		}
		input[type="file"] {
			position: absolute;
			top: 0;
			right: 0;
			min-width: 100%;
			min-height: 100%;
			font-size: 100px;
			text-align: right;
			filter: alpha(opacity=0);
			opacity: 0;
			outline: none;
			background: white;
			cursor: inherit;
			display: block;
		}
		&.image-upbtn {
			background: #13323C;
			border: 1px solid #13323C;
			border-radius: 5px;
			color: #fff;
			padding: 12px 15px;
			font-size: 16px;
			font-weight: 500;
			cursor: pointer;
		}
	}
	&.award-upload {
		&.image-upbtn {
			border: 1px solid #F15C3D;
			border-radius: 5px;
			width: 76px;
			height: 46px;
			cursor: pointer;
			justify-content: center;
			display: flex;
			align-items: center;
			color: #F15C3D;
		}
		input[type="file"] {
			position: absolute;
			top: 0;
			right: 0;
			min-width: 100%;
			min-height: 100%;
			font-size: 100px;
			text-align: right;
			filter: alpha(opacity=0);
			opacity: 0;
			outline: none;
			background: white;
			cursor: inherit;
			display: block;
		}
	}
}
a {
	color: #2E2E2E;
	text-decoration: none;
	&:hover {
		color: #86E53E;
	}
}
a:hover,
a:active,
a:focus {
	outline: none;
	text-decoration: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
	-webkit-box-shadow: 0 0 0px 1000px white inset !important;
}
input,
button,
a {
	transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
}
button {
	&:focus {
		outline: 0;
	}
	&.scroll-top {
		&:focus {
			outline: none;
		}
	}
}
input[type=text],
input[type=password] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.dropdown-menu {
	color: #161c2d;
	font-size: 14px;
	border: 1px solid #eff2f7;
	background-color: #fff;
	box-shadow: 0 0 1.25rem rgba(31,45,61,.08);
}
.btn.focus, .btn:focus {
	box-shadow: inherit;
	box-shadow: unset;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
	color: #fff;
	background-color: #86E53E;
	border-color: #86E53E;
}
.btn-primary {
	color: #fff;
	background-color: #86E53E;
	border-color: #86E53E;
	background-color: #86E53E;
	border: 1px solid #86E53E;
}
.form-control {
	border-color: #E7E8EA;
	color: #353535;
	font-size: 15px;
	min-height: 46px;
	padding: 6px 15px;
	&:focus {
		border-color: #C7C8CA;
		box-shadow: none;
		outline: 0 none;
	}
}
.nav-link {
	font-weight: bold;
	font-size: 14px;
	color: #6A1B9A;
	color: #444444;
}
.form-row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -5px;
	margin-left: -5px;
}
.form-row>.col, .form-row>[class*=col-] {
	padding-right: 5px;
	padding-left: 5px;
}
.input-group {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: stretch;
	align-items: stretch;
	width: 100%;
}
.dashboard-page {
	.content {
		padding: 144px 0 30px;
		& > .container-fluid {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
	.content-page {
		padding: 60px 0 30px;
	}
	.header {
		border-bottom: 1px solid #e3e3e3;
	}
	.footer {
		.container {
			max-width: 100%;
			padding-left: 30px;
			padding-right: 30px;
		}
	}
}
.table {
	color: #161c2d;
	max-width: 100%;
	margin-bottom: 0;
	width: 100%;
	& > :not(:first-child) {
		border-top: 1px solid rgb(0 0 0 / 13%);
	}
	& > :not(caption) {
		& > * {
			& > * {
				box-shadow: unset;
			}
		}
	}
	&.dataTable {
		border-collapse: collapse !important;
	}
	thead {
		border-bottom: 1px solid rgba(0, 0, 0, 0.03);
		tr {
			th {
				border-bottom: 1px solid rgba(0, 0, 0, 0.05);
			}
		}
	}
	tbody {
		tr {
			border-bottom: 1px solid rgb(0 0 0 / 12%);
			&:last-child {
				border-color: transparent;
			}
		}
	}
	.thead-pink {
		th {
			color: #86E53E;
			background-color: #FFF6F4;
			border-color: #FFF6F4;
			font-size: 14px;
		}
	}
}
.table-striped {
	& > tbody {
		& > tr {
			&:nth-of-type(2n+1){
			 background-color: #f8f9fa;
			}
		}
	}
	thead {
		tr {
			border-color: transparent;
		}
	}
	tbody {
		tr {
			border-color: transparent;
			&:nth-of-type(even) {
				background-color: rgba(255, 255, 255, 0.3);
			}
			&:nth-of-type(odd) {
				background-color: rgba(235, 235, 235, 0.4);
			}
		}
	}
}
.table.no-border > tbody > tr > td,
.table > tbody > tr > th,
.table.no-border > tfoot > tr > td,
.table.no-border > tfoot > tr > th,
.table.no-border > thead > tr > td,
.table.no-border > thead > tr > th {
	border-top: 0;
	padding: 10px 8px;
}
.table-nowrap td,
.table-nowrap th {
	white-space: nowrap;
}
table {
	&.table {
		td {
			h2 {
				display: inline-block;
				font-size: inherit;
				font-weight: 400;
				margin: 0;
				padding: 0;
				vertical-align: middle;
				&.table-avatar {
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					display: -webkit-inline-box;
					display: -ms-inline-flexbox;
					display: inline-flex;
					font-size: inherit;
					font-weight: 400;
					margin: 0;
					padding: 0;
					vertical-align: middle;
					white-space: nowrap;
					.profile-name {
						font-weight: 600;
						font-size: 15px;
						color: #000000;
					}
				}
				a {
					color: #161c2d;
					&:hover {
						color: #ff508b;
					}
				}
				span {
					color: #6e727d;
					display: block;
					font-size: 12px;
					margin-top: 3px;
				}
			}
		}
	}
	&.dataTable {
		margin: 0 !important;
	}
}
.table td, .table th {
	padding: 1rem 0.75rem;
}
.table.table-center td, .table-center td, .table-center th, .table.table-center th {
	vertical-align: middle;
	font-size: 15px;
}
.table-hover {
	tbody {
		tr {
			&:hover {
				background-color: #f7f7f7;
				td {
					color: #474648;
				}
			}
		}
	}
}
.table tr td:first-child, .table tr th:first-child {
	padding-left: 20px;
}
.table tr td:last-child, .table tr th:last-child {
	padding-right: 20px;
}
.table-bordered {
	border: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.table-bordered th,
.table-bordered td {
	border-color: rgba(0, 0, 0, 0.05);
}
.card-table {
	.card-body {
		padding: 0;
		.table {
			& > thead {
				& > tr {
					& > th {
						border-top: 0;
						font-size: 14px;
					}
				}
			}
		}
	}
}
.card-table .table tr td:first-child,
.card-table .table tr th:first-child {
	padding-left: 1.5rem;
}
.card-table .table tr td:last-child,
.card-table .table tr th:last-child {
	padding-right: 1.5rem;
}
.card-table .table td, .card-table .table th {
	border-top: 1px solid #e2e5e8;
	padding: 1rem 0.75rem;
	white-space: nowrap;
}
.btn-block {
	width: 100%;
}
.btn-link {
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}
.btn-white {
	background-color: #fff;
	border-color: #ebebeb;
}
.btn {
	&.btn-rounded {
		border-radius: 50px;
	}
	&.btn-danger {
		&.trash {
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			height: 46px;
			width: 46px;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			justify-content: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
		}
	}
}
.bg-primary,
.badge-primary {
	background-color: #86E53E !important;
}
a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
	background-color: #86E53E !important;
}
.bg-success,
.badge-success {
	background-color: #00e65b !important;
}
a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
	background-color: #00cc52 !important;
}
.bg-info,
.badge-info {
	background-color: #86E53E !important;
}
a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
	background-color: #028ee1 !important;
}
.bg-warning,
.badge-warning {
	background-color: #ffbc34 !important;
}
a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
	background-color: #e9ab2e !important;
}
.bg-danger,
.badge-danger {
	background-color: #ff0100 !important;
}
a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
	background-color: #e63333 !important;
}
.bg-white {
	background-color: #fff;
}
.bg-purple,
.badge-purple {
	background-color: #9368e9 !important;
}
.text-primary,
.dropdown-menu > li > a.text-primary {
	color: #86E53E !important;
}
.text-success,
.dropdown-menu > li > a.text-success {
	color: #00cc52 !important;
}
.text-danger,
.dropdown-menu > li > a.text-danger {
	color: #ff0100 !important;
}
.text-info,
.dropdown-menu > li > a.text-info {
	color: #ff3115 !important;
}
.text-warning,
.dropdown-menu > li > a.text-warning {
	color: #ffbc34 !important;
}
.text-purple,
.dropdown-menu > li > a.text-purple {
	color: #7460ee !important;
}
.text-muted {
	color: #757575 !important;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
	background-color: #86E53E;
	border: 1px solid #86E53E;
	box-shadow: unset;
}
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
	background-color: #86E53E;
	border: 1px solid #86E53E;
}
.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle {
	background-color: #86E53E;
	border-color: #86E53E;
	color: #fff;
}
.btn-primary.active:focus:not(:disabled):not(.disabled),
.btn-primary:active:focus:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-primary.disabled, .btn-primary:disabled {
	background-color: #c7c7c7;
	border-color: #c7c7c7;
	color: #000;
}
.btn-secondary.active:focus:not(:disabled):not(.disabled),
.btn-secondary:active:focus:not(:disabled):not(.disabled),
.show > .btn-secondary.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-success {
	background-color: #00e65b;
	border: 1px solid #00e65b;
	color: #fff;
	background-color: #FF3115;
	border-color: #FF3115;
	&:hover {
		background-color: #86E53E;
		border-color: #86E53E;
	}
}
.btn-success:hover,
.btn-success:focus,
.btn-success.active,
.btn-success:active,
.open > .dropdown-toggle.btn-success {
	background-color: #00cc52;
	border: 1px solid #00cc52;
	color: #fff;
}
.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover {
	background-color: #00cc52;
	border: 1px solid #00cc52;
}
.btn-success.active:not(:disabled):not(.disabled),
.btn-success:active:not(:disabled):not(.disabled),
.show > .btn-success.dropdown-toggle {
	background-color: #00cc52;
	border-color: #00cc52;
	color: #fff;
}
.btn-success.active:focus:not(:disabled):not(.disabled),
.btn-success:active:focus:not(:disabled):not(.disabled),
.show > .btn-success.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-success.disabled, .btn-success:disabled {
	background-color: #00e65b;
	border-color: #00e65b;
	color: #fff;
}
.btn-info {
	background-color: #009efb;
	border: 1px solid #009efb;
}
.btn-info:hover,
.btn-info:focus,
.btn-info.active,
.btn-info:active,
.open > .dropdown-toggle.btn-info {
	background-color: #028ee1;
	border: 1px solid #028ee1;
}
.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover {
	background-color: #028ee1;
	border: 1px solid #028ee1;
}
.btn-info.active:not(:disabled):not(.disabled),
.btn-info:active:not(:disabled):not(.disabled),
.show > .btn-info.dropdown-toggle {
	background-color: #028ee1;
	border-color: #028ee1;
	color: #fff;
}
.btn-info.active:focus:not(:disabled):not(.disabled),
.btn-info:active:focus:not(:disabled):not(.disabled),
.show > .btn-info.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-info.disabled, .btn-info:disabled {
	background-color: #009efb;
	border-color: #009efb;
	color: #fff;
}
.btn-warning {
	background-color: #ffbc34;
	border: 1px solid #ffbc34;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.active,
.btn-warning:active,
.open > .dropdown-toggle.btn-warning {
	background-color: #e9ab2e;
	border: 1px solid #e9ab2e;
}
.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover {
	background-color: #e9ab2e;
	border: 1px solid #e9ab2e;
}
.btn-warning.active:not(:disabled):not(.disabled),
.btn-warning:active:not(:disabled):not(.disabled),
.show > .btn-warning.dropdown-toggle {
	background-color: #e9ab2e;
	border-color: #e9ab2e;
	color: #fff;
}
.btn-warning.active:focus:not(:disabled):not(.disabled),
.btn-warning:active:focus:not(:disabled):not(.disabled),
.show > .btn-warning.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-warning.disabled, .btn-warning:disabled {
	background-color: #ffbc34;
	border-color: #ffbc34;
	color: #fff;
}
.btn-danger {
	background-color: #ff0100;
	border: 1px solid #ff0100;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active,
.btn-danger:active,
.open > .dropdown-toggle.btn-danger {
	background-color: #e63333;
	border: 1px solid #e63333;
}
.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover {
	background-color: #e63333;
	border: 1px solid #e63333;
}
.btn-danger.active:not(:disabled):not(.disabled),
.btn-danger:active:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle {
	background-color: #e63333;
	border-color: #e63333;
	color: #fff;
}
.btn-danger.active:focus:not(:disabled):not(.disabled),
.btn-danger:active:focus:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-danger.disabled, .btn-danger:disabled {
	background-color: #f62d51;
	border-color: #f62d51;
	color: #fff;
}
.btn-light.active:focus:not(:disabled):not(.disabled),
.btn-light:active:focus:not(:disabled):not(.disabled),
.show > .btn-light.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-dark.active:focus:not(:disabled):not(.disabled),
.btn-dark:active:focus:not(:disabled):not(.disabled),
.show > .btn-dark.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-outline-primary {
	color: #86E53E;
	border-color: #86E53E;
	color: #86E53E;
	border-color: #86E53E;
	&:hover {
		background-color: #86E53E;
		border-color: #86E53E;
		background-color: #86E53E;
		border-color: #86E53E;
	}
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
	box-shadow: none;
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
	color: #86E53E;
	background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
	background-color: #86E53E;
	border-color: #86E53E;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-success {
	color: #00e65b;
	border-color: #00e65b;
	&:hover {
		background-color: #00e65b;
		border-color: #00e65b;
	}
}
.btn-outline-success:focus, .btn-outline-success.focus {
	box-shadow: none;
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
	color: #00e65b;
	background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
	background-color: #00e65b;
	border-color: #00e65b;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-info {
	color: #009efb;
	border-color: #009efb;
	&:hover {
		color: #fff;
		background-color: #009efb;
		border-color: #009efb;
	}
}
.btn-outline-info:focus, .btn-outline-info.focus {
	box-shadow: none;
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
	background-color: transparent;
	color: #009efb;
}
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
	background-color: #009efb;
	border-color: #009efb;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-warning {
	color: #ffbc34;
	border-color: #ffbc34;
	&:hover {
		color: #212529;
		background-color: #ffbc34;
		border-color: #ffbc34;
	}
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
	box-shadow: none;
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
	background-color: transparent;
	color: #ffbc34;
}
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
	color: #212529;
	background-color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-danger {
	color: #ff0100;
	border-color: #ff0100;
	&:hover {
		color: #fff;
		background-color: #ff0100;
		border-color: #ff0100;
	}
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
	box-shadow: none;
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
	background-color: transparent;
	color: #ff0100;
}
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
	background-color: #ff0100;
	border-color: #ff0100;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-light {
	color: #ababab;
	border-color: #e6e6e6;
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
	color: #ababab;
}
.add-btn {
	max-width: 140px;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
	background-color: #ff3115;
	border-color: #ff3115;
}
.pagination > li > a,
.pagination > li > span {
	color: #ff3115;
}
.page-link {
	&:hover {
		color: #ff3115;
	}
	&:focus {
		box-shadow: unset;
	}
}
.page-item {
	&.active {
		.page-link {
			background-color: #ff3115;
			border-color: #ff3115;
		}
	}
}
.card {
	border: 1px solid #e8e8e8;
	background-color: #fff;
	box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.25);
	margin-bottom: 30px;
	.card-header {
		background-color: #fff;
		border-bottom: 1px solid #f0f0f0;
		.card-title {
			margin-bottom: 0;
			font-weight: bold;
			font-size: 18px;
			color: #161c2d;
		}
	}
}
.card-body {
	padding: 1.5rem;
}
.card-header {
	border-bottom: 1px solid #f0f0f0;
	padding: 1rem 1.5rem;
}
.card-footer {
	background-color: #fff;
	border-top: 1px solid #f0f0f0;
	padding: 1rem 1.5rem;
}
.btn-light {
	border-color: #e6e6e6;
	color: #a6a6a6;
	&:hover {
		color: #212529;
		background-color: #dae0e5;
		border-color: #d3d9df;
	}
}
.bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover {
	background-color: #ff508b;
	text-shadow: unset;
}
.bootstrap-datetimepicker-widget {
	table {
		td {
			&.today {
				&::before {
					border-bottom-color: #ff508b;
				}
			}
		}
	}
}
.bg-info-light {
	background-color: rgba(2, 182, 179,0.12) !important;
	color: #1db9aa !important;
}
.bg-primary-light {
	background-color: rgba(17, 148, 247,0.12) !important;
	color: #2196f3 !important;
}
.bg-danger-light {
	background-color: rgba(242, 17, 54,0.12) !important;
	color: #e63c3c !important;
}
.bg-warning-light {
	background-color: rgba(255, 152, 0,0.12) !important;
	color: #f39c12 !important;
}
.bg-success-light {
	background-color: rgba(15, 183, 107,0.12) !important;
	color: #26af48 !important;
}
.bg-purple-light {
	background-color: rgba(197, 128, 255,0.12) !important;
	color: #c580ff !important;
}
.bg-default-light {
	background-color: rgba(40, 52, 71,0.12) !important;
	color: #283447 !important;
}
.text-xs {
	font-size: .75rem !important;
}
.text-sm {
	font-size: .875rem !important;
}
.text-lg {
	font-size: 1.25rem !important;
}
.text-xl {
	font-size: 1.5rem !important;
}
.form-group {
	margin-bottom: 1.25rem;
	&.working-position {
		position: relative;
		margin-bottom: 0;
	}
}
.form-check {
	margin-bottom: 1.25rem;
}
.slider-btn {
	background: #09e5ab;
	color: #fff;
	border: 1px solid #09e5ab;
	padding: 8px 16px !important;
	transition: .5s;
	&:hover {
		background: #fff;
		color: #09e5ab;
		border: 1px solid #fff;
		padding: 8px 16px !important;
	}
}
.avatar {
	position: relative;
	display: inline-block;
	width: 3rem;
	height: 3rem;
	& > img {
		width: 100%;
		height: 100%;
		-o-object-fit: cover;
		object-fit: cover;
	}
	.border {
		border-width: 3px !important;
	}
	.rounded {
		border-radius: 6px !important;
	}
	.avatar-title {
		font-size: 18px;
	}
}
.avatar-title {
	width: 100%;
	height: 100%;
	background-color: #ff508b;
	color: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.avatar-away::before,
.avatar-offline::before,
.avatar-online::before {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 25%;
	height: 25%;
	border-radius: 50%;
	content: '';
	border: 2px solid #fff;
}
.avatar-online {
	&::before {
		background-color: #00e65b;
	}
}
.avatar-offline {
	&::before {
		background-color: #ff0100;
	}
}
.avatar-away {
	&::before {
		background-color: #ffbc34;
	}
}
.avatar-xs {
	width: 1.65rem;
	height: 1.65rem;
	.border {
		border-width: 2px !important;
	}
	.rounded {
		border-radius: 4px !important;
	}
	.avatar-title {
		font-size: 12px;
	}
}
.avatar-xs.avatar-away::before,
.avatar-xs.avatar-offline::before,
.avatar-xs.avatar-online::before {
	border-width: 1px;
}
.avatar-sm {
	width: 2.5rem;
	height: 2.5rem;
	.border {
		border-width: 3px !important;
	}
	.rounded {
		border-radius: 4px !important;
	}
	.avatar-title {
		font-size: 15px;
	}
}
.avatar-sm.avatar-away::before,
.avatar-sm.avatar-offline::before,
.avatar-sm.avatar-online::before {
	border-width: 2px;
}
.avatar-md {
	width: 3.125rem;
	height: 3.125rem;
}
.avatar-lg {
	width: 3.75rem;
	height: 3.75rem;
	.border {
		border-width: 3px !important;
	}
	.rounded {
		border-radius: 8px !important;
	}
	.avatar-title {
		font-size: 24px;
	}
}
.avatar-lg.avatar-away::before,
.avatar-lg.avatar-offline::before,
.avatar-lg.avatar-online::before {
	border-width: 3px;
}
.avatar-xl {
	width: 5rem;
	height: 5rem;
	.border {
		border-width: 4px !important;
	}
	.rounded {
		border-radius: 8px !important;
	}
	.avatar-title {
		font-size: 28px;
	}
}
.avatar-xl.avatar-away::before,
.avatar-xl.avatar-offline::before,
.avatar-xl.avatar-online::before {
	border-width: 4px;
}
.avatar-xxl {
	width: 5.125rem;
	height: 5.125rem;
	.border {
		border-width: 6px !important;
	}
	.rounded {
		border-radius: 8px !important;
	}
	.avatar-title {
		font-size: 30px;
	}
}
.avatar-xxl.avatar-away::before,
.avatar-xxl.avatar-offline::before,
.avatar-xxl.avatar-online::before {
	border-width: 4px;
}
.avatar-group {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	.avatar {
		& + .avatar {
			margin-left: -.75rem;
		}
		&:hover {
			z-index: 1;
		}
	}
	.avatar-xs {
		& + .avatar-xs {
			margin-left: -.40625rem;
		}
	}
	.avatar-sm {
		& + .avatar-sm {
			margin-left: -.625rem;
		}
	}
	.avatar-lg {
		& + .avatar-lg {
			margin-left: -1rem;
		}
	}
	.avatar-xl {
		& + .avatar-xl {
			margin-left: -1.28125rem;
		}
	}
}
.nav-tabs {
	border-bottom: 1px solid #f0f0f0;
	& > li {
		& > a {
			margin-right: 0;
			color: #888;
			border-radius: 0;
		}
	}
	&.nav-tabs-solid {
		background-color: #fbfcff;
		border: 0;
		& > li {
			margin-bottom: 0;
			text-align: center;
			& > a {
				color: #161c2d;
				color: #161c2d;
				border-color: transparent;
			}
		}
		&.nav-tabs-rounded {
			border-radius: 50px;
			& > li {
				& > a {
					border-radius: 50px;
				}
			}
		}
		& > .open {
			&:not(.active) {
				& > a {
					background-color: #f5f5f5;
					border-color: transparent;
				}
			}
		}
	}
	.nav-link {
		border-radius: 0;
	}
	&.nav-justified {
		& > li {
			& > a {
				border-radius: 0;
				margin-bottom: 0;
			}
		}
		&.nav-tabs-solid {
			& > li {
				& > a {
					border-color: transparent;
				}
			}
		}
		&.nav-tabs-top {
			border-bottom: 1px solid #ddd;
		}
	}
	&.nav-tabs-top {
		& > li {
			margin-bottom: 0;
			& + li {
				& > a {
					margin-left: 1px;
				}
			}
		}
	}
}
.card-header-tabs {
	border-bottom: 0;
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
	border-color: transparent;
	color: #161c2d;
}
.nav-tabs.nav-tabs-solid > .active > a,
.nav-tabs.nav-tabs-solid > .active > a:hover,
.nav-tabs.nav-tabs-solid > .active > a:focus {
	background-color: #ff508b;
	border-color: #ff508b;
	color: #fff;
}
.tab-content {
	padding-top: 20px;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
	background-color: #eee;
	border-color: transparent;
	color: #161c2d;
}
.nav-tabs.nav-justified > li > a:hover,
.nav-tabs.nav-justified > li > a:focus {
	border-bottom-color: #ddd;
}
.nav-tabs.nav-tabs-solid > li > a.active,
.nav-tabs.nav-tabs-solid > li > a.active:hover,
.nav-tabs.nav-tabs-solid > li > a.active:focus {
	background-color: #fff;
	border-color: #fff;
	color: #86E53E;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:hover,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:focus {
	border-radius: 50px;
}
.nav-tabs-justified {
	& > li {
		& > a {
			border-radius: 0;
			margin-bottom: 0;
		}
	}
	&.nav-tabs-solid {
		& > li {
			& > a {
				border-color: transparent;
			}
		}
	}
	&.nav-tabs-top {
		border-bottom: 1px solid #ddd;
	}
}
.nav-tabs-justified > li > a:hover,
.nav-tabs-justified > li > a:focus {
	border-bottom-color: #ddd;
}
.nav-tabs.nav-justified.nav-tabs-top > li > a,
.nav-tabs.nav-justified.nav-tabs-top > li > a:hover,
.nav-tabs.nav-justified.nav-tabs-top > li > a:focus {
	border-width: 2px 0 0 0;
}
.nav-tabs.nav-tabs-top > li > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
	border-width: 2px 0 0 0;
}
.nav-tabs.nav-tabs-top > li.open > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
	border-top-color: #ddd;
}
.nav-tabs.nav-tabs-top > li > a.active,
.nav-tabs.nav-tabs-top > li > a.active:hover,
.nav-tabs.nav-tabs-top > li > a.active:focus {
	border-top-color: #ff508b;
}
.nav-tabs.nav-tabs-bottom > li > a.active,
.nav-tabs.nav-tabs-bottom > li > a.active:hover,
.nav-tabs.nav-tabs-bottom > li > a.active:focus {
	border-bottom-width: 2px;
	border-color: transparent;
	border-bottom-color: #86E53E;
	background-color: transparent;
	transition: none 0s ease 0s;
	-moz-transition: none 0s ease 0s;
	-o-transition: none 0s ease 0s;
	-ms-transition: none 0s ease 0s;
	-webkit-transition: none 0s ease 0s;
}
.nav-tabs.nav-tabs-solid > li > a:hover,
.nav-tabs.nav-tabs-solid > li > a:focus {
	background-color: #f5f5f5;
}
.nav-tabs-justified.nav-tabs-top > li > a,
.nav-tabs-justified.nav-tabs-top > li > a:hover,
.nav-tabs-justified.nav-tabs-top > li > a:focus {
	border-width: 2px 0 0 0;
}
.provider-tabs {
	.nav-tabs {
		&.nav-tabs-solid {
			background: #fff;
			box-shadow: 0px 4px 34px rgb(215 215 215 / 25%);
			border-radius: 5px;
			border: 0;
			padding: 10px;
			& > li {
				& > a {
					p {
						margin: 5px 0 0;
						font-weight: 500;
						font-size: 15px;
					}
				}
			}
		}
		&.nav-justified {
			&.nav-tabs-top {
				border-bottom: 1px solid #ddd;
			}
		}
	}
	.nav-tabs-justified {
		& > li {
			& > a {
				border-radius: 0;
				margin-bottom: 0;
			}
		}
		&.nav-tabs-solid {
			& > li {
				& > a {
					border-color: transparent;
				}
			}
		}
	}
	&.abouts-view {
		.nav-tabs {
			&.nav-tabs-solid {
				li {
					.active-about {
						p {
							color: #F15C3D;
							font-weight: 500;
						}
					}
				}
				& > li {
					& > a {
						&:hover {
							background-color: rgba(255, 91, 55, 0.15);
							border-radius: 5px;
						}
						p {
							color: #13323C;
						}
					}
				}
			}
		}
	}
}
.provider-tabs .nav-tabs.nav-tabs-solid > li > a:hover, .provider-tabs .nav-tabs.nav-tabs-solid > li > a:focus, .provider-tabs .nav-tabs.nav-tabs-solid > li > a.active {
	background-color: #f9f9f9;
	border-color: #f9f9f9;
	color: inherit;
}
.provider-tabs .nav-tabs-justified > li > a:hover,
.provider-tabs .nav-tabs-justified > li > a:focus {
	border-bottom-color: #ddd;
}
.provider-tabs .nav-tabs.nav-justified.nav-tabs-top > li > a,
.provider-tabs .nav-tabs.nav-justified.nav-tabs-top > li > a:hover,
.provider-tabs .nav-tabs.nav-justified.nav-tabs-top > li > a:focus {
	border-width: 2px 0 0 0;
}
.bg-red {
	color: #FF8B7B;
}
.bg-blue {
	color: #129BE8;
}
.bg-violet {
	color: #8553EE;
}
.bg-yellow {
	color: #FEBE42;
}
.bg-pink {
	color: #F5365C;
	.input-group-text {
		background: #ffe6e1;
		border-color: #ffe6e1;
		color: #86E53E;
	}
}
.bg-green {
	color: #159C5B;
}
.bg-navy {
	color: #11099F;
}
.user-tabs {
	ul {
		li {
			& + li {
				margin-left: 26px;
			}
		}
	}
	.nav-pills {
		.nav-link {
			background: #fff;
			border-radius: 3px;
			color: #86E53E;
			border: 1px solid #86E53E;
			padding: 0.75rem 1rem;
		}
	}
	.nav-tabs {
		& > li {
			& > a {
				border: 0;
				border-bottom: 3px solid transparent;
				color: #161c2d;
				font-weight: 600;
				padding: 20px;
				&:hover {
					background-color: unset;
					color: #ff3115;
				}
			}
		}
	}
	.med-records {
		display: inline-block;
		min-width: 130px;
	}
	&.project-tabs {
		margin-bottom: 30px;
	}
}
.user-tabs  .nav-pills .nav-link.active, .user-tabs  .nav-pills .show>.nav-link {
	color: #fff;
	background-color: #86E53E;
	border: 1px solid #86E53E;
}
.form-focus {
	height: 58px;
	position: relative;
	.focus-label {
		font-size: 14px;
		font-weight: 400;
		pointer-events: none;
		position: absolute;
		-webkit-transform: translate3d(0, 22px, 0) scale(1);
		-ms-transform: translate3d(0, 22px, 0) scale(1);
		-o-transform: translate3d(0, 22px, 0) scale(1);
		transform: translate3d(0, 22px, 0) scale(1);
		transform-origin: left top;
		transition: 240ms;
		left: 12px;
		top: -3px;
		z-index: 1;
		color: #8C635B;
		margin-bottom: 0;
	}
	&.focused {
		.focus-label {
			opacity: 1;
			top: -18px;
			font-size: 12px;
			z-index: 1;
		}
		.form-control {
			&::-webkit-input-placeholder {
				color: #bbb;
			}
		}
	}
	.form-control {
		height: 58px;
		padding: 21px 12px 6px;
		border: 1px solid #ffe6e1;
		&::-webkit-input-placeholder {
			color: transparent;
			transition: 240ms;
		}
		&:focus {
			&::-webkit-input-placeholder {
				transition: none;
			}
		}
	}
	&.select-focus {
		.focus-label {
			opacity: 1;
			font-weight: 300;
			top: -20px;
			font-size: 12px;
			z-index: 1;
		}
	}
	.select2-container {
		.select2-selection--single {
			border: 1px solid #e3e3e3;
			height: 50px;
			.select2-selection__rendered {
				padding-right: 30px;
				padding-left: 12px;
				padding-top: 10px;
			}
		}
	}
	.select2-container--default {
		.select2-selection--single {
			.select2-selection__arrow {
				height: 48px;
				right: 7px;
				b {
					border-color: #ccc transparent transparent;
					border-style: solid;
					border-width: 6px 6px 0;
					height: 0;
					left: 50%;
					margin-left: -10px;
					margin-top: -2px;
					position: absolute;
					top: 50%;
					width: 0;
				}
			}
			.select2-selection__rendered {
				color: #676767;
				font-size: 14px;
				font-weight: normal;
				line-height: 38px;
			}
		}
		&.select2-container--open {
			.select2-selection--single {
				.select2-selection__arrow {
					b {
						border-color: transparent transparent #ccc;
						border-width: 0 6px 6px;
					}
				}
			}
		}
		.select2-results__option--highlighted[aria-selected] {
			background-color: #fc6075;
		}
	}
}
.form-focus .form-control:focus ~ .focus-label,
.form-focus .form-control:-webkit-autofill ~ .focus-label {
	opacity: 1;
	font-weight: 400;
	top: -18px;
	font-size: 12px;
	z-index: 1;
}
.modal {
	-webkit-overflow-scrolling: touch;
	.card {
		box-shadow: unset;
	}
	label {
		font-weight: 500;
		color: #161c2d;
	}
}
.modal-footer {
	&.text-center {
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
	}
	&.text-left {
		justify-content: flex-start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: flex-start;
	}
}
.modal-dialog {
	overflow: hidden;
	&.modal-md {
		max-width: 600px;
	}
}
.modal-header {
	padding: 20px 30px;
	align-items: center;
	.close {
		width: 30px;
		height: 30px;
		line-height: 30px;
		padding: 0px;
		background: #fff;
		color: #86E53E;
		border: 1px solid #86E53E;
		border-radius: 50%;
		margin: -1rem -1rem -1rem auto;
		&:hover {
			opacity: .75;
		}
	}
}
.modal-body {
	padding: 30px;
}
.custom-modal {
	.modal-content {
		border: 0;
		border-radius: 10px;
	}
	.modal-header {
		padding: 20px 20px 0px;
		border-bottom: 0;
	}
	.modal-footer {
		padding: 1.25rem;
	}
	.modal-body {
		padding: 2rem;
	}
	.close {
		border: 2px solid #86E53E;
		border-radius: 50%;
		color: #86E53E;
		font-size: 17px;
		height: 20px;
		line-height: 16px;
		margin: 0;
		opacity: 1;
		padding: 0;
		position: absolute;
		right: 20px;
		top: 26px;
		width: 20px;
		z-index: 99;
	}
	.modal-title {
		font-size: 24px;
		margin-bottom: 5px;
	}
	.modal-subtitle {
		font-size: 16px;
	}
}
.rate-bottom {
	border-bottom: 1px solid #F3F3F3;
}
.modal-backdrop {
	&.show {
		opacity: 0.4;
		-webkit-transition-duration: 400ms;
		transition-duration: 400ms;
	}
}
.bg-modal {
	position: relative;
	&:before {
		content: "";
		position: absolute;
		margin: -32px;
		width: 100px;
		top: 0;
		left: 0;
		height: 100px;
		border-radius: 50%;
		background-color: #86E53E;
		overflow: hidden;
	}
}
.comp-header {
	margin-bottom: 1.875rem;
	.comp-title {
		color: #161c2d;
	}
}
.line {
	background-color: #ff3115;
	height: 2px;
	width: 60px;
}
.comp-buttons {
	.btn {
		margin-bottom: 5px;
	}
}
.pagination-box {
	.pagination {
		margin-top: 0;
	}
}
.comp-dropdowns {
	.btn-group {
		margin-bottom: 5px;
	}
}
.progress-example {
	.progress {
		margin-bottom: 1.5rem;
	}
}
.progress-xs {
	height: 4px;
}
.progress-sm {
	height: 15px;
}
.progress {
	margin-bottom: 25px;
	width: 100%;
	margin-bottom: 0;
	height: 12px;
	border-radius: 50px;
	&.progress-sm {
		height: 6px;
	}
	&.progress-md {
		height: 8px;
	}
	&.progress-lg {
		height: 18px;
	}
}
.header {
	width: 100%;
	position: fixed;
	z-index: 1040;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	background-color: transparent;
	// border: 1px solid #e3e3e3;
	&.min-header {
		width: 100%;
		position: fixed;
		z-index: 1040;
		-webkit-transition: all 0.3s ease 0s;
		-o-transition: all 0.3s ease 0s;
		transition: all 0.3s ease 0s;
		background-color: transparent;
	}
	.has-arrow {
		.dropdown-toggle {
			&:after {
				border-top: 0;
				border-left: 0;
				border-bottom: 2px solid #161c2d;
				border-right: 2px solid #161c2d;
				content: '';
				height: 8px;
				display: inline-block;
				pointer-events: none;
				-webkit-transform-origin: 66% 66%;
				-ms-transform-origin: 66% 66%;
				transform-origin: 66% 66%;
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				transform: rotate(45deg);
				-webkit-transition: all 0.15s ease-in-out;
				transition: all 0.15s ease-in-out;
				width: 8px;
				vertical-align: 2px;
				margin-left: 10px;
			}
		}
		.dropdown-toggle[aria-expanded="true"]:after {
			-webkit-transform: rotate(-135deg);
			-ms-transform: rotate(-135deg);
			transform: rotate(-135deg);
		}
	}
	&.sticky {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		background: #fff;
		z-index: 99999;
		display: block;
		-webkit-box-shadow: 0px 10px 20px 0px rgba(8, 0, 42, 0.08);
		-moz-box-shadow: 0px 10px 20px 0px rgba(8, 0, 42, 0.08);
		box-shadow: 0px 10px 20px 0px rgba(8, 0, 42, 0.08);
	}
}
.home-page {
	.header {
		border-color: transparent;
	}
}
.header-nav {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
	border: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	position: relative;
	padding: 0 30px;
	margin-bottom: 0;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	box-shadow: 0px 0px 15px rgba(134, 229, 62, 0.7)
}
.main-nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
	& > li {
		&.active {
			& > a {
				color: #86E53E;
			}
		}
		& > a {
			position: relative;
			&:hover {
				color: #86E53E;
			}
			& > i {
				&.fa-search {
					font-size: 14px;
				}
			}
		}
		.submenu {
			li {
				position: relative;
				a {
					display: block;
					padding: 10px 15px;
					clear: both;
					white-space: nowrap;
					font-size: 15px;
					color: #2B2B2B;
					-webkit-transition: all .35s ease;
					transition: all .35s ease;
					width: 100%;
					border-top: 1px solid #f0f0f0;
				}
			}
			& > li {
				&.has-submenu {
					& > a {
						&::after {
							content: "\f054";
							font-family: "Font Awesome 5 Free";
							font-weight: 900;
							position: absolute;
							right: 15px;
							top: 12px;
							font-size: 13px;
						}
					}
				}
			}
		}
	}
	li {
		a {
			display: block;
			font-size: 15px;
			font-weight: 600;
		}
		&.login-link {
			display: none;
		}
	}
	.log-select {
		background-color: #fff;
		padding: 9px 7px !important;
		color: #cc2d78;
		border-radius: 30px;
		font-size: 12px;
		font-weight: 700;
		text-transform: uppercase;
		line-height: 85px;
		cursor: pointer;
		&.dropdown-toggle {
			&:after {
				color: #000;
			}
		}
		i {
			background-color: #cc2d78;
			padding: 5px 7px;
			border-radius: 50%;
			color: #fff;
		}
	}
	.log-menu {
		.dropdown-item {
			color: #161c2d !important;
			font-weight: 400;
		}
	}
}
.main-menu-wrapper {
	margin: 0;
	padding: 0;
}
.logo {
	display: inline-block;
	margin-right: 0;
}
.togglesearch {
	position: absolute;
	top: 55px;
	left: 0;
	width: 285px;
	display: none;
	z-index: 999;
	background: #fff;
	padding: 10px 10px;
	border-radius: 5px;
	box-shadow: 0 2px 8px rgb(183 183 183 / 25%);
	input[type="text"] {
		width: 182px;
		padding: 5px 10px;
		border: 1px solid #ff3115;
		outline: none;
		height: 39px;
		border-radius: 5px 0 0 5px;
	}
	button {
		background: #ff3115;
		color: #fff;
		border: 1px solid #ff3115;
		outline: none;
		cursor: pointer;
		border-radius: 0 5px 5px 0;
	}
}
.header-contact-img {
	display: inline-block;
	i {
		color: rgba(0, 0, 0, 0.5);
		font-size: 30px;
	}
}
.header-contact-detail {
	display: inline-block;
	padding-left: 10px;
	p {
		&.contact-header {
			color: #484848;
			font-size: 13px;
			font-weight: 400;
			margin-bottom: 2px;
			text-align: left;
		}
		&.contact-info-header {
			color: #000;
			font-weight: 500;
			font-size: 14px;
			margin-bottom: 0;
		}
	}
}
.header-navbar-rht {
	margin: 0;
	padding: 0;
	color: #fff;
	font-size: 15px;
	font-weight: 600;
	margin-right: 2px;
	li {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		font-size: 14px;
		margin-right: 25px;
		&.searchbar {
			width: 30px;
			height: 30px;
			background: #86E53E;
			border-radius: 50%;
			border: 1px solid #86E53E;
			position: relative;
			cursor: pointer;
		}
		a {
			color: #2E2E2E;
			font-weight: 600;
			font-size: 15px;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			img {
				padding-bottom: 1px;
			}
			i {
				margin-right: 5px;
			}
			&.header-login {
				border: 2px solid #cc2d78;
				border-radius: 4px;
				padding: 10px 15px !important;
				text-align: center;
				font-size: 15px;
				color: #cc2d78;
				text-transform: uppercase;
				font-weight: 500;
				&:hover {
					background-color: #cc2d78;
					border-color: #cc2d78;
					color: #fff;
				}
			}
		}
		&.lang-item {
			& > a {
				padding-left: 0;
				padding-right: 0;
			}
		}
		.dropdown-menu {
			padding: 0;
			margin: 0;
			border-radius: 5px;
			&:before {
				border: 7px solid #fff;
				border-color: transparent transparent #fff #fff;
				box-shadow: -2px 2px 2px -1px rgb(0 0 0 / 10%);
				content: "";
				left: 45px;
				position: absolute;
				top: 2px;
				-webkit-transform-origin: 0 0;
				transform-origin: 0 0;
				-webkit-transform: rotate(135deg);
				transform: rotate(135deg);
			}
		}
		&.account-item {
			.dropdown-menu {
				min-width: 280px;
				padding: 20px;
				box-shadow: 0px 2px 14px rgb(189 189 189 / 25%);
				border-radius: 0;
				border: 0;
				&:before {
					content: none;
				}
				&.emp {
					padding: 0;
					border: 1px solid #f0f0f0;
					box-shadow: unset;
				}
			}
		}
		&:last-child {
			margin-right: 0;
		}
	}
	.dropdown-item {
		color: #161c2d;
		padding: 6px 15px;
		font-size: 14px;
		border-bottom: 1px solid #f0f0f0;
		&:last-child {
			border-bottom: 0;
		}
		img {
			margin-right: 7px;
		}
	}
	.login-btn {
		background-color: #86E53E;
		border-color: #86E53E;
		border-radius: 50px;
		padding: 10px 39px;
		font-size: 16px;
		font-weight: bold;
		color: #fff;
		border-radius: 5px;
	}
	.account-item {
		.emp {
			.dropdown-item {
				padding: 10px 20px;
				display: flex;
				align-items: center;
				border-top: 1px solid #f0f0f0;
				&:hover {
					color: #86E53E;
				}
				i {
					font-size: 14px;
					margin-right: 5px;
				}
				&.bal {
					padding-left: 25px;
				}
				.amt {
					float: right;
					color: #86E53E;
				}
			}
		}
		.dropdown-item {
			color: #161c2d;
			padding: 6px 0;
			font-size: 14px;
			font-weight: normal;
			border-bottom: 0;
		}
	}
	.signout-btn {
		background-color: #86E53E;
		border-radius: 130px;
		color: #fff;
		padding: 11px 30px;
		font-size: 14px;
		margin-right: 8px;
		font-weight: 700;
		color: #fff;
		display: inline-block;
		text-transform: uppercase;
	}
}
.space-login {
	color: #2e2e2e;
	margin: 0px 5px;
}
.header-navbar-rht li a:hover, .header-navbar-rht li a:focus {
	color: #86E53E;
}
.header-navbar-rht .login-btn:hover, .header-navbar-rht .login-btn:focus {
	background: #13323C;
	border-color: #13323C;
	color: #fff;
}
.account-item {
	.dropdown-menu {
		h6 {
			font-size: 17px;
			color: #161c2d;
			font-weight: normal;
		}
	}
	.custom_radio {
		color: #8C635B;
		font-weight: normal;
	}
	.arrow-circle {
		background: #13323C;
		color: #FFFBFB;
		width: 31px;
		height: 31px;
		border-radius: 50%;
		align-items: center;
		text-align: center;
		line-height: 31px;
	}
}
.emp {
	.drop-head {
		background: #f3f3f3;
		padding: 10px 20px;
		font-weight: bold;
		font-size: 14px;
		border-top: 1px solid #f0f0f0;
	}
}
.account-item .dropdown-item:focus, .account-item .dropdown-item:hover {
	background-color: transparent;
}
.min-header {
	.header-navbar-rht {
		li {
			a {
				&.header-login {
					border-color: #fff;
					color: #fff;
					&:hover {
						background-color: #cc2d78;
						border-color: #cc2d78;
					}
				}
			}
		}
	}
}
.user-menu {
	float: right;
	margin: 0;
	position: relative;
	z-index: 99;
	&.nav {
		& > li {
			& > a {
				color: #fff;
				font-size: 14px;
				line-height: 58px;
				padding: 0 15px;
				height: 60px;
				&.mobile_btn {
					border: 0;
					position: relative;
					padding: 0;
					margin: 0;
					cursor: pointer;
				}
			}
		}
	}
}
.user-menu.nav > li > a:hover,
.user-menu.nav > li > a:focus {
	background-color: rgba(0, 0, 0, 0.2);
}
.user-menu.nav > li > a:hover i,
.user-menu.nav > li > a:focus i {
	color: #fff;
}
.user-img {
	display: inline-block;
	position: relative;
	margin-right: 5px;
	& > img {
		height: 46px;
		object-fit: cover;
		width: 46px;
		border-radius: 50%;
	}
}
.user-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 10px 15px;
	.user-text {
		margin-left: 10px;
		h6 {
			font-size: 15px;
			margin-bottom: 2px;
		}
	}
}
.course {
	margin-left: 10px;
	padding-top: 17px;
}
.language-select {
	cursor: pointer;
}
.dot-menu {
	.nav-link {
		color: #c4c4c4;
		padding: 2px;
	}
	.dropdown-menu {
		padding: 0;
		right: 0;
		left: auto !important;
	}
	.dropdown-toggle {
		&::after {
			content: none;
		}
	}
}
.dot-menu .dropdown-item:focus, .dot-menu .dropdown-item:hover {
	color: #86E53E;
	background-color: transparent;
}
.header-two {
	background: #fff;
	top: 0;
	.header-navbar-rht {
		li {
			a {
				&.reg-btn {
					background: #FFF5F1;
					border: 1px solid #EBEBEB;
					width: 55px;
					height: 55px;
					border-radius: 50px;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					justify-content: center;
					-webkit-justify-content: center;
					-ms-flex-pack: center;
				}
			}
		}
		.login-btn {
			padding: 12px 39px;
			border-radius: 5px;
		}
	}
}
.header-three {
	.header-navbar-rht {
		.login-btn {
			border-radius: 5px;
		}
		li {
			a {
				color: #fff;
				font-weight: 600;
				&.log-btn {
					&:hover {
						color: #86E53E;
					}
				}
			}
			span {
				color: #fff;
			}
		}
	}
	&.sticky {
		.header-navbar-rht {
			li {
				span {
					color: #2e2e2e;
				}
			}
		}
		.scroll-logo {
			display: none;
		}
		.original-logo {
			display: block;
		}
	}
	.main-nav {
		li {
			a {
				color: #FFF5F1;
			}
		}
	}
}
.header-three .main-nav > li.active > a,
.header-three.sticky .main-nav > li.active > a {
	color: #86E53E;
}
.header-three .main-nav li a:hover,
.header-three.sticky .main-nav li a:hover {
	color: #86E53E;
}
.original-logo {
	display: none;
}
.header-three.sticky .main-nav li a,
.header-three.sticky .header-navbar-rht li a.log-btn {
	color: #2e2e2e;
}
.trend-projects {
	padding: 80px 0px;
}
.section-header.section-header-three p,
.section-header.section-header-four p {
	color: #515151;
}
.header-four {
	background: #fff;
	top: 0;
	.header-navbar-rht {
		li {
			a {
				&.reg-btn {
					color: #101828;
					font-weight: 600;
					font-size: 15px;
				}
			}
		}
		.login-btn {
			border-radius: 5px;
		}
	}
}
.header-five {
	.header-navbar-rht {
		li {
			.reg-btn {
				font-size: 15px;
				color: #101828;
				justify-content: center;
			}
		}
		.sign-reg {
			background: #FFFFFF;
			padding: 11px 15px;
			border-radius: 5px;
			min-width: 198px;
		}
	}
	&.sticky {
		.header-navbar-rht {
			li {
				.reg-btn {
					box-shadow: 0px 4px 48px rgb(160 160 160 / 10%);
				}
			}
		}
		.main-nav {
			li {
				a {
					color: #2E2E2E;
				}
			}
		}
		.logo {
			&.scroll-logo {
				display: block;
			}
		}
	}
	.main-nav {
		li {
			a {
				color: #fff;
			}
		}
	}
}
.header-five .main-nav > li.active > a,
.header-five.sticky .main-nav > li.active > a {
	color: #86E53E;
}
.header-five .scroll-logo,
.header-five.sticky .logo {
	display: none;
}
.sidebar-overlay {
	display: none;
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
}
.menu-opened {
	.main-menu-wrapper {
		transform: translateX(0);
	}
}
.menu-header {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff4f0;
	height: 60px;
	padding: 0 20px;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	border-bottom: 1px solid #f7d0c6;
	display: none;
}
.menu-close {
	font-size: 18px;
	i {
		color: #f38244;
	}
}
.bar-icon {
	display: inline-block;
	width: 31px;
	span {
		background-color: #ee6c2f;
		display: block;
		float: left;
		height: 3px;
		margin-bottom: 7px;
		width: 31px;
		border-radius: 2px;
		&:nth-child(2) {
			width: 16px;
		}
		&:nth-child(3) {
			margin-bottom: 0;
		}
	}
}
#mobile_btn {
	display: none;
	margin-right: 30px;
}
html {
	&.menu-opened {
		body {
			overflow: hidden;
		}
	}
}
.social-icon {
	ul {
		display: inline-flex;
		display: -ms-inline-flexbox;
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			a {
				font-weight: 900;
				font-size: 15px;
				color: #86E53E;
				i {
					width: 25px;
					height: 25px;
					border-radius: 50px;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					justify-content: center;
					-webkit-justify-content: center;
					-ms-flex-pack: center;
					color: #fff;
				}
			}
			.fa-facebook-f {
				background: #337FFF;
				transition: all 0.5s ease;
				-moz-transition: all 0.5s ease;
				-o-transition: all 0.5s ease;
				-ms-transition: all 0.5s ease;
				-webkit-transition: all 0.5s ease;
			}
			.fa-instagram {
				background: linear-gradient(315deg, #FBE18A 0.96%, #FCBB45 21.96%, #F75274 38.96%, #D53692 52.96%, #8F39CE 74.96%, #5B4FE9 100.96%);
				transition: all 0.5s ease;
				-moz-transition: all 0.5s ease;
				-o-transition: all 0.5s ease;
				-ms-transition: all 0.5s ease;
				-webkit-transition: all 0.5s ease;
			}
			.fa-twitter {
				background: #33CCFF;
				transition: all 0.5s ease;
				-moz-transition: all 0.5s ease;
				-o-transition: all 0.5s ease;
				-ms-transition: all 0.5s ease;
				-webkit-transition: all 0.5s ease;
			}
			& + li {
				margin-left: 10px;
			}
			.icon {
				-webkit-transition: all .3s;
				-o-transition: all .3s;
				transition: all .3s;
			}
		}
	}
	span {
		color: #000000;
		font-size: 14px;
		font-weight: 500;
		margin-right: 10px;
	}
}
html.menu-opened body:before,
html.menu-opened body header:before {
	content: "";
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.6);
	z-index: 99;
}
.home-banner {
	background-image: url(../img/banner2.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	padding-top: 84px;
}
.banner-img {
	float: right;
	padding: 40px 0;
}
.banner-content {
	.rating {
		.checked {
			color: #86E53E;
		}
		h5 {
			padding-left: 15px;
		}
	}
	h5 {
		font-weight: 500;
		font-size: 14px;
		color: #000;
		text-transform: uppercase;
	}
	h1 {
		font-weight: bold;
		font-size: 48px;
		color: #515151;
		margin-bottom: 20px;
		.orange-text {
			font-weight: 700;
			color: #86E53E;
		}
	}
	p {
		font-size: 20px;
		color: #2B2B2B;
		font-weight: 600;
	}
	.form-inner {
		padding: 2px;
		background-color: #fff;
		border-radius: 130px;
		width: 100%;
		box-shadow: 0px 4px 4px rgba(207, 207, 207, 0.25);
	}
	.input-group {
		background: #fff;
		border-radius: 130px;
		.form-control {
			&::placeholder {
				color: #353535;
			}
		}
		.input-group-prepend {
			margin-right: 0;
		}
	}
	.form-control {
		background: #fff;
		border-radius: 40px;
		border: 0;
		height: 70px;
		color: #353535;
		font-size: 16px;
		font-weight: 500;
	}
	.select2-container {
		border-right: 1px solid #CFCFCF;
		width: 99% !important;
		z-index: unset;
		.select2-selection--single {
			border: 0;
			align-items: center;
			display: flex;
			background: transparent;
			height: 30px;
			.select2-selection__rendered {
				font-size: 16px;
				padding: 0 25px;
			}
		}
	}
	.select2-container--default {
		.select2-selection--single {
			.select2-selection__arrow {
				top: auto;
			}
		}
	}
	.sub-btn {
		background: #86E53E;
		border-radius: 50px !important;
		font-weight: bold;
		border: 1px solid #86E53E;
		height: 52px;
		margin: 9px;
		font-size: 16px;
		line-height: 18px;
		padding: 10px 30px;
		text-transform: capitalize;
		color: #fff;
		min-width: 172px;
		transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
	}
}
.drop-detail {
	width: 165px;
	display: flex;
	align-items: center;
}
.banner-content .sub-btn:hover,
.banner-content .sub-btn:focus {
	background: #13323C;
	border-color: #13323C;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
}
.btn-item {
	margin-top: 25px;
}
.get-btn {
	background: #86E53E;
	border-radius: 130px;
	border-color: #86E53E;
	font-weight: bold;
	padding: 14px 42px;
	color: #fff;
	margin-right: 10px;
	text-transform: uppercase;
	font-size: 14px;
	&:hover {
		color: #fff;
		background-color: #fb451d;
		border-color: #fb451d;
	}
}
.courses-btn {
	background: #13323C;
	border-radius: 130px;
	font-weight: bold;
	padding: 12px 39px;
	color: #fff;
	text-transform: uppercase;
	font-size: 15px;
	&:hover {
		color: #fff;
		background-color: #86E53E;
		border-color: #86E53E;
	}
}
.feature {
	position: relative;
	padding-bottom: 20px;
	background-image: url(../../assets/img/bg/bg-07.png);
	background-repeat: no-repeat;
	background-position: left bottom;
	background-size: 20%;
	padding-top: 50px;
	.feature-icon {
		margin-bottom: 25px;
		position: relative;
		min-height: 78px;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 50%;
			-webkit-transform: translateX(-50%);
			-ms-transform: translateX(-50%);
			transform: translateX(-50%);
			width: 108px;
			height: 70px;
			background-image: url(../img/icon/icon-bg.png);
		}
		img {
			position: relative;
		}
	}
	.feature-content {
		h3 {
			color: #101828;
			font-weight: bold;
			margin-bottom: 10px;
		}
		p {
			font-size: 18px;
			text-transform: capitalize;
			color: #5F5F5F;
			margin-bottom: 0;
			font-weight: 500;
		}
	}
}
.feature-item {
	position: relative;
	padding: 30px;
	margin-bottom: 30px;
	background: #fff;
	box-shadow: 0px 4px 48px rgba(160, 160, 160, 0.1);
	border-radius: 6px;
	text-align: center;
	overflow: hidden;
	border: 1px solid #fff;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	&:hover {
		border: 1px solid #86E53E;
		transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
	}
}
.work {
	position: relative;
	&:before {
		content: "";
		position: absolute;
		width: 108px;
		height: 128px;
		z-index: 9;
		bottom: 60px;
		left: 0;
		transform: translate(-50%, 0);
		background: url(../../assets/img/bg/bg-08.png) top center no-repeat;
	}
	&:after {
		content: "";
		position: absolute;
		width: 108px;
		height: 128px;
		z-index: 9;
		top: 60px;
		right: 0;
		transform: translate(50%, 0);
		background: url(../../assets/img/bg/bg-14.png) top center no-repeat;
	}
}
.work-box {
	padding: 0;
	overflow: hidden;
}
.bg1 {
	background: #86E53E;
	background-image: url(../../assets/img/bg/project-bg.png);
	background-repeat: no-repeat;
	background-position: right bottom;
	background-size: 270px;
	&:before {
		content: "";
		position: absolute;
		width: 28px;
		top: 50px;
		left: 80px;
		height: 28px;
		border-radius: 50%;
		background-color: #fff;
	}
}
.bg2 {
	background: #FFF5F3;
	background-image: url(../../assets/img/bg/work-bg.png);
	background-repeat: no-repeat;
	background-position: right bottom;
	background-size: 270px;
	.work-content {
		h2 {
			color: #161c2d;
			&:after {
				background: #86E53E;
			}
		}
		p {
			color: #161c2d;
		}
		.long-arrow {
			background: #13323C;
			color: #FFFBFB;
		}
	}
}
.work-content {
	width: 84%;
	padding: 70px 50px 50px 120px;
	h2 {
		font-weight: bold;
		color: #fff;
		padding-bottom: 20px;
		position: relative;
		margin-bottom: 20px;
		span {
			display: block;
		}
		&:after {
			content: "";
			width: 48px;
			height: 3px;
			background: #fff;
			left: 0px;
			right: 0;
			bottom: 0;
			position: absolute;
			border-radius: 170px;
		}
	}
	p {
		font-weight: 500;
		font-size: 18px;
		color: #fff;
		margin-bottom: 25px;
	}
	.long-arrow {
		width: 37px;
		height: 37px;
		background: #FFFAF9;
		color: #86E53E;
		border-radius: 50%;
		line-height: 37px;
		text-align: center;
		font-size: 20px;
		transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
	}
}
.want-works {
	.work-content {
		width: 74%;
		padding: 70px 50px;
	}
}
.work-content .long-arrow:hover,
.work-content .long-arrow:focus {
	background: #13323C;
	color: #fff;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
}
.bg2 .work-content .long-arrow:hover, .bg2 .work-content .long-arrow:focus {
	background: #fb451d;
}
.services {
	position: relative;
	padding: 80px 0;
	background: #FFFBFB;
	background-image: url(../../assets/img/bg/bg-01.png), url(../../assets/img/bg/bg-02.png);
	background-repeat: no-repeat, no-repeat;
	background-position: right bottom, left top;
	background-size: 270px, 56%;
}
.service-item {
	background: #fff;
	border: 1px solid #ececec;
	text-align: center;
	border-radius: 2px;
	padding: 20px;
	margin-bottom: 30px;
	transition: ease-in-out .15s;
	-moz-transition: ease-in-out .15s;
	-o-transition: ease-in-out .15s;
	-webkit-transition: ease-in-out .15s;
	cursor: pointer;
}
.service-item:hover, .service-item:focus {
	background: #FFF0F0;
	border-color: #FFF0F0;
}
.select-item {
	&.selected {
		box-shadow: 0px 8px 16px 0px #EEEEEE;
		-webkit-filter: grayscale(0%);
		-moz-filter: grayscale(0%);
		-o-filter: grayscale(0%);
		-ms-filter: grayscale(0%);
		filter: grayscale(0%);
	}
}
.selected {
	background: #86E53E;
	.service-content {
		h5 {
			color: #fff;
		}
	}
}
.service-icon {
	margin-bottom: 20px;
	line-height: 77px;
}
.service-content {
	h5 {
		font-weight: bold;
		font-size: 18px;
		text-transform: uppercase;
		margin-bottom: 0;
	}
}
.services-item {
	position: relative;
	border-radius: 20px;
	overflow: hidden;
	margin-bottom: 15px;
	img {
		transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	}
	.content-text {
		position: absolute;
		transition: all .3s ease-in-out;
		text-align: center;
		bottom: 10%;
		width: 100%;
	}
	.content-part {
		h3 {
			color: #fff;
			text-transform: uppercase;
			font-weight: bold;
			font-size: 18px;
		}
		h4 {
			font-size: 17px;
			font-weight: 600;
			color: #fff;
		}
	}
	.text {
		text-align: center;
		position: absolute;
		top: 70%;
	}
	&:hover {
		img {
			-webkit-transform: scale(1.15);
			-moz-transform: scale(1.15);
			transform: scale(1.15);
			transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
		}
	}
}
.section-header {
	margin-bottom: 50px;
	h5 {
		font-size: 21px;
		color: #86E53E;
		font-weight: 700;
		position: relative;
		margin-bottom: 0;
		color: #2E2E2E;
		font-size: 18px;
		font-weight: 500;
	}
	p {
		font-weight: 500;
		font-size: 18px;
		color: #86E53E;
		margin-bottom: 0;
	}
	h6 {
		font-size: 20px;
		color: #2E2E2E;
		font-weight: 600;
	}
}
.section-line {
	width: 35px;
	height: 8px;
	background: #86E53E;
	border-radius: 5px;
	margin: auto;
	position: relative;
	margin-bottom: 20px;
}
.header-title {
	color: #2E2E2E;
	font-weight: bold;
	position: relative;
	margin-bottom: 15px;
	font-size: 24px;
	span {
		color: #86E53E;
	}
}
.see-all {
	margin-top: 20px;
}
.all-btn {
	padding: 12px 40px;
	background: #86E53E;
	border: 3px solid #86E53E;
	border-radius: 50px;
	color: #FFF5F3;
	font-weight: bold;
	font-size: 16px;
	&:hover {
		background: #13323C;
		border: 3px solid #13323C;
		color: #fff;
		transition: all 0.3s ease;
		.right-arrow {
			color: #115241;
		}
	}
}
.right-arrow {
	height: 20px;
	width: 20px;
	background: #fff;
	color: #86E53E;
	text-align: center;
	border-radius: 50%;
	line-height: 20px;
	padding-left: 2px;
	font-size: 14px;
	margin-left: 10px;
}
.projects {
	position: relative;
	padding: 80px 0;
	background: #fbfbfb;
}
.project-item {
	background: #fff;
	margin-bottom: 25px;
	width: 100%;
	border: 1px solid #EBEBEB;
	box-shadow: 0px 4px 48px rgba(160, 160, 160, 0.1);
	border-radius: 6px;
	&:hover {
		.project-icon {
			border-color: #FFCCC1;
		}
	}
	&.feature-project-item {
		background: #FFFFFF;
		box-shadow: 0px 4px 4px rgba(232, 232, 232, 0.25);
		border: 0;
		padding: 10px;
		transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
		.project-img {
			padding: 0;
		}
		&:hover {
			transition: all 0.5s ease;
			-moz-transition: all 0.5s ease;
			-o-transition: all 0.5s ease;
			-ms-transition: all 0.5s ease;
			-webkit-transition: all 0.5s ease;
			box-shadow: 0px 1px 4px rgb(0 0 0 / 20%);
		}
	}
	&.trend-project-item {
		padding: 15px;
		border: 1px solid #EBEBEB;
		border-radius: 5px;
		box-shadow: none;
	}
	&.project-item-feature {
		background: #FFFFFF;
		border: 1px solid #EBEBEB;
		box-shadow: 0px 4px 34px rgba(233, 233, 233, 0.25);
		border-radius: 10px;
		padding: 10px;
	}
	&.project-popular-item {
		background: #FFFFFF;
		border: 1px solid #FFF5F1;
		box-shadow: 0px 4px 44px rgba(220, 220, 220, 0.25);
		border-radius: 10px;
		padding: 10px;
		transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
		&:hover {
			transition: all 0.5s ease;
			-moz-transition: all 0.5s ease;
			-o-transition: all 0.5s ease;
			-ms-transition: all 0.5s ease;
			-webkit-transition: all 0.5s ease;
			box-shadow: 0px 1px 4px rgb(0 0 0 / 20%);
		}
		.project-img {
			padding: 0;
		}
	}
	.feature-foot {
		p {
			color: #5F5F5F;
			font-weight: 500;
			margin-bottom: 0;
			font-size: 15px;
			span {
				color: #86E53E;
				font-weight: 700;
			}
		}
	}
}
.project-img {
	overflow: hidden;
	padding: 3px 3px 0 3px;
	border-radius: 6px;
	img {
		-webkit-transform: translateZ(0);
		-moz-transform: translateZ(0);
		transform: translateZ(0);
		-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
		-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
		-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
		-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
		transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
		width: 100%;
		border-radius: 6px;
	}
	&:hover {
		img {
			-webkit-transform: scale(1.15);
			-moz-transform: scale(1.15);
			transform: scale(1.15);
		}
		.hour-dollr {
			img {
				transform: unset;
			}
		}
	}
}
.project-icon {
	position: relative;
	width: 5px;
	height: 48px;
	border-radius: 50%;
	background: transparent;
	border: 0;
	margin: 15px 0;
	display: inline-flex;
	justify-content: center;
	align-items: center;
}
.pro-icon {
	position: relative;
	&:before {
		content: "";
		width: 1px;
		height: 100%;
		background: #ECECEC;
		margin: 0 auto;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		position: absolute;
	}
}
.project-content {
	margin: 0 auto;
	text-align: center;
	width: 42%;
	h4 {
		font-weight: 600;
		font-size: 16px;
		line-height: 30px;
		text-transform: uppercase;
		color: #86E53E;
		margin-bottom: 0;
	}
	h5 {
		font-weight: 500;
		font-size: 14px;
		line-height: 21px;
		margin-bottom: 0;
		a {
			color: #353535;
			&:hover {
				color: #86E53E;
			}
		}
	}
}
.developer {
	position: relative;
	padding: 40px 0 50px;
	// background-image: url(../../assets/img/bg/bg-15.png);
	background-repeat: no-repeat, no-repeat;
	background-position: right center;
}
.dev-item {
	position: relative;
}
.dev-img, .dev-top {
	position: relative;
}
.dev-text {
	position: absolute;
	top: 20px;
	text-align: center;
	right: 20px;
	.right {
		padding: 10px 15px;
		color: #86E53E;
		font-weight: bold;
		background: #FFFBFB;
		border-radius: 50px;
		font-size: 14px;
		display: inline-block;
	}
}
.dev-user {
	position: absolute;
	left: 20px;
	top: 20px;
	display: flex;
	display: -webkit-box;
	display: -ms-flexbox;
	img {
		margin: auto;
		width: 40px;
	}
}
.dev-content {
	background: #fff;
	border: 1px solid #FFEEEA;
	width: 100%;
	text-align: center;
	padding: 20px;
	.rating {
		margin-bottom: 10px;
		i {
			margin-bottom: 10px;
			font-size: 13px;
		}
	}
	h3 {
		font-weight: 600;
		font-size: 20px;
		margin-bottom: 12px;
	}
	h4 {
		font-weight: 500;
		font-size: 15px;
		color: #86E53E;
		margin-bottom: 14px;
	}
	p {
		font-weight: 600;
		font-size: 16px;
		color: #6e727d;
		margin-bottom: 0;
		text-transform: uppercase;
	}
}
.btn-view {
	color: #fff;
	background-color: #86E53E;
	padding: 15px 22px;
	font-size: 18px;
	font-weight: bold;
	width: 100%;
	border-radius: 0px 0px 15px 15px;
	&:hover {
		color: #fff;
		background-color: #fb451d;
		border-color: #fb451d;
	}
}
.developer-slider {
	.slick-slide {
		display: block;
		margin-left: 0;
		padding: 0 10px;
	}
}
.rating {
	.checked {
		color: #ff9415;
	}
	.non-checked {
		color: #C4C4C4;
	}
	i {
		color: #5F5F5F;
		font-size: 12px;
		&.filled {
			color: #86E53E;
		}
	}
	.average-rating {
		font-size: 14px;
		color: #6e727d;
		font-weight: 500;
	}
	&.red {
		i {
			&.filled {
				color: #febe42;
			}
		}
	}
}
.test-quote-img {
	margin-left: auto;
}
.right-text {
	text-align: right;
}
.review {
	background: #FFF5F1;
	padding: 80px 0;
	background-image: url(../../assets/img/bg/bg-16.png);
	background-repeat: no-repeat, no-repeat;
	background-position: left center;
	&.review-two {
		background-image: none;
	}
}
.review-blog {
	background-color: #fff;
	padding: 20px;
	position: relative;
	box-shadow: 0px 4px 48px rgba(160, 160, 160, 0.1);
	border-radius: 6px;
	.review-content {
		padding: 0;
		border-bottom: 0;
		margin-bottom: 0px;
		p {
			font-size: 15px;
			margin: 0;
			line-height: 26px;
			font-weight: 500;
		}
	}
	.review-img {
		img {
			width: 75px;
			border-radius: 50%;
			margin-right: 20px;
		}
	}
	.review-top {
		margin-bottom: 20px;
	}
	.rating {
	}
	&.user-review {
		border: 1px solid #EBEBEB;
		box-shadow: 20px 24px 34px rgba(225, 225, 225, 0.25);
		border-radius: 10px;
		.review-img {
			margin-bottom: 15px;
			img {
				margin: auto;
			}
		}
	}
}
.owl-carousel {
	.owl-item {
		.review-img {
			img {
				width: 96px;
			}
		}
		.company-logos {
			img {
				width: auto;
				margin: auto;
			}
		}
		.trend-project-list {
			img {
				display: flex;
				width: inherit;
			}
		}
		img {
			display: block;
			width: inherit;
		}
		.reviw-profile-img {
			img {
				width: 48px;
				height: 48px;
				border-radius: 50px;
			}
		}
	}
	&.trend-slider {
		.owl-nav {
			position: absolute;
			margin: 0;
			right: 0;
			top: -97px;
			min-width: 90px;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			justify-content: space-between;
			-webkit-justify-content: space-between;
			-ms-flex-pack: space-between;
		}
		.owl-dots {
			position: absolute;
			margin: 0;
			right: 0;
			min-width: 90px;
			top: -94px;
			span {
				margin: 0px 2px;
				width: 7px;
				height: 7px;
			}
		}
	}
	.hour-dollr {
		img {
			width: auto;
		}
	}
}
.trend-slider {
	.owl-nav {
		position: absolute;
		margin: 0;
		right: 0;
		top: -97px;
		min-width: 90px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: space-between;
		-webkit-justify-content: space-between;
		-ms-flex-pack: space-between;
		.owl-prev, .owl-next {
			background: 0 0;
			color: inherit;
			border: none;
			padding: 0!important;
			font: inherit;
		}
	}
	.owl-theme {
		.owl-dots {
			position: absolute;
			margin: 0;
			right: 0;
			min-width: 90px;
			top: -94px;
			span {
				margin: 0px 2px;
				width: 7px;
				height: 7px;
			}
		}
	}
}
.review-info {
	h3 {
		font-size: 20px;
		font-weight: 600;
		color: #000000;
	}
	h5 {
		font-size: 14px;
		color: #5F5F5F;
		margin-bottom: 6px;
		font-weight: 500;
	}
	.rating {
		i {
			font-size: 12px;
			&.filled {
				color: #86E53E;
			}
		}
		.average-rating {
			font-size: 14px;
			color: #5F5F5F;
		}
	}
}
.review-bottom {
	margin-top: 60px;
	.review-img {
		img {
			width: 50px;
			height: 50px;
			border-radius: 50%;
			margin-right: 15px;
		}
	}
	.review-info {
		h3 {
			font-size: 18px;
			font-weight: bold;
			color: #161c2d;
			margin-bottom: 3px;
		}
	}
	p {
		color: #5F5F5F;
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 0;
	}
}
.review-icon {
	img {
		max-width: 50px;
	}
}
.review-slider {
	.slick-slide {
		display: block;
		margin-left: 0;
		padding: 0 10px;
		width: 280px;
	}
}
.app-version {
	padding: 80px 0;
	background-image: url(../../assets/img/bg/bg-17.png);
	background-repeat: no-repeat, no-repeat;
	background-position: right bottom;
	background-size: cover;
}
.app-version-blk {
	h2 {
		color: #2e2e2e;
		margin-bottom: 20px;
	}
	p {
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 25px;
	}
	h5 {
		color: #000;
		font-size: 14px;
		font-weight: 500;
		margin-bottom: 0;
	}
}
.top-company {
	padding: 80px 0;
	border-bottom: 1px solid #EBEBEB;
}
.news {
	padding: 80px 0px;
	.see-all {
		margin-top: 20px;
		text-align: center;
	}
}
.news-content {
	background: #fff;
	border-radius: 0px 0px 10px 10px;
	padding: 20px;
	h2 {
		font-size: 24px;
		color: #161c2d;
	}
	p {
		font-size: 16px;
		color: #161c2d;
	}
	.entry-meta {
		background: #FFFBFB;
		display: inline-block;
		padding: 5px 10px 5px 0;
		border-radius: 0 25px 25px 0;
		color: #757575;
		list-style: none;
		margin-bottom: 15px;
		li {
			display: inline-block;
			margin-right: 15px;
			font-size: 15px;
			font-weight: 500;
			color: #6e727d;
			&.name {
				i {
					font-size: 25px;
				}
				img {
					width: 40px;
					height: 40px;
					border-radius: 100%;
				}
			}
			&.cal {
				i {
					color: #86E53E;
				}
			}
		}
	}
	a {
		&.read {
			font-weight: 600;
			font-size: 14px;
			color: #86E53E;
		}
	}
}
.clients {
	padding-top: 50px;
}
.client-img {
	text-align: center;
}
.job-counter {
	padding: 60px 0;
	background: #86E53E;
}
.counter {
	h2 {
		font-weight: 600;
		font-size: 36px;
		color: #fff;
	}
	h4 {
		font-weight: 500;
		font-size: 20px;
		color: #fff;
	}
}
.about {
	padding: 80px 0;
}
.about-content {
	h2 {
		font-weight: 500;
		font-size: 28px;
		color: #161c2d;
		margin-bottom: 17px;
		font-weight: 600;
	}
	p {
		font-size: 15px;
		margin-bottom: 20px;
		line-height: 30px;
		font-weight: 500;
	}
}
.orange-text {
	color: #86E53E;
}
.learn-btn {
	background: #13323C;
	border-radius: 150px;
	font-weight: 600;
	font-size: 14px;
	padding: 8px 32px;
	color: #fff;
	&:hover {
		color: #fff;
		background: #86E53E;
	}
}
.expert {
	padding: 80px 0;
	text-align: center;
	background-image: url(../img/bg/about-bg.png);
	background-size: cover;
	h5 {
		font-weight: 600;
		font-size: 16px;
		color: #86E53E;
	}
	h2 {
		font-size: 30px;
		color: #161c2d;
		margin-bottom: 25px;
	}
	p {
		font-size: 16px;
		color: #161c2d;
	}
	.btn-item {
		margin-top: 50px;
	}
}
.agency {
	position: relative;
}
.agency-box {
	padding: 0;
	overflow: hidden;
}
.black-bg {
	background: #13323C;
}
.agency-content {
	padding: 0 100px;
	h2 {
		font-weight: 600;
		font-size: 28px;
		color: #fff;
		position: relative;
		margin-bottom: 25px;
		line-height: 36px;
	}
	p {
		font-size: 16px;
		color: #fff;
		margin-bottom: 25px;
	}
	.learn-btn {
		background: #86E53E;
		&:hover {
			color: #fff;
			background: #fb451d;
		}
	}
}
.about-img {
	img {
		position: relative;
		&:before {
			content: "";
			position: absolute;
			top: 100%;
			left: -5px;
			right: -5px;
			bottom: -5px;
			background-color: rgba(0,0,0,.2);
		}
	}
}
.job-register {
	background: #161515;
	padding: 50px 0px;
}
.register-job-blk {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.job-content-blk {
	h1 {
		color: #fff;
	}
	p {
		color: #FFF5F1;
		font-size: 18px;
		font-weight: 500;
		margin-bottom: 0;
	}
}
.home-two {
	background-image: url(../img/banner3.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	padding-top: 100px;
	.banner-img {
		padding: 24px 0;
	}
	.banner-content {
		h1 {
			margin-bottom: 40px;
		}
	}
}
.banner-dot {
	span {
		width: 11px;
		height: 11px;
		border-radius: 50px;
		display: block;
		position: absolute;
	}
}
.blue-dot-banner {
	background: #337FFF;
	top: 0;
	right: 0;
}
.red-dot-banner {
	background: #86E53E;
	bottom: 100px;
	left: -40px;
}
.banner-top-bottom {
	position: absolute;
	bottom: -25px;
	left: 22%;
	z-index: 9;
	a {
		background: #FFFFFF;
		box-shadow: 0px 4px 14px rgba(217, 217, 217, 0.25);
		width: 55px;
		height: 55px;
		border-radius: 50px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		color: #86E53E;
	}
}
.market-place {
	h3 {
		color: #86E53E;
	}
}
.home-count {
	margin-top: 45px;
	ul {
		li {
			color: #101828;
			font-size: 24px;
			font-weight: 700;
			.list-count {
				color: #5F5F5F;
				font-size: 18px;
				font-weight: 500;
				display: block;
				margin-bottom: 0;
			}
			& + li {
				border-left: 1px solid #EBEBEB;
				padding-left: 20px;
				margin-left: 20px;
			}
		}
	}
}
.section-header-two {
	.header-title {
		margin-bottom: 10px;
	}
	p {
		color: #515151;
	}
}
.update-project {
	position: relative;
	padding-bottom: 80px;
	background-image: url(../../assets/img/bg/bg-19.png);
	background-repeat: no-repeat;
	background-position: right center;
	background-size: 30%;
	padding-top: 80px;
}
.update-project-blk {
	background: #FFFFFF;
	box-shadow: 20px 4px 34px rgba(181, 181, 181, 0.15);
	border-radius: 10px;
	padding: 25px;
	margin-bottom: 25px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	&:hover {
		.update-content {
			h6 {
				color: #86E53E;
				transition: all 0.5s ease;
				-moz-transition: all 0.5s ease;
				-o-transition: all 0.5s ease;
				-ms-transition: all 0.5s ease;
				-webkit-transition: all 0.5s ease;
			}
		}
	}
}
.update-content {
	h6 {
		color: #2E2E2E;
		font-weight: 600;
		margin-bottom: 10px;
		transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
	}
	p {
		color: #5F5F5F;
		font-weight: 500;
		margin-bottom: 0;
		font-size: 14px;
	}
}
.platform-group {
	h1 {
		color: #2E2E2E;
		margin-bottom: 25px;
		span {
			color: #86E53E;
		}
	}
	h5 {
		color: #515151;
		margin-bottom: 15px;
		font-weight: 500;
	}
	p {
		color: #000;
		font-size: 14px;
		margin-bottom: 20px;
	}
}
.platform {
	padding: 80px 0px;
	background: linear-gradient(270.05deg, #F8ECF8 -28.55%, #FFF3EA 112.03%);
	position: relative;
	z-index: 1;
	&:before {
		content: "";
		background-image: url(../../assets/img/bg/bg-20.png);
		background-repeat: no-repeat, no-repeat;
		background-position: left top;
		position: absolute;
		width: 220px;
		height: 390px;
		left: 0;
		top: 0;
		z-index: -1;
	}
}
.plat-form-img {
	img {
		width: 100%;
	}
}
.more-project {
	margin-top: 25px;
	.btn-primary {
		min-width: 200px;
		padding: 10px 15px;
		font-size: 16px;
		font-weight: 600;
		transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
		&:hover {
			background-color: #13323C;
			border: 1px solid #13323C;
			transition: all 0.5s ease;
			-moz-transition: all 0.5s ease;
			-o-transition: all 0.5s ease;
			-ms-transition: all 0.5s ease;
			-webkit-transition: all 0.5s ease;
		}
	}
	.start-bid {
		background: #101828;
		border: 1px solid #101828;
		&:hover {
			background: #86E53E;
			border: 1px solid #86E53E;
		}
	}
	.all-article {
		min-width: 177px;
	}
}
.feature-project {
	padding: 80px 0px;
	background-image: url(../../assets/img/bg/bg-22.png);
	background-repeat: no-repeat, no-repeat;
	background-position: right center;
	&:before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		background-image: url(../../assets/img/bg/bg-frame.png);
		background-repeat: no-repeat, no-repeat;
	}
}
.feature-content {
	margin-top: 15px;
	h4 {
		margin-bottom: 15px;
		font-size: 18px;
		a {
			color: #353535;
			&:hover {
				color: #86E53E;
			}
		}
	}
}
.feature-time-blk {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	margin-bottom: 15px;
	.btn-primary {
		min-width: 88px;
		padding: 2px 10px;
		font-weight: 500;
		font-size: 14px;
		color: #FFF5F1;
	}
	.green-active {
		background: #2DA646;
		border: 1px solid #2DA646;
		&:hover {
			background: #1b8b32;
			border: 1px solid #1b8b32;
		}
	}
	.sky-active {
		background: #8F39CE;
		border: 1px solid #8F39CE;
		&:hover {
			background: #792faf;
			border: 1px solid #792faf;
		}
	}
	span {
		font-weight: 500;
		font-size: 14px;
		i {
			font-size: 12px;
		}
	}
}
.feature-project-list {
	padding-bottom: 15px;
	margin-bottom: 10px;
	border-bottom: 1px solid #EBEBEB;
	li {
		font-weight: 500;
		font-size: 15px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		& + li {
			margin: auto 0 0 auto;
		}
	}
}
.feature-foot {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.logo-company {
	span {
		color: #5F5F5F;
		font-weight: 500;
		font-size: 14px;
	}
}
.bid-now {
	color: #86E53E;
	font-size: 15px;
	font-weight: 700;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	&:hover {
		color: #13323C;
	}
}
.top-skill {
	padding: 80px 0px;
	background: #FBFBFB;
}
.skill-head .nav-pills .nav-link.active,
.skill-head .nav-pills .show>.nav-link {
	background-color: transparent;
	color: #101828;
}
.skill-head {
	.nav-pills {
		.nav-link {
			font-size: 24px;
			color: #EBEBEB;
			transition: all 0.5s ease;
			-moz-transition: all 0.5s ease;
			-o-transition: all 0.5s ease;
			-ms-transition: all 0.5s ease;
			-webkit-transition: all 0.5s ease;
			&:hover {
				color: #101828;
				transition: all 0.5s ease;
				-moz-transition: all 0.5s ease;
				-o-transition: all 0.5s ease;
				-ms-transition: all 0.5s ease;
				-webkit-transition: all 0.5s ease;
			}
		}
	}
	.nav {
		display: block;
	}
	&.skill-head-two {
		.nav {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			justify-content: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			margin-bottom: 55px;
		}
		.nav-pills {
			.nav-link {
				padding: 0 15px;
			}
		}
	}
}
.skill-list-out {
	ul {
		li {
			list-style: none;
			margin-bottom: 15px;
			a {
				color: #101828;
				font-size: 14px;
				font-weight: 500;
				&:hover {
					color: #86E53E;
				}
			}
		}
	}
}
.feature-develop-blk {
	border: 1px solid #EBEBEB;
	box-shadow: 20px 24px 34px rgba(225, 225, 225, 0.25);
	border-radius: 10px;
	margin-bottom: 25px;
	background: #FFFFFF;
}
.developer-sub-blk {
	padding: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.feature-developer {
	padding: 80px 0px;
	position: relative;
	background-image: url(../../assets/img/bg/bg-19.png);
	background-repeat: no-repeat;
	background-position: right center;
	background-size: 30%;
}
.img-developer {
	img {
		border-radius: 10px;
	}
}
.developer-content {
	h4 {
		font-size: 16px;
		margin-bottom: 5px;
		color: #353535;
	}
	p {
		font-size: 14px;
		color: #515151;
		font-weight: 500;
		margin-bottom: 4px;
	}
	.rating {
		.average-rating {
			color: #5f5f5f;
		}
	}
}
.verify {
	background-color: #fff;
	color: #159C5B;
	z-index: 2;
	width: 16px;
	height: 16px;
}
.hour-rate {
	ul {
		padding: 10px;
		border-top: 1px solid #EBEBEB;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: space-between;
		-webkit-justify-content: space-between;
		-ms-flex-pack: space-between;
		li {
			color: #101828;
			font-weight: 500;
			font-size: 13px;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
		}
	}
	.feather-map-pin {
		color: #9D9D9D;
		font-size: 16px;
	}
}
.client-rate {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	margin-bottom: 10px;
	h4 {
		color: #2e2e2e;
		margin-bottom: 0;
		margin-right: 15px;
	}
}
.great-about {
	padding: 80px 0px 460px;
	background: linear-gradient(270.05deg, #F8ECF8 -28.55%, #FFF3EA 112.03%);
	z-index: 1;
	position: relative;
	&:before {
		content: "";
		background-image: url(../../assets/img/bg/bg-21.png);
		background-repeat: no-repeat, no-repeat;
		background-position: right top;
		position: absolute;
		width: 220px;
		height: 390px;
		right: 0;
		top: 0;
		z-index: -1;
	}
}
.great-blk {
	background: #FFFFFF;
	border: 1px solid #EBEBEB;
	border-radius: 9px;
	padding: 30px;
	margin-bottom: 25px;
}
.great-icon {
	background: linear-gradient(270.05deg, #F8ECF8 -28.55%, #FFF3EA 112.03%);
	width: 71px;
	height: 71px;
	margin-bottom: 15px;
	border-radius: 50px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.great-content {
	h4 {
		color: #000000;
		margin-bottom: 15px;
	}
	p {
		color: #515151;
		font-size: 14px;
		margin-bottom: 0;
	}
}
.about-it-blk {
	background: #FFFFFF;
	border: 1px solid #EBEBEB;
	box-shadow: 0px 4px 24px rgba(224, 224, 224, 0.25);
	border-radius: 25px;
	padding: 20px;
}
.about-it-content {
	margin-top: 20px;
	h4 {
		color: #2E2E2E;
		font-weight: 700;
		margin-bottom: 15px;
	}
	p {
		color: #515151;
		font-weight: 500;
		font-size: 16px;
		margin-bottom: 20px;
	}
}
.location-img {
	overflow: hidden;
	border-radius: 10px;
	img {
		border-radius: 10px;
	}
}
.about-it-img {
	border-radius: 20px;
	overflow: hidden;
	img {
		border-radius: 20px;
	}
}
.about-it-img img,
.location-img img {
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	transform: translateZ(0);
	-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	width: 100%;
}
.about-it-img:hover img,
.location-img:hover img {
	-webkit-transform: scale(1.15);
	-moz-transform: scale(1.15);
	transform: scale(1.15);
}
.about-project {
	min-height: 270px;
	position: relative;
}
.about-position {
	position: absolute;
	bottom: 80px;
	z-index: 9;
	width: 100%;
}
.job-location {
	padding: 80px 0px 55px;
	background: #FBFBFB;
}
.section-locate {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.view-locations {
	.btn-primary {
		border: 1px solid #101828;
		border-radius: 5px;
		background: transparent;
		color: #000000;
		font-size: 14px;
		min-width: 191px;
		padding: 10px 15px;
		font-weight: 500;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
		&:hover {
			border: 1px solid #101828;
			background: #101828;
			color: #fff;
			transition: all 0.5s ease;
			-moz-transition: all 0.5s ease;
			-o-transition: all 0.5s ease;
			-ms-transition: all 0.5s ease;
			-webkit-transition: all 0.5s ease;
		}
	}
	&.all-views {
		margin-top: 25px;
		display: inline-flex;
		.btn-primary {
			max-width: 191px;
		}
	}
}
.location-right {
	background: #86E53E;
	width: 18px;
	height: 18px;
	border-radius: 50px;
	color: #fff;
	margin-left: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.job-locate-blk {
	background: #FFFFFF;
	border: 1px solid #EBEBEB;
	border-radius: 10px;
	padding: 10px;
	margin-bottom: 25px;
}
.job-it-content {
	margin-top: 10px;
	h6 {
		font-weight: 600;
		margin-bottom: 8px;
		a {
			color: #000000;
		}
	}
}
.job-locate-foot {
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	li {
		color: #474747;
		font-size: 14px;
		font-weight: 500;
	}
}
.user-review {
	.review-info {
		h5 {
			font-size: 14px;
			color: #2B2B2B;
			font-weight: 400;
		}
	}
	.rating {
		text-align: center;
		margin-top: 20px;
	}
}
.review-two {
	.owl-nav {
		position: absolute;
		overflow: hidden;
		bottom: 100%;
		right: -5px;
		margin-bottom: 50px;
	}
	.owl-theme {
		.owl-nav {
			[class*="owl-"]:hover {
				background: #86E53E;
				i {
					color: #fff;
				}
			}
		}
	}
}
.top-company-two {
	padding: 80px 0px 0px;
	.company-logos {
		border: 1px solid #EBEBEB;
		background: #fff;
		padding: 7px 0px;
		border: 1px solid #EBEBEB;
		border-radius: 4px;
	}
}
.review-two .owl-carousel .owl-nav button.owl-next,
.review-two .owl-carousel .owl-nav button.owl-prev,
.review-two .owl-carousel button.owl-dot {
	display: block;
	float: left;
	width: 32px;
	height: 32px;
	font-size: 13px;
	color: #fff;
	border: 0;
	background: #13323C;
	background: #FFFFFF;
	border: 1px solid #EBEBEB;
	text-align: center;
	-webkit-transition: color .7s ease, background-color .7s ease, border-color .7s ease;
	transition: color .7s ease, background-color .7s ease, border-color .7s ease;
	box-shadow: 0px 1px 4px rgba(183, 183, 183, 0.25);
	border-radius: 50px;
}
.review-two .page-prev,
.review-two .owl-nav .owl-prev {
	border-radius: 50px;
	border:1px solid #EBEBEB;
	color:#86E53E;
	background: #FFFFFF;
	width:32px;
	height:32px;
}
.review-two .page-next,
.review-two .owl-nav .owl-next {
    color:#86E53E;
	border-radius: 50px;
	border:1px solid #EBEBEB;
	background: #FFFFFF;
	width:32px;
	height:32px;
}
.review-two .owl-carousel .owl-nav button.owl-next i,
.review-two .owl-carousel .owl-nav button.owl-prev i {
	color: #86E53E;
	line-height: 2.5;
}
.grid-blog {
	&.blog-two {
		background: #FFFFFF;
		border: 1px solid #F5F6FA;
		box-shadow: 0px 4px 24px rgba(214, 214, 214, 0.25);
		border-radius: 10px;
		.blog-content {
			p {
				margin: 0;
			}
		}
	}
	&.blog-five {
		padding: 20px;
		border: 1px solid #EBEBEB;
		box-shadow: 0px 4px 24px rgba(204, 204, 204, 0.25);
		border-radius: 10px;
		.blog-trick-read {
			p {
				margin-bottom: 0;
				color: #86E53E;
			}
		}
		.blog-content {
			padding: 20px 0px 0px;
		}
		.blog-title {
			color: #2b2b2b;
		}
		.blog-reead {
			color: #5f5f5f;
		}
	}
	.blog-title {
		font-size: 16px;
		margin: 10px 0 10px;
		color: #2e2e2e;
		font-weight: 700;
		width: 300px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.blog-content {
		p {
			margin: 0 0 15px;
		}
	}
	.blog-info {
		margin-bottom: 15px;
	}
	.blog-image {
		margin-bottom: 0px;
		border-radius: 6px 6px 0px 0px;
	}
	.entry-meta {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 0;
		li {
			margin-bottom: 10px;
			.fa-calendar-alt {
				font-weight: 500;
			}
			&:last-child {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				justify-content: end;
				-webkit-justify-content: end;
				-ms-flex-pack: end;
			}
			i {
				margin-right: 3px;
			}
		}
	}
	.post-author {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		a {
			color: #5F5F5F;
			font-size: 14px;
			font-weight: 500;
			&:hover {
				color: #ff3115;
			}
		}
	}
}
.blog-two {
	.blog-read {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: space-between;
		-webkit-justify-content: space-between;
		-ms-flex-pack: space-between;
	}
	.blog-content {
		padding: 20px;
	}
}
.blog-tricks {
	padding: 80px 0px;
	.owl-dots {
		display: none;
	}
}
.blog-slider.owl-carousel .owl-nav button.owl-prev,
.blog-slider.owl-carousel .owl-nav button.owl-next {
	width: 44px;
	height: 44px;
	font-size: 16px;
	background: #fff !important;
	color: #86E53E;
	border-radius: 50px;
	position: absolute;
	border: 1px solid #EBEBEB;
}
.blog-slider.owl-carousel .owl-nav button.owl-prev:hover,
.blog-slider.owl-carousel .owl-nav button.owl-next:hover {
	background: #86E53E;
	color: #fff;
}
.blog-slider {
	&.owl-carousel {
		.owl-nav {
			button {
				&.owl-prev {
					top: 40%;
					left: -25px;
					-webkit-transform: translate(0, 50%);
					-ms-transform: translate(0, 50%);
					transform: translate(0, 50%);
				}
				&.owl-next {
					top: 40%;
					right: -25px;
					-webkit-transform: translate(0, 50%);
					-ms-transform: translate(0, 50%);
					transform: translate(0, 50%);
				}
			}
		}
	}
}
.owl-theme {
	&.blog-slider {
		.owl-dots {
			.owl-dot {
				span {
					width: 16px;
					height: 16px;
					background: #C4C4C4;
				}
			}
		}
	}
}
.owl-theme.blog-slider .owl-dots .owl-dot.active span,
.owl-theme.blog-slider .owl-dots .owl-dot:hover span {
	background: #2567E5;
}
.sign-in-btn {
	.btn-primary {
		min-width: 202px;
		padding: 10px 15px;
		background: #101828;
		border-radius: 5px;
		border: 1px solid #101828;
		font-weight: 600;
		&:hover {
			background: #000;
			border: 1px solid #000;
		}
	}
}
.job-register-two {
	background: #86E53E;
	padding: 50px 0px;
}
.home-three {
	background-image: none;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	padding: 0;
	.slick-prev {
		right: 90px;
		&:before {
			background-image: url(../../assets/img/pre.svg);
		}
	}
	.slick-next {
		right: 40px;
		&:before {
			background-image: url(../../assets/img/next.svg);
		}
	}
	.banner-content {
		h1 {
			font-size: 32px;
			color: #fff;
			margin-bottom: 34px;
		}
	}
}
.home-three .banner-content .form-control::placeholder,
.home-three .select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #9d9d9d;
}
.dot-slider {
	.slick-dots {
		position: absolute;
		top: 30%;
		right: 50px;
		bottom: unset;
		width: unset;
		position: absolute;
		top: 50%;
		right: 50px;
		bottom: unset;
		width: unset;
		left: unset;
		-webkit-transform: translate(0, -50%);
		-ms-transform: translate(0, -50%);
		transform: translate(0, -50%);
		li {
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 8px 0;
			button {
				width: 7px;
				height: 7px;
				border-radius: 50%;
				background-color: #fff;
				&:before {
					display: none;
					font-family: 'slick';
					font-size: 76px;
					line-height: 20px;
					position: absolute;
					top: 0;
					left: 0;
					width: 20px;
					height: 20px;
					content: '-';
					text-align: center;
					opacity: .25;
					color: black;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
				}
			}
			&.slick-active {
				button {
					background-color: #1860CA;
					background-color: #86E53E;
					&:before {
						opacity: 1;
						color: #0071DC;
					}
				}
			}
		}
	}
	&.slick-vertical {
		.slick-slide {
			height: 589px;
			border: 0px solid transparent;
		}
	}
	.slick-slide {
		outline: none !important;
	}
	&.slick-dotted {
		&.slick-slider {
			margin-bottom: 0px;
		}
	}
	.slick-track {
		height: 589px;
	}
}
.slick-dots {
	position: absolute;
	bottom: -25px;
	display: block;
	width: 100%;
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center;
	position: absolute;
	bottom: -25px;
	display: block;
	width: auto;
	transform: translate(-50px, 0);
	left: 50%;
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center;
	li {
		position: relative;
		display: inline-block;
		width: 20px;
		height: 20px;
		margin: 0 5px;
		padding: 0;
		cursor: pointer;
		button {
			font-size: 0;
			line-height: 0;
			display: block;
			width: 20px;
			height: 5px;
			padding: 0;
			cursor: pointer;
			color: transparent;
			border: 0;
			outline: none;
			background: #C0C0C0;
			font-size: 0;
			line-height: 0;
			display: block;
			width: 20px;
			height: 20px;
			padding: 5px;
			cursor: pointer;
			color: transparent;
			border: 0;
			outline: none;
			background: transparent;
			&:before {
				font-family: 'slick';
				font-size: 40px;
				line-height: 20px;
				position: absolute;
				top: 0;
				left: 0;
				width: 20px;
				height: 20px;
				content: '•';
				text-align: center;
				opacity: .25;
				color: #86E53E;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}
		}
		&.slick-active {
			button {
				&:before {
					opacity: .75;
					color: #86E53E;
				}
			}
		}
	}
}
.dot-slider .slick-dots li button:hover,
.dot-slider .slick-dots li button:focus {
	outline: none;
}
.dot-slider .slick-dots li button:hover:before,
.dot-slider .slick-dots li button:focus:before {
	opacity: 1;
}
.home-three .slick-prev,
.home-three .slick-next {
	background: #fff;
	width: 40px;
	height: 40px;
	top: inherit;
	bottom: 35px;
	border-radius: 50px;
	border: 1px solid #EBEBEB;
}
.home-three .slick-prev:hover:before,
.home-three .slick-next:hover:before {
	filter: invert(0) brightness(100);
}
.home-three .slick-prev:before,
.home-three .slick-next:before {
	content: "";
	background-image: url(../../assets/img/pre.svg);
	background-repeat: no-repeat;
	position: absolute;
	width: 13px;
	height: 13px;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%)rotate(-45deg);
	-ms-transform: translate(-50%, -50%)rotate(-45deg);
	transform: translate(-50%, -50%)rotate(-45deg);
	background-size: cover;
}
.banner-kofe {
	position: absolute;
	top: 50%;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	.banner-content {
		.rating {
			.checked {
				color: #FFC700;
			}
		}
		h5 {
			color: #fff;
		}
	}
}
.home-three .banner-content .form-inner,
.home-three .banner-content .sub-btn {
	border-radius: 5px !important;
}
.company-hire {
	padding: 60px 0px;
	background: #86E53E;
	.company-logos {
		background: #fff;
		padding: 7px 0px;
		border: 1px solid #EBEBEB;
		border-radius: 4px;
	}
}
.hire-heading {
	h6 {
		color: #fff;
	}
}
.section-header-three .header-title,
.section-header-four .header-title {
	margin-bottom: 10px;
	color: #000000;
}
.different-categories {
	padding: 80px 0px 55px;
}
.different-categories-blk {
	background: #FFFFFF;
	margin-bottom: 25px;
	width: 100%;
	border: 1px solid #EBEBEB;
	border-radius: 5px;
	padding: 15px;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	&:hover {
		border: 1px solid #EBEBEB;
		box-shadow: -10px 54px 44px rgba(218, 218, 218, 0.25);
		transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
	}
}
.category-foot {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	a {
		border: 1px solid #EBEBEB;
		width: 31px;
		height: 31px;
		border-radius: 50px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
	}
}
.category-foot a:hover,
.feature-developer-foot a:hover {
	background: #13323C;
	border: 1px solid #13323C;
	color: #fff;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
}
.differ-img {
	margin-bottom: 20px;
}
.differ-content {
	h6 {
		color: #2E2E2E;
		margin-bottom: 10px;
	}
	p {
		margin-bottom: 25px;
	}
}
.differ-content p,
.category-foot span {
	color: #5F5F5F;
	font-weight: 500;
	font-size: 14px;
}
.best-marketplace {
	padding: 80px 0px;
	background: #FBFBFB;
}
.market-place-group {
	h2 {
		color: #000000;
		font-weight: 700;
		margin-bottom: 30px;
		span {
			color: #86E53E;
		}
		.platform-head {
			color: #2E2E2E;
			display: block;
		}
	}
	p {
		color: #515151;
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 20px;
	}
	&.join-place-blk {
		h2 {
			span {
				color: #2B2B2B;
			}
		}
	}
	.platform-pasage {
		color: #000000;
		font-size: 14px;
		font-weight: 400;
		margin-bottom: 25px;
	}
}
.market-list-out {
	margin-bottom: 35px;
	li {
		font-size: 14px;
		font-weight: 500;
		margin-bottom: 15px;
		list-style: none;
	}
}
.market-place-btn {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	.btn-primary {
		min-width: 137px;
		padding: 10px 0px;
		font-weight: 600;
		font-size: 15px;
		transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
		&:hover {
			background: #13323C;
			border-color: #13323C;
			transition: all 0.5s ease;
			-moz-transition: all 0.5s ease;
			-o-transition: all 0.5s ease;
			-ms-transition: all 0.5s ease;
			-webkit-transition: all 0.5s ease;
		}
	}
	.market-developer {
		border: 1px solid #101828;
		border-radius: 5px;
		background: transparent;
		color: #000000;
		font-size: 15px;
		&:hover {
			border: 1px solid #101828;
			background: #101828;
			color: #fff;
		}
	}
	&.platform-btn {
		.btn-primary {
			min-width: 200px;
			padding: 10px 0px;
			font-weight: 600;
		}
		.project-post {
			background: #101828;
			border: 1px solid #2E2E2E;
			&:hover {
				background: #86E53E;
				border: 1px solid #86E53E;
			}
		}
	}
	&.bidding-btn {
		.btn-primary {
			min-width: 157px;
		}
		.project-post {
			background: #101828;
			border: 1px solid #101828;
			&:hover {
				background: #86E53E;
				border: 1px solid #86E53E;
			}
		}
		.market-project {
			&:hover {
				background: #13323C;
				border: 1px solid #13323C;
			}
		}
	}
	&.company-register-btn {
		.market-project {
			min-width: 177px;
			&:hover {
				background: #13323C;
				border: 1px solid #13323C;
			}
		}
		.project-post {
			min-width: 206px;
			background: #101828;
			border: 1px solid #101828;
			&:hover {
				background: #86E53E;
				border: 1px solid #86E53E;
			}
		}
	}
}
.trend-project-item {
	.project-img {
		padding: 0;
		margin-bottom: 10px;
	}
}
.trend-project-content {
	h5 {
		color: #86E53E;
		margin-bottom: 10px;
		font-size: 14px;
	}
	h4 {
		font-size: 18px;
		color: #101828;
		font-weight: 600;
		margin-bottom: 15px;
	}
}
.trend-project-list {
	border-bottom: 1px solid #EBEBEB;
	margin-bottom: 15px;
	padding-bottom: 20px;
	li {
		list-style: none;
		font-size: 14px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		& + li {
			margin-top: 12px;
		}
	}
}
.trend-rate {
	.nav {
		justify-content: space-between;
		-webkit-justify-content: space-between;
		-ms-flex-pack: space-between;
	}
	li {
		color: #515151;
		font-size: 14px;
		span {
			color: #86E53E;
			font-weight: 600;
		}
	}
}
.owl-carousel.trend-slider .owl-nav .owl-prev,
.owl-carousel.trend-slider .owl-nav .owl-next {
	z-index: 9;
}
.owl-carousel.trend-slider .owl-nav .owl-prev:hover,
.owl-carousel.trend-slider .owl-nav .owl-next:hover {
	background: transparent;
	color: #000;
}
.trend-slider {
	&.owl-theme {
		.owl-dots {
			.owl-dot {
				span {
					background: #D9D9D9;
				}
				&.active {
					span {
						background: #263238;
					}
				}
			}
		}
	}
}
.feature-count-head {
	.header-title {
		font-size: 24px;
		color: #fff;
	}
}
.feature-count {
	position: relative;
	padding-bottom: 20px;
	padding-top: 50px;
	background: #86E53E;
	.feature-content {
		margin-top: 0;
		h3 {
			color: #fff;
			margin-bottom: 3px;
			transition: all 0.5s ease;
			-moz-transition: all 0.5s ease;
			-o-transition: all 0.5s ease;
			-ms-transition: all 0.5s ease;
			-webkit-transition: all 0.5s ease;
		}
		p {
			color: #FFF5F1;
			font-size: 14px;
			font-weight: 500;
			transition: all 0.5s ease;
			-moz-transition: all 0.5s ease;
			-o-transition: all 0.5s ease;
			-ms-transition: all 0.5s ease;
			-webkit-transition: all 0.5s ease;
		}
	}
	.feature-icon {
		margin-bottom: 25px;
		position: relative;
	}
	.feature-item {
		background: transparent;
		box-shadow: none;
		border: 0;
		transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
		&:hover {
			background: #fff;
			transition: all 0.5s ease;
			-moz-transition: all 0.5s ease;
			-o-transition: all 0.5s ease;
			-ms-transition: all 0.5s ease;
			-webkit-transition: all 0.5s ease;
			img {
				filter: invert(64%) sepia(215%) saturate(478%) hue-rotate(266deg) brightness(68%) contrast(628%);
			}
			.feature-content {
				h3 {
					color: #101828;
					transition: all 0.5s ease;
					-moz-transition: all 0.5s ease;
					-o-transition: all 0.5s ease;
					-ms-transition: all 0.5s ease;
					-webkit-transition: all 0.5s ease;
				}
				p {
					color: #5F5F5F;
					transition: all 0.5s ease;
					-moz-transition: all 0.5s ease;
					-o-transition: all 0.5s ease;
					-ms-transition: all 0.5s ease;
					-webkit-transition: all 0.5s ease;
				}
			}
		}
	}
}
.heart-blk {
	.favourite {
		width: 28px;
		height: 28px;
		background: #FFFFFF;
		box-shadow: 0px 4px 4px rgba(206, 206, 206, 0.25);
		border: 0;
		left: 10px;
		right: unset;
		top: 10px;
		&.three-active {
			color: #fff;
			background: #86E53E;
			border: 1px solid #86E53E;
		}
	}
}
.project-item-feature {
	.project-img {
		padding: 0;
		position: relative;
	}
}
.feaure-project {
	padding: 80px 0px;
}
.feature-project-content {
	padding: 20px 0px 10px;
	h4 {
		margin-bottom: 5px;
		font-size: 18px;
		color: #353535;
	}
	p {
		color: #515151;
		font-size: 14px;
		font-weight: 500;
		margin-bottom: 10px;
	}
	.rating {
		margin-bottom: 20px;
	}
}
.feature-developer-foot {
	a {
		border: 1px solid #EBEBEB;
		width: 32px;
		height: 32px;
		border-radius: 50px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
	}
}
.circle-active {
	background-color: #fff;
	color: #38B653;
	font-size: 16px;
	position: absolute;
	right: 10px;
	top: 14px;
	border-radius: 100%;
	z-index: 2;
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}
.hour-dollr {
	background: #FFFFFF;
	border-radius: 5px;
	position: absolute;
	bottom: 10px;
	right: 10px;
	font-size: 14px;
	font-weight: 500;
	padding: 3px 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	&.develop-dollr {
		right: unset;
		left: 10px;
	}
}
.blog {
	margin-bottom: 30px;
	padding: 1.5rem;
	position: relative;
	background: #FFFFFF;
	box-shadow: 0px 4px 48px rgba(160, 160, 160, 0.1);
	border-radius: 6px;
	&.blog-trick {
		background: #FFFFFF;
		border: 1px solid #EBEBEB;
		box-shadow: 0px 4px 34px rgba(233, 233, 233, 0.25);
		border-radius: 10px;
		padding: 20px;
		box-shadow: none;
	}
	&.grid-blog {
		padding: 0px;
		.blog-content {
			padding: 15px;
		}
	}
}
.blog-trick {
	.blog-image {
		margin-bottom: 20px;
		border-radius: 5px;
		img {
			border-radius: 5px;
		}
	}
}
.blog-trick-content {
	p {
		color: #5F5F5F;
		margin-bottom: 30px;
	}
	h3 {
		font-size: 20px;
		color: #2B2B2B;
	}
}
.post-author {
	&.blog-hover-img {
		img {
			width: 45px !important;
			height: 45px;
		}
		a {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
		}
	}
	a {
		display: flex;
		display: -webkit-box;
		display: -ms-flexbox;
		align-items: center;
	}
	img {
		border-radius: 100%;
		width: 28px !important;
		margin-right: 7px;
	}
}
.post-author-three {
	span {
		color: #2B2B2B;
		font-weight: 500;
		font-size: 14px;
	}
}
.skill-head.skill-head-two .nav-pills .nav-link.active,
.skill-head.skill-head-two .nav-pills .show>.nav-link {
	color: #101828;
}
.next-job {
	padding: 80px 0;
	background-image: url(../../assets/img/bg/bg-23.jpg);
	background-repeat: no-repeat, no-repeat;
	background-position: right bottom;
	background-size: cover;
	position: relative;
	&:before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		background: #86E53E;
		opacity: 0.97;
		top: 0;
	}
}
.job-next-blk {
	h2 {
		color: #fff;
		font-size: 32px;
		margin-bottom: 20px;
	}
	p {
		color: #FFF5F1;
		font-size: 18px;
		font-weight: 500;
		margin-bottom: 35px;
	}
}
.next-job-btn {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	.btn-primary {
		font-size: 15px;
		font-weight: 600;
		min-width: 158px;
		padding: 10px 15px;
		transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
		&.next-sign {
			background: #fff;
			border: 1px solid #fff;
			color: #101828;
			&:hover {
				background: transparent;
				color: #fff;
				transition: all 0.5s ease;
				-moz-transition: all 0.5s ease;
				-o-transition: all 0.5s ease;
				-ms-transition: all 0.5s ease;
				-webkit-transition: all 0.5s ease;
			}
		}
		&.next-register {
			background: transparent;
			border: 1px solid #fff;
			color: #fff;
			&:hover {
				background: #fff;
				border: 1px solid #fff;
				color: #101828;
				transition: all 0.5s ease;
				-moz-transition: all 0.5s ease;
				-o-transition: all 0.5s ease;
				-ms-transition: all 0.5s ease;
				-webkit-transition: all 0.5s ease;
			}
		}
	}
	&.next-with-job {
		.next-register {
			background: #101828;
			border: 1px solid #FFFFFF;
			transition: all 0.5s ease;
			-moz-transition: all 0.5s ease;
			-o-transition: all 0.5s ease;
			-ms-transition: all 0.5s ease;
			-webkit-transition: all 0.5s ease;
			&:hover {
				background: #fff;
				border: 1px solid #fff;
				color: #101828;
				transition: all 0.5s ease;
				-moz-transition: all 0.5s ease;
				-o-transition: all 0.5s ease;
				-ms-transition: all 0.5s ease;
				-webkit-transition: all 0.5s ease;
			}
		}
		.next-sign {
			&:hover {
				background: #13323C;
				border: 1px solid #13323C;
				transition: all 0.5s ease;
				-moz-transition: all 0.5s ease;
				-o-transition: all 0.5s ease;
				-ms-transition: all 0.5s ease;
				-webkit-transition: all 0.5s ease;
			}
		}
	}
	&.next-reg-now {
		justify-content: start;
		-webkit-justify-content: start;
		-ms-flex-pack: start;
		.next-sign {
			background: #86E53E;
			border: 1px solid #86E53E;
			color: #fff;
			&:hover {
				background: #13323C;
				border: 1px solid #13323C;
			}
		}
		.next-register {
			background: #101828;
			border: 1px solid #101828;
			color: #fff;
			&:hover {
				background: #86E53E;
				border: 1px solid #86E53E;
				color: #fff;
			}
		}
	}
}
.review-people {
	padding: 80px 0 130px;
	background-color: #F7F7F7;
	background-image: url(../../assets/img/bg/bg-17.png);
	background-repeat: no-repeat, no-repeat;
	background-position: right bottom;
	background-size: cover;
	.owl-carousel {
		&.trend-slider {
			.owl-dots {
				position: absolute;
				margin: 0;
				left: 50%;
				min-width: 90px;
				bottom: -42px;
				top: inherit;
				right: inherit;
				-webkit-transform: translate(-50%, 50%);
				-ms-transform: translate(-50%, 50%);
				transform: translate(-50%, 50%);
			}
			.owl-nav {
				left: 46%;
				bottom: -62px;
				min-width: 90px;
				top: inherit;
				right: inherit;
			}
		}
	}
}
.review-love-group {
	max-width: 680px;
	margin: auto;
}
.review-passage {
	text-align: center;
	max-width: 600px;
	margin: auto;
	margin-bottom: 40px;
}
.quote-love-img {
	margin-bottom: 45px;
	img {
		margin: auto;
	}
}
.review-profile-blk {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background: #FFFFFF;
	border-radius: 110px;
	padding: 8px;
	margin-right: 15px;
	min-width: 200px;
}
.review-name-blk {
	h6 {
		margin-bottom: 2px;
		a {
			color: #000000;
		}
	}
	p {
		color: #515151;
		font-size: 14px;
		margin-bottom: 0;
	}
}
.review-love-list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.scroll-top {
	width: 50px;
	height: 50px;
	line-height: 50px;
	position: fixed;
	bottom: 105%;
	right: 35px;
	border-radius: 10px;
	z-index: 9;
	color: #ffffff;
	text-align: center;
	cursor: pointer;
	background: #86E53E;
	transition: 1s ease;
	border: none;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	&.scroll-top-next {
		-webkit-transform: rotate(46deg);
		-ms-transform: rotate(46deg);
		transform: rotate(46deg);
		background: #fff;
		border-radius: 0;
		box-shadow: 0px 4px 20px rgb(224 224 224 / 40%);
		&:hover {
			background: #86E53E;
			span {
				color: #fff;
			}
		}
		span {
			-webkit-transform: rotate(-46deg);
			-ms-transform: rotate(-46deg);
			transform: rotate(-46deg);
			color: #161515;
		}
	}
	&.open {
		bottom: 30px;
	}
	&:hover {
		background: #13323C;
		transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
	}
	span {
		color: #fff;
		line-height: 0;
	}
}
.home-four {
	background-image: url(../img/banner5.jpg);
	padding: 0;
	margin-top: 85px;
	min-height: 580px;
	&:before {
		content: "";
		position: absolute;
		background-image: url(../../assets/img/bg/bg-24.svg);
		background-repeat: no-repeat;
		background-position: left center;
		width: 120px;
		height: 250px;
		background-size: 95%;
		top: 50%;
		-webkit-transform: translate(0, -50%);
		-ms-transform: translate(0, -50%);
		transform: translate(0, -50%);
	}
	.banner-content {
		h5 {
			font-weight: 600;
			font-size: 20px;
			color: #86E53E;
			text-transform: unset;
			margin-bottom: 15px;
		}
		h1 {
			font-weight: bold;
			font-size: 48px;
			color: #2E2E2E;
			margin-bottom: 40px;
		}
		.form-control {
			&::placeholder {
				color: #9d9d9d;
			}
		}
		.sub-btn {
			background: #2B2B2B;
			border-radius: 5px !important;
			border: 1px solid #2B2B2B;
			&:hover {
				background: #86E53E;
				border: 1px solid #86E53E;
			}
		}
	}
}
.banner-blk-four {
	min-height: 580px;
	width: 100%;
	display: inline-grid;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.home-four .banner-content .form-inner,
.home-four .banner-content .sub-btn {
	border-radius: 5px !important;
}
.fullstack-blk {
	background: #FFFFFF;
	box-shadow: 0px 4px 64px rgba(143, 143, 143, 0.25);
	border-radius: 10px;
	padding: 15px;
	max-width: 234px;
	text-align: center;
	position: absolute;
	top: 120px;
	right: 30px;
}
.stacker-active {
	background-color: #fff;
	color: #38B653;
	font-size: 14px;
	position: absolute;
	right: 10px;
	bottom: 0px;
	border-radius: 100%;
	z-index: 2;
	width: 19px;
	height: 19px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}
.fullstacker-img {
	width: 90px;
	height: 90px;
	position: relative;
	margin: auto;
	margin-bottom: 20px;
	img {
		border-radius: 50px;
	}
}
.fullstacker-name {
	h4 {
		color: #2B2B2B;
		font-weight: 600;
	}
	p {
		color: #515151;
		font-weight: 500;
		font-size: 14px;
		margin-bottom: 2px;
	}
}
.coplete-job {
	background: #FFFFFF;
	border-radius: 10px;
	position: absolute;
	top: 40px;
	left: 42%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 15px;
}
.complete-icon {
	width: 60px;
	height: 60px;
	background: #86E53E;
	border-radius: 50px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	i {
		font-size: 20px;
		color: #fff;
	}
}
.complete-content {
	h3 {
		font-weight: 700;
		color: #2E2E2E;
		margin-bottom: 5px;
	}
	p {
		font-weight: 500;
		color: #5F5F5F;
		font-size: 14px;
		margin-bottom: 0;
	}
}
.register-profesion {
	background: #FFFFFF;
	border-radius: 10px;
	position: absolute;
	bottom: 40px;
	left: 37%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 15px;
	p {
		margin-bottom: 0;
		color: #000000;
		font-weight: 600;
		margin-right: 15px;
	}
	.avatar-group {
		.avatar {
			width: 48px;
			height: 48px;
			border: 3px solid #fff;
			border-radius: 50px;
		}
		.avatar-xs {
			& + .avatar-xs {
				margin-left: -20px;
			}
		}
	}
}
.browse-categories {
	padding: 80px 0px 55px;
}
.browse-circle-blk {
	background: #F5F4F8;
	padding: 5px;
	margin-bottom: 25px;
	border-radius: 100px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	&:hover {
		box-shadow: 0px 4px 24px rgba(215, 215, 215, 0.25);
	}
}
.move-box {
	-webkit-transition: top ease .5s;
	transition: top ease .5s;
	position: relative;
	top: 0;
	&:hover {
		top: -6px;
	}
}
.browse-icon {
	background: #FFFFFF;
	width: 50px;
	height: 50px;
	border-radius: 50px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.browse-content {
	h6 {
		color: #2E2E2E;
		font-size: 15px;
		font-weight: 500;
		margin-bottom: 0;
	}
	p {
		color: #5F5F5F;
		font-size: 14px;
		font-weight: 500;
		margin-bottom: 0;
	}
}
.what-about-it {
	background: #FBFBFB;
	padding: 80px 0px 55px;
}
.about-it-item {
	background: #FFFFFF;
	border: 1px solid #EBEBEB;
	box-shadow: 0px 4px 24px rgba(215, 215, 215, 0.25);
	border-radius: 9px;
	padding: 30px;
	width: 100%;
	margin-bottom: 25px;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	&:hover {
		box-shadow: 0px 15px 30px rgb(215 215 215 / 50%);
		transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
	}
}
.about-it-icon {
	background: linear-gradient(270.05deg, #F8ECF8 -28.55%, #FFF3EA 112.03%);
	width: 70px;
	height: 70px;
	margin-bottom: 15px;
	border-radius: 50px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.about-great-content {
	h4 {
		color: #000000;
		font-weight: 600;
		margin-bottom: 10px;
	}
	p {
		color: #515151;
		margin-bottom: 0px;
		font-size: 14px;
	}
}
.feaure-for-developer {
	padding: 80px 0px 55px;
	background-image: url(../../assets/img/bg/bg-25.png), url(../../assets/img/bg/bg-25.png);
	background-repeat: no-repeat, no-repeat;
	background-position: right center, left center;
}
.develop-dollr {
	img {
		width: auto;
	}
}
.foot-profile-btn {
	.btn-primary {
		border-radius: 11px;
		font-size: 15px;
		font-weight: 600;
		padding: 8px 15px;
		transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
		&:hover {
			background-color: #13323C;
			border: 1px solid #13323C;
			transition: all 0.5s ease;
			-moz-transition: all 0.5s ease;
			-o-transition: all 0.5s ease;
			-ms-transition: all 0.5s ease;
			-webkit-transition: all 0.5s ease;
		}
	}
}
.join-marketplace {
	background: #FBFBFB;
	padding: 80px 0;
	background-image: url(../../assets/img/bg/bg-26.png);
	background-repeat: no-repeat, no-repeat;
	background-position: right center;
	background-size: 170px;
}
.popular-projects {
	padding: 80px 0px 55px;
}
.popular-group {
	background: #FFFFFF;
	border: 1px solid #EBEBEB;
	border-radius: 10px;
	padding: 15px;
	.blog-image {
		border-radius: 10px;
		margin-bottom: 20px;
	}
}
.head-popular {
	margin-bottom: 25px;
	h4 {
		color: #101828;
		font-weight: 600;
		font-size: 18px;
	}
	p {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		color: #5F5F5F;
		font-size: 14px;
		margin-bottom: 0;
	}
}
.popular-list-box {
	margin-bottom: 20px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	ul {
		li {
			background: #FFF5F1;
			border-radius: 4px;
			font-size: 14px;
			color: #474747;
			font-weight: 500;
			padding: 6px 15px;
			min-width: 88px;
			margin-right: 10px;
			text-align: center;
			&:last-child {
				margin-right: 0px;
			}
		}
	}
	h4 {
		color: #86E53E;
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 0;
		span {
			color: #5F5F5F;
			font-size: 15px;
			font-weight: 500;
			padding-left: 5px;
		}
	}
}
.popular-foot {
	color: #5F5F5F;
	font-weight: 500;
	margin-bottom: 0;
	font-size: 15px;
}
.popular-slider.developers-slider.owl-carousel .owl-nav button.owl-next,
.popular-slider.developers-slider.owl-carousel .owl-nav button.owl-prev {
	transform: none;
	background: #FFF5F1;
	border: 1px solid #EBEBEB;
	width: 44px;
	height: 44px;
	border-radius: 50px !important;
	box-shadow: none;
}
.popular-slider.developers-slider.owl-carousel .owl-nav button.owl-next:hover,
.popular-slider.developers-slider.owl-carousel .owl-nav button.owl-prev:hover {
	background: #86E53E;
	border: 1px solid #86E53E;
}
.popular-slider.developers-slider.owl-carousel .owl-nav button.owl-next i,
.popular-slider.developers-slider.owl-carousel .owl-nav button.owl-prev i {
	transform: none;
	color: #86E53E;
}
.popular-slider.developers-slider.owl-carousel .owl-nav button.owl-next:hover i,
.popular-slider.developers-slider.owl-carousel .owl-nav button.owl-prev:hover i {
	color: #fff;
}
.popular-slider {
	&.developers-slider {
		.owl-nav {
			bottom: 100%;
			right: 18%;
			margin-bottom: 55px;
		}
	}
}
.achive-feature {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	.feature-content {
		p {
			margin-bottom: 0;
		}
		h4 {
			color: #fff;
			margin-bottom: 5px;
		}
	}
	&:hover {
		.feature-content {
			h4 {
				color: #101828;
			}
		}
	}
}
.review-four {
	background: #FFF5F1;
	padding: 80px 0px 150px;
	position: relative;
	background-image: url(../../assets/img/bg/bg-28.png);
	background-repeat: no-repeat, no-repeat;
	background-position: left center;
	.testimonial-thumb {
		padding: 10px 0;
		img {
			width: 58px;
			border-radius: 60px;
		}
	}
	.slider-nav {
		width: 300px;
		margin: auto;
		.slick-current {
			.testimonial-thumb {
				transform: scale(1.1);
				-webkit-transform: scale(1.1);
				-ms-transform: scale(1.1);
				transition: ease all 0.5s;
				-webkit-transition: ease all 0.5s;
				-ms-transition: ease all 0.5s;
				img {
					border: 2px solid #86E53E;
					border-radius: 50px;
					padding: 2px;
				}
			}
		}
	}
	.slick-list {
		padding: 0 !important;
	}
	.slick-prev {
		left: 0;
		z-index: 99;
		background: #fff;
		width: 50px;
		height: 50px;
		border-radius: 50px;
		display:none ;
		top: 50%;
		&:hover {
			background: #86E53E;
		}
		&:before {
			content: "\f053";
			font-family: "Font Awesome 5 Free";
			font-size: 18px;
			font-weight: 900;
		}
	}
	.slick-next {
		right: 0;
		background: #fff;
		width: 50px;
		height: 50px;
		border-radius: 50px;
		top: 50%;
		display: none;
		&:hover {
			background: #86E53E;
		}
		&:before {
			content: "\f054";
			font-family: "Font Awesome 5 Free";
			font-size: 18px;
			font-weight: 900;
		}
	}
	.slick-slide {
		width: 70px;
	}
	.client-img {
		position: absolute;
		left: 50%;
		bottom: 70px;
		-webkit-transform: translate(-50%, 0) !important;
		-ms-transform: ttranslate(-50%, 0) !important;
		transform: translate(-50%, 0) !important;
		img {
			margin: auto;
		}
	}
	.review-passage {
		font-weight: 600;
	}
}
.review-four .slick-prev:before,
.slick-next:before {
	color: #000;
}
.review-four .slick-prev:hover,
.review-four .slick-next:hover,
.review-four .swiper-button-prev:hover,
.review-four .swiper-button-next:hover {
	color: #fff;
	opacity: 1;
}
.review-four .slick-prev:hover:before,
.review-four .slick-prev:focus:before,
.review-four .slick-next:hover:before,
.review-four .slick-next:focus:before,
.review-four .swiper-button-prev:hover:before,
.review-four .swiper-button-next:hover:before {
	color: #fff;
	opacity: 1;
}
.review-four .slick-next:focus,
.review-four .slick-prev:focus {
	color: #000;
	background: #86E53E;
}
.review-four .slick-prev:before,
.review-four .slick-next:before {
	color: #000;
}
.say-name-blk {
	margin-bottom: 40px;
	h6 {
		color: #353535;
		font-weight: 600;
	}
	p {
		color: #515151;
		font-size: 14px;
		font-weight: 400;
		margin-bottom: 0;
	}
}
.platform-location {
	background: #FBFBFB;
}
.blog-article {
	padding: 80px 0px 55px;
}
.blog-article-group {
	background: #FFFFFF;
	border: 1px solid #EBEBEB;
	box-shadow: 0px 4px 34px rgba(233, 233, 233, 0.25);
	border-radius: 10px;
	padding: 8px;
	.blog-image {
		border-radius: 10px;
		margin-bottom: 0;
		position: relative;
		&:before {
			content: "";
			background: linear-gradient(358.04deg, rgba(0, 0, 0, 0.85) 20.78%, rgba(0, 0, 0, 0) 84.46%);
			position: absolute;
			height: 100%;
			width: 100%;
			border-radius: 10px;
			z-index: 1;
		}
	}
}
.article-blog-content {
	position: absolute;
	bottom: 0px;
	z-index: 2;
	padding: 20px;
	span {
		color: #FFFFFF;
		font-size: 15px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		margin-bottom: 20px;
	}
	h4 {
		font-size: 18px;
		margin-bottom: 20px;
		&:hover {
			a {
				color: #86E53E;
			}
		}
		a {
			color: #fff;
		}
	}
	p {
		color: #EBEBEB;
		font-size: 15px;
		margin-bottom: 0px;
		font-weight: 500;
	}
}
.article-Production {
	position: absolute;
	top: 20px;
	left: 20px;
	z-index: 9;
	span {
		background: #86E53E;
		border-radius: 10px;
		color: #FFFFFF;
		font-size: 14px;
		font-weight: 500;
		padding: 10px 15px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
}
.top-company-four {
	padding-bottom: 55px;
	.company-logos {
		border: 1px solid #EBEBEB;
		background: #fff;
		padding: 7px 0px;
		border-radius: 4px;
	}
}
.next-great-job {
	background: linear-gradient(270.05deg, #F8ECF8 -28.55%, #FFF3EA 112.03%);
	padding: 80px 0px;
	&:before {
		content: "";
		position: absolute;
		width: 160px;
		height: 160px;
		background-image: url(../../assets/img/bg/bg-27.png);
		background-repeat: no-repeat, no-repeat;
		background-position: right center;
		right: 60px;
		background-size: 140px;
	}
	.job-next-blk {
		h2 {
			color: #2E2E2E;
			font-size: 32px;
			margin-bottom: 20px;
		}
		p {
			color: #515151;
		}
	}
}
.trust-slider.developers-slider.owl-carousel .owl-nav button.owl-next,
.trust-slider.developers-slider.owl-carousel .owl-nav button.owl-prev {
	transform: none;
	background: #fff;
	border: 1px solid #EBEBEB;
	width: 44px;
	height: 44px;
	border-radius: 50px !important;
	box-shadow: none;
}
.trust-slider.developers-slider.owl-carousel .owl-nav button.owl-next:hover,
.trust-slider.developers-slider.owl-carousel .owl-nav button.owl-prev:hover {
	background: #86E53E;
	border: 1px solid #86E53E;
}
.trust-slider.developers-slider.owl-carousel .owl-nav button.owl-next i,
.trust-slider.developers-slider.owl-carousel .owl-nav button.owl-prev i {
	transform: none;
	color: #86E53E;
}
.trust-slider.developers-slider.owl-carousel .owl-nav button.owl-next:hover i,
.trust-slider.developers-slider.owl-carousel .owl-nav button.owl-prev:hover i {
	color: #fff;
}
.trust-slider {
	&.developers-slider {
		.owl-nav {
			bottom: 100%;
			right: 0;
			margin-bottom: 33px;
		}
	}
}
.home-five {
	&.home-banner {
		background-image: url(../img/banner6.jpg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
		padding-top: 0px;
		&:before {
			content: "";
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 86.88%, rgba(0, 0, 0, 0) 100%);
			position: absolute;
			width: 100%;
			height: 100%;
		}
	}
	.banner-content {
		h1 {
			color: #FFF5F1;
		}
		h5 {
			color: #FFFFFF;
			margin-bottom: 0;
		}
		p {
			color: #FFFFFF;
			margin-bottom: 40px;
		}
		.sub-btn {
			height: 52px;
			min-width: 52px;
			border-radius: 10px !important;
			padding: 10px 12px;
			i {
				font-size: 25px;
			}
		}
		.form-control {
			&::placeholder {
				color: #9D9D9D;
			}
		}
	}
}
.banner-blk-five {
	min-height: 650px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	.rating {
		margin-bottom: 10px;
		.checked {
			color: #FDC232;
		}
	}
	.home-count {
		ul {
			justify-content: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
		}
	}
}
.banner-blk-five .home-count ul li,
.banner-blk-five .home-count ul li .list-count {
	color: #FFFFFF;
}
.home-five .banner-content .form-inner,
.home-five .banner-content .input-group {
	border-radius: 4px;
}
.need-job {
	padding: 80px 0px 0px;
	background-image: url(../../assets/img/bg/bg-29.png);
	background-repeat: no-repeat, no-repeat;
	background-position: left 20%,top;
}
.section-header-five {
	.header-title {
		margin-bottom: 5px;
		color: #2e2e2e;
	}
	p {
		color: #515151;
		margin-bottom: 0px;
		padding-bottom: 20px;
	}
	&:before {
		content: "";
		width: 31px;
		height: 4px;
		background: #86E53E;
		bottom: 0;
		left: 49%;
		position: absolute;
		border-radius: 50px;
		-webkit-transform: translate(-50%, 0);
		-ms-transform: translate(-50%, 0);
		transform: translate(-50%, 0);
	}
	&:after {
		content: "";
		width: 14px;
		height: 4px;
		background: #86E53E;
		bottom: 0;
		left: 52%;
		position: absolute;
		border-radius: 50px;
		-webkit-transform: translate(-50%, 0);
		-ms-transform: translate(-50%, 0);
		transform: translate(-50%, 0);
	}
}
.need-job-group {
	padding-bottom: 55px;
	border-bottom: 1px solid #ebebeb;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	.about-it-item {
		background: #FFFFFF;
		border: 0;
		border-right: 1px solid #EBEBEB;
		box-shadow: none;
		border-radius: 0;
		padding: 0px 30px;
		width: 100%;
		margin-bottom: 25px;
		&:last-child {
			border-right: 0;
		}
		&:hover {
			box-shadow: none;
		}
	}
	.about-great-content {
		h4 {
			color: #2e2e2e;
		}
		p {
			color: #353535;
		}
	}
}
.need-job-icon {
	margin-bottom: 20px;
}
.popular-projects-job {
	padding: 80px 0px;
	background-image: url(../../assets/img/bg/bg-30.png), url(../../assets/img/bg/bg-31.png);
	background-repeat: no-repeat, no-repeat;
	background-position: right top, left center;
}
.recent-categories {
	padding: 80px 0px;
	background: linear-gradient(113.89deg, #FFFCFB 51.94%, #FFDCD4 100.96%, #FFFFFF 100.97%);
	position: relative;
	z-index: 1;
	&:before {
		content: "";
		background-image: url(../../assets/img/bg/bg-32.png);
		background-repeat: no-repeat, no-repeat;
		background-position: left center;
		position: absolute;
		width: 170px;
		height: 220px;
		left: 0;
		top: 20%;
		z-index: -1;
		background-size: 145px;
	}
}
.update-icon-end {
	i {
		color: #9D9D9D;
	}
}
.profesion-job {
	padding: 80px 0px;
	background-image: url(../../assets/img/bg/bg-34.png);
	background-repeat: no-repeat;
	background-position: right center;
	background-size: 145px;
}
.demand-post-job {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 20px;
}
.demand-post-img {
	margin-right: 20px;
	flex-shrink: 0;
	width: 45px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.demand-professional {
	flex-grow: 1;
	h2 {
		color: #2E2E2E;
		font-size: 32px;
		font-weight: 700;
		margin-bottom: 15px;
	}
	p {
		color: #515151;
		font-weight: 500;
		margin-bottom: 20px;
	}
}
.demand-content {
	h6 {
		color: #000000;
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 10px;
	}
	p {
		font-size: 14px;
		font-weight: 400;
		margin-bottom: 0;
	}
}
.market-place-join {
	padding: 0px 0px 80px;
}
.market-place-bg {
	background: #FBFBFB;
	padding-top: 45px;
}
.join-market-world {
	padding-left: 100px;
	h2 {
		color: #101828;
		font-size: 28px;
		font-weight: 700;
		margin-bottom: 30px;
	}
	p {
		color: #515151;
		font-weight: 600;
		margin-bottom: 50px;
	}
}
.join-platform-img {
	&:before {
		content: "";
		position: absolute;
		background-image: url(../../assets/img/bg-market.png);
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: right bottom;
	}
}
.join-platform-img,
.join-platform-img img {
	position: relative;
}
.talent-developer {
	padding: 80px 0px;
	background: #FBFBFB;
	&:before {
		content: "";
		background-image: url(../../assets/img/bg/bg-33.png);
		background-repeat: no-repeat;
		background-position: right top;
		background-size: 250px;
		width: 260px;
		height: 220px;
		position: absolute;
		right: 40px;
	}
}
.freelance-widget {
	background-color: #fff;
	text-align: center;
	position: relative;
	margin-bottom: 25px;
	z-index: 1;
	border: 1px solid #EBEBEB;
	box-shadow: 0px 4px 48px rgba(160, 160, 160, 0.1);
	border-radius: 6px;
	padding: 10px;
	&.talent-widget {
		background: #FFFFFF;
		box-shadow: 0px 4px 34px rgba(220, 220, 220, 0.25);
		border-radius: 10px;
		border: 0;
	}
	.favourite {
		&.color-active {
			animation: size .4s;
			background: #86E53E;
			color: #fff;
		}
	}
	.rating {
		margin-bottom: 15px;
	}
	&:hover {
		.freelancers-price {
			color: #159C5B;
		}
	}
}
.talent-widget {
	.freelance-img {
		img {
			margin: auto;
			width: 106px;
			height: 106px;
			border-radius: 50%;
			border: 0;
			padding: 0;
		}
	}
	.freelance-tags {
		border-bottom: 1px solid #EBEBEB;
		padding-bottom: 10px;
	}
	.freelance-content {
		padding-bottom: 10px;
	}
	&.freelance-widget {
		.rating {
			margin-bottom: 15px;
		}
	}
	.freelance-specific {
		margin-bottom: 5px;
	}
}
.talent-foot-list {
	ul {
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		li {
			text-align: left;
			margin-right: 20px;
			color: #2E2E2E;
			font-size: 14px;
			font-weight: 500;
			&:last-child {
				margin-right: 0px;
			}
			span {
				display: block;
				color: #515151;
				font-weight: 400;
			}
		}
	}
}
.compay-hire {
	padding: 80px 0px;
	background-image: url(../../assets/img/bg/bg-35.png);
	background-repeat: no-repeat;
	background-position: right center;
}
.compay-hire-blk {
	h3 {
		color: #2E2E2E;
		font-weight: 700;
		margin-bottom: 15px;
	}
	p {
		font-weight: 500;
		margin-bottom: 25px;
	}
}
.hire-company-list {
	ul {
		li {
			padding: 10px 30px;
			width: 180px;
			min-height: 80px;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			justify-content: space-evenly;
			-webkit-justify-content: space-evenly;
			-ms-flex-pack: space-evenly;
			border: 1px solid #EBEBEB;
		}
	}
}
.hire-company-list ul li:nth-child(1),
.hire-company-list ul li:nth-child(2),
.hire-company-list ul li:nth-child(3),
.hire-company-list ul li:nth-child(4),
.hire-company-list ul li:nth-child(5),
.hire-company-list ul li:nth-child(6),
.hire-company-list ul li:nth-child(7),
.hire-company-list ul li:nth-child(8),
.hire-company-list ul li:nth-child(9) {
	border-top: 0;
	border-left: 0;
}
.hire-company-list ul li:nth-child(3),
.hire-company-list ul li:nth-child(6),
.hire-company-list ul li:nth-child(9) {
	border-right: 0;
}
.hire-company-list ul li:nth-child(7),
.hire-company-list ul li:nth-child(8),
.hire-company-list ul li:nth-child(9) {
	border-bottom: 0;
}
.review-five {
	padding: 80px 0px;
	background: linear-gradient(113.89deg, #FFFCFB 51.94%, #FFDCD4 100.96%, #FFFFFF 100.97%);
	position: relative;
	&:before {
		content: "";
		background-image: url(../../assets/img/bg/bg-32.png);
		background-repeat: no-repeat, no-repeat;
		background-position: left center;
		position: absolute;
		width: 170px;
		height: 220px;
		left: 0;
		top: 20%;
		background-size: 145px;
	}
	.testimonial-all {
		margin-top: 30px;
		padding-bottom: 50px;
		position: relative;
	}
	.testimonial-thumb {
		padding: 20px 0;
		img {
			width: 60px;
			border-radius: 60px;
		}
	}
	.slider-nav {
		width: 370px;
		margin-left: auto;
		.slick-current {
			.testimonial-thumb {
				transform: scale(1.4);
				-webkit-transform: scale(1.4);
				-ms-transform: scale(1.4);
				transition: ease all 0.5s;
				-webkit-transition: ease all 0.5s;
				-ms-transition: ease all 0.5s;
			}
		}
	}
	.slick-list {
		padding: 0 !important;
	}
	.client-img {
		position: absolute;
		right: 0px;
		bottom: -30px;
		img {
			margin: auto;
		}
	}
	.slick-prev {
		left: 25%;
		z-index: 99;
		background: #fff;
		width: 44px;
		height: 44px;
		border-radius: 50px;
		bottom: 0px;
		top: inherit;
		border: 1px solid #EBEBEB;
		&:before {
			content: "\f053";
			font-family: "Font Awesome 5 Free";
			font-size: 18px;
			font-weight: 900;
		}
	}
	.slick-next {
		right: 65%;
		background: #fff;
		width: 44px;
		height: 44px;
		border-radius: 50px;
		bottom: 0px;
		top: inherit;
		border: 1px solid #EBEBEB;
		&:before {
			content: "\f054";
			font-family: "Font Awesome 5 Free";
			font-size: 18px;
			font-weight: 900;
			.testimonial-two {
				.slick-next {
					&:hover {
						color: #fff;
					}
				}
			}
		}
	}
	.slick-slide {
		width: 70px;
	}
	.slick-center {
		.say-client {
			.say-client-name {
				display: block;
			}
		}
	}
}
.testimonial-two-head {
	max-width: 100%;
	background: transparent;
	border-radius: 10px;
	position: relative;
	h3 {
		margin: 0px 0px 10px;
		font-size: 24px;
		font-weight: 700;
		color: #2b2b2b;
	}
	span {
		margin-bottom: 20px;
		color: #515151;
		font-weight: 500;
		display: block;
	}
	p {
		font-weight: 500;
		line-height: 1.7;
		margin-bottom: 20px;
	}
}
.img-reviews {
	img {
		max-width: 193px;
		margin: auto;
		border-radius: 50%;
	}
}
.review-quote {
	img {
		position: absolute;
		max-width: 60px;
		top: -27px;
	}
}
.slid-review-group {
	position: relative;
}
.review-five .slick-prev:before,
.review-five .slick-next:before {
	color: #000;
	color: #000;
}
.review-five .slick-prev:hover,
.review-five .slick-next:hover {
	background: #FF3115;
}
.review-five .slick-prev:hover,
.review-five .slick-next:hover,
.review-five .swiper-button-prev:hover,
.review-five .swiper-button-next:hover {
	color: #fff;
	opacity: 1;
}
.review-five .slick-prev:hover:before,
.review-five .slick-prev:focus:before,
.review-five .slick-next:hover:before,
.review-five .slick-next:focus:before,
.review-five .swiper-button-prev:hover:before,
.review-five .swiper-button-next:hover:before {
	color: #fff;
	opacity: 1;
}
.review-five .slick-next:focus,
.review-five .slick-prev:focus {
	color: #000;
	background: #FF3115;
}
.blog-tricks-five {
	padding: 80px 0px 230px;
}
.blog-trick-five {
	.owl-dots {
		display: none;
	}
	&.blog-slider {
		&.owl-carousel {
			.owl-nav {
				button {
					&.owl-prev {
						top: inherit;
						left: 48%;
					}
					&.owl-next {
						top: inherit;
						right: 52%;
					}
				}
			}
		}
	}
}
.blog-trick-five.blog-slider.owl-carousel .owl-nav button.owl-prev,
.blog-trick-five.blog-slider.owl-carousel .owl-nav button.owl-next {
	background: #FFF5F1;
}
.blog-trick-five.blog-slider.owl-carousel .owl-nav button.owl-prev:hover,
.blog-trick-five.blog-slider.owl-carousel .owl-nav button.owl-next:hover {
	background: #86E53E;
	color: #fff;
}
.blog-trick-read {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	padding-bottom: 15px;
	a {
		font-size: 14px;
		font-weight: 500;
	}
}
.great-opportunity {
	padding: 80px 0px;
	background: linear-gradient(113.89deg, #FFFCFB 51.94%, #FFDCD4 100.96%, #FFFFFF 100.97%);
	position: relative;
}
.great-find-job {
	&.job-next-blk {
		h2 {
			color: #2B2B2B;
		}
		p {
			color: #515151;
		}
	}
}
.opportunity-img {
	position: absolute;
	bottom: 0;
	right: 0;
}
div#global-loader {
	background: #FFF5F1;
	position: fixed;
	z-index: 999999;
	width: 100%;
	height: 100vh;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	top: 0;
}
.whirly-loader {
	background: #fff;
	border: 4px solid #fff;
	border-radius: 50%;
	border-top: 4px solid #86E53E;
	width: 100px;
	height: 100px;
	-webkit-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
}
.loader-img {
	position: absolute;
	z-index: 9;
}
.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}
.btn-file {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	font-size: 20px;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	overflow: hidden;
	padding: 0 0.75rem;
	position: relative;
	vertical-align: middle;
}
.incoming-btns {
	margin-top: 20px;
}

.slick-list {
position: relative;
display: block;
overflow: hidden;
margin: 0;
padding: 0;
&:focus {
	outline: none;
}
&.dragging {
	cursor: pointer;
}
}
.slick-slider .slick-track,
.slick-slider .slick-list {
-webkit-transform: translate3d(0, 0, 0);
-moz-transform: translate3d(0, 0, 0);
-ms-transform: translate3d(0, 0, 0);
-o-transform: translate3d(0, 0, 0);
transform: translate3d(0, 0, 0);
}
.slick-track {
position: relative;
top: 0;
left: 0;
display: block;
margin-left: auto;
margin-right: auto;
&:after {
	clear: both;
}
}
.slick-track:before,
.slick-track:after {
display: table;
content: '';
}
.slick-loading {
.slick-track {
	visibility: hidden;
}
.slick-slide {
	visibility: hidden;
}
}
.slick-slide {
display: none;
float: left;
height: 100%;
min-height: 1px;
img {
	display: block;
}
&.slick-loading {
	img {
		display: none;
	}
}
&.dragging {
	img {
		pointer-events: none;
	}
}
}
[dir='rtl'] {
.slick-slide {
	float: right;
}
.slick-prev {
	right: -25px;
	left: auto;
	&:before {
		content: '\25C0';
	}
}
.slick-next {
	right: auto;
	left: -25px;
	&:before {
		content: '\25B6';
	}
}
}
.slick-initialized {
.slick-slide {
	display: block;
}
}
.slick-vertical {
.slick-slide {
	display: block;
	height: auto;
	border: 1px solid transparent;
}
}
.slick-arrow {
&.slick-hidden {
	display: none;
}
}
.slick-prev,
.slick-next {
font-size: 0;
line-height: 0;
position: absolute;
top: -50px;
display: block;
width: 32px;
height: 32px;
padding: 0;
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
transform: rotate(45deg);
border-radius: 10px;
cursor: pointer;
border: none;
outline: none;
background: #13323C;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
background-color: #FF3115;
color: #fff;
opacity: 1;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
color: #fff;
opacity: 1;
filter: invert(1) brightness(200);
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
opacity: .25;
}
.slick-prev:before,
.slick-next:before {
font-size: 18px;
line-height: 1;
opacity: .75;
color: #fff;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}
.slick-prev {
left: auto;
z-index: 1;
right: 52px;
&:before {
	content: '\003c';
	display: inline-block;
	transform: rotate(-45deg);
}
}
.slick-next {
right: 10px;
&:before {
	content: '\003e';
	transform: rotate(-45deg);
	display: inline-block;
}
}
.slick-dotted {
&.slick-slider {
	margin-bottom: 30px;
}
}
.slick-dots li button:hover, .slick-dots li button:focus {
outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
opacity: 1;
}
.account-page {
background-color: #fff;
.footer {
	display: none;
}
.content {
	padding: 144px 0 60px;
	background: #fffcfc;
	border-top: 1px solid #e5e6e6;
}
}
.login-header {
margin-bottom: 20px;
img {
	margin-bottom: 15px;
}
p {
	font-size: 16px;
	color: #17191A;
	margin-bottom: 20px;
	font-weight: 500;
}
h3 {
	font-size: 22px;
	font-weight: 600;
	margin-bottom: 9px;
	color: #161c2d;
	a {
		color: #ff3115;
		float: right;
		font-size: 15px;
		margin-top: 2px;
	}
}
}
.login-right {
	.dont-have {
		color: #161c2d;
		font-weight: 500;
		margin-top: 30px;
		font-size: 16px;
		label {
			color: #161c2d;
			font-weight: 500;
			font-size: 16px;
			margin-bottom: 30px;
		}
		a {
			color: #86E53E;
		}
	}
}
.login-btn {
	font-size: 18px;
	font-weight: bold;
	background: #86E53E;
	border: 1px solid #86E53E;
	border-radius: 5px;
	padding: 14px 10px;
}
.login-or {
	color: #d4d4d4;
	padding-bottom: 25px;
	padding-top: 25px;
	position: relative;
	text-align: center;
	p {
		font-size: 16px;
		color: #161c2d;
		margin-bottom: 0;
	}
}
.or-line {
	background-color: #e5e5e5;
	height: 1px;
	margin-bottom: 0;
	margin-top: 0;
	display: block;
}
.span-or {
	background-color: #fff;
	display: block;
	left: 50%;
	margin-left: -20px;
	position: absolute;
	text-align: center;
	top: -3px;
	width: 42px;
}
.forgot-link {
	color: #161c2d;
	font-weight: 500;
	margin-top: 30px;
	font-size: 16px;
	display: inline-block;
}
.btn-facebook {
	background: #3F76E4;
	border-radius: 165px;
	color: #fff;
	font-size: 14px;
	font-weight: bold;
	padding: 10px 12px;
}
.btn-google {
	background-color: #dd4b39;
	border-radius: 165px;
	color: #fff;
	font-size: 14px;
	font-weight: bold;
	padding: 10px 12px;
}
.btn-twitter {
	background: #29B2FF;
	border-radius: 165px;
	color: #fff;
	font-size: 14px;
	font-weight: bold;
	padding: 10px 12px;
}
.social-login {
	border-bottom: 1px solid #E9E9E9;
	padding-bottom: 30px;
}
.social-login .btn:hover, .social-login .btn:focus {
	color: #fff;
}
.empty-content {
	padding: 50px 0;
	img {
		margin-bottom: 30px;
	}
	h2 {
		text-transform: uppercase;
		color: #161c2d;
		margin-bottom: 30px;
		font-size: 24px;
	}
	p {
		font-size: 14px;
		text-transform: uppercase;
		color: #161c2d;
		margin-bottom: 0;
		font-weight: 500;
	}
	.btn-item {
		margin-top: 40px;
	}
}
.title-detail .custom-file, .title-detail .custom-file-input {
	height: 48px;
}
.title-box {
	background: #fff;
	box-shadow: 0px 4px 14px rgba(215, 215, 215, 0.25);
	border-radius: 5px;
}
.title {
	border-bottom: 1px solid #F3F1F1;
	padding: 30px;
	h3 {
		font-weight: 600;
		font-size: 20px;
		text-transform: uppercase;
		color: #161c2d;
		margin-bottom: 0;
		position: relative;
		padding-bottom: 8px;
		&::before {
			height: 3px;
			content: "";
			position: absolute;
			width: 29px;
			background: #86E53E;
			bottom: 0;
			left: 0;
		}
		&::after {
			height: 3px;
			content: "";
			position: absolute;
			width: 11px;
			background: #86E53E;
			bottom: 0;
			left: 32px;
		}
	}
}
.title-content {
	padding: 30px;
	h3 {
		font-weight: 600;
		font-size: 16px;
		margin-bottom: 15px;
		color: #161c2d;
	}
	p {
		font-size: 14px;
		color: #494949;
		padding-top: 12px;
	}
}
.select-project {
	.title-content {
		padding: 0 0 30px;
	}
}
.next-btn {
	background: #86E53E;
	border-radius: 90px;
	border-color: #86E53E;
	font-weight: bold;
	font-size: 17px;
	padding: 13px 50px;
	color: #fff;
	text-transform: uppercase;
	&:hover {
		color: #fff;
		background-color: #fb451d;
		border-color: #fb451d;
	}
}
.cancel-btn {
	background: #13323C;
	border-radius: 90px;
	font-weight: bold;
	font-size: 17px;
	padding: 13px 50px;
	color: #fff;
	margin-right: 15px;
	text-transform: uppercase;
	&:hover {
		color: #fff;
		background-color: #86E53E;
		border-color: #86E53E;
	}
}
.technology {
	padding: 30px 0;
	.btn-item {
		margin: 25px 0;
	}
}
.upload-document {
	position: relative;
	width: 125px;
	img {
		border-radius: 4px;
		height: 80px;
		width: auto;
	}
	.btn-danger {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		font-size: 17px;
		width: 28px;
		height: 28px;
		background: #F5365C;
		border-radius: 50%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
	}
}
.upload-wrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	.upload-document {
		&:not(:last-child) {
			margin-right: 25px;
		}
	}
	.upload-images {
		& + .upload-images {
			margin-left: 20px;
		}
	}
}
.add-links {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 46px;
	width: 46px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	background-color: #86E53E;
	color: #fff;
	&.more-link {
		width: auto;
		height: auto;
		background: transparent;
		color: #898989;
		display: inline-block;
		&:hover {
			color: #898989;
			opacity: 0.8;
		}
	}
	&:hover {
		color: #fff;
		opacity: 0.8;
	}
}
.back {
	margin-top: 35px;
	color: #86E53E;
	padding-left: 20px;
	p {
		a {
			color: #86E53E;
			font-weight: bold;
			font-size: 18px;
		}
		&::before {
			content: "\f104";
			font-family: "Font Awesome 5 Free";
			font-weight: 900;
			left: 0;
			margin-right: 5px;
			font-size: 18px;
			font-weight: bold;
			position: absolute;
			color: #86E53E;
			padding-left: 20px;
		}
	}
}
.faq {
	padding: 30px 0;
	.card {
		border: 1px solid #ffe6e1;
		box-shadow: 0px 4px 4px rgba(244, 244, 244, 0.25);
		border-radius: 5px;
		.card-header {
			border-bottom: 1px solid #ffe6e1;
		}
	}
}
#accordion {
	h4 {
		font-weight: 600;
		font-size: 20px;
		text-transform: uppercase;
		color: #86E53E;
		margin-bottom: 22px;
	}
	i {
		float: right;
	}
	a[aria-expanded="true"] {
		i {
			transform: rotate(90deg);
		}
	}
	.card-header {
		font-size: 18px;
		color: #161c2d;
	}
	.card-body {
		font-size: 16px;
		color: #161c2d;
	}
}
#accordion a[aria-expanded="true"] .card-header, #accordion a[aria-expanded="true"] .card-header i {
	color: #86E53E;
}
.basics-faq {
	margin-bottom: 40px;
}
.select2-container {
	z-index: 11111;
	.select2-selection--single {
		border: 1px solid #E7E8EA;
		height: 46px;
		outline: 0;
		.select2-selection__rendered {
			padding-right: 30px;
			padding-left: 15px;
		}
	}
}
.select2-results__option {
	padding: 6px 15px;
}
.select2-container--default {
	.select2-selection--single {
		.select2-selection__arrow {
			height: 44px;
			right: 7px;
			b {
				border-color: #353535 transparent transparent;
				border-style: solid;
				border-width: 6px 6px 0;
				height: 0;
				left: 50%;
				margin-left: -10px;
				margin-top: -2px;
				position: absolute;
				top: 50%;
				width: 0;
			}
		}
		.select2-selection__rendered {
			color: #353535;
			font-size: 16px;
			font-weight: normal;
			line-height: 44px;
			font-weight: 500;
		}
	}
	&.select2-container--open {
		.select2-selection--single {
			.select2-selection__arrow {
				b {
					border-color: transparent transparent #353535;
					border-width: 0 6px 6px;
				}
			}
		}
	}
	.select2-results__option--highlighted[aria-selected] {
		background-color: #161c2d;
		border: 0;
	}
	.select2-selection--multiple {
		border: 1px solid #dcdcdc;
		min-height: 46px;
	}
}
.select2-dropdown {
	border-color: #dcdcdc;
}
.result {
	background: #FFFDFD;
	margin: 0 0 20px 0;
	.select2-container {
		.select2-selection--single {
			border: 0;
			background-color: unset;
			height: 46px;
		}
	}
	.select2-container--default {
		.select2-selection--single {
			.select2-selection__rendered {
				color: #86E53E;
				font-weight: 500;
				font-size: 14px;
				&:focus {
					outline: 0;
					border: 0;
					-webkit-appearance: none;
				}
			}
		}
	}
	&.bg-white {
		background: #fff;
		border: 1px solid rgba(239, 239, 239, 0.42);
		box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	}
	.sort-title {
		font-weight: 500;
		font-size: 14px;
		color: #161c2d;
	}
	h4 {
		font-weight: 500;
		font-size: 14px;
		color: #86E53E;
		margin-bottom: 0;
		span {
			font-weight: 700;
		}
	}
}
.select2-container:focus, .select2-container--below .select2-container--focus {
	border: 0;
	outline: 0;
	-webkit-appearance: none;
}
.search-filter {
	background: #fff;
	border: 1px solid rgba(239, 239, 239, 0.42);
	box-shadow: 0px 4px 34px rgb(215 215 215 / 25%);
	border-radius: 5px;
	.card-header {
		border: 1px solid rgba(239, 239, 239, 0.42);
		box-shadow: 0px 4px 34px rgb(215 215 215 / 25%);
		border-radius: 5px;
		align-items: center;
		-webkit-align-items: center;
		h2 {
			font-weight: 500;
			font-size: 20px;
			text-transform: uppercase;
			color: #161c2d;
		}
		a {
			font-weight: 600;
			font-size: 14px;
			color: #86E53E;
		}
	}
}
.search-filter .select2-container .select2-selection--single, .search-filter .form-control {
	border: 1px solid #ecf0f9;
	border-radius: 6px;
}
.breadcrumb-bar {
	background: url(../../assets/img/inner-banner.jpg);
	background-size: cover;
	padding-top: 84px;
	background-repeat: no-repeat;
	min-height: 304px;
	.breadcrumb-title {
		font-weight: bold;
		font-size: 40px;
		color: #86E53E;
		margin-bottom: 15px;
	}
}
.bread-crumb-bar {
	background-color: #FFF;
	padding-top: 84px;
	.inner-banner {
		padding: 20px 0;
	}
	.page-breadcrumb {
		ol {
			li {
				a {
					img {
						margin-right: 10px;
					}
				}
			}
		}
	}
	.breadcrumb-list {
		.page-breadcrumb {
			.breadcrumb-item {
				& + .breadcrumb-item {
					&:before {
						line-height: 5px;
						margin: 0px 19px 0;
						padding-right: 0;
					}
				}
			}
		}
	}
}
.breadcrumb-list {
	.page-breadcrumb {
		.breadcrumb-item {
			& + .breadcrumb-item {
				&:before {
					color: #22100D;
					font-size: 14px;
					content: "\f054";
					font-weight: 600;
					line-height: 1.5;
					font-family: "Font Awesome 5 Free";
					padding-right: 16px;
					margin: 0px 15px 0;
					background: none;
				}
			}
		}
		ol {
			display: flex;
			li {
				text-transform: uppercase;
				font-size: 14px;
				color: #13323C;
				font-weight: 600;
			}
		}
	}
}
.inner-content {
	margin-top: 40px;
}
.content {
	min-height: 200px;
	padding: 60px 0 30px;
	background-color: #fff;
}
.inner-banner {
	padding: 70px 0;
}
.page-breadcrumb {
	ol {
		background-color: transparent;
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 0;
		padding: 0;
		display: inline-flex;
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		li {
			a {
				color: #161c2d;
				font-weight: 600;
			}
			&.active {
				color: #161c2d;
			}
		}
	}
	.breadcrumb {
		a {
			&:hover {
				color: #86E53E;
			}
		}
	}
	.breadcrumb-item {
		& + .breadcrumb-item {
			padding-left: 0;
			&:before {
				color: #161c2d;
				font-size: 16px;
				content: "";
				width: 5px;
				height: 5px;
				background: red;
				border-radius: 20px;
				margin: 10px 15px 0;
				display: block;
				padding: 0;
			}
		}
	}
}
.bread-crumb-bar .page-breadcrumb ol li a,
.bread-crumb-bar .breadcrumb-list .page-breadcrumb ol li,
.bread-crumb-bar .breadcrumb-list .page-breadcrumb ol {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.sort-by {
	float: right;
	select {
		border: none;
		outline: none;
		width: 150px;
		padding: 0 20px;
		font-size: 15px;
		color: #53545b;
		font-weight: 500;
		height: 36px;
	}
}
.sort-title {
	color: #fff;
	font-size: 14px;
	margin-right: 10px;
}
.sortby-fliter {
	display: inline-block;
	width: 130px;
}
.cal-icon {
	position: relative;
	width: 100%;
	&:after {
		color: #86E53E;
		content: '\f073';
		display: block;
		font-family: "Font Awesome 5 Free";
		font-size: 16px;
		font-weight: 400;
		margin: auto;
		position: absolute;
		right: 15px;
		top: 10px;
	}
}
.custom_check {
	color: #55545b;
	display: inline-block;
	position: relative;
	font-size: 14px;
	font-size: .9375rem;
	padding-left: 30px;
	margin-bottom: 10px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		&:checked {
			& ~ .checkmark {
				background-color: #fff;
				border-color: #ffe6e1;
				&:after {
					display: block;
				}
			}
		}
	}
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		border: 1px solid #ecf0f9;
		background-color: #fff;
		border-radius: 3px;
		-moz-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		-webkit-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		&::after {
			content: "\f00c";
			font-family: "Font Awesome 5 Free";
			font-weight: 900;
			position: absolute;
			display: none;
			left: 4px;
			top: 0;
			color: #86E53E;
			font-size: 11px;
		}
	}
}
.custom_radio {
	color: #555;
	display: inline-flex;
	align-items: center;
	position: relative;
	font-size: 14px;
	font-size: 0.9375rem;
	padding-left: 30px;
	margin-bottom: 10px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	input {
		position: absolute;
		opacity: 0;
		&:checked {
			& ~ .checkmark {
				&:after {
					opacity: 1;
				}
			}
		}
	}
	.checkmark {
		position: absolute;
		left: 0;
		height: 20px;
		width: 20px;
		background-color: #fff;
		border: 1px solid #ffe6e1;
		border-radius: 50%;
		&:after {
			display: block;
			content: "";
			position: absolute;
			opacity: 0;
			top: 3px;
			left: 3px;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			background: #86E53E;
			-moz-transition: all 0.3s ease-in-out;
			-o-transition: all 0.3s ease-in-out;
			-webkit-transition: all 0.3s ease-in-out;
			-ms-transition: all 0.3s ease-in-out;
			transition: all 0.3s ease-in-out;
		}
	}
}
.radio_input {
	.custom_radio {
		& + .custom_radio {
			margin-left: 15px;
		}
	}
}
.custom-file {
	position: relative;
	display: inline-block;
	width: 100%;
	margin-bottom: 0;
}
.custom-file-input {
	position: relative;
	z-index: 2;
	width: 100%;
	margin: 0;
	overflow: hidden;
	opacity: 0;
	&:lang(en) {
		& ~ .custom-file-label {
			&::after {
				content: "Upload";
			}
		}
	}
}
.custom-file-label {
	height: 48px;
	border: 1px solid #E7E8EA;
	margin: 0;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1;
	padding: 0.375rem 0.75rem;
	overflow: hidden;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	border-radius: 0.25rem;
	&::after {
		margin: 0.1rem;
		height: 41px;
		border-radius: 5px;
		padding: 10px 25px;
		border-left: 0;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 3;
		display: block;
		line-height: 1.5;
		color: #495057;
		content: "Browse";
		background-color: #e9ecef;
	}
}
.filter-widget {
	margin-bottom: 20px;
	h4 {
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 15px;
	}
	.custom_check {
		line-height: 18px;
	}
	.select2-container {
		z-index: unset;
	}
}
.btn-search {
	.btn {
		background-color: #13323C;
		border: 1px solid #13323C;
		border-radius: 5px;
		color: #fff;
		height: 46px;
		font-weight: 500;
		font-size: 16px;
	}
}
.btn-search .btn:hover, .btn-search .btn:focus {
	background-color: #86E53E;
	border: 1px solid #86E53E;
}
.provider-widget {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	.pro-name {
		font-size: 20px;
		font-weight: 600;
		margin-bottom: 3px;
	}
	.rating {
		i {
			font-size: 14px;
		}
	}
	.average-rating {
		font-size: 14px;
		font-weight: 500;
	}
}
.pro-info-left {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	.rating {
		list-style: none;
		margin: 0 0 7px;
		padding: 0;
		width: 100%;
	}
}
.pro-info-right {
	margin-left: auto;
	-ms-flex: 0 0 200px;
	flex: 0 0 200px;
	max-width: 200px;
}
.provider-img {
	-ms-flex: 0 0 150px;
	flex: 0 0 150px;
	margin-right: 20px;
	width: 150px;
	height: 154px;
	position: relative;
	img {
		border-radius: 5px;
	}
}
.load-more {
	margin-bottom: 30px;
	.btn {
		background-color: #ff3115;
		border-color: #ff3115;
	}
}
.invited-freelancer-label {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0;
}
.provider-action {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-bottom: 15px;
	a {
		& + a {
			margin-left: 8px;
		}
	}
	.fav-btn {
		&:hover {
			background-color: #fb1612;
			border-color: #fb1612;
			color: #fff;
		}
	}
}
.msg-btn:hover, .msg-btn:focus {
	background-color: #ff3115;
	border-color: #ff3115;
	color: #fff;
}
.call-btn:hover, .call-btn:focus {
	background-color: #ff3115;
	border-color: #ff3115;
	color: #fff;
}
.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active,
.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:hover,
.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:focus {
	border-bottom-width: 3px;
	color: #86E53E;
}
.widget {
	margin-bottom: 30px;
}
.widget-title {
	margin-bottom: 15px;
}
.before-circle {
	background-color: rgb(255 49 21 / 20%);
	border-radius: 50%;
	height: 12px;
	width: 12px;
	border: 2px solid #ff3115;
}
.awards-widget {
	.experience-list {
		& > li {
			&:last-child {
				p {
					margin-bottom: 0;
				}
			}
		}
	}
}
.service-list {
	margin-bottom: 30px;
	&:last-child {
		border-bottom: 0;
		margin-bottom: 0;
		padding-bottom: 0;
	}
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			float: left;
			margin: 6px 0;
			padding-left: 25px;
			position: relative;
			width: 33%;
			&::before {
				color: #ccc;
				content: '\f30b';
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				left: 0;
				position: absolute;
			}
		}
	}
}
.dropzone {
	background-color: #fbfbfb;
	border: 2px dashed rgba(0, 0, 0, 0.1);
	background: #FFFFFF;
	border: 1px dashed #8A2BE2;
	border-radius: 10px;
	min-height: 130px;
	text-align: center;
	.dz-preview {
		&.dz-error {
			&:hover {
				.dz-error-message {
					display: none;
				}
			}
		}
	}
	p {
		font-size: 16px;
		color: #8A2BE2;
	}
	span {
		display: none;
	}
}
.profile {
	background: #fff;
	box-shadow: 0px 4px 14px rgb(215 215 215 / 25%);
	border-radius: 5px;
	margin: -130px 0 25px 0;
	.select2-container {
		z-index: unset;
	}
	&.profile-freelance {
		margin: 0px 0 25px 0;
	}
}
.profile-image {
	img {
		margin-bottom: 1.5rem;
	}
}
.change-photo-btn {
	background-color: #FF3115;
	border-radius: 50px;
	color: #fff;
	cursor: pointer;
	display: block;
	font-size: 13px;
	font-weight: 600;
	margin: 0 auto;
	padding: 10px 15px;
	position: relative;
	transition: .3s;
	-moz-transition: .3s;
	-webkit-transition: .3s;
	-o-transition: .3s;
	text-align: center;
	width: 220px;
	input {
		&.upload {
			bottom: 0;
			cursor: pointer;
			filter: alpha(opacity=0);
			left: 0;
			margin: 0;
			opacity: 0;
			padding: 0;
			position: absolute;
			right: 0;
			top: 0;
			width: 220px;
		}
	}
}
.btn-icon {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
	position: absolute;
	padding: 0;
	font-size: 10px;
	width: 20px;
	height: 20px;
	display: -ms-inline-flexbox;
	display: inline-flex;
	right: 3px;
	top: 3px;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.upload-images {
	position: relative;
	width: 80px;
	img {
		border-radius: 4px;
		height: 80px;
		width: auto;
	}
}
.add-more {
	a {
		color: #ff3115;
	}
}
.bootstrap-tagsinput {
	border-color: #E7E8EA;
	box-shadow: inherit;
	min-height: 46px;
	width: 100%;
	border-radius: 5px;
	&.focus {
		border-color: #C7C8CA;
	}
	.tag {
		background-color: #ff508b;
		color: #fff;
		display: inline-block;
		font-size: 14px;
		font-weight: normal;
		margin-right: 2px;
		padding: 11px 15px;
		border-radius: 0;
		&.badge-info {
			border-radius: 40px;
			margin-bottom: 0;
			border: 0;
		}
	}
}
.submit-section {
	.submit-btn {
		padding: 12px 30px;
		font-weight: 600;
		font-size: 16px;
		min-width: 120px;
		& + .submit-btn {
			margin-left: 15px;
		}
	}
}
.info-widget {
	border-bottom: 1px solid #f0f0f0;
	padding-bottom: 30px;
	margin-bottom: 30px;
}
.payment-radio {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 16px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-weight: 600;
	color: #161c2d;
	text-transform: capitalize;
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		&:checked {
			& ~ .checkmark {
				border-color: #ff3115;
				&::after {
					-webkit-transform: scale(1);
					-moz-transform: scale(1);
					-ms-transform: scale(1);
					-o-transform: scale(1);
					transform: scale(1);
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		width: 19px;
		height: 19px;
		margin: 3px 0 0 0;
		border: 2px solid #ddd;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		-webkit-transition: all .3s;
		-moz-transition: all .3s;
		-ms-transition: all .3s;
		-o-transition: all .3s;
		transition: all .3s;
		&::after {
			position: absolute;
			left: 3px;
			top: 3px;
			content: '';
			width: 9px;
			height: 9px;
			background-color: #ff3115;
			opacity: 0;
			visibility: hidden;
			-webkit-transform: scale(0.1);
			-moz-transform: scale(0.1);
			-ms-transform: scale(0.1);
			-o-transform: scale(0.1);
			transform: scale(0.1);
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;
			-webkit-transition: all .3s;
			-moz-transition: all .3s;
			-ms-transition: all .3s;
			-o-transition: all .3s;
			transition: all .3s;
		}
	}
}
.submit-btn-bottom {
	margin-bottom: 30px;
}
.btn-file {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -ms-inline-flexbox;
	display: inline-flex;
	font-size: 20px;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	overflow: hidden;
	padding: 0 0.75rem;
	position: relative;
	vertical-align: middle;
	input {
		cursor: pointer;
		filter: alpha(opacity=0);
		font-size: 23px;
		height: 100%;
		margin: 0;
		opacity: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		cursor: pointer;
		filter: alpha(opacity=0);
		font-size: 23px;
		height: 100%;
		margin: 0;
		opacity: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
	}
}
#calendar-events {
	background-color: #fcfcfc;
}
.calendar-events {
	border: 1px solid transparent;
	cursor: move;
	padding: 10px 15px;
	&:hover {
		border-color: #e9e9e9;
		background-color: #fff;
	}
	i {
		margin-right: 8px;
	}
}
.calendar {
	float: left;
	margin-bottom: 0;
}
.fc-toolbar {
	&.fc-header-toolbar {
		margin-bottom: 1.5rem;
	}
	h2 {
		font-size: 18px;
		font-weight: 600;
		font-family: 'Roboto', sans-serif;
		line-height: 30px;
		text-transform: uppercase;
	}
}
.none-border {
	.modal-footer {
		border-top: none;
	}
}
.fc-day-grid-event {
	.fc-time {
		font-family: 'Roboto', sans-serif;
	}
}
.fc-day {
	background: #fff;
}
.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
	z-index: 0;
}
.fc {
	th {
		&.fc-widget-header {
			background: #eeeeee;
			font-size: 14px;
			line-height: 20px;
			padding: 10px 0;
			text-transform: uppercase;
		}
	}
}
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
	border-color: #f3f3f3;
}
.fc-basic-view .fc-day-number,
.fc-basic-view .fc-week-number {
	padding: 2px 5px;
}
.fc-button {
	background: #f1f1f1;
	border: none;
	color: #797979;
	text-transform: capitalize;
	box-shadow: none !important;
	border-radius: 3px !important;
	margin: 0 3px !important;
	padding: 6px 12px !important;
	height: auto !important;
}
.fc-text-arrow {
	font-family: inherit;
	font-size: 16px;
}
.fc-state-hover {
	background: #f3f3f3;
}
.fc-state-highlight {
	background: #f0f0f0;
}
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
	background-color: #ff3115 !important;
	color: #fff !important;
	text-shadow: none !important;
}
.fc-cell-overlay {
	background: #f0f0f0;
}
.fc-unthemed {
	.fc-today {
		background: #fff;
	}
}
.fc-event {
	border-radius: 2px;
	border: none;
	color: #fff !important;
	cursor: move;
	font-size: 13px;
	margin: 1px 7px;
	padding: 5px 5px;
	text-align: center;
}
.fc-basic-view {
	td {
		&.fc-week-number {
			span {
				padding-right: 8px;
				font-weight: 700;
				font-family: 'Roboto', sans-serif;
			}
		}
		&.fc-day-number {
			padding-right: 8px;
			font-weight: 700;
			font-family: 'Roboto', sans-serif;
		}
	}
}
.event-form {
	.input-group {
		.form-control {
			height: 40px;
		}
	}
}
.profile-sidebar {
	background-color: #fff;
	border: 1px solid #f0f0f0;
	border-radius: 4px;
	margin-bottom: 30px;
	overflow: hidden;
}
.pro-widget-content {
	padding: 20px;
	text-align: center;
}
.profile-info-widget {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	text-align: left;
	.booking-pro-img {
		margin-right: 15px;
		img {
			border-radius: 4px;
			height: 90px;
			width: 90px;
			object-fit: cover;
		}
	}
}
.profile-det-info {
	overflow: hidden;
	h3 {
		font-size: 16px;
		font-weight: 500;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		margin-bottom: 12px;
	}
	h4 {
		font-weight: 500;
		font-size: 14px;
		color: #161c2d;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		margin-bottom: 12px;
	}
	p {
		color: #696969;
		font-size: 14px;
		font-weight: normal;
		margin-bottom: 8px;
		margin-bottom: 12px;
	}
}
.customer-details {
	h5 {
		color: #AAAAAA;
		font-size: 12px;
		font-weight: normal;
		margin-bottom: 8px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		margin-bottom: 12px;
		i {
			width: 18px;
		}
	}
	.rating {
		.average-rating {
			font-size: 9px;
			background-color: #FEBE42;
			border-radius: 5px;
			color: #fff;
			font-weight: 600;
			width: 22px;
			height: 16px;
			line-height: 16px;
			display: inline-flex;
			display: -ms-inline-flexbox;
			margin-right: 5px;
			align-items: center;
			justify-content: center;
		}
	}
}
.customer-job {
	padding: 20px 0;
	i {
		color: #F5365C;
		font-size: 18px;
		margin-right: 15px;
	}
}
.pat-widget-profile {
	background: #fff;
	border: 1px solid rgba(239, 239, 239, 0.42);
	box-sizing: border-box;
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	border-radius: 5px;
	.pro-widget-content {
		padding: 0;
		margin-top: 0;
	}
	.booking-date {
		li {
			font-size: .875rem;
			span {
				font-size: .875rem;
			}
		}
	}
	.card-body {
		padding: 35px 20px 20px;
	}
	.card-footer {
		padding: 15px;
		background: #FBFBFB;
	}
}
.card-top {
	position: relative;
	&::before {
		content: '';
		background-color: rgba(20, 20, 20, 0.7);
		bottom: 0;
		height: 100%;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
	}
	.dev-user {
		position: absolute;
		left: 50%;
		transform: translate(-50%, -80%);
		-webkit-transform: translate(-50%, -80%);
		-ms-transform: translate(-50%, -80%);
		display: flex;
		display: -webkit-flex;
		&::before {
			background-color: #159C5B;
			position: absolute;
			right: 0;
			bottom: 0;
			width: 22px;
			height: 22px;
			border-radius: 50%;
			content: "\f00c";
			font-weight: 600;
			font-family: "Font Awesome 5 Free";
			font-size: 13px;
			justify-content: center;
			align-items: center;
			color: #fff;
			display: inline-flex;
			display: -ms-inline-flexbox;
		}
		img {
			margin: auto;
			width: 86px;
			height: 86px;
			border-radius: 30px;
		}
	}
	.bg-none {
		&.dev-user {
			&::before {
				visibility: hidden;
			}
		}
	}
	.dev-text {
		position: absolute;
		top: 0;
		transform: none;
		text-align: center;
		padding: 15px;
		width: 100%;
		left: 0;
		right: 0;
		.left {
			font-weight: 500;
			font-size: 10px;
			text-transform: uppercase;
			color: #fff;
		}
		.right {
			color: #fff;
			font-weight: bold;
			background: #86E53E;
			border: 1px solid #86E53E;
			border-radius: 130px;
			font-size: 14px;
			width: 37px;
			height: 37px;
			line-height: 34px;
			padding: 0;
			&.bg-grey {
				background: #EEEEEE;
				border: 1px solid #EEEEEE;
			}
		}
		a {
			&:hover {
				.right {
					&.bg-grey {
						background: #86E53E;
						border: 1px solid #86E53E;
					}
				}
			}
		}
	}
}
.left-bid {
	padding-top: 20px;
}
.vote {
	background: #EEEEEE;
	border-radius: 5px;
	font-weight: 600;
	font-size: 9px;
	color: #7D7D7D;
	padding: 5px 10px;
}
.pro-widget-profile {
	border: 1px solid rgba(239, 239, 239, 0.42);
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	border-radius: 5px;
	.dev-user {
		transform: translate(-50%, -50%);
	}
	.dev-text {
		.left {
			text-align: left;
			img {
				display: block;
				float: left;
				width: 66px;
				border-radius: 12px;
			}
			.left-content {
				margin-left: 75px;
				img {
					width: auto;
				}
			}
		}
	}
	.customer-info {
		margin-top: 0;
		ul {
			padding: 0;
		}
	}
	.slot {
		p {
			font-size: 12px;
		}
		h5 {
			font-size: 13px;
			margin: 0;
		}
	}
	.badge-design {
		font-size: 12px;
		padding: 5px 15px;
		margin-bottom: 5px;
	}
	.card-header {
		border-bottom: 1px solid #F8F8F8;
		padding: 1.5rem;
	}
	.profile-info-widget {
		text-align: left;
	}
	.pro-widget-content {
		padding: 0;
	}
}
.left {
	.left-img {
		position: absolute;
		&::before {
			background-color: #159C5B;
			position: absolute;
			right: 2px;
			bottom: 0;
			width: 22px;
			height: 22px;
			border-radius: 50%;
			content: "\f00c";
			font-weight: 600;
			font-family: "Font Awesome 5 Free";
			font-size: 13px;
			justify-content: center;
			align-items: center;
			color: #fff;
			display: inline-flex;
			display: -ms-inline-flexbox;
		}
	}
}
.client {
	&.pro-widget-profile {
		.dev-text {
			.left {
				.left-content {
					margin-left: 0;
				}
			}
		}
		.card-img-top {
			border-radius: 5px 0 5px 0;
		}
	}
}
.left-content {
	h6 {
		font-weight: 500;
		font-size: 12px;
		color: #fff;
		i {
			color: #159C5B;
		}
	}
	h4 {
		font-weight: bold;
		font-size: 16px;
		color: #fff;
		a {
			color: #fff;
		}
	}
	p {
		font-weight: 500;
		font-size: 11px;
		color: #fff;
		margin-bottom: 5px;
	}
}
.dev-right {
	margin-left: 20px;
	p {
		font-weight: bold;
		font-size: 17px;
		color: #fff;
	}
	h6 {
		font-weight: 600;
		font-size: 14px;
		color: #fff;
		margin-top: 5px;
	}
}
.profile-box {
	padding: 30px;
	.bid-btn {
		font-size: 14px;
	}
}
.profile-freelance {
	.provider-img {
		img {
			border-radius: 15px;
		}
	}
}
.bid-btn {
	background: #FF5E3B;
	border-radius: 4px;
	font-weight: 600;
	font-size: 14px;
	color: #fff;
	white-space: nowrap;
	&:hover {
		color: #fff;
		background-color: #fb451d;
		border-color: #fb451d;
	}
}
.star {
	color: #fff;
	font-weight: bold;
	background: #86E53E;
	border: 1px solid #86E53E;
	border-radius: 130px;
	font-size: 14px;
	width: 37px;
	height: 37px;
	line-height: 37px;
	padding: 0;
	text-align: center;
}
.bootstrap-tags {
	padding: 0 15px 15px;
	text-align: center;
	border-top: 1px solid #FFFBFB;
}
.badge-tech {
	font-weight: 500;
	font-size: 10px;
	line-height: 15px;
	color: #161c2d;
	background: rgba(19, 17, 53, 0.1);
	border-radius: 40px;
	margin-right: 4px;
	&:last-child {
		margin-right: 0;
	}
}
.badge-design {
	font-weight: 500;
	font-size: 12px;
	color: #2e2e2e;
	background: #FFEEEA;
	border-radius: 60px;
	margin-right: 3px;
	display: inline-block;
	padding: 7px 10px;
	&:last-child {
		margin-right: 0;
	}
	&:hover {
		background-color: #FFF0F0;
	}
}
.slots {
	padding-bottom: 10px;
}
.slot {
	p {
		font-size: 12px;
		color: #6e727d;
		margin-bottom: 3px;
		font-weight: normal;
	}
	h5 {
		font-size: 12px;
		font-weight: 500;
		color: #161c2d;
		i {
			color: #86E53E;
		}
	}
}
.profile-btn {
	background: #86E53E;
	border-radius: 5px;
	font-weight: 500;
	font-size: 12px;
	color: #fff;
	&:hover {
		color: #fff;
		background-color: #fb451d;
		border-color: #fb451d;
	}
}
.job-btn {
	background: #8553EE;
	border-radius: 5px;
	font-weight: 500;
	font-size: 14px;
	color: #fff;
	&:hover {
		color: #fff;
		background-color: #7d46ef;
		border-color: #7d46ef;
	}
}
.badge-skill {
	background: #E7E8EA;
	border-radius: 6px;
	font-weight: 500;
	font-size: 12px;
	color: #6e727d;
	padding: 10px;
	margin: 0 7px 7px 0;
}
.tags {
	list-style: none;
	margin: 0;
	overflow: hidden;
	padding: 0;
	.badge-skills {
		width: 195px;
	}
	li {
		float: left;
	}
}
.badge-skills {
	background-color: #edeef3;
	border-radius: 6px;
	font-size: 15px;
	font-weight: normal;
	margin: 0 2px 7px 0;
	padding: 10px 15px;
	color: #55545b;
	&:hover {
		background-color: #FFF0F0;
		.tag-close {
			background-color: #86E53E;
			color: #fff;
		}
	}
}
.tag-close {
	width: 20px;
	height: 20px;
	display: inline-flex;
	background-color: #d3d4d9;
	border-radius: 50%;
	justify-content: center;
	align-items: center;
	margin-left: 5px;
	font-size: 12px;
	cursor: pointer;
}
.profile-title {
	font-weight: 600;
	font-size: 30px;
	color: #161c2d;
}
.profile-position {
	font-size: 16px;
	color: #86E53E;
}
.prof-img, .camera-bg {
	position: relative;
}
.prof-img {
	&::before {
		background-color: #159C5B;
		position: absolute;
		left: 50%;
		transform: translate(-50%, -23%);
		bottom: 0;
		width: 22px;
		height: 22px;
		border-radius: 50%;
		content: "\f00c";
		font-weight: 600;
		font-family: "Font Awesome 5 Free";
		font-size: 13px;
		justify-content: center;
		align-items: center;
		color: #fff;
		display: inline-flex;
		display: -ms-inline-flexbox;
	}
}
.camera-bg {
	background-color: #86E53E;
	position: absolute;
	left: 50%;
	transform: translate(-50%, 30%);
	bottom: 0;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	content: "\f00c";
	content: "\f030";
	font-weight: 600;
	font-family: "Font Awesome 5 Free";
	font-size: 18px;
	justify-content: center;
	align-items: center;
	color: #fff;
	display: inline-flex;
	display: -ms-inline-flexbox;
}
.full-btn {
	background: #129BE8;
	border-radius: 5px;
	font-weight: 500;
	font-size: 14px;
	color: #fff;
	&:hover {
		color: #fff;
		opacity: 0.8;
	}
}
.profile-list {
	ul {
		margin: 0;
		padding-left: 0;
		list-style: none;
		display: flex;
		display: -webkit-flex;
		align-items: center;
		-webkit-align-items: center;
		flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
		justify-content: space-between;
		-webkit-justify-content: space-between;
		border-top: 1px solid #E9E9E9;
	}
	li {
		font-weight: 500;
		font-size: 16px;
		color: #161c2d;
		border-right: 1px solid #E9E9E9;
		text-align: center;
		margin: 0 auto;
		padding: 15px 5px;
		width: calc(100%/6);
		&:last-child {
			border-right: 0;
		}
	}
	.proj-title {
		font-weight: 500;
		font-size: 16px;
		color: #161c2d;
	}
}
.profile-preword {
	margin: 15px 0 0 0;
	padding-left: 0;
	list-style: none;
	display: inline-flex;
	display: -ms-inline-flexbox;
	flex-wrap: wrap;
	li {
		font-weight: 500;
		font-size: 16px;
		color: #8D8D8D;
		margin-right: 20px;
		margin-bottom: 5px;
		img {
			box-shadow: 1px 0px 5px #aaaaaa;
			margin-right: 2px;
		}
	}
	.rating {
		.average-rating {
			font-size: 11px;
			background-color: #FEBE42;
			border-radius: 5px;
			color: #fff;
			font-weight: 600;
			width: 22px;
			height: 16px;
			line-height: 16px;
			display: inline-flex;
			display: -ms-inline-flexbox;
			margin-right: 5px;
			align-items: center;
			justify-content: center;
		}
	}
}
.profile-inf {
	text-align: right;
	p {
		i {
			&.heart {
				color: #F5365C;
				font-size: 30px;
				margin-right: 10px;
			}
		}
	}
}
.bottom-text {
	position: absolute;
	right: 0;
	bottom: 0;
}
.profile-right {
	padding-left: 0;
	list-style: none;
	display: inline-flex;
	display: -ms-inline-flexbox;
	flex-wrap: wrap;
	li {
		font-weight: 600;
		font-size: 12px;
		color: #161c2d;
		margin-right: 20px;
		&:last-child {
			margin-right: 0;
			padding-top: 0;
		}
	}
}
.amt-hr {
	font-weight: 500;
	font-size: 36px;
	color: #F5365C;
	p {
		font-size: 16px;
		color: #161c2d;
	}
}
.pro-post {
	background: #fff;
	box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.25);
	margin-bottom: 30px;
	border: 1px solid #e8e8e8;
}
.pro-title {
	font-weight: bold;
	font-size: 20px;
	position: relative;
	color: #161c2d;
	padding-bottom: 10px;
	margin-bottom: 15px;
	border-bottom: 1px solid #E6E6E6;
	&::before {
		height: 3px;
		content: "";
		position: absolute;
		width: 29px;
		background: #86E53E;
		bottom: 0;
		left: 0;
	}
	&::after {
		height: 3px;
		content: "";
		position: absolute;
		width: 11px;
		background: #86E53E;
		bottom: 0;
		left: 32px;
	}
	&.without-border {
		border: 0;
		padding-bottom: 0;
		margin-bottom: 0;
	}
}
.pro-title.without-border:before,
.pro-title.without-border:after {
	display: none;
}
.pro-list {
	list-style: none;
	padding: 0;
}
.project-widget {
	background: #fff;
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	margin-bottom: 30px;
	.select2-container {
		z-index: unset;
	}
	.pro-image {
		border-radius: 5px 5px 0px 0px;
		border-radius: 5px 5px 0px 0px;
	}
	.pro-detail {
		text-align: center;
		padding: 15px;
	}
	.pro-name {
		font-weight: 600;
		font-size: 13px;
		text-transform: uppercase;
		color: #161c2d;
		margin-bottom: 5px;
	}
	.pro-designation {
		font-size: 12px;
		color: #636363;
		margin-bottom: 0;
	}
}
.more-btn {
	background-color: #86E53E;
	border-radius: 150px;
	color: #fff;
	padding: 9px 16px;
	font-size: 12px;
	margin-right: 8px;
	font-weight: 600;
	color: #fff;
	&:hover {
		color: #fff;
		opacity: 0.8;
	}
}
.widget-list {
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			float: left;
			margin: 6px 0;
			padding-left: 25px;
			position: relative;
			&::before {
				color: #86E53E;
				content: '\f30b';
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				left: 0;
				position: absolute;
			}
			h4 {
				font-weight: 500;
				font-size: 16px;
				color: #161c2d;
			}
			h5 {
				font-weight: normal;
				font-size: 14px;
				color: #F38247;
				margin-bottom: 15px;
			}
			p {
				font-size: 14px;
				color: #000;
			}
		}
	}
}
.bookmark {
	margin-bottom: 0;
	h5 {
		font-weight: 500;
		font-size: 18px;
		color: #161c2d;
		margin-bottom: 15px;
	}
}
.bookmark-btn {
	background-color: #FEBE42;
	border-radius: 6px;
	color: #fff;
	padding: 9px 16px;
	font-size: 18px;
	margin-right: 8px;
	font-weight: 600;
	color: #fff;
	&:hover {
		color: #fff;
		background-color: #fab530;
		border-color: #fab530;
	}
}
.share-icon {
	display: inline-block;
	background-color: #13323C;
	border-radius: 6px;
	color: #fff;
	padding: 9px 16px;
	font-size: 18px;
	font-weight: 600;
	color: #fff;
	&:hover {
		color: #fff;
		opacity: 0.8;
	}
}
.follow-btn {
	background-color: #8553EE;
	border-radius: 50px;
	color: #fff;
	padding: 9px 30px;
	font-size: 20px;
	font-weight: 600;
	color: #fff;
	&:hover {
		color: #fff;
		background-color: #7a40f3;
		border-color: #7a40f3;
	}
}
.follow-posts {
	margin: 30px auto 0;
	padding: 10px 0;
	list-style: none;
	width: 76%;
	li {
		border-right: 1px solid #E9E9E9;
		display: inline-block;
		padding: 0 20px;
		&:last-child {
			border-right: 0;
		}
		p {
			font-size: 16px;
			color: #161c2d;
			margin-bottom: 2px;
		}
		h6 {
			font-size: 20px;
			color: #161c2d;
			font-weight: 500;
		}
	}
}
.about-widget {
	ul {
		li {
			p {
				font-size: 15px;
				color: #5c5c5c;
				margin-bottom: 0;
			}
			h6 {
				font-size: 15px;
				color: #000;
				font-weight: 600;
				margin-bottom: 3px;
			}
		}
	}
	&.profile-overview {
		p {
			margin-bottom: 10px;
		}
	}
}
.feedback-form {
	background: #FEFEFE;
}
.proposal-widget {
	padding: 20px;
	p {
		margin-bottom: 0;
		padding: 10px 0 0 30px;
	}
	.proposal-text {
		font-weight: 600;
		font-size: 16px;
	}
	.custom_check {
		margin-bottom: 0;
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: center;
		.checkmark {
			top: auto;
		}
	}
	.about-author {
		& + .about-author {
			margin-top: 20px;
		}
	}
	.about-author-img {
		border-radius: 0;
		background-color: transparent;
	}
}
.proposal-features {
	.proposal-widget {
		margin-bottom: 20px;
	}
}
.proposal-success {
	background: rgb(250 255 251 / 30%);
	border: 1px solid rgb(0 190 95 / 30%);
	.proposal-text {
		color: #00BE5F;
	}
}
.proposal-light {
	background: rgba(255, 248, 189, 0.3);
	border: 1px solid rgba(185, 166, 0, 0.3);
	.proposal-text {
		color: #E2BD00;
	}
}
.proposal-danger {
	background: rgba(241, 92, 61, 0.2);
	border: 1px solid rgba(241, 92, 61, 0.3);
	.proposal-text {
		color: #CA2500;
	}
}
.proposal-pink {
	background: rgba(255, 248, 189, 0.3);
	border: 1px solid rgb(255 248 189 / 30%);
}
.proposal-greens {
	background: #FAFFFB;
	border: 1px solid #CFFAD8;
}
.activity-list {
	list-style: none;
	padding: 0;
	margin: 0;
	li {
		font-weight: 600;
		font-size: 16px;
		color: #4B4B4B;
		& + li {
			padding-top: 13px;
		}
		&:last-child {
			padding-bottom: 0;
		}
		span {
			color: #000;
		}
	}
}
.tags .badge-design, .tags .badge-tech {
	font-size: 14px;
	padding: 10px 30px;
	margin-bottom: 5px;
}
.payment-detail {
	padding: 30px;
	img {
		margin-bottom: 10px;
	}
	h2 {
		font-size: 32px;
	}
	p {
		font-size: 18px;
		font-weight: 500;
		margin-bottom: 0;
	}
}
.click-btn {
	font-weight: 500;
	border-radius: 3px;
	padding: 11px 35px;
	color: #fff;
	text-align: center;
	display: inline-block;
	&:hover {
		color: #fff;
	}
}
.transaction {
	padding: 35px;
	text-align: center;
	border-top: 1px solid #E7E7E7;
	h5 {
		font-weight: 500;
		font-size: 20px;
		color: #161c2d;
		padding: 15px 0;
	}
}
.billing {
	border: 0;
	.card-header {
		border-bottom: 1px solid #E7E7E7;
		p {
			font-size: 19px;
			color: #161c2d;
			margin-bottom: 0;
		}
	}
}
.payment-list {
	h3 {
		font-weight: 700;
		color: #161c2d;
		margin-bottom: 25px;
		font-size: 20px;
	}
	label {
		font-weight: 600;
		font-size: 15px;
		color: #161c2d;
	}
}
.btn-pad {
	margin: 25px 0;
}
.billing-method {
	padding: 20px;
}
.review-content {
	border-bottom: 1px solid #ececec;
	padding: 30px;
	h4 {
		font-weight: 600;
		font-size: 17px;
		color: #161c2d;
	}
	p {
		font-size: 15px;
		margin: 12px 0px;
		font-weight: 500;
	}
	&.no-padding {
		padding: 0 0 1.5rem 0;
		& + .review-content {
			&.no-padding {
				padding-top: 1.5rem;
			}
		}
	}
	&:last-child {
		padding-bottom: 0;
		border: 0;
	}
}
.review-btn {
	background: #86E53E;
	border-radius: 5px;
	font-weight: 500;
	font-size: 14px;
	color: #fff;
}
.grey-btn {
	background: #D9D9D9;
}
.widget-profile {
	background-color: #fff;
	border-bottom: 1px solid #f0f0f0;
	.profile-info-widget {
		display: block;
		text-align: center;
		.booking-pro-img {
			display: inline-block;
			margin: 0 0 15px;
			width: auto;
			padding: 8px;
			background-color: #f7f7f7;
			border-radius: 50%;
			img {
				border-radius: 50%;
				height: 120px;
				width: 120px;
			}
		}
	}
	&.pat-widget-profile {
		.profile-info-widget {
			.booking-pro-img {
				padding: 0;
				img {
					border-radius: 50%;
					height: 100px;
					width: 100px;
				}
			}
		}
	}
}
.info-details {
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		margin-bottom: 10px;
		.title {
			color: #161c2d;
			font-weight: 500;
		}
		.text {
			color: #757575;
			display: block;
			font-size: 16px;
			overflow: hidden;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.align-right {
	text-align: right;
}
.slider-labels {
	margin-top: 10px;
	.caption {
		background: #FFEEEA;
		border-radius: 80px;
		padding: 3px 8px;
		text-align: center;
		margin: 0 auto;
		position: relative;
		top: 10px;
		font-weight: bold;
		font-size: 16px;
		color: #86E53E;
		&::before {
			border: 5px solid #ffeeea;
			border-color: transparent transparent #ffeeea #ffeeea;
			box-shadow: -2px 2px 2px -1px rgb(0 0 0 / 10%);
			content: "";
			left: 38px;
			position: absolute;
			top: 2px;
			-webkit-transform-origin: 0 0;
			transform-origin: 0 0;
			-webkit-transform: rotate(135deg);
			transform: rotate(135deg);
		}
	}
}
.noUi-target,.noUi-target * {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-ms-touch-action: none;
	touch-action: none;
	-ms-user-select: none;
	-moz-user-select: none;
	user-select: none;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.noUi-target {
	position: relative;
	direction: ltr;
	border-radius: 2px;
}
.noUi-base {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1;
}
.noUi-origin {
	position: absolute;
	right: 0;
	top: 0;
	left: 0;
	bottom: 0;
	border-radius: 2px;
}
.noUi-handle {
	position: relative;
	z-index: 1;
	cursor: default;
	-webkit-box-sizing: content-box !important;
	-moz-box-sizing: content-box !important;
	box-sizing: content-box !important;
	&:active {
		border: 8px solid #345DBB;
		border: 8px solid rgba(53,93,187,0.38);
		-webkit-background-clip: padding-box;
		background-clip: padding-box;
		left: -14px;
		top: -14px;
	}
}
.noUi-stacking {
	.noUi-handle {
		z-index: 10;
	}
}
.noUi-state-tap {
	.noUi-origin {
		-webkit-transition: left 0.3s,top .3s;
		-moz-transition: left 0.3s,top .3s;
		transition: left 0.3s,top .3s;
	}
}
.noUi-state-drag {
	* {
		cursor: inherit !important;
	}
}
.noUi-base,.noUi-handle {
	-webkit-transform: translate3d(0,0,0);
	-moz-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}
.noUi-horizontal {
	height: 4px;
	.noUi-handle {
		width: 18px;
		height: 18px;
		border-radius: 50%;
		left: -7px;
		top: -7px;
		background-color: #86E53E;
		cursor: pointer;
	}
}
.noUi-background {
	background: #EDEDED;
}
.noUi-connect {
	background: #86E53E;
	-webkit-transition: background 450ms;
	transition: background 450ms;
}
.noUi-draggable {
	cursor: w-resize;
}
.noUi-vertical {
	.noUi-draggable {
		cursor: n-resize;
	}
}
[disabled].noUi-connect,[disabled] .noUi-connect {
	background: #B8B8B8;
}
[disabled].noUi-origin,[disabled] .noUi-handle {
	cursor: not-allowed;
}
.add-new-btn {
	background-color: #ff3115;
	border-radius: 30px;
	color: #fff;
	display: inline-block;
	font-weight: 500;
	margin-bottom: 20px;
	padding: 10px 20px;
}
.add-new-btn:focus, .add-new-btn:hover,.add-new-btn:active {
	background-color: #ff508b;
	color: #fff;
}
.customer-info {
	margin-top: 0;
	ul {
		padding: 0 0 10px;
		list-style: none;
		font-size: .875rem;
		margin: 0;
		li {
			position: relative;
			font-size: .875rem;
			font-weight: 600;
			color: #161c2d;
			text-transform: capitalize;
			span {
				color: #757575;
				float: right;
				font-weight: 400;
			}
		}
	}
}
.add-more-item {
	margin-bottom: 10px;
	a {
		color: #ff3115;
		font-weight: 500;
	}
}
.blog-image {
	overflow: hidden;
	margin-bottom: 30px;
	img {
		-webkit-transform: translateZ(0);
		-moz-transform: translateZ(0);
		transform: translateZ(0);
		-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
		-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
		-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
		-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
		transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	}
	a {
		&:hover {
			img {
				-webkit-transform: scale(1.15);
				-moz-transform: scale(1.15);
				transform: scale(1.15);
			}
		}
	}
}
.blog-image,
.blog-image > a,
.blog-image img {
	display: block;
	position: relative;
	width: 100%;
	height: auto;
}
.blog-content {
	position: relative;
	p {
		font-size: 15px;
		margin: 0 0 20px;
		line-height: 1.7;
		font-weight: 500;
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.blog-title {
	font-size: 22px;
	margin: 0 0 15px;
	a {
		color: #161c2d;
		&:hover {
			color: #ff3115;
		}
	}
}
.blog-read {
	margin-top: 15px;
	a {
		color: #86E53E;
		font-size: 15px;
		font-weight: 600;
		&:hover {
			color: #13323C;
		}
		i {
			font-size: 13px;
		}
	}
}
.read-more {
	display: inline-block;
	font-size: 16px;
	padding: 0;
	color: #86E53E;
	text-decoration: underline;
	font-weight: 500;
}
.blog-info {
	color: #606060;
	font-size: 15px;
	margin-bottom: 15px;
	a {
		color: #606060;
		&:hover {
			color: #ff3115;
		}
	}
	i {
		margin-right: 7px;
	}
}
.entry-meta {
	color: #9D9D9D;
	font-size: 14px;
	list-style: none;
	margin-bottom: 15px;
	padding: 0;
	li {
		display: inline-block;
		margin-right: 15px;
		&:last-child {
			margin-right: 0;
		}
	}
}
.blog-pagination {
	.pagination {
		margin-bottom: 30px;
		li {
			a {
				padding: 12px 20px;
				font-weight: 600;
				color: #2E3842;
				margin-right: 10px;
				border-radius: inherit !important;
				border-color: #f0f0f0;
			}
			&.disabled {
				a {
					border-color: #f0f0f0;
				}
			}
			&.active {
				a {
					background: #ff3115;
					border-color: transparent;
					color: #fff;
				}
			}
		}
	}
}
.post-left {
	ul {
		margin: 0;
		padding-left: 0;
		list-style: none;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		li {
			margin-bottom: 5px;
			margin-right: 20px;
			&:last-child {
				margin-right: 0;
			}
		}
	}
}
.video {
	position: relative;
	padding-bottom: 56%;
	height: 0;
	overflow: hidden;
}
.video iframe,
.video object,
.video embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: none;
}
.language-widget {
	.latest-posts {
		li {
			border-bottom: 0;
		}
	}
}
.latest-posts {
	margin: 0;
	padding: 0;
	li {
		display: table;
		width: 100%;
		padding: 11px 0;
		&:last-child {
			padding-bottom: 0;
			margin-bottom: 0;
			border-bottom: none;
		}
	}
}
.category-widget {
	.latest-posts {
		li {
			padding: 15px 30px;
			&:first-child {
				padding-top: 0;
			}
			&:last-child {
				margin-bottom: 0;
				border-bottom: none;
			}
		}
	}
	.category-link {
		list-style: none;
		padding: 0;
		margin: 0;
		li {
			margin-bottom: 17px;
			a {
				color: #2E3842;
				font-size: 15px;
				-webkit-transition: 0.3s;
				-o-transition: 0.3s;
				transition: 0.3s;
				text-transform: capitalize;
				display: block;
				position: relative;
				&:after {
					display: inline-block;
					position: absolute;
					right: 0;
					content: "\f105";
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
					color: #2E3842;
				}
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.categories {
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			margin-bottom: 20px;
			&:last-child {
				border-bottom: none;
				margin-bottom: 0;
				padding-bottom: 0;
			}
			a {
				color: #2E3842;
				font-size: 15px;
				font-weight: 500;
				span {
					float: right;
					color: #757575;
				}
				&:hover {
					color: #ff3115;
				}
			}
		}
		& > li {
			& > a {
				& > i {
					color: #73379a;
					font-size: 18px;
					margin-right: 10px;
				}
			}
		}
	}
}
.post-thumb {
	border-radius: 10px;
	width: 77px;
	float: left;
	overflow: hidden;
	a {
		img {
			border-radius: 10px;
			-webkit-transform: translateZ(0);
			-moz-transform: translateZ(0);
			transform: translateZ(0);
			-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
			-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
			-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
			-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
			transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
		}
		&:hover {
			img {
				-webkit-transform: scale(1.15);
				-moz-transform: scale(1.15);
				transform: scale(1.15);
			}
		}
	}
}
.b-0 {
	border: 0 !important;
}
.post-info {
	margin-left: 95px;
	h4 {
		font-size: 16px;
		font-weight: bold;
		line-height: 25px;
		margin-bottom: 0;
		a {
			color: #161c2d;
			&:hover {
				color: #ff3115;
			}
		}
	}
	p {
		color: #757575;
		font-size: 12px;
		margin: 0;
		i {
			color: #73379a;
			font-size: 14px;
			margin-right: 4px;
		}
	}
	.posts-date {
		color: #6e727d;
		font-size: 14px;
	}
}
.tags-widget {
	.card-body {
		padding-bottom: 1rem;
	}
}
.tag {
	display: inline-block;
	padding: 3px 20px;
	background-color: #E7E8EA;
	border-radius: 50px;
	font-size: 15px;
	color: #6e727d;
	margin: 0 10px 10px 0;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	&:hover {
		background-color: #86E53E;
		border-color: #86E53E;
		color: #fff;
	}
}
.comment-by {
	display: block;
	font-size: 14px;
	line-height: 21px;
	margin: 0 0 10px;
}
.comments-list {
	.comment-block {
		p {
			margin-bottom: 5px;
			color: #6e727d;
			line-height: 28px;
		}
	}
}
.blog-view {
	.blog-title {
		font-size: 24px;
		font-weight: bold;
	}
	.blog-info {
		border: 0;
		margin-bottom: 20px;
		padding: 0;
	}
}
.social-links {
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		li {
			-webkit-transition: all .3s linear;
			-o-transition: all .3s linear;
			transition: all .3s linear;
			display: inline-block;
			margin-right: 15px;
		}
	}
	li {
		a {
			display: block;
			color: #606060;
			-webkit-transition: 0.3s;
			-o-transition: 0.3s;
			transition: 0.3s;
			&:hover {
				color: #86E53E;
			}
		}
	}
}
.social-share {
	float: left;
	list-style: none;
	margin: 0;
	padding: 0;
	& > li {
		display: inline-block;
		float: left;
		margin-left: 10px;
		text-align: center;
		&:first-child {
			margin-left: 0;
		}
		& > a {
			border: 1px solid #dfdfdf;
			color: #666;
			display: inline-block;
			font-size: 22px;
			height: 40px;
			line-height: 40px;
			width: 40px;
			&:hover {
				background-color: #73379a;
				color: #fff;
				border-color: #73379a;
			}
		}
	}
}
.blog-reply {
	& > a {
		color: #999;
		font-size: 12px;
		font-weight: 500;
	}
}
.blog-date {
	color: #999;
	font-size: 12px;
}
.blog-comments {
	.comments-list {
		list-style: none;
		margin: 0;
		padding: 0;
		position: relative;
		li {
			clear: both;
			padding-left: 80px;
			.comment {
				margin-bottom: 40px;
			}
			.comment-author {
				left: 0;
				position: absolute;
			}
			img {
				&.avatar {
					height: 58px;
					width: 58px;
					border-radius: 58px;
				}
			}
		}
	}
	.comment-btn {
		color: #606060;
		display: inline-block;
		font-size: 16px;
		font-weight: bold;
	}
}
.blog-author-name {
	color: #161c2d;
	font-size: 18px;
	font-weight: bold;
	color: #161c2d;
	font-size: 18px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 5px;
	font-weight: 700;
}
.new-comment {
	label {
		font-weight: 500;
	}
}
.comment-submit {
	.btn {
		background-color: #ff508b;
		border-color: #ff508b;
		border-radius: 0;
		font-size: 18px;
		padding: 8px 26px;
		color: #fff;
	}
}
.about-author {
	border-bottom: 1px solid #EAEAEA;
	padding: 30px;
	&:last-child {
		border-bottom: 0;
	}
}
.about-author-img {
	background-color: #fff;
	height: 86px;
	overflow: hidden;
	position: absolute;
	width: 86px;
	border-radius: 50%;
}
.author-details {
	margin-left: 116px;
	.blog-author-name {
		display: inline-block;
		margin-bottom: 10px;
	}
	h4 {
		font-weight: 500;
		font-size: 18px;
		color: #161c2d;
		margin-bottom: 6px;
	}
	p {
		font-size: 16px;
		color: #55545b;
	}
	h3 {
		font-weight: 500;
		font-size: 24px;
		color: #F5365C;
	}
	h5 {
		font-weight: normal;
		font-size: 14px;
		color: #F38247;
	}
	.rating {
		margin-bottom: 6px;
	}
}
.widget-box {
	padding: 20px;
}
.widget-title-box {
	padding: 30px 30px 0;
}
.r-0 {
	right: 0 !important;
}
.follow-widget {
	padding: 30px;
	text-align: center;
	position: relative;
}
.custom-edit-btn {
	position: absolute;
	right: 30px;
	top: 30px;
}
.profile-group {
	.input-group {
		background: #fff;
		border: 1px solid #E7E8EA;
		border-radius: 5px;
		padding: 2px;
		.form-control {
			border: transparent;
		}
		.input-group-append {
			margin: 5px 5px;
			justify-content: space-between;
			align-items: center;
			display: flex;
		}
		.sub-btn {
			background: #86E53E;
			border-radius: 10px;
		}
	}
}
.attach-img {
	position: relative;
}
.attach-detail {
	position: absolute;
	bottom: 12px;
	left: 12px;
	p {
		margin-bottom: 2px;
		font-size: 14px;
		color: #161c2d;
	}
	h6 {
		font-size: 14px;
		color: #A3A3A3;
		font-weight: normal;
		margin-bottom: 0;
	}
}
.client-list {
	padding: 15px;
	text-align: center;
	p {
		font-weight: 500;
		font-size: 16px;
		color: #5C5C5C;
		margin-bottom: 10px;
	}
	h6 {
		font-weight: 600;
		font-size: 24px;
		margin-bottom: 0;
	}
}
.job-type {
	padding: 15px;
	text-align: center;
	p {
		font-weight: 500;
		font-size: 14px;
		color: #4E4E4E;
		margin-bottom: 10px;
	}
	h6 {
		font-weight: 500;
		font-size: 20px;
		margin-bottom: 0;
		color: #161c2d;
	}
}
.exp-detail {
	margin-bottom: 30px;
	img {
		float: left;
	}
	p {
		font-weight: 500;
		font-size: 14px;
		color: #4E4E4E;
		margin-bottom: 8px;
		font-weight: 500;
		font-size: 16px;
		color: #161c2d;
	}
	.exp-info {
		margin-left: 40px;
		h5 {
			margin-bottom: 0;
		}
	}
}
.management-widget {
	.profile-btn {
		padding: 12px 0;
		font-size: 18px;
		text-transform: uppercase;
		margin-top: 20px;
	}
}
.sub-title {
	font-weight: 500;
	font-size: 14px;
	color: #86E53E;
}
.budget-info {
	margin-bottom: 20px;
}
.list-details {
	display: flex;
	display: -ms-flexbox;
	justify-content: space-between;
	flex-wrap: wrap;
	list-style: none;
	padding: 0;
}
.white-circle {
	background: #fff;
	border-radius: 110px;
	width: 25px;
	display: inline-block;
	height: 25px;
	line-height: 25px;
	color: #86E53E;
}
.btn-view-outline {
	color: #86E53E;
	border-color: #86E53E;
	font-size: 14px;
	&:hover {
		color: #fff;
		background-color: #86E53E;
		border-color: #86E53E;
	}
}
.w-80 {
	max-width: 80%;
}
.bg-none {
	background: transparent;
	border: 0;
}
.square-box {
	width: 34px;
	height: 34px;
	line-height: 34px;
	border: 1px solid;
	background: #F8F8F8;
	border: 1px solid #FFE6E0;
	text-align: center;
	border-radius: 5px;
}
.reply-group {
	.input-group {
		input {
			background: #F8F8F8;
			border: 0;
		}
	}
	.sub-btn {
		padding: 8px 40px;
		border: 0;
		border-radius: 0;
	}
}
.profile-widget {
	background-color: #fff;
	border: 1px solid #f0f0f0;
	border-radius: 4px;
	margin-bottom: 30px;
	position: relative;
	-webkit-transition: all .3s ease 0s;
	-moz-transition: all .3s ease 0s;
	-o-transition: all .3s ease 0s;
	transition: all .3s ease 0s;
	padding: 15px;
	.rating {
		color: #757575;
		font-size: 14px;
		margin-bottom: 15px;
	}
	.fav-btn {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		position: absolute;
		top: 5px;
		right: 5px;
		background-color: #fff;
		width: 30px;
		height: 30px;
		display: -ms-inline-flexbox;
		display: inline-flex;
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		border-radius: 3px;
		color: #2E3842;
		-webkit-transform: translate3d(100%, 0, 0);
		-ms-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
		opacity: 0;
		visibility: hidden;
		z-index: 99;
		&:hover {
			background-color: #fb1612;
			color: #fff;
		}
	}
	&:hover {
		.fav-btn {
			opacity: 1;
			visibility: visible;
			-webkit-transform: translate3d(0, 0, 0);
			-ms-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
		}
	}
	.pro-content {
		.title {
			a {
				display: inline-block;
			}
		}
	}
	.verified {
		color: #28a745;
		margin-left: 3px;
	}
	p {
		&.speciality {
			font-size: 13px;
			color: #757575;
			margin-bottom: 5px;
			min-height: 40px;
		}
	}
}
.pro-img {
	position: relative;
	overflow: hidden;
	z-index: 1;
	border-radius: 4px;
	img {
		border-radius: 4px;
		-webkit-transform: translateZ(0);
		-moz-transform: translateZ(0);
		transform: translateZ(0);
		-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
		-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
		-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
		-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
		transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
		width: 100%;
	}
	&:hover {
		img {
			-webkit-transform: scale(1.15);
			-moz-transform: scale(1.15);
			transform: scale(1.15);
		}
	}
}
.pro-content {
	padding: 15px 0 0;
	p {
		font-size: 15px;
		font-weight: 500;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.title {
		font-size: 17px;
		font-weight: 500;
		margin-bottom: 5px;
	}
	.book-btn {
		background-color: #115241;
		border: 2px solid #115241;
		color: #fff;
		font-size: 13px;
		text-align: center;
		display: block;
		font-weight: 500;
		padding: 6px;
		border-radius: .25rem;
	}
}
.available-info {
	font-size: 13px;
	color: #757575;
	font-weight: 400;
	list-style: none;
	padding: 0;
	margin-bottom: 15px;
	li {
		& + li {
			margin-top: 5px;
		}
		i {
			width: 22px;
		}
	}
}
.row {
	&.row-sm {
		margin-left: -3px;
		margin-right: -3px;
		& > div {
			padding-left: 3px;
			padding-right: 3px;
		}
	}
}
.view-btn {
	color: #115241;
	font-size: 13px;
	border: 2px solid #115241;
	text-align: center;
	display: block;
	font-weight: 500;
	padding: 6px;
}
.view-btn:hover, .view-btn:focus {
	background-color: #115241;
	color: #fff;
}
.book-btn:hover, .book-btn:focus {
	background-color: #0d5341;
	border-color: #115241;
	color: #fff;
}
.view-icons {
	display: inline-flex;
	display: -ms-inline-flexbox;
	float: right;
	a {
		align-items: center;
		background-color: #fff;
		border: 1px solid #ddd;
		border-radius: 4px;
		color: #161c2d;
		display: flex;
		display: -webkit-box;
		display: -ms-flexbox;
		font-size: 20px;
		justify-content: center;
		padding: 4px 10px;
		text-align: center;
		margin-left: 10px;
		width: 44px;
		height: 44px;
		&:hover {
			border-color: #ff3115;
			color: #ff3115;
		}
		&.active {
			background-color: #ff3115;
			border-color: #ff3115;
			color: #fff;
		}
	}
}
.terms-text {
	margin-bottom: 20px;
	h3 {
		font-weight: 600;
		font-size: 20px;
		line-height: 30px;
		text-transform: uppercase;
		color: #86E53E;
		margin-bottom: 25px;
	}
	h4 {
		font-size: 20px;
		font-weight: 600;
		margin-bottom: 20px;
		color: #000;
	}
	p {
		color: #000;
		display: inline-block;
		font-size: 15px;
		line-height: 30px;
	}
	ul {
		padding-left: 20px;
		margin-bottom: 25px;
		li {
			padding-left: 5px;
			color: #000;
			& + li {
				margin-top: 10px;
			}
			&::marker {
				content: "\f105";
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				left: 0;
				margin-right: 5px;
				position: absolute;
				top: 0;
				color: #86E53E;
			}
		}
	}
}
.list-widget {
	box-shadow: 0px 2px 4px rgba(189, 189, 189, 0.25);
	.card-body {
		padding: 0;
	}
}
.list-group-item {
	border: 1px solid #ffe6e1;
	a {
		font-size: 16px;
		color: #161c2d;
		&:hover {
			color: #86E53E;
		}
	}
	ul {
		li {
			padding: 0.75rem 0;
			&::marker {
				content: "";
			}
			&:last-child {
				padding: 0;
			}
		}
	}
	&.active {
		z-index: 2;
		color: #fff;
		background-color: #86E53E;
		border-color: #86E53E;
	}
}
.web-project {
	h4 {
		font-weight: 500;
		font-size: 22px;
		color: #86E53E;
	}
}
.stone-btn {
	font-size: 16px;
	font-weight: bold;
	background: #86E53E;
	color: #fff;
	border-radius: 30px;
	padding: 18px 25px;
}
.nav-tabs-bottom {
	.nav-link {
		padding: 1rem 1rem;
	}
}
.quick-title {
	margin-bottom: 30px;
}
.btn-group button, .btn-group .btn {
	border-radius: 130px;
}
.in-btn {
	background: #86E53E;
	border: 1px solid transparent;
	padding: 10px 15px;
}
.out-btn {
	background: #FAFAFA;
	color: #161c2d;
	border: 1px solid transparent;
	padding: 10px 15px;
}
.btn-cancel {
	background: #86E53E;
	border-radius: 4px;
	font-weight: 600;
	font-size: 16px;
	padding: 12px 20px;
	color: #fff;
}
.sort-show {
	.select2-container {
		.select2-selection--single {
			background: #fff;
			border: 1px solid #FFF5F3;
			box-shadow: 0px 4px 14px rgba(239, 239, 239, 0.35);
		}
	}
	.sort-title {
		color: #161c2d;
	}
}
.sec-line {
	position: relative;
	display: inline-flex;
	&::before {
		height: 3px;
		content: "";
		position: absolute;
		width: 29px;
		background: #FF3F00;
		bottom: 0;
		left: 0;
	}
	&::after {
		height: 3px;
		content: "";
		position: absolute;
		width: 11px;
		background: #FF3F00;
		bottom: 0;
		left: 32px;
	}
}
.stats-box {
	background: #FBFCFF;
	border-radius: 5px;
	padding: 25px;
	height: 100%;
}
.stats-content {
	margin-top: 25px;
	display: -ms-flexbox;
	display: flex;
}
.stats-detail {
	background: #fff;
	box-shadow: 0px 4px 4px rgba(236, 236, 236, 0.25);
	border-radius: 5px;
	padding: 30px;
	text-align: center;
	width: 50%;
	margin: 0 5px;
	h3 {
		color: #86E53E;
		font-family: Roboto;
		font-weight: 500;
		font-size: 34px;
	}
	p {
		font-family: Roboto;
		font-weight: 500;
	}
}
.table-borderless {
	&.table {
		tbody {
			tr {
				border-bottom: 0;
				td {
					font-weight: 500;
					font-size: 22px;
					padding: 20px;
				}
			}
		}
	}
}
.grey-text {
	color: #8C8C8C;
	padding: 10px 20px;
}
.milestone-payment, .profit-transactions, .quick-stat {
	margin-bottom: 35px;
}
.position-title {
	.sort-by {
		padding-left: 15px;
	}
}
.finance-title {
	.sort-show {
		float: right;
		.sort-title {
			color: #86E53E;
		}
	}
}
.bg-bluelight {
	background: #F5F9FF;
}
.bg-light {
	background: #FCFCFC;
}
.text-rbold {
	font-weight: bold;
	font-size: 24px;
	color: #86E53E;
}
.transaction-position {
	background: #FBFCFF;
	border-radius: 5px;
	padding: 20px;
	margin-bottom: 30px;
	h3 {
		font-size: 22px;
	}
}
.transaction-payment {
	box-shadow: 0px 4px 14px rgba(206, 206, 206, 0.25);
	margin-bottom: 50px;
	.transaction-table {
		box-shadow: none;
	}
	.tab-content {
		padding-top: 0;
	}
	ul {
		&.nav-tabs-solid {
			padding: 10px;
		}
	}
}
.transaction-table {
	box-shadow: 0px 4px 14px rgb(206 206 206 / 25%);
	.main-head {
		background: #FBFCFF;
		.sort-show {
			padding-left: 15px;
		}
	}
	.head {
		background: #F5F9FF;
	}
	.head-title {
		color: #86E53E;
		font-weight: 600;
		font-size: 24px;
	}
	.sort-title {
		font-size: 15px;
		font-weight: 500;
	}
	.table {
		td {
			font-weight: 500;
		}
	}
}
.transaction-table .main-head th, .transaction-table .main-head td {
	border: 0;
}
.export-item {
	font-weight: 500;
	font-size: 17px;
	color: #86E53E;
}
.pink-btn {
	background: #FFF8F8;
	padding: 5px 10px;
}
.green-btn {
	background: #F0FFF3;
	padding: 5px 10px;
}
.dol-btn {
	background: #F8F8F8;
	border-radius: 5px;
	padding: 10px 30px;
}
.wallet, .payment-process {
	padding: 30px;
	background: #fff;
	box-shadow: 0px 4px 14px rgb(198 198 198 / 25%);
	border-radius: 0px 0px 10px 10px;
}
.payment-process {
	.select2-container {
		z-index: unset;
	}
	ul {
		padding: 0;
		margin: 0;
		li {
			display: flex;
			display: -webkit-flex;
			justify-content: space-between;
			-webkit-justify-content: space-between;
			padding: 12px 0;
			align-items: center;
			&:last-child {
				font-size: 18px;
				padding-bottom: 0;
				font-weight: 600;
			}
		}
	}
	.sortby-fliter {
		width: 160px;
	}
	.input-group {
		&.dep-amt {
			width: 160px;
			.form-control {
				border-color: #e9ecef;
				border-radius: 0;
				text-align: right;
			}
			.input-group-text {
				border-radius: 0;
				padding: 10px 15px;
			}
		}
	}
	p {
		padding-top: 20px;
		font-size: 14px;
		color: #777777;
	}
}
.wallet-detail {
	background: #fff;
	box-shadow: 0px 4px 14px rgba(198, 198, 198, 0.25);
	border-radius: 0px 0px 10px 10px;
}
.wallet-title {
	padding: 25px;
}
.wallet-bal {
	background: #86E53E;
	padding: 25px;
	display: flex;
	align-items: center;
	p {
		color: #FFF;
		margin-bottom: 10px;
	}
	h3 {
		color: #FFF;
		font-size: 22px;
	}
}
.dol-circle {
	width: 80px;
	height: 80px;
	background: #FF4117;
	border-radius: 50%;
	text-align: center;
	line-height: 80px;
	padding: 0;
	font-size: 38px;
	color: #FFF;
	display: block;
	float: left;
	margin-right: 30px;
}
.wallet-content {
	display: flex;
	flex-wrap: wrap;
}
.wallet-pay {
	width: 50%;
	text-align: center;
	padding: 25px;
	&:first-child {
		border-right: 1px solid #EAEAEA;
	}
	p {
		margin-bottom: 5px;
	}
	h3 {
		font-weight: 600;
		font-size: 22px;
	}
}
.pay-btn {
	background: #86E53E;
	border-radius: 5px;
	color: #fff;
	font-weight: 700;
	font-size: 18px;
	padding: 12px 0;
	&:hover {
		color: #fff;
		opacity: 0.8;
	}
}
.tot-border {
	border-bottom: 1px solid #ebebeb;
}
.card-list {
	margin: 0;
	padding: 0;
	li {
		display: flex;
		display: -webkit-flex;
		justify-content: space-between;
		-webkit-justify-content: space-between;
		padding: 10px 0;
		align-items: center;
	}
}
.column-5 {
	width: calc(100%/5);
}
.price-features {
	list-style-type: none;
	border: 1px solid #eee;
	margin: 0;
	padding: 0;
	background: #FDFFFE;
	border: 1px solid #F0EAEA;
	box-shadow: 0px 4px 14px rgba(198, 198, 198, 0.25);
	border-radius: 10px;
	-webkit-transition: 0.3s;
	transition: 0.3s;
	margin: 30px 5px 0 5px;
	li {
		padding: 15px;
		text-align: center;
		color: #646464;
		font-weight: 500;
		font-size: 16px;
	}
	.header-price {
		h3 {
			font-weight: 500;
			font-size: 30px;
			color: #86E53E;
		}
		p {
			font-size: 14px;
			margin-bottom: 0;
		}
	}
	.yr-price {
		background: #FBFBFB;
		h3 {
			font-weight: 500;
			font-size: 26px;
		}
		p {
			font-size: 14px;
			margin-bottom: 0;
		}
	}
	&.plus-price {
		.yr-price {
			background: transparent;
		}
	}
	.subheader-price {
		p {
			margin-bottom: 0;
			font-weight: 500;
		}
	}
}
.plus-price {
	background: #fffcfc;
	border: 1px solid #86E53E;
	margin-top: 0;
	.plan-btn {
		background: linear-gradient(0deg, #86E53E, #86E53E), #00A171;
	}
}
.tab-btn {
	display: inline-flex;
	.in-btn {
		a {
			color: #fff;
		}
	}
	.out-btn {
		a {
			color: #161c2d;
		}
	}
}
.plan-btn {
	background: #13323C;
	border-radius: 10px;
	font-weight: 500;
	font-size: 20px;
	color: #fff;
	&:hover {
		color: #fff;
		background: #fb451d;
	}
}
.change-btn {
	background: linear-gradient(113.98deg, #86E53E 19.08%, #86E53E 68.11%), #86E53E;
	border-radius: 10px;
	font-weight: 600;
	font-size: 20px;
	color: #fff;
}
.plan-detail {
	background: #fff;
	box-shadow: 0px 4px 24px 10px rgba(231, 231, 231, 0.3);
	border-radius: 15px;
}
.bt-0 {
	border: 0;
}
.plan-left {
	h2 {
		color: #86E53E;
	}
}
.plan-right {
	text-align: right;
}
.plan-body {
	padding: 30px;
}
.transaction-table .table td, .transaction-table .table th {
	border-top: 0;
}
.myprofile-widget {
	border: 1px solid #E8E8E8;
	box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.25);
	height: 100%;
}
.myprofile-header {
	background: #86E53E;
	padding: 20px;
	h2 {
		color: #fff;
	}
	p {
		color: #fff;
		margin-bottom: 12px;
	}
}
.member-btn {
	background: #fff;
	border-radius: 50px;
	font-weight: 500;
	font-size: 16px;
	color: #86E53E;
}
.myprofile-body {
	background: #fff;
	padding: 20px;
}
.prog-card {
	background-color: #F4F4F4;
	padding: 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
}
.prog-acc {
	display: flex;
	align-items: center;
	justify-content: space-between;
	h5 {
		font-weight: 500;
		font-size: 14px;
		color: #A8A8A8;
	}
}
.icon-circle {
	background: #86E53E;
	width: 29px;
	height: 29px;
	display: inline-block;
	line-height: 29px;
	text-align: center;
	border-radius: 50%;
	color: #fff;
}
.icon-grey {
	background: #F4F4FA;
	width: 32px;
	height: 32px;
	display: inline-block;
	line-height: 32px;
	text-align: center;
	border-radius: 50%;
	color: #C8C8D8;
	font-size: 18px;
}
.progress-bar {
	background: #159C5B;
	width: 25%;
}
.progress-info {
	width: 20%;
	h5 {
		color: #343434;
		font-size: 15px;
		font-weight: 500;
	}
	p {
		margin-bottom: 0;
		margin-left: 10px;
	}
}
.fund-btn {
	background: #13323C;
	border-radius: 5px;
	color: #fff;
	font-weight: bold;
	&:hover {
		color: #fff;
	}
}
.pro-head {
	border-bottom: 1px solid #F8F8F8;
	padding: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	h2 {
		font-weight: 500;
		font-size: 20px;
		margin-bottom: 0;
	}
}
.pro-body {
	padding: 20px;
}
.news-feature {
	position: relative;
	display: flex;
	align-items: center;
	padding: 0.75rem 1.5rem;
	img {
		margin-right: 15px;
	}
	p {
		font-size: 14px;
		margin-bottom: 0;
	}
	h5 {
		font-weight: 600;
		font-size: 14px;
	}
}
.table-budget {
	font-weight: 500;
	font-size: 14px;
	color: #161c2d;
}
.text-expiry {
	color: #161c2d;
	font-weight: 600;
	font-size: 12px;
}
.detail-text {
	font-size: 15px;
	color: #161c2d;
}
.table-job {
	.table {
		td {
			font-weight: 500;
			font-size: 14px;
			color: #5F5F5F;
		}
	}
}
.dev-feature {
	padding: 20px;
	h4 {
		font-weight: 500;
		color: #86E53E;
	}
	p {
		font-size: 14px;
		margin-bottom: 10px;
	}
	h5 {
		font-weight: bold;
		font-size: 16px;
		margin-bottom: 15px;
		color: #808080;
	}
}
.dev-feature:not(:last-child), .news-feature:not(:last-child) {
	border-bottom: 1px solid #F3F3F3;
}
.bgg-yellow {
	background: #FF7A03;
	color: #fff;
	border-radius: 50%;
	width: 17px;
	line-height: 17px;
	padding: 0;
	height: 17px;
}
.filter-form {
	background: #fff;
	box-shadow: 0px 4px 24px rgba(200, 200, 200, 0.25);
	padding: 30px;
	margin-bottom: 40px;
}
.rate-box {
	background: #86E53E;
	border-radius: 0px 0px 30px 30px;
	width: 54px;
	height: 66px;
	line-height: 66px;
	text-align: center;
	font-weight: 600;
	font-size: 20px;
	color: #fff;
}
.bg-black {
	background: #13323C;
	border: 1px solid #13323C;
}
.setting-tab {
	.nav-link {
		padding: 15px 30px;
		font-weight: 600;
		font-size: 16px;
		color: #86E53E;
		&.active {
			background: #86E53E;
			border-radius: 80px 0px 0px 80px;
			color: #fff;
		}
	}
}
.setting-content {
	padding-top: 0;
	margin-bottom: 30px;
	.pro-card {
		background: #fff;
		box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
		border-radius: 5px;
		border: 0;
	}
	.pro-pad {
		padding: 20px;
	}
	.pro-head {
		h2 {
			font-size: 28px;
		}
	}
}
.pay-tab {
	.nav-link {
		background: #FFF8F7;
		padding: 35px;
		color: #161c2d;
	}
}
.chat-page {
	.content {
		padding: 110px 0 0 0;
	}
	.footer {
		display: none;
	}
	footer {
		display: none;
	}
}
.chat-window {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	position: relative;
	.chat-scroll {
		min-height: calc(100vh - 328px);
		max-height: calc(100vh - 328px);
		overflow-y: auto;
	}
}
.chat-cont-left {
	border-right: 1px solid #f0f0f0;
	-ms-flex: 0 0 34%;
	flex: 0 0 34%;
	left: 0;
	margin-right: 1%;
	max-width: 35%;
	position: relative;
	z-index: 4;
	background: #fff;
	box-shadow: 0px 4px 14px rgba(198, 198, 198, 0.25);
	border-radius: 0px 0px 10px 10px;
	.chat-header {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		background-color: #fff;
		border-bottom: 1px solid #f0f0f0;
		color: #161c2d;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		height: 72px;
		justify-content: space-between;
		-webkit-justify-content: space-between;
		-ms-flex-pack: space-between;
		padding: 0 15px;
		span {
			font-size: 20px;
			font-weight: 500;
			text-transform: capitalize;
		}
		.chat-compose {
			color: #8a8a8a;
			display: -webkit-inline-box;
			display: -ms-inline-flexbox;
			display: inline-flex;
		}
	}
	.chat-search {
		background-color: #fff;
		width: 100%;
		.input-group {
			width: 100%;
			.form-control {
				background-color: #fff;
				border-radius: 50px;
				padding-left: 36px;
				&:focus {
					border-color: #ccc;
					box-shadow: none;
				}
			}
			.input-group-prepend {
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				bottom: 0;
				color: #666;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				right: 15px;
				pointer-events: none;
				position: absolute;
				top: 0;
				z-index: 4;
			}
		}
	}
	.chat-users-list {
		background-color: #fff;
		a {
			&.media {
				background: #fff;
				box-shadow: 0px 4px 4px #f5f8ff;
				margin: 10px;
				padding: 10px 15px;
				transition: all 0.2s ease 0s;
				&:last-child {
					border-bottom: 0;
				}
				.media-img-wrap {
					margin-right: 15px;
					position: relative;
					.avatar {
						height: 45px;
						width: 45px;
					}
					.status {
						bottom: 7px;
						height: 10px;
						right: 4px;
						position: absolute;
						width: 10px;
						border: 2px solid #fff;
					}
				}
				.media-body {
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					justify-content: space-between;
					-webkit-justify-content: space-between;
					-ms-flex-pack: space-between;
					& > div {
						&:first-child {
							.user-name {
								color: #86E53E;
								font-weight: 600;
								font-size: 14px;
								text-transform: capitalize;
							}
							.user-last-chat {
								color: #232323;
								font-size: 12px;
								line-height: 24px;
							}
						}
						&:last-child {
							text-align: right;
							.last-chat-time {
								color: #8a8a8a;
								font-size: 13px;
							}
						}
					}
				}
				&:hover {
					background-color: #f5f5f6;
				}
				&.read-chat {
					.media-body {
						& > div {
							&:last-child {
								.last-chat-time {
									color: #8a8a8a;
								}
							}
						}
					}
				}
				&.active {
					background-color: #f5f5f6;
				}
			}
		}
	}
}
.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name,
.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
	max-width: 250px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.chat-cont-right {
	-ms-flex: 0 0 65%;
	flex: 0 0 65%;
	max-width: 65%;
	background: #fff;
	box-shadow: 0px 4px 14px rgba(198, 198, 198, 0.25);
	border-radius: 0px 0px 10px 10px;
	padding: 10px;
	.chat-header {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		background: #fff;
		border: 1px solid #F4EEFF;
		box-shadow: 0px 4px 4px #F5F8FF;
		border-radius: 5px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		height: 72px;
		justify-content: space-between;
		-webkit-justify-content: space-between;
		-ms-flex-pack: space-between;
		padding: 0 15px;
		.back-user-list {
			display: none;
			margin-right: 5px;
			margin-left: -7px;
		}
		.media {
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			.media-img-wrap {
				position: relative;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				margin-right: 15px;
				.avatar {
					height: 50px;
					width: 50px;
				}
				.status {
					border: 2px solid #fff;
					bottom: 0;
					height: 10px;
					position: absolute;
					right: 3px;
					width: 10px;
				}
			}
			.media-body {
				.user-name {
					color: #161c2d;
					font-size: 15px;
					font-weight: 500;
					text-transform: capitalize;
				}
				.user-status {
					color: #666;
					font-size: 14px;
				}
			}
		}
		.chat-options {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			& > a {
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				border-radius: 50%;
				color: #8a8a8a;
				display: -webkit-inline-box;
				display: -ms-inline-flexbox;
				display: inline-flex;
				height: 30px;
				justify-content: center;
				-webkit-justify-content: center;
				-ms-flex-pack: center;
				margin-left: 10px;
				width: 30px;
			}
		}
	}
	.chat-body {
		background-color: #fff;
		ul {
			&.list-unstyled {
				margin: 0 auto;
				padding: 15px;
				width: 100%;
			}
		}
		.media {
			.avatar {
				height: 30px;
				width: 30px;
				bottom: 0;
				position: absolute;
			}
			.media-body {
				margin-left: 45px;
				.msg-box {
					& > div {
						padding: 10px 15px;
						border-radius: 15px;
						display: inline-block;
						position: relative;
						p {
							color: #fff;
							margin-bottom: 0;
							font-size: 15px;
						}
					}
					& + .msg-box {
						margin-top: 5px;
					}
				}
			}
			&.received {
				margin-bottom: 20px;
				position: relative;
				.media-body {
					.msg-box {
						position: relative;
						& > div {
							background-color: #86E53E;
							.chat-time {
								color: #f6f6f6;
							}
						}
						&:first-child {
							&:before {
								border-bottom: 15px solid #86E53E;
								border-right: 15px solid #86E53E;
								border-top: 15px solid transparent;
								border-left: 15px solid transparent;
								content: "";
								height: 0;
								left: -10px;
								position: absolute;
								right: auto;
								bottom: 12px;
								width: 0;
							}
						}
					}
				}
			}
			&:last-child {
				margin-bottom: 0;
			}
			&.sent {
				margin-bottom: 20px;
				position: relative;
				.avatar {
					height: 30px;
					width: 30px;
					bottom: 0;
					right: 0;
					position: absolute;
				}
				.media-body {
					-webkit-box-align: flex-end;
					-ms-flex-align: flex-end;
					align-items: flex-end;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-ms-flex-direction: column;
					flex-direction: column;
					justify-content: flex-end;
					-webkit-justify-content: flex-end;
					-ms-flex-pack: flex-end;
					margin-left: 0;
					margin-right: 45px;
					.msg-box {
						padding-left: 50px;
						position: relative;
						& > div {
							background-color: #E8EFFF;
							p {
								color: #232323;
							}
							.chat-time {
								color: #919191;
							}
							.chat-msg-info {
								flex-direction: row-reverse;
								li {
									padding-left: 16px;
									padding-right: 0;
									position: relative;
								}
							}
							.chat-msg-attachments {
								flex-direction: row-reverse;
							}
							&:hover {
								.chat-msg-actions {
									opacity: 1;
								}
							}
						}
						h6 {
							text-align: right;
						}
						&:first-child {
							&:before {
								border-bottom: 15px solid #e8efff;
								border-left: 15px solid #e8efff;
								border-top: 15px solid transparent;
								border-right: 15px solid transparent;
								content: "";
								height: 0;
								left: auto;
								position: absolute;
								right: -10px;
								bottom: 12px;
								width: 0;
							}
						}
						.chat-msg-info {
							li {
								&:not(:last-child)::after {
									right: auto;
									left: 8px;
									transform: translate(-50%, -50%);
									background: #aaa;
								}
							}
						}
					}
				}
			}
		}
		.chat-date {
			font-size: 14px;
			margin: 1.875rem 0;
			overflow: hidden;
			position: relative;
			text-align: center;
			text-transform: capitalize;
			&:before {
				background-color: #e0e3e4;
				content: "";
				height: 1px;
				margin-right: 28px;
				position: absolute;
				right: 50%;
				top: 50%;
				width: 100%;
			}
			&:after {
				background-color: #e0e3e4;
				content: "";
				height: 1px;
				left: 50%;
				margin-left: 28px;
				position: absolute;
				top: 50%;
				width: 100%;
			}
		}
	}
	.chat-footer {
		background-color: #fff;
		border-top: 1px solid #f0f0f0;
		padding: 10px 0px;
		position: relative;
		.input-group {
			width: 100%;
			background-color: #f5f5f6;
			border: none;
			border-radius: 50px;
			padding: 5px;
			.form-control {
				background-color: #f5f5f6;
				border: none;
				border-radius: 50px;
				&:focus {
					background-color: #f5f5f6;
					border: none;
					box-shadow: none;
				}
			}
			.btn {
				&.msg-send-btn {
					background-color: #86E53E;
					border-color: #86E53E;
					border-radius: 48px;
					width: 48px;
					height: 48px;
					color: #fff;
					font-size: 26px;
					font-weight: 600;
					display: flex;
					display: -webkit-flex;
					justify-content: center;
					-webkit-justify-content: center;
					align-items: center;
					-webkit-align-items: center;
				}
			}
		}
	}
}
.chat-cont-right .chat-footer .input-group .btn,
.chat-cont-right .chat-footer .input-group .btn {
	background-color: transparent;
	border: none;
	color: #9f9f9f;
}
.msg-typing {
	width: auto;
	height: 24px;
	padding-top: 8px;
	span {
		height: 8px;
		width: 8px;
		float: left;
		margin: 0 1px;
		background-color: #a0a0a0;
		display: block;
		border-radius: 50%;
		opacity: .4;
		&:nth-of-type(1) {
			animation: 1s blink infinite .33333s;
		}
		&:nth-of-type(2) {
			animation: 1s blink infinite .66666s;
		}
		&:nth-of-type(3) {
			animation: 1s blink infinite .99999s;
		}
	}
}
.chat-cont-left .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
	color: #ff5837;
	background-color: #fff;
	border-color: #dee2e6 #dee2e6 #fff;
}
.chat-cont-right .chat-body .media.received .media-body .msg-box h6, .chat-cont-right .chat-body .media.sent .media-body .msg-box h6 {
	font-size: 10px;
	margin-bottom: 0;
	color: #86E53E;
}
.chat-msg-info {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	clear: both;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	list-style: none;
	padding: 0;
	margin: 5px 0 0;
	li {
		font-size: 13px;
		padding-right: 16px;
		position: relative;
		&:not(:last-child):after {
			position: absolute;
			right: 8px;
			top: 50%;
			content: '';
			height: 4px;
			width: 4px;
			background: #d2dde9;
			border-radius: 50%;
			transform: translate(50%, -50%);
		}
		a {
			color: #777;
			&:hover {
				color: #2c80ff;
			}
		}
	}
}
.chat-seen {
	i {
		color: #00d285;
		font-size: 16px;
	}
}
.chat-msg-attachments {
	padding: 4px 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin: 0 -1px;
	& > div {
		margin: 0 1px;
	}
}
.chat-attachment {
	position: relative;
	max-width: 130px;
	overflow: hidden;
	border-radius: .25rem;
	img {
		max-width: 100%;
	}
	&:before {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: #000;
		content: "";
		opacity: 0.4;
		transition: all .4s;
	}
	&:hover {
		&:before {
			opacity: 0.6;
		}
		.chat-attach-caption {
			opacity: 0;
		}
		.chat-attach-download {
			opacity: 1;
		}
	}
}
.chat-attach-caption {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	color: #fff;
	padding: 7px 15px;
	font-size: 13px;
	opacity: 1;
	transition: all .4s;
}
.chat-attach-download {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0;
	transition: all .4s;
	color: #fff;
	width: 32px;
	line-height: 32px;
	background: rgba(255, 255, 255, 0.2);
	text-align: center;
	&:hover {
		color: #495463;
		background: #fff;
	}
}
.chat-attachment-list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin: -5px;
	li {
		width: 33.33%;
		padding: 5px;
	}
}
.chat-attachment-item {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border: 5px solid rgba(230, 239, 251, 0.5);
	height: 100%;
	min-height: 60px;
	text-align: center;
	font-size: 30px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.chat-msg-actions {
	position: absolute;
	left: -30px;
	top: 50%;
	transform: translateY(-50%);
	opacity: 0;
	transition: all .4s;
	z-index: 2;
	& > a {
		padding: 0 10px;
		color: #495463;
		font-size: 24px;
		&:hover {
			color: #2c80ff;
		}
	}
}

.call-box {
	.call-wrapper {
		height: auto;
		text-align: center;
		.call-avatar {
			margin-bottom: 30px;
			cursor: pointer;
			animation: ripple 2s infinite;
		}
		.call-user {
			margin-bottom: 30px;
			span {
				display: block;
				font-weight: 500;
				text-align: center;
			}
			h4 {
				font-size: 24px;
			}
		}
		.call-items {
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			justify-content: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			.call-item {
				background-color: rgba(255, 255, 255, 0.2);
				border: 1px solid transparent;
				border-radius: 100%;
				color: #fff;
				line-height: 0;
				margin: 0 5px;
				padding: 15px;
				&:hover {
					opacity: 0.9;
				}
				&:first-child {
					margin-top: -30px;
				}
				&:last-child {
					margin-top: -30px;
				}
				&.call-end {
					padding: 20px;
					margin: 30px 20px 0;
					background: #f06060;
					border: 1px solid #f06060;
					color: #fff;
					line-height: 0;
					border-radius: 100%;
				}
				&.call-start {
					padding: 20px;
					margin: 30px 20px 0;
					background: #55ce63;
					border: 1px solid #55ce63;
					color: #fff;
					line-height: 0;
					border-radius: 100%;
				}
			}
		}
	}
	&.incoming-box {
		.call-wrapper {
			.call-items {
				.call-item {
					&.call-start {
						margin: 0 10px;
					}
					&.call-end {
						margin: 0 10px;
					}
				}
			}
		}
	}
	.call-avatar {
		border-radius: 100%;
		height: 140px;
		max-width: 140px;
		min-width: 140px;
		position: relative;
		width: 100%;
		border: 10px solid #fafafa;
	}
	.btn {
		background: rgba(0, 0, 0, 0);
		transition: all 0.3s ease 0s;
	}
}
.call-wrapper {
	position: relative;
	height: calc(100vh - 145px);
}
.call-page {
	.footer {
		display: none;
	}
}
.dropdown-action {
	.dropdown-toggle {
		&::after {
			display: none;
		}
	}
}
.call-modal {
	.modal-body {
		padding: 40px;
	}
	.modal-content {
		border: 0;
		border-radius: 10px;
	}
}
.pro-edit {
	background: #F9F9F9;
	height: 560px;
	overflow-y: hidden;
	margin-top: 20px;
	padding: 20px;
}
.profile-update-btn, .profile-cancel-btn {
	font-size: 15px;
	font-weight: 600;
	padding: 12px 25px;
	border-radius: 4px;
	margin-top: 10px;
}
.profile-cancel-btn {
	background: #DEDEDE;
	color: #161c2d;
}
.edit-sub-title {
	background: #F8F8F8;
	padding: 10px;
	border-radius: 50%;
	color: #161c2d;
}
.profile-edit-form .form-control , .profile-edit-form .select2-container .select2-selection--single {
	background: #fff;
	border: 1px solid rgba(239, 239, 239, 0.42);
	box-sizing: border-box;
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	border-radius: 5px;
}
.form-check-label {
	font-size: 14px;
	color: #616161;
}
.submit-profile-sec {
	a {
		padding: 8px 20px;
	}
}
.input-group-text {
	background: #f5f5f5;
	border: 1px solid #f5f5f5;
}
.pro-new,.pro-new1,.pro-new2,.pro-new3 {
	display: none;
}
.pro-text,.pro-text1,.pro-text2,.pro-text3 {
	display: block;
	line-height: 30px;
}
.dropdown {
	&.profile-action {
		position: absolute;
		right: 5px;
		text-align: right;
		top: 10px;
	}
}
.profile-action {
	.dropdown-toggle {
		&::after {
			display: none;
		}
	}
	.dropdown-menu {
		background: #fff;
		border: 1px solid #FFE6E0;
		box-sizing: border-box;
		box-shadow: 0px 4px 4px rgba(165, 165, 165, 0.25);
		color: #86E53E;
		.dropdown-item {
			color: #86E53E;
		}
	}
}
.add-skills {
	.modal-header {
		background: #FFF8F8;
		padding: 25px;
		margin: 30px;
		text-align: center;
		border-bottom: none;
		border-radius: 5px 5px 0px 0px;
		text-transform: uppercase;
		display: flex;
		align-items: center;
		flex-direction: column;
	}
	.modal-title {
		font-size: 24px;
		font-weight: 700;
	}
	.list-group-item {
		border: 0px solid #ffe6e1;
	}
	.menu-arrow {
		-webkit-transition: -webkit-transform 0.15s;
		-o-transition: -o-transform 0.15s;
		transition: transform .15s;
		position: absolute;
		right: 15px;
		display: inline-block;
		font-family: 'FontAwesome';
		text-rendering: auto;
		line-height: 40px;
		font-size: 18px;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		-o-transform: translate(0, 0);
		transform: translate(0, 0);
		line-height: 18px;
		top: 11px;
		&:before {
			content: "\f105";
		}
	}
	li {
		a {
			&.subdrop {
				.menu-arrow {
					-ms-transform: rotate(90deg);
					-webkit-transform: rotate(90deg);
					-o-transform: rotate(90deg);
					transform: rotate(90deg);
				}
			}
		}
	}
	.card {
		background: #fff;
		border: 1px solid rgba(239, 239, 239, 0.42);
		box-sizing: border-box;
		box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
		border-radius: 5px;
	}
}
.hr-text {
	line-height: 1em;
	position: relative;
	outline: 0;
	border: 0;
	color: black;
	text-align: center;
	height: 1.5em;
	opacity: 1;
	margin-bottom: 5rem;
	&:before {
		content: "";
		background: #E9E9E9;
		position: absolute;
		left: 0;
		top: 30px;
		width: 100%;
		height: 1px;
	}
	&:after {
		content: attr(data-content);
		position: relative;
		display: inline-block;
		padding: 0 0.5em;
		color: #fff;
		background-color: #86E53E;
		width: 66px;
		height: 66px;
		border-radius: 50%;
		line-height: 66px;
	}
}
.skill-list-group {
	padding: 20px;
}
.f-7 {
	font-size: 7px;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
	z-index: 2;
	color: #86E53E;
	background-color: #FFF7F7;
	border-color: #FFF7F7;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
	background-color: #86E53E;
	border-color: #86E53E;
}
.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle {
	color: #fff;
	background-color: #86E53E;
	border-color: #86E53E;
}
.f-20 {
	font-size: 20px;
}
.project-details-tab {
	.nav-tabs {
		border-bottom: 1px solid #86E53E;
		background: #86E53E;
		& > li {
			& > a {
				color: #fff;
				font-weight: normal;
			}
		}
	}
	.profile-action {
		width: 30px;
		height: 30px;
		line-height: 30px;
		background: #fff;
		text-align: center;
		border-radius: 50%;
		right: 20px;
		top: 14px;
	}
}
.project-details-tab .nav-tabs .nav-item.show .nav-link, .project-details-tab .nav-tabs .nav-link.active {
	color: #fff;
}
.project-details-tab .nav-tabs.nav-tabs-bottom > li > a.active, .project-details-tab .nav-tabs.nav-tabs-bottom > li > a.active:hover, .project-details-tab .nav-tabs.nav-tabs-bottom > li > a.active:focus {
	border-bottom-width: 5px;
	border-color: transparent;
	border-bottom-color: #fff;
}
.project-details-tab .nav-tabs .nav-link:focus, .project-details-tab .nav-tabs .nav-link:hover {
	background-color: #86E53E;
	border-color: transparent;
	color: #fff;
	border-bottom-width: 5px;
	border-bottom-color: #fff;
}
.btn-purple {
	background: #1E1A60;
	border-radius: 5px;
	color: #fff;
}
.project-details-table {
	.select2-container {
		.select2-selection--single {
			height: 40px;
			line-height: 40px;
		}
	}
	.select2-container--default {
		.select2-selection--single {
			.select2-selection__arrow {
				b {
					top: 40%;
				}
			}
		}
	}
}
.task-btn {
	align-items: center;
	border: 3px dashed #dedede;
	border-radius: 0;
	color: #4d525b;
	cursor: pointer;
	display: flex;
	height: 96px;
	justify-content: center;
	margin-bottom: 20px;
}
.task-form {
	background: #FAFAFA;
	margin-top: 40px;
	padding: 30px;
}
.close-task {
	position: absolute;
	right: 10px;
	top: 10px;
	color: #c7c5c5;
}
.action-task {
	span {
		margin-right: 15px;
	}
}
.settings-widget {
	background: #fff;
	border: 1px solid #E8E8E8;
	box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.25);
	margin-bottom: 30px;
}
.settings-header {
	background: #86E53E;
	padding: 20px;
	p {
		color: #fff;
	}
}
.settings-header h3, .settings-header h3 a {
	color: #fff;
	font-size: 22px;
}
.settings-menu {
	ul {
		display: block;
		border: 0;
		padding: 20px;
		margin: 0;
		list-style: none;
		li {
			& + li {
				margin-top: 25px;
			}
			a {
				color: #161c2d;
				font-size: 15px;
				font-weight: 600;
				padding: 0;
				border: 0 !important;
				display: flex;
				display: -webkit-flex;
				align-items: center;
				-webkit-align-items: center;
				line-height: normal;
				&.active {
					color: #86E53E !important;
					border: 0;
				}
				i {
					margin-right: 8px;
					font-size: 20px;
				}
			}
		}
	}
}
.settings-menu ul li a:hover, .settings-menu ul li a:focus {
	color: #86E53E;
	background-color: transparent !important;
	border: 0 !important;
}
.dash-widget {
	background: #fff;
	border: 1px solid #f0f0f0;
	box-shadow: 0px 4px 4px rgba(200, 200, 200, 0.25);
	margin-bottom: 30px;
}
.dash-info {
	padding: 20px;
	border-bottom: 1px solid #f0f0f0;
}
.dash-widget-info {
	font-weight: bold;
	font-size: 18px;
	margin-bottom: 5px;
	color: #161c2d;
}
.dash-widget-count {
	font-weight: bold;
	font-size: 28px;
	color: #86E53E;
	line-height: 1.2;
}
.dash-widget-more {
	padding: 20px;
	a {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		font-weight: 500;
	}
}
.plan-details {
	h4 {
		font-weight: 600;
		font-size: 24px;
		margin-bottom: 0;
		color: #86E53E;
	}
	h3 {
		font-weight: 500;
		font-size: 30px;
		margin-bottom: 1px;
		color: #86E53E;
	}
	.expiry {
		font-weight: bold;
		font-size: 14px;
		margin-bottom: 25px;
	}
}
.yr-amt {
	font-size: 15px;
	margin-bottom: 25px;
}
.yr-duration {
	font-size: 14px;
	margin-bottom: 25px;
}
.btn-plan {
	border-radius: 10px;
}
.plan-feature {
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		outline: none;
		li {
			margin-bottom: 23px;
			position: relative;
			font-size: 14px;
			padding-left: 25px;
			&:last-child {
				margin-bottom: 0;
			}
			&::before {
				content: "\f00c";
				font-family: "Font Awesome 5 Free";
				font-weight: 600;
				color: #4B9F18;
				left: 0;
				margin-right: 5px;
				position: absolute;
				top: 0;
			}
		}
	}
	a {
		font-weight: 600;
		font-size: 14px;
		color: #86E53E;
	}
}
.on-project {
	border-bottom: 1px solid #F7F7F7;
	padding: 20px;
	h5 {
		font-weight: 500;
		font-size: 16px;
		color: #86E53E;
		margin-bottom: 5px;
	}
	p {
		font-weight: 500;
		font-size: 12px;
		color: #948E8E;
		margin-bottom: 10px;
	}
}
.pro-info {
	.list-details {
		margin-bottom: 0;
	}
	.slot {
		p {
			font-weight: 500;
			font-size: 12px;
			color: #161c2d;
			margin-bottom: 5px;
		}
		h5 {
			font-weight: 600;
			font-size: 13px;
			color: #838383;
		}
	}
}
.verify-box {
	border: 1px solid #86E53E;
	border-radius: 2px;
	padding: 25px;
	img {
		margin-bottom: 20px;
	}
	h5 {
		font-size: 20px;
		margin-bottom: 20px;
	}
	a {
		font-weight: 600;
		color: #86E53E;
		text-decoration: underline;
	}
}
.back-text {
	text-align: right;
	margin-bottom: 20px;
	padding-right: 25px;
}
.back-btn {
	padding: 5px 20px;
	border-radius: 50px;
	font-weight: bold;
	font-size: 14px;
}
.trash-icon {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 30px;
	width: 30px;
	display: -webkit-box;
	border-radius: 50%;
	background: #F5365C;
	color: #fff;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	&:hover {
		color: #fff;
		opacity: 0.8;
	}
}
.avatar-medium {
	border-radius: 5px;
	width: 76px;
	height: 46px;
}
.pro-pic {
	margin-bottom: 0;
	p {
		font-weight: 500;
		margin-top: 15px;
		margin-bottom: 0;
	}
}
.light-pink-text {
	font-size: 12px;
	color: #F38247;
	padding-top: 10px;
}
.btn-list {
	background: rgba(255, 91, 55, 0.1);
	padding: 20px;
	border-radius: 5px;
}
.proedit-widget {
	background: #fff;
	border: 1px solid #D6D6D6;
	box-shadow: 0px 4px 14px 10px rgba(241, 241, 241, 0.3);
	border-radius: 5px;
	padding: 15px;
	.btn {
		height: 50px;
	}
}
.proedit-header {
	padding-bottom: 40px;
	h4 {
		font-weight: 500;
		font-size: 20px;
	}
}
.proedit-body {
	list-style: none;
	padding: 0;
	margin: 0;
	li {
		padding-bottom: 40px;
		display: -ms-flexbox;
		display: flex;
		span {
			color: #86E53E;
			margin-right: 12px;
		}
	}
}
.small-text {
	font-size: 12px;
	color: #86E53E;
	margin-bottom: 0;
}
.del-modal {
	i {
		color: #DFDFDF;
		margin-bottom: 35px;
	}
}
.black-btn {
	background: #13323C;
	border: 1px solid #13323C;
}
.portfolio-img {
	position: relative;
	overflow: hidden;
	z-index: 1;
	border-radius: 0;
	.portfolio-live {
		transition: .5s ease;
		position: absolute;
		opacity: 0;
		width: 100%;
		height: 100%;
		top: 0;
		visibility: hidden;
		z-index: 99;
		color: #fff;
	}
	.portfolio-content {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		text-align: center;
		position: absolute;
	}
	&:hover {
		.portfolio-live {
			opacity: 1;
			visibility: visible;
			background: rgba(255, 91, 55, 0.7);
			-webkit-transform: translate3d(0, 0, 0);
			-ms-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
		}
	}
}
.port-icon {
	width: 34px;
	height: 34px;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	background: #fff;
	border-radius: 50%;
	color: #86E53E;
}
.portfolio-detail {
	text-align: center;
	padding: 30px;
	.pro-name {
		font-weight: 600;
		font-size: 16px;
		margin-bottom: 0;
		text-transform: none;
	}
}
.hire-select {
	.select2-container {
		.select2-selection--single {
			background: #86E53E;
			color: #fff;
			border-color: #86E53E;
		}
	}
	.select2-container--default {
		.select2-selection--single {
			.select2-selection__rendered {
				color: #fff;
			}
			.select2-selection__arrow {
				b {
					border-color: #fff transparent transparent;
				}
			}
		}
	}
}
.br-0 {
	border-radius: 0 !important;
}
.port-title {
	h3 {
		font-size: 22px;
		font-weight: 500;
		margin-bottom: 35px;
	}
}
.projects-list {
	h2 {
		font-size: 24px;
		margin-bottom: 20px;
	}
}
.prolist-details {
	display: flex;
	list-style: none;
	margin: 0;
	flex-wrap: wrap;
	display: flex;
	display: -ms-flexbox;
	padding: 0;
	h5 {
		font-weight: normal;
		font-size: 16px;
		margin-bottom: 5px;
		color: #6e727d;
		font-weight: 500;
	}
	p {
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 0;
	}
	li {
		&:not(:last-child) {
			margin-right: 40px;
		}
	}
	.price {
		font-size: 23px;
		color: #F5365C;
		font-weight: 600;
		margin-bottom: 0;
	}
}
.pro-overview {
	h4 {
		font-size: 18px;
	}
	p {
		font-size: 15px;
		font-weight: 500;
	}
}
.red-text {
	color: #FF0000;
}
.hire-box {
	h6 {
		font-weight: bold;
		font-size: 14px;
		color: #86E53E;
	}
	p {
		font-weight: 600;
		font-size: 14px;
	}
}
.btn-chat {
	font-weight: 500;
	font-size: 12px;
	color: #86E53E;
	background: #fff;
	border: 1px solid #D8D8D8;
	border-radius: 70px;
}
.bg-success-dark {
	background-color: #4B9F18;
	border: 1px solid #4B9F18;
	padding: 10px 15px;
	color: #fff;
	width: 80px;
	border-radius: 10px;
}
.bg-grey-dark {
	background-color: #808080;
	border: 1px solid #808080;
	padding: 10px 15px;
	color: #fff;
	width: 80px;
	border-radius: 10px;
}
.bg-pink-dark {
	background: #F5365C;
	border-radius: 5px;
	padding: 10px 20px;
	color: #fff;
	border-radius: 10px;
}
.bg-grey-light {
	background: #DCDCDC;
	border-radius: 5px;
	padding: 10px 20px;
	color: #161c2d;
	border-radius: 10px;
}
.bg-outline-red {
	border: 1px solid #86E53E;
	border-radius: 20px;
	padding: 10px 15px;
	font-size: 14px;
	color: #86E53E;
}
.bg-outline-green {
	border: 1px solid #4B9F18;
	border-radius: 20px;
	padding: 10px 15px;
	font-size: 14px;
	color: #4B9F18;
}
.table-box {
	background: #fff;
	border: 1px solid #E7E7E7;
	div {
		&.dataTables_wrapper {
			div {
				&.dataTables_info {
					padding-left: 15px;
					padding-bottom: 15px;
				}
				&.dataTables_paginate {
					padding-right: 15px;
					padding-bottom: 15px;
				}
			}
		}
	}
}
.project-table {
	h4 {
		font-weight: 600;
		font-size: 16px;
		color: #86E53E;
		margin-bottom: 20px;
	}
	h3 {
		font-weight: 500;
		font-size: 24px;
		color: #161c2d;
		margin: 20px 20px 0;
	}
}
.read-text {
	font-weight: 500;
	font-size: 14px;
	color: #86E53E;
}
.br-30 {
	border-radius: 30px;
}
.file-circle {
	width: 33px;
	height: 33px;
	background: #FDF4F3;
	color: #86E53E;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
}
.dataTables_wrapper .dataTables_filter, .dataTables_wrapper .dataTables_length {
	padding: 20px 20px 5px;
}
.dataTables_wrapper .dataTables_length select, .dataTables_wrapper .dataTables_filter input {
	border: 1px solid #E7E7E7;
	appearance: auto;
	-webkit-appearance: auto;
	-moz-appearance: auto;
}
.reply-box {
	padding: 20px;
	margin-bottom: 20px;
	h4 {
		margin-bottom: 0;
		color: #fff;
	}
}
.dashboard-sec {
	.pro-box {
		margin-bottom: 30px;
	}
	.card-title {
		font-weight: 500;
	}
}
.text-violet {
	color: #7B46BE;
}
.text-pink {
	color: #FA6CA4;
}
.text-yellow {
	color: #FACD3A;
}
.text-blue {
	color: #24C0DC;
}
.static-list {
	list-style: none;
	margin: 0;
	li {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 15px;
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.earn-feature {
	border: 1px solid #F7F7F7;
	padding: 20px;
	.price {
		font-weight: 600;
		font-size: 24px;
		color: #86E53E;
		margin-left: auto;
	}
}
.earn-info {
	p {
		font-size: 16px;
		margin-bottom: 12px;
		font-weight: 600;
	}
	.date {
		font-size: 14px;
		font-weight: 500;
	}
}
.earn-img {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	span {
		font-weight: 600;
		font-size: 14px;
		img {
			margin-right: 15px;
		}
	}
}
.pro-box {
	background: #fff;
	box-shadow: 0px 4px 24px 10px rgba(231, 231, 231, 0.3);
	border-radius: 5px;
}
.action {
	align-items: center;
	display: inline-flex;
}
.rangslider {
	-webkit-appearance: none;
	width: 100%;
	height: 15px;
	background: #ffe6e1;
	border-radius: 5px;
	outline: none;
	overflow: hidden;
	-webkit-transition: .2s;
	transition: opacity .2s;
	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: 62px;
		height: 18px;
		background: #fff;
		border-radius: 5px;
		box-shadow: -100vw 0 0 100vw #86E53E, 0px 4px 24px rgba(0, 0, 0, 0.25);
		cursor: pointer;
	}
	&::-moz-range-thumb {
		width: 25px;
		height: 25px;
		border-radius: 50%;
		background: #04AA6D;
		cursor: pointer;
	}
}
.paginations {
	padding: 0;
	margin-bottom: 0;
	li {
		list-style: none;
		display: inline-block;
		padding-right: 20px;
		list-style: none;
		display: inline-block;
		padding-right: 0;
		a {
			font-weight: 600;
			font-size: 14px;
			color: #161c2d;
			font-weight: 200;
			font-size: 16px;
			color: #6c757d;
			padding: 0.375rem 0.75rem;
			&.active {
				background: #86E53E;
				color: #fff;
				padding: 5px 11px;
				border-radius: 50%;
				display: inline-block;
				background: #86E53E;
				color: #fff;
				padding: 0.375rem 0.75rem;
				border-radius: 0;
			}
		}
		&:hover {
			a {
				color: #86E53E;
				color: #86E53E;
				&.active {
					color: #fff;
					color: #fff;
				}
			}
		}
		&:last-child {
			padding-right: 0;
		}
	}
	&.freelancer {
		text-align: right;
		li {
			&:last-child {
				padding-right: 0;
			}
		}
	}
}
.guide-account {
	border: 1px solid #86E53E;
	border-radius: 2px;
	padding: 30px 20px;
	position: relative;
	margin-bottom: 1.875rem;
	width: 100%;
}
.account-verify {
	img {
		width: 65px;
	}
	h2 {
		font-size: 20px;
		margin: 15px 0;
	}
	p {
		color: #797979;
	}
}
.verify-link {
	color: #86E53E;
	font-size: 14px;
	font-weight: 600;
	border-bottom: 4px solid #86E53E;
}
.projects-card {
	background: #fff;
	border: 1px solid #E8E8E8;
	box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.25);
	.card-body {
		height: 100%;
	}
}
.project-info {
	width: 50%;
	display: block;
	padding-right: 20px;
	span {
		font-weight: 500;
		font-size: 13px;
		color: #86E53E;
		display: inline-block;
		margin-bottom: 5px;
	}
	h2 {
		font-weight: 700;
		margin: 0 0 10px;
		font-size: 16px;
	}
	.customer-info {
		ul {
			padding: 0;
			li {
				h5 {
					margin: 0;
					display: flex;
					align-items: center;
					img {
						margin-right: 3px;
					}
				}
			}
		}
	}
	&.project {
		width: 45%;
	}
}
.projects-btn {
	font-size: 12px;
	font-weight: 600;
	background: #86E53E;
	border-radius: 3px;
	padding: 8px 10px;
	color: #fff;
	text-align: center;
	width: 140px;
	display: block;
	margin: 0 auto 10px;
	&:hover {
		background-color: #13323C;
		color: #fff;
	}
	&.project {
		margin: 0 0 10px;
	}
}
.projects-action {
	width: 50%;
	padding-left: 20px;
	.projects-btn {
		& ~ .projects-btn {
			margin: 0 auto 0;
		}
	}
	&.single-btn {
		width: 100%;
	}
	&.project {
		width: 35%;
		padding-left: 20px;
	}
}
.projects-details {
	display: flex;
	width: 100%;
}
.projects-amount {
	width: 50%;
	display: block;
	text-align: center;
	p {
		font-weight: 500;
		font-size: 14px;
		color: #161c2d;
		margin-bottom: .25rem;
	}
	h3 {
		font-size: 15px;
		font-weight: 600;
	}
	h5 {
		font-weight: 600;
		font-size: 13px;
		color: #86E53E;
		margin-bottom: 0;
	}
	&.proposals {
		width: 100%;
	}
}
.prj-proposal-count {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	span {
		color: #86E53E;
		font-weight: 600;
		font-size: 34px;
		line-height: 1.2;
	}
	h3 {
		font-size: 16px;
	}
	&.hired {
		h3 {
			font-weight: 600;
			font-size: 14px;
			color: #86E53E;
			margin-bottom: 12px;
		}
		img {
			height: 38px;
			width: 38px;
			border-radius: 50%;
			margin-bottom: 8px;
			object-fit: cover;
		}
		p {
			font-weight: 500;
			font-size: 14px;
		}
	}
}
.my-projects-list {
	margin-bottom: 30px;
}
.content-divider {
	border-right: 1px solid #F5F5F5;
	height: 96px;
}
.content-divider-1 {
	border-right: 1px solid #EAEAEA;
	height: 96px;
}
.hired-detail {
	font-size: 13px;
	color: #000;
	font-size: 12px;
	color: #6e727d;
	font-weight: 700;
	margin-bottom: 0;
}
.project-hire-info {
	display: flex;
	width: 50%;
	align-items: center;
	&.project {
		width: 55%;
	}
}
.page-title {
	h3 {
		font-size: 25px;
		margin-bottom: 1.5rem !important;
	}
}
.page-subtitle {
	font-size: 20px;
	color: #161c2d;
	margin-bottom: 1rem;
}
.proposal-card {
	border: 1px solid #E8E8E8;
	box-shadow: 0px 4px 4px rgb(204 204 204 / 25%);
	padding: 12px;
}
.project-proposals {
	background: #FFF5F3;
	border: 1px solid rgba(255, 91, 55, 0.1);
	padding: 12px;
	margin-bottom: 15px;
	&.freelancer {
		margin-bottom: 0;
		padding: 18px;
	}
}
.proposer-img {
	img {
		height: 54px;
		width: 54px;
		border-radius: 50%;
	}
}
.proposer-info {
	display: flex;
	align-items: center;
	width: 60%;
	&.project {
		width: 65%;
		padding-left: 25px;
	}
}
.proposer-detail {
	margin-left: 15px;
	list-style: none;
	padding: 0;
	li {
		display: inline-block;
		color: #808080;
		font-size: 12px;
		border-right: 1px solid #ADADAD;
		padding-right: 12px;
		padding-left: 10px;
		&:first-child {
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
			border-right: 0;
		}
	}
	h4 {
		font-weight: 500;
		font-size: 16px;
		color: #000;
		margin-bottom: .25rem;
	}
}
.proposal-details {
	margin-bottom: 0;
}
.font-semibold {
	font-weight: 500;
}
.proposals-info {
	display: flex;
	margin-bottom: 15px;
}
.proposer-bid-info {
	display: flex;
	align-items: center;
	width: 40%;
}
.proposer-confirm {
	width: 50%;
	.projects-btn {
		margin: 0 0 0 auto;
	}
}
.proposer-bid {
	width: 50%;
	h3 {
		font-size: 20px;
		color: #161c2d;
		margin-bottom: .25rem;
	}
	h5 {
		font-size: 13px;
		color: #86E53E;
		margin-bottom: 0;
	}
}
.description-proposal {
	padding: 12px;
	background: #fff;
	p {
		margin-bottom: 0;
		font-size: 14px;
	}
}
.font-bold {
	font-weight: 600;
}
.warning-card {
	padding: 12px;
	background: rgba(255, 0, 0, 0.1);
	color: #FF0000;
	margin-bottom: 30px;
	p {
		color: #FF0000;
		font-size: 14px;
		margin-bottom: 0;
	}
}
.completed-badge {
	font-size: 14px;
	color: #4B9F18;
	font-weight: 700;
}
.cancelled-badge {
	font-weight: 600;
	font-size: 15px;
	color: #FF0000;
}
.projects-delete-details {
	padding: 20px;
	background: rgba(255, 0, 0, 0.04);
	display: flex;
	box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.25);
	border: 1px solid #e8e8e8;
	.content-divider {
		border-right: 1px solid rgba(255, 0, 0, 0.1);
	}
}
.profile-edit-btn {
	background: #fff;
	font-weight: 500;
	font-size: 16px;
	color: #86E53E;
	border: 1px solid #86E53E;
	border-radius: 2px;
	&:hover {
		background: #86E53E;
		color: #FFF;
		border: 1px solid #86E53E;
	}
}
.my-projects-view {
	.pro-post {
		margin-bottom: 0;
	}
}
.pro-pos {
	.select2-container {
		z-index: unset;
	}
}
.package-detail {
	background: #fff;
	border: 1px solid #FEFEFE;
	box-shadow: 0px 4px 24px rgba(199, 199, 199, 0.25);
	border-radius: 5px;
	padding: 25px;
	margin-bottom: 25px;
	h4 {
		font-weight: 600;
		font-size: 18px;
	}
	p {
		font-size: 12px;
	}
	.package-price {
		font-weight: 600;
		font-size: 25px;
		color: #86E53E;
		margin-bottom: 0;
	}
}
.package-feature {
	ul {
		list-style: none;
		margin: 25px 0;
		padding: 0;
		outline: none;
		li {
			margin-bottom: 14px;
			position: relative;
			font-size: 15px;
			font-weight: 600;
			padding-left: 25px;
			&:last-child {
				margin-bottom: 0;
			}
			&.non-check {
				&::before {
					content: "\f057";
					color: #F5365C;
				}
			}
			&::before {
				content: "\f00c";
				font-family: "Font Awesome 5 Free";
				font-weight: 600;
				color: #4B9F18;
				left: 0;
				position: absolute;
				top: 0;
			}
		}
	}
}
.price-btn {
	background: #13323C;
	border: 1px solid #13323C;
	border-radius: 5px;
	font-weight: 500;
	color: #fff;
	padding: 10px 20px;
}
.member-detail {
	h4 {
		font-weight: 600;
		font-size: 22px;
		color: #86E53E;
	}
	h3 {
		font-weight: 600;
		font-size: 22px;
		color: #86E53E;
		margin-bottom: 2px;
	}
	.expiry-on {
		font-size: 15px;
	}
	.expiry {
		font-weight: 500;
		font-size: 16px;
	}
}
.member-plan {
	margin-bottom: 25px;
}
.freelance-title {
	margin-bottom: 40px;
	h3 {
		font-weight: 600;
		font-size: 26px;
	}
	p {
		font-size: 16px;
		color: #757575;
	}
}
.change-plan {
	float: right;
}
.fav .filled, .fav i {
	color: #F5365C;
}
.fav {
	i {
		&:hover {
			color: #F5365C;
			font-weight: 700;
		}
	}
}
.tab-imgcircle {
	&::before {
		background-color: #159C5B;
		position: absolute;
		right: 0;
		bottom: 0;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		content: "\f00c";
		font-weight: 600;
		font-family: "Font Awesome 5 Free";
		font-size: 9px;
		justify-content: center;
		align-items: center;
		color: #fff;
		display: inline-flex;
		display: -ms-inline-flexbox;
	}
}
.freelancer-proposals {
	background: #fff;
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	border-radius: 5px;
	padding: 8px;
	margin-bottom: 13px;
}
.proposals-title {
	font-weight: 700;
	font-size: 18px;
	color: #161c2d;
	margin-bottom: 1rem;
	line-height: 1.3;
}
.proposals-detail {
	display: block;
	width: 50%;
}
.proposer-content {
	display: block;
	width: 50%;
	display: block;
	padding-right: 20px;
}
.info-btn {
	background: #fff;
	border: 1px solid #D8D8D8;
	border-radius: 70px;
	font-weight: 500;
	font-size: 12px;
	color: #ADADAD;
	padding: 3px 35px;
}
.proposal-img {
	display: block;
	margin-right: 60px;
	img {
		width: 50px;
		height: 50px;
		border-radius: 50%;
	}
	h4 {
		font-weight: 600;
		font-size: 14px;
		color: #161c2d;
		margin: .5rem 0;
	}
}
.proposal-client {
	margin-right: 60px;
}
.proposals-content {
	display: flex;
}
.proposal-delete {
	font-weight: 500;
	font-size: 12px;
	color: #F5365C;
}
.title-info {
	font-weight: 500;
	font-size: 15px;
	color: #161c2d;
	margin-bottom: .25rem;
}
.client-price {
	font-weight: 600;
	font-size: 20px;
	margin-bottom: .25rem;
	color: #F5365C;
}
.price-type {
	font-size: 16px;
	color: #161c2d;
}
.proposal-type {
	h3 {
		font-weight: 600;
		font-size: 16px;
		color: #F5365C;
		margin-bottom: 0;
	}
}
.amnt-type {
	font-size: 16px;
	color: #161c2d;
	margin-bottom: 0;
	padding-top: 5px;
}
.hired-date {
	font-weight: 500;
	font-size: 13px;
	color: #161c2d;
	margin-bottom: 0;
}
.projects-cancelled-card {
	background: rgba(255, 0, 0, 0.07);
}
#more {
	display: none;
}
.readmore {
	&:hover {
		cursor: pointer;
	}
}
ul {
	padding: 0;
}
.modal-title {
	font-weight: 600;
	font-size: 24px;
	color: #161c2d;
}
.rating-text {
	font-weight: 500;
	font-size: 16px;
	color: #161c2d;
}
.modal-close {
	a {
		i {
			color: #86E53E;
			font-size: 20px;
		}
	}
}
.update-btn {
	padding: 10px 20px;
	font-weight: bold;
	font-size: 15px;
	border-radius: 10px;
	min-width: 120px;
	text-transform: uppercase;
}
.rating-btn {
	font-weight: 500;
	font-size: 14px;
	color: #161c2d;
}
.bids-card {
	border-bottom: 1px solid #D9D9D9;
	padding: 20px 0;
	&:last-child {
		border: 0;
		padding-bottom: 0;
	}
}
.author-img-wrap {
	img {
		border-radius: 20px;
	}
}
.author-detail {
	p {
		margin-top: 15px;
	}
}
.proposal-amnt {
	h3 {
		font-weight: 500;
		font-size: 24px;
		color: #F5365C;
	}
}
.average-bids {
	background: #FFF6F4;
	padding: 15px 20px;
	p {
		margin-bottom: 0;
		color: #000;
		font-size: 16px;
	}
}
.text-highlight {
	font-weight: 700;
	color: #86E53E;
}
.pro-btn {
	background: #86E53E;
	border-radius: 10px;
	font-weight: 500;
	font-size: 16px;
	color: #fff;
	padding: 15px 30px;
	margin: auto;
	&:hover {
		color: #fff;
		background: #fb451d;
	}
}
.proposal-btns {
	padding: 20px;
	background: #FFF9F9;
	display: flex;
	display: -webkit-flex;
	border-radius: 5px;
}
.custom-breadcrumb {
	padding: 50px 0 160px;
}
.profile-cmpny {
	font-weight: 500;
	font-size: 24px;
	color: #86E53E;
}
.bck-btn {
	font-weight: 500;
	font-size: 20px;
	color: #fff;
	background: #86E53E;
	border-radius: 5px;
	padding: 10px 15px;
	float: right;
}
.note-editor {
	&.card {
		padding: 0.5rem;
	}
	&.note-frame {
		border: 1px solid #E7E8EA;
		.note-status-output {
			display: none;
		}
	}
}
.note-toolbar .btn-group button, .note-toolbar  .btn-group .btn {
	border-radius: 0;
}
.note-toolbar {
	z-index: 2;
}
.map {
	width: 100%;
	height: 314px;
}
.table-width {
	width: 35%;
}
.invoice-item {
	.invoice-logo {
		margin-bottom: 30px;
		img {
			width: auto;
			max-height: 52px;
		}
	}
	.invoice-text {
		padding-top: 42px;
		padding-bottom: 36px;
		h2 {
			color: #161c2d;
			font-size: 36px;
			font-weight: 600;
			font-weight: 400;
		}
	}
	.invoice-details {
		text-align: right;
		font-weight: 500;
		strong {
			color: #161c2d;
		}
	}
	.invoice-details-two {
		text-align: left;
	}
	.customer-text {
		font-size: 18px;
		color: #161c2d;
		font-weight: 600;
		margin-bottom: 8px;
		display: block;
	}
}
.invoice-info {
	margin-bottom: 30px;
	p {
		margin-bottom: 0;
	}
	&.invoice-info2 {
		text-align: right;
	}
	h5 {
		font-size: 16px;
		font-weight: 500;
	}
}
.invoice-table tr th,
.invoice-table tr td,
.invoice-table-two tr th,
.invoice-table-two tr td {
	color: #161c2d;
	font-weight: 600;
	padding: 10px 20px;
	line-height: inherit;
}
.invoice-table tr td,
.invoice-table-two tr td {
	font-weight: 500;
}
.invoice-table-two {
	margin-bottom: 0;
	tr {
		td {
			text-align: right;
		}
	}
}
.invoice-table-two tr th,
.invoice-table-two tr td {
	border-top: 0;
}
.other-info {
	margin-top: 10px;
}
.footer {
	background-image: url(../img/bg/bg-18.png);
	background-position: top;
	background-repeat: no-repeat;
	position: relative;
	background-size: cover;
	overflow: hidden;
	background: #fdfdfd;
	.footer-widget {
		.footer-about-content {
			p {
				color: #E5E5E5;
			}
		}
		&.footer-menu {
			ul {
				list-style: none;
				margin: 0;
				padding: 0;
				outline: none;
			}
		}
	}
	.footer-menu {
		ul {
			li {
				margin-bottom: 10px;
				&:last-child {
					margin-bottom: 0;
				}
				a {
					color: #353535;
					font-size: 14px;
					font-weight: 500;
					position: relative;
					transition: all 0.4s ease;
					-moz-transition: all 0.4s ease;
					-webkit-transition: all 0.4s ease;
					-o-transition: all 0.4s ease;
					&:hover {
						color: #FF3115;
					}
				}
			}
		}
	}
	.banner-content {
		.form-inner {
			border-radius: 50px;
			border: 0;
			height: 50px;
			margin-bottom: 35px;
		}
		.form-control {
			background: #fff;
			border-radius: 50px;
			height: 50px;
			margin-right: 3px;
			&::placeholder {
				font-size: 14px;
				color: #9D9D9D;
			}
		}
		.sub-btn {
			min-width: 72px;
			height: 38px;
			margin: 6px;
			background: #101828;
			border: 1px solid #101828;
			transition: all 0.5s ease;
			-moz-transition: all 0.5s ease;
			-o-transition: all 0.5s ease;
			-ms-transition: all 0.5s ease;
			-webkit-transition: all 0.5s ease;
			&:hover {
				background: #86E53E;
				border: 1px solid #86E53E;
				transition: all 0.5s ease;
				-moz-transition: all 0.5s ease;
				-o-transition: all 0.5s ease;
				-ms-transition: all 0.5s ease;
				-webkit-transition: all 0.5s ease;
			}
		}
	}
	.form-control {
		line-height: 40px;
		border-right-color: transparent;
	}
	.input-group {
		.input-group-append {
			padding: 3px;
			background: #fff;
			.btn {
				border-radius: 0;
				padding: 10px 30px;
			}
		}
	}
	&.footer-two {
		background: #161515;
		.footer-menu {
			ul {
				li {
					a {
						color: #FFF5F1;
						&:hover {
							color: #86E53E;
						}
					}
				}
			}
		}
		.banner-content {
			.form-inner {
				box-shadow: none;
				border-radius: 8px;
				height: 50px;
				padding: 0;
			}
			.sub-btn {
				height: 50px;
				margin: 0;
				background: #86E53E;
				border: 1px solid #86E53E;
				&:hover {
					background: #13323C;
					border: 1px solid #13323C;
				}
			}
		}
	}
	&.footer-three {
		background: #FFF5F1;
		.banner-content {
			.sub-btn {
				background: transparent;
				border: 0;
				color: #86E53E;
				padding: 10px 12px;
				&:hover {
					color: #2e2e2e;
				}
			}
		}
		.copyright-text {
			p {
				color: #353535;
			}
		}
	}
	&.footer-four {
		.footer-menu {
			ul {
				li {
					a {
						color: #FFF5F1;
					}
				}
			}
			.social-icon {
				ul {
					li {
						margin-bottom: 0;
					}
				}
			}
		}
		.banner-content {
			.form-control {
				border-radius: 4px;
				height: 48px;
			}
			.sub-btn {
				height: 38px;
				border-radius: 4px !important;
			}
		}
	}
	&.footer-five {
		background: #101010;
		background-image: url(../../assets/img/bg/bg-36.png);
		background-repeat: no-repeat;
		background-position: left center;
		background-size: 200px;
		.footer-menu {
			ul {
				li {
					a {
						color: #FFF5F1;
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-align: center;
						-ms-flex-align: center;
						align-items: center;
						i {
							font-size: 6px;
							color: #474747;
							margin-right: 10px;
							transition: all 0.5s ease;
							-moz-transition: all 0.5s ease;
							-o-transition: all 0.5s ease;
							-ms-transition: all 0.5s ease;
							-webkit-transition: all 0.5s ease;
						}
					}
				}
			}
		}
		.banner-content {
			.sub-btn {
				background: #86E53E;
				border: 1px solid #86E53E;
				border-radius: 0px 6px 6px 0px !important;
				height: 48px;
				margin: 0px;
				&:hover {
					background: #13323C;
					border: 1px solid #13323C;
				}
			}
		}
	}
}
.footer-user {
	position: absolute;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	top: 75px;
}
.footer-top {
	padding: 60px 0;
}
.footer-contact {
	p {
		color: #E5E5E5;
	}
}
.footer-title {
	color: #101828;
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 40px;
	position: relative;
	padding-bottom: 10px;
}
h2 {
	&.footer-title {
		&:after {
			content: "";
			width: 35px;
			height: 2px;
			background: #86E53E;
			bottom: 0;
			left: 0px;
			right: 0;
			bottom: 0;
			position: absolute;
			border-radius: 50px;
		}
		&:before {
			content: "";
			width: 62px;
			height: 2px;
			background: #D9D9D9;
			bottom: 0;
			left: 0px;
			right: 0;
			bottom: 0;
			position: absolute;
			border-radius: 50px;
		}
	}
}
.footer-menu {
	p {
		color: #515151;
		font-weight: 500;
		font-size: 14px;
	}
	.applicate-mobile-blk {
		h6 {
			color: #2B2B2B;
			font-weight: 600;
		}
	}
}
.footer .social-icon ul li .fa-facebook-f:hover,
.footer .social-icon ul li .fa-instagram:hover,
.footer .social-icon ul li .fa-twitter:hover {
	opacity: .7;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
}
.footer-three .social-icon ul li .fa-facebook-f,
.footer-three .social-icon ul li .fa-linkedin-in,
.footer-three .social-icon ul li .fa-twitter,
.footer-four .social-icon ul li .fa-facebook-f,
.footer-four .social-icon ul li .fa-instagram,
.footer-four .social-icon ul li .fa-twitter,
.footer-five .social-icon ul li .fa-facebook-f,
.footer-five .social-icon ul li .fa-linkedin-in,
.footer-five .social-icon ul li .fa-twitter {
	background: transparent;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
}
.footer-three .social-icon ul li .fa-facebook-f:hover,
.footer-three .social-icon ul li .fa-linkedin-in:hover,
.footer-three .social-icon ul li .fa-twitter:hover {
	background: #5F5F5F;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
}
.footer-five .social-icon ul li .fa-facebook-f:hover,
.footer-five .social-icon ul li .fa-linkedin-in:hover,
.footer-five .social-icon ul li .fa-twitter:hover {
	background: #fff;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
}
.social-icon ul li .icon:hover, .social-icon ul li .icon:focus {
	color: #fff;
}
.footer-newsletter {
	input {
		box-shadow: 0px 4px 4px rgb(194 194 194 / 25%);
		border-radius: 200px;
		height: 43px;
		width: 100%;
		padding: 6px 15px;
		border: 0;
		&:focus {
			outline: none;
		}
	}
}
.btn-newsletter {
	background: #FF3115;
	border-radius: 200px;
	position: absolute;
	height: 43px;
	right: 0;
	width: 80px;
}
.footer-contact-info {
	p {
		color: #fff;
		font-size: 16px;
		font-weight: 800;
	}
	.social-icon {
		ul {
			padding: 0;
			margin-top: 10px;
			li {
				.icon {
					width: 26px;
					height: 26px;
					font-size: 12px;
				}
			}
		}
	}
}
.copyright {
	padding: 30px 0;
	border-top: 1px solid #EBEBEB;
}
.copyright-text p, .copyright-text p a {
	color: #9D9D9D;
	font-size: 14px;
}
.footer-bottom-nav {
	justify-content: end;
	-webkit-justify-content: end;
	-ms-flex-pack: end;
	margin-bottom: 5px;
	li {
		a {
			color: #515151;
			font-weight: 500;
			font-size: 14px;
			&:hover {
				color: #86E53E !important;
			}
		}
		& + li {
			margin-left: 10px;
		}
	}
}
.footer-two {
	.footer-bottom-nav {
		li {
			& + li {
				margin-left: 10px;
				padding-left: 10px;
				border-left: 1px solid #2B2B2B;
			}
		}
	}
	.footer-title {
		margin-bottom: 10px;
	}
	.banner-content {
		.sub-btn {
			background: #86E53E;
			border-radius: 0px 5px 5px 0px !important;
		}
	}
}
.center-text {
	text-align: center;
}
.copyright-text {
	p {
		a {
			&:hover {
				color: #FF3115;
			}
		}
	}
}
.footer-address {
	.off-address {
		& + .off-address {
			margin-top: 20px;
		}
		p {
			margin-bottom: 0;
		}
	}
}
.footer-two .footer-title,
.footer-two .footer-menu p {
	color: #fff;
}
.footer-two h2.footer-title:before,
.footer-two h2.footer-title:after {
	display: none;
}
.footer-two .footer-bottom-nav li a,
.footer-two .copyright-text p {
	color: #fff;
}
.footer.footer-two .banner-content .form-control,
.footer.footer-two .banner-content .input-group {
	height: 50px;
}
.footer-top-blk {
	border-bottom: 1px solid #EBEBEB;
	padding: 25px 0px;
}
.footer-social-group {
	.footer-bottom-nav {
		justify-content: start;
		-webkit-justify-content: start;
		-ms-flex-pack: start;
		li {
			a {
				color: #353535;
			}
		}
	}
	.social-icon {
		justify-content: end;
		-webkit-justify-content: end;
		-ms-flex-pack: end;
		ul {
			li {
				a {
					background: transparent;
					transition: all 0.5s ease;
					-moz-transition: all 0.5s ease;
					-o-transition: all 0.5s ease;
					-ms-transition: all 0.5s ease;
					-webkit-transition: all 0.5s ease;
					&:hover {
						background: #5F5F5F;
						transition: all 0.5s ease;
						-moz-transition: all 0.5s ease;
						-o-transition: all 0.5s ease;
						-ms-transition: all 0.5s ease;
						-webkit-transition: all 0.5s ease;
						i {
							color: #fff;
						}
					}
				}
				i {
					color: #000;
				}
			}
		}
	}
}
.footer-three h2.footer-title:before,
.footer-three h2.footer-title:after {
	display: none;
}
.footer-three {
	.footer-title {
		margin-bottom: 20px;
		padding-bottom: 0px;
		color: #2B2B2B;
		font-size: 16px;
	}
}
.footer.footer-three .banner-content .form-inner,
.footer.footer-three .banner-content .input-group {
	border-radius: 4px;
}
.footer-four {
	background: #161515;
	background-image: url(../../assets/img/bg/bg-37.png);
	background-repeat: no-repeat, no-repeat;
	background-position: right center;
	background-size: 170px;
	.footer-title {
		margin-bottom: 20px;
		padding-bottom: 0;
		color: #FFFFFF;
		font-size: 16px;
		font-weight: 600;
	}
	.footer-menu {
		.applicate-mobile-blk {
			h6 {
				color: #FFFFFF;
			}
			p {
				color: #FFF5F1;
				font-size: 14px;
			}
		}
		.free-customer {
			p {
				color: #5F5F5F;
				font-size: 15px;
				font-weight: 500;
			}
		}
		.social-icon {
			p {
				color: #FFF5F1;
			}
		}
	}
	.applicate-mobile-blk {
		margin-top: 50px;
	}
	.social-icon {
		margin-top: 50px;
		ul {
			li {
				a {
					width: auto;
				}
			}
		}
	}
	.copyright-text {
		p {
			color: #FFF5F1;
		}
	}
	.copyright {
		padding: 30px 0;
		border-top: 1px solid #2E2E2E;
	}
}
.footer-four h2.footer-title:before,
.footer-four h2.footer-title:after {
	display: none;
}
.footer.footer-four .footer-menu ul li a:hover i,
.footer.footer-four .footer-menu ul li a:hover {
	color: #86E53E;
}
.subscribe-four {
	h4 {
		color: #FFFFFF;
		font-size: 16px;
		font-weight: 700;
		margin-bottom: 20px;
	}
}
.footer.footer-four .banner-content .form-inner,
.footer.footer-four .banner-content .input-group {
	border-radius: 4px;
	padding: 0;
	max-width: 600px;
}
.play-app {
	img {
		max-width: 142px;
	}
}
.free-customer {
	margin-bottom: 10px;
	p {
		color: #5F5F5F;
		font-size: 14px;
		font-weight: 500;
		margin-bottom: 5px;
	}
	h6 {
		color: #FFFFFF;
		font-weight: 600;
		font-size: 16px;
		font-size: 16px;
		margin-bottom: 0;
	}
}
.footer-four .social-icon ul li:nth-child(1) a,
.footer-four .social-icon ul li:nth-child(2) a,
.footer-four .social-icon ul li:nth-child(3) a {
	background: transparent;
}
.footer-five .footer-social-group .footer-bottom-nav li a,
.footer-five .social-icon span,
.footer-five .footer-social-group .social-icon ul li i,
.footer-five .footer-menu p {
	color: #FFF5F1;
}
.footer-five {
	.footer-social-group {
		.social-icon {
			ul {
				li {
					a {
						&:hover {
							background: #EBEBEB;
							i {
								color: #515151;
							}
						}
					}
				}
			}
		}
	}
	.footer-title {
		margin-bottom: 10px;
		font-size: 16px;
		font-weight: 700;
	}
	.copyright-text {
		p {
			color: #FFF5F1;
			font-size: 14px;
		}
	}
	.copyright {
		border-top: 1px solid #2E2E2E;
	}
	.footer-top-blk {
		border-bottom: 1px solid #2E2E2E;
	}
}
.footer-five .footer-title,
.footer-five .footer-menu .applicate-mobile-blk h6 {
	color: #fff;
}
.footer.footer-five .footer-menu ul li a:hover,
.footer.footer-five .footer-menu ul li a:hover i {
	color: #86E53E;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
}
.play-icon-store {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.footer-five h2.footer-title:before,
.footer-five h2.footer-title:after {
	display: none;
}
.footer.footer-five .banner-content .form-inner,
.footer.footer-five .banner-content .input-group {
	border-radius: 4px;
}
.transaction-shortby {
	.sort-show {
		padding-left: 10px;
	}
}
.freelance-img {
	position: relative;
	width: 106px;
	height: 106px;
	margin: 0 auto 15px;
	img {
		margin: auto;
		width: 106px;
		height: 106px;
		border-radius: 50%;
		border: 1px solid #86E53E;
		padding: 5px;
	}
	.verified {
		background-color: #fff;
		color: #159C5B;
		font-size: 18px;
		position: absolute;
		right: 5px;
		bottom: 5px;
		border-radius: 100%;
		z-index: 2;
		width: 25px;
		height: 25px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
	}
}
.freelance-info {
	h3 {
		font-size: 18px;
		font-weight: 600;
		line-height: 1.5;
		margin-bottom: 2px;
		a {
			color: #353535;
			&:hover {
				color: #86E53E;
			}
		}
	}
}
.freelance-specific {
	font-size: 14px;
	font-weight: 500;
	color: #515151;
	margin-bottom: 5px;
}
.freelance-location {
	color: #515151;
	font-size: 14px;
	margin-bottom: 10px;
	font-weight: 500;
}
.favourite {
	color: #9D9D9D;
	font-weight: bold;
	background: #fafafa;
	border: 1px solid #EBEBEB;
	border-radius: 50px;
	font-size: 12px;
	width: 30px;
	height: 30px;
	padding: 0;
	display: inline-flex;
	position: absolute;
	right: 15px;
	top: 15px;
	justify-content: center;
	align-items: center;
	&:hover {
		color: #fff;
		background: #86E53E;
		border: 1px solid #86E53E;
	}
}
.favourited {
	color: #fff;
	background: #86E53E;
	border: 1px solid #86E53E;
}
.cart-hover {
	text-align: center;
}
.btn-cart {
	width: 50%;
	display: inline-block;
	border-radius: 55px;
	border-color: transparent;
	text-transform: capitalize;
	background: #86E53E;
	color: #fff;
	padding: 10px 15px;
	font-weight: 700;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	font-size: 14px;
}
.btn-cart:hover,
.btn-cart:focus {
	background: #13323C;
	color: #fff;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
}
.freelance-content {
	padding: 20px 0px;
}
.sort-tab {
	background-color: #edeef3;
	padding: 15px 20px;
	border-radius: 6px;
	margin-bottom: 22px;
}
.freelance-view {
	h4 {
		font-size: 15px;
		font-weight: 500;
		color: #53545b;
		display: inline-block;
		margin-bottom: 0;
	}
}
.freelance-tags {
	margin-bottom: 20px;
}
.freelancers-price {
	font-size: 16px;
	font-weight: 600;
	color: #101828;
}
.freelance-blk-location {
	border-top: 1px solid #EBEBEB;
	padding-top: 15px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.widget-author {
	background-color: #fff;
	border-radius: 8px;
	margin-bottom: 20px;
	text-align: center;
	position: relative;
	background-color: #fff;
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	border: 1px solid rgba(239, 239, 239, 0.42);
	text-align: center;
	position: relative;
	margin-bottom: 30px;
	.cover-img {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		img {
			border-radius: 8px 8px 0 0;
			width: 100%;
			height: 100px;
		}
	}
	.profile-img {
		box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.12);
		background-color: #fff;
		padding: 15px;
		border-radius: 50%;
		width: 120px;
		height: 120px;
		margin: 0 auto 15px;
		a {
			border-radius: 50%;
			position: relative;
			display: inline-block;
			img {
				width: 100%;
			}
		}
	}
	.author-name {
		font-size: 16px;
		font-weight: 700;
		margin-bottom: 0;
		a {
			color: #161c2d;
		}
	}
	.author-location {
		font-size: 14px;
		color: #86E53E;
		margin-bottom: 10px;
		text-transform: uppercase;
		font-weight: 500;
	}
	.dev-text {
		position: relative;
		.left {
			img {
				display: block;
				float: left;
				width: 66px;
				border-radius: 12px;
			}
			.left-content {
				margin-left: 75px;
			}
		}
	}
	.left-content {
		h6 {
			color: #000;
		}
		h4 {
			color: #000;
			a {
				color: #000;
			}
		}
	}
	&.pro-post {
		box-shadow: 0px 4px 4px rgb(204 204 204 / 25%);
		border: 1px solid #e8e8e8;
		border-radius: 0;
	}
}
.counter-stats {
	margin-top: 25px;
	ul {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		margin: 0;
		list-style: none;
		padding: 0;
		li {
			padding-left: 18px;
			padding-right: 18px;
			position: relative;
			width: 33%;
			h3 {
				color: #51595e;
				font-weight: 700;
				margin-bottom: 3px;
				font-size: 16px;
			}
			h5 {
				color: #6e727d;
				text-transform: capitalize;
				font-size: 14px;
				margin-bottom: 0;
			}
			& + li {
				&::after {
					content: "";
					position: absolute;
					left: 0;
					top: 50%;
					-webkit-transform: translateY(-50%);
					transform: translateY(-50%);
					width: 1px;
					height: 18px;
					background-color: #ecf0f9;
				}
			}
		}
	}
}
.jobtype {
	color: #159C5B;
}
.developers-slider {
	.owl-nav {
		position: absolute;
		overflow: hidden;
		bottom: 100%;
		right: -5px;
		margin-bottom: 50px;
	}
}
.developers-slider.owl-carousel .owl-nav button.owl-next, .developers-slider.owl-carousel .owl-nav button.owl-prev, .developers-slider.owl-carousel button.owl-dot {
	display: block;
	float: left;
	width: 32px;
	height: 32px;
	font-size: 13px;
	color: #fff;
	border: 0;
	background: #13323C;
	text-align: center;
	-webkit-transition: color .7s ease, background-color .7s ease, border-color .7s ease;
	transition: color .7s ease, background-color .7s ease, border-color .7s ease;
	box-shadow: 0px 1px 4px rgba(183, 183, 183, 0.25);
	border-radius: 10px !important;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
.developers-slider.owl-carousel .owl-nav button.owl-next i, .developers-slider.owl-carousel .owl-nav button.owl-prev i, .developers-slider.owl-carousel button.owl-dot i {
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
.popular-slider .page-prev .owl-nav .owl-prev {
	transform:none;
	background: #FFF5F1;
	border: 1px solid #EBEBEB;
	width:44px;
	height: 44px;
	border-radius: 50px !important;
	box-shadow: none;

}
.popular-slider .page-prev  .owl-nav .owl-prev i{
    color:#86E53E;
}
// .popular-slider .page-prev,  .owl-nav .owl-next i{
//     color:#86E53E;
// }
.popular-slider.owl-carousel.owl-nav button.owl-prev:hover{
	background: #86E53E !important;
	border:1px solid #86E53E;
}

// .popular-slider .page-next,  .owl-nav .owl-next {
// 	transform:none;
// 	background: #FFF5F1;
// 	border: 1px solid #EBEBEB;
// 	width:44px;
// 	height: 44px;
// 	border-radius: 50px !important;
// 	box-shadow: none;
// }
.popular-slider.owl-carousel.owl-nav button.owl-prev i{
	color:#86E53E;
	transform:none;
}
// .developers-slider .page-next, .developers-slider .owl-nav .owl-next {
// 	border-right-width: 0px;
// 	border-radius: 50%;
// }
.testimonial-section {
	.owl-nav {
		position: absolute;
		overflow: hidden;
		bottom: 100%;
		right: -5px;
		margin-bottom: 50px;
	}
}
.testimonial-section .owl-carousel .owl-nav .owl-next  {
	display: block;
	float: left;
	width: 32px;
	height: 32px;
	font-size: 13px;
	color: #fff;
	border: 0;
	background: #13323C;
	text-align: center;
	-webkit-transition: color .7s ease, background-color .7s ease, border-color .7s ease;
	transition: color .7s ease, background-color .7s ease, border-color .7s ease;
	box-shadow: 0px 1px 4px rgba(183, 183, 183, 0.25);
	border-radius: 10px !important;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
.testimonial-section .owl-carousel .owl-nav .owl-prev  {
	display: block;
	float: left;
	width: 32px;
	height: 32px;
	font-size: 13px;
	color: #fff;
	border: 0;
	background: #13323C;
	text-align: center;
	-webkit-transition: color .7s ease, background-color .7s ease, border-color .7s ease;
	transition: color .7s ease, background-color .7s ease, border-color .7s ease;
	box-shadow: 0px 1px 4px rgba(183, 183, 183, 0.25);
	border-radius: 10px !important;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
.testimonial-section .owl-carousel .owl-nav .owl-next i{
	transform: rotate(-45deg);
}
.testimonial-section .owl-carousel .owl-nav .owl-prev i{
	transform: rotate(-45deg);
}
.owl-carousel .trend-slider .owl-nav{
	position:absolute;
	margin:0;
	right:0;
	top:-97px;
	min-width:90px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
}
.owl-carousel .trend-slider .owl-prev{
	position:absolute;
	margin:0;
	right:0;
	top:-97px;
	min-width:90px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
}
.owl-carousel .trend-slider .owl-nav .owl-prev{
	background: 0 0;
    color: inherit;
    border: none;
    padding: 0!important;
    font: inherit;
}

.list-pagination {
	margin-bottom: 0;
	float: right;
	border: 1px solid #dee2e6;
	border-top-left-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
}
.pro-status {
	border-top: 1px solid #F5F5F5;
	padding-top: 20px;
	margin-top: 20px;
}
.pro-list-view {
	.project-info {
		width: 70%;
		h2 {
			font-size: 22px;
			line-height: 1.3;
		}
	}
	.slot {
		p {
			font-size: 15px;
		}
		h5 {
			font-size: 18px;
		}
	}
	.projects-amount {
		h3 {
			font-size: 23px;
			font-weight: bold;
			color: #F5365C;
		}
		h5 {
			color: #6e727d;
		}
	}
}
.off-address {
	a {
		color: #fff;
	}
}
body {
	&.chat-page {
		overflow-y: hidden;
	}
}
.invoice-id {
	color: #FF3115;
}
.company-profile {
	h4 {
		font-size: 16px;
		color: #000;
		text-transform: uppercase;
	}
	.widget-box {
		padding: 0px;
		.latest-posts {
			li {
				padding: 13px 30px 13px;
				span {
					color: #86E53E;
				}
			}
		}
	}
}
.profile-head {
	padding: 30px 30px 6px 30px;
	.pro-title {
		border: 0;
	}
}
.profile-overview {
	.latest-posts {
		li {
			border-bottom: 1px solid #EDEDED;
			padding: 13px 0;
			&:last-child {
				border-bottom: 0;
				padding-bottom: 0;
				h6 {
					margin-bottom: 0;
				}
			}
		}
	}
}
.profile-overview,
.working-days,
.location-widget,
.social-widget {
	border-radius: 5px;
}
.map-location {
	padding: 30px;
	padding-top: 0;
	iframe {
		width: 100%;
		height: 200px;
	}
}
.contact-btn {
	margin-top: 0px;
	padding: 30px;
	.btn-primary {
		width: 100%;
		padding: 11px 15px;
		font-size: 20px;
		background: #8553EE;
		border: 0;
		border-radius: 50px;
		i {
			margin-right: 12px;
		}
	}
}
.working-days {
	.latest-posts {
		li {
			color: #6D7E8F;
			font-size: 14px;
			font-weight: 400;
			border-bottom: 1px solid #EDEDED;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			p {
				margin-bottom: 0;
			}
			h6 {
				margin-left: auto;
				color: #13323C;
				font-size: 14px;
				font-weight: 600;
				margin-bottom: 0;
			}
		}
	}
}
.social-link-profile {
	display: flex;
	padding: 30px;
	padding-top: 10px;
	margin: 0;
	li {
		list-style: none;
		margin-right: 15px;
		transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
		&:hover {
			box-shadow: 0px 4px 18px rgb(0 0 0 / 15%);
			transition: all 0.5s ease;
			-moz-transition: all 0.5s ease;
			-o-transition: all 0.5s ease;
			-ms-transition: all 0.5s ease;
			-webkit-transition: all 0.5s ease;
		}
		&:last-child {
			margin-right: 0px;
		}
		a {
			i {
				width: 50px;
				height: 50px;
				border: 1px solid #86E53E;
				border-radius: 5px;
				font-size: 24px;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				-webkit-box-pack: center;
				-ms-flex-pack: center;
				justify-content: center;
			}
		}
		&:nth-child(1) {
			a {
				i {
					color: #1877F2;
				}
			}
		}
		&:nth-child(2) {
			a {
				i {
					color: #1DA1F2;
				}
			}
		}
		&:nth-child(3) {
			a {
				i {
					color: #F5365C;
				}
			}
		}
		&:nth-child(4) {
			a {
				i {
					color: #0A66C2;
				}
			}
		}
		&:nth-child(5) {
			a {
				i {
					color: #229ED9;
				}
			}
		}
	}
}
.location-widget {
	.pro-title {
		margin-bottom: 40px;
	}
}
.abouts-view {
	border: 1px solid #E8E8E8;
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	border-radius: 5px;
	margin-bottom: 27px;
	li {
		margin-right: 10px;
		a {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			position: relative;
			img {
				margin-right: 10px;
			}
		}
		&:last-child {
			margin-right: 0px;
		}
	}
	ul {
		li {
			.active-about {
				background-color: rgba(255, 91, 55, 0.15);
				border-radius: 5px;
				&:before {
					content: "";
					position: absolute;
					width: 100%;
					height: 5px;
					background: #86E53E;
					top: -10px;
					left: 0;
					width: 100%;
					border-radius: 10px;
				}
			}
		}
	}
	.nav-tabs {
		&.nav-tabs-solid {
			padding: 20px;
			padding-top: 28px;
		}
	}
}
.abouts-view .nav-justified .nav-item,
.abouts-view  .nav-justified>.nav-link {
	flex-basis: inherit;
	flex-grow: inherit;
}
.company-post {
	border: 1px solid #E8E8E8;
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	border-radius: 5px;
	.pro-title {
		color: #000;
		margin-bottom: 38px;
		border-bottom: 0;
	}
	.list-pagination {
		margin: 0;
	}
	.project-company {
		margin-bottom: 30px;
		.project-info {
			span {
				font-weight: 600;
			}
		}
	}
}
.profile-baner {
	background: url(../../assets/img/bg/bg-13.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	padding: 60px 0px;
}
.profile-img {
	img {
		border-radius: 10px;
	}
}
.header-bg {
	background: #fff;
}
.profile-main {
	h2 {
		color: #fff;
		text-transform: uppercase;
		margin-bottom: 5px;
		font-size: 22px;
		i {
			color: #159C5B;
			background: #fff;
			border-radius: 50px;
			font-size: 16px;
		}
	}
	p {
		margin-bottom: 6px;
		font-size: 12px;
		font-weight: 400;
	}
	.rating {
		.average-rating {
			font-weight: 400;
		}
		i {
			&.filled {
				color: #febe42;
			}
		}
	}
}
.about-list {
	margin-bottom: 8px;
	ul {
		list-style: none;
		display: flex;
		margin-bottom: 0;
		li {
			padding: 0px 16px;
			border-right: 1px solid #FFFFFF;
			font-size: 12px;
			font-weight: 400;
			i {
				margin-right: 7px;
			}
			&:last-child {
				border-right: 0;
			}
			&:first-child {
				padding-left: 0;
				img {
					border-radius: 50px;
					width: 20px;
					height: 20px;
					margin-right: 12px;
				}
			}
		}
	}
}
.about-list ul li,
.profile-main p {
	color: #fff;
}
.profile-main .rating span,
.profile-main .rating i {
	color: #fff;
}
.project-company .slot p,
.project-company .slot h5,
.project-company .project-info span {
	font-size: 14px;
}
.project-company {
	margin-bottom: 15px;
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	border-radius: 5px;
	.project-info {
		.customer-info {
			ul {
				li {
					h5 {
						img {
							margin-right: 5px;
						}
					}
				}
			}
		}
	}
	.projects-action {
		.hired-detail {
			color: #86E53E;
			font-size: 13px;
			span {
				color: #000;
			}
		}
		.projects-btn {
			font-size: 14px;
			&:nth-child(2) {
				margin-bottom: 11px;
			}
		}
	}
}
.project-company .project-info span,
.project-company .project-info h2 {
	margin-bottom: 15px;
}
.project-company .project-info h2,
.project-company .projects-amount h3 {
	color: #000;
	font-size: 14px;
}
.company-gallery {
	.project-widget {
		position: relative;
		margin-bottom: 25px;
		transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
		.pro-image {
			a {
				&:before {
					content: "";
					position: absolute;
					width: 100%;
					height: 100%;
					border-radius: 5px;
					opacity: 0;
					background: linear-gradient(0deg, rgba(133, 83, 238, 0.87), rgba(133, 83, 238, 0.87));
					transition: all 0.5s ease;
					-moz-transition: all 0.5s ease;
					-o-transition: all 0.5s ease;
					-ms-transition: all 0.5s ease;
					-webkit-transition: all 0.5s ease;
				}
				&:hover {
					&:before {
						opacity: 1;
						transition: all 0.5s ease;
						-moz-transition: all 0.5s ease;
						-o-transition: all 0.5s ease;
						-ms-transition: all 0.5s ease;
						-webkit-transition: all 0.5s ease;
					}
				}
			}
		}
		&:hover {
			.view-gallery {
				i {
					display: block;
					transition: all 0.5s ease;
					-moz-transition: all 0.5s ease;
					-o-transition: all 0.5s ease;
					-ms-transition: all 0.5s ease;
					-webkit-transition: all 0.5s ease;
				}
			}
		}
	}
	.pro-image {
		img {
			width: 100%;
		}
	}
}
.view-gallery {
	i {
		position: absolute;
		top: 50%;
		left: 50%;
		color: #fff;
		display: none;
		transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
	}
}
.review-heading {
	padding: 25px;
}
.company-gallery .more-btn,
.company-review .more-btn {
	background: #F5365C;
	border-radius: 5px;
	min-width: 135px;
	padding: 9px 15px;
	font-size: 14px;
	margin-top: 20px;
}
.company-gallery .more-btn img,
.company-review .more-btn img {
	margin-right: 10px;
}
.company-review {
	.review-content {
		border: 1px solid #E8E8E8;
		border-radius: 5px;
		padding: 15px;
		margin-bottom: 20px;
		&.no-padding {
			& + .review-content {
				&.no-padding {
					padding-top: 15px;
				}
			}
		}
		h4 {
			font-size: 16px;
			color: #444444;
		}
	}
	p {
		font-size: 14px;
	}
}
.tab-reviews {
	margin-top: 15px;
	.review-img {
		width: 40px;
		img {
			border-radius: 50px;
		}
	}
	.review-info {
		margin: 0px 20px;
		h3 {
			a {
				font-size: 14px;
				color: #000;
				margin-bottom: 3px;
				font-weight: 600;
			}
		}
		h5 {
			font-size: 11px;
			color: #646464;
			margin-bottom: 0;
			font-weight: 400;
		}
	}
	.rating {
		i {
			color: #6E727D;
			&.filled {
				color: #febe42;
			}
		}
		.average-rating {
			font-weight: 400;
			margin-left: 10px;
		}
	}
}
.post-comment {
	label {
		color: #343434;
		font-size: 16px;
	}
	.form-control {
		border-color: #FFE6E1;
	}
}
.post-btn {
	.more-btn {
		background: #86E53E;
		border-radius: 5px;
		font-size: 18px;
		min-width: 160px;
		padding: 12px 15px;
	}
}
.abouts-detail {
	.pro-title {
		margin-bottom: 29px;
		font-size: 18px;
		text-transform: uppercase;
	}
	.pro-content {
		padding: 0;
	}
}
.developer-view .pro-title,
.develop-view .pro-title {
	border: 0;
	text-transform: uppercase;
	color: #000;
	font-size: 16px;
}
.develop-view {
	.widget-box {
		padding: 20px;
		border: 1px solid #E8E8E8;
		box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
		border-radius: 5px;
	}
	.abouts-view {
		.nav-link {
			padding: 8px;
		}
	}
	.provider-tabs {
		&.abouts-view {
			.nav-tabs {
				&.nav-tabs-solid {
					li {
						p {
							margin: 0;
						}
					}
				}
			}
		}
	}
}
.developer-profile-img {
	position: relative;
	margin-bottom: 25px;
}
.developer-view {
	.progress-bar {
		background: #3398FB;
	}
	.bg-success {
		background: #5BB054 !important;
	}
	.follow-widget {
		text-align: inherit;
		.follow-btn {
			padding: 10px 44px;
		}
	}
	.widget-box {
		padding: 20px;
		border: 1px solid #E8E8E8;
		box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
		border-radius: 5px;
	}
	.follow-posts {
		margin: 0;
		width: 100%;
		margin-bottom: 20px;
		box-shadow: 0px 4px 14px rgba(215, 215, 215, 0.25);
		border-radius: 5px;
		border: 0;
		li {
			&:first-child {
				width: 50%;
			}
		}
	}
}
.img-profile {
	img {
		border-radius: 50%;
		position: absolute;
		top: 36px;
		right: 43px;
		width: 150px;
		height: 150px;
		border: 4px solid #E0E0E0;
	}
}
.about-field {
	table {
		td {
			padding: 20px 0 20px;
			font-size: 15px;
		}
	}
	.table {
		tr {
			td {
				&:first-child {
					padding-left: 0px;
					color: #5C5C5C;
				}
			}
			&:last-child {
				td {
					padding-bottom: 0;
				}
			}
		}
	}
	.pro-title {
		margin-bottom: 15px;
		font-size: 16px;
		color: #000;
	}
}
.developer-box {
	h2 {
		font-size: 26px;
	}
	h3 {
		i {
			color: #159C5B;
			font-size: 24px;
		}
	}
	p {
		font-size: 16px;
		color: #000000;
		span {
			background: #129BE8;
			padding: 7px 17px;
			color: #fff;
			font-size: 14px;
			border-radius: 5px;
		}
	}
}
.developer-box h3,
.developer-box h3 a {
	font-size: 24px;
	font-weight: 500;
	color: #86E53E;
}
.head-develop {
	h2 {
		font-size: 28px;
		margin-bottom: 15px;
		color: #000;
		i {
			color: #159C5B;
			font-size: 28px;
			margin-left: 10px;
		}
	}
	p {
		span {
			margin-left: 11px;
		}
	}
	.rating {
		i {
			&.filled {
				width: 20px;
				height: 20px;
			}
		}
	}
	.develope-list-rate {
		li {
			font-size: 16px;
			font-weight: 500;
		}
	}
}
.develope-list-rate {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 27px;
	li {
		padding: 0px 20px;
		list-style: none;
		border-right: 1px solid #000000;
		color: #000000;
		&:last-child {
			border: 0;
			img {
				margin-right: 8px;
			}
		}
		&:first-child {
			padding-left: 0;
		}
	}
	.full-time {
		background: #129BE8;
		padding: 4px 15px;
		color: #fff;
		font-size: 14px;
		border-radius: 5px;
		margin-left: 20px;
		min-width: 87px;
	}
	.rating {
		.average-rating {
			background: #FEBE42;
			padding: 5px 5px;
			font-size: 11px;
			color: #000;
			border-radius: 5px;
			margin-right: 10px;
		}
	}
	.emp-edit {
		margin-left: auto;
	}
}
.project-box-view {
	h2 {
		color: #373737;
		margin-bottom: 30px;
		i {
			margin-left: 5px;
		}
	}
	h3 {
		margin-bottom: 17px;
	}
	.develope-list-rate {
		li {
			color: #13323C;
			font-size: 14px;
			font-weight: 500;
		}
		.full-time {
			color: #fff;
		}
	}
}
.project-details-view {
	.pro-post {
		transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
		&:hover {
			box-shadow: 0px 6px 6px rgb(204 204 204 / 40%);
			transition: all 0.5s ease;
			-moz-transition: all 0.5s ease;
			-o-transition: all 0.5s ease;
			-ms-transition: all 0.5s ease;
			-webkit-transition: all 0.5s ease;
		}
	}
	.pro-post-head {
		p {
			color: #4E4E4E;
			font-size: 14px;
		}
		h6 {
			color: #13323C;
			font-size: 17px;
			font-weight: 600;
		}
	}
	.job-type {
		text-align: left;
		width: 100%;
		margin-bottom: 30px;
		border: 1px solid #efefef;
		border-radius: 5px;
	}
	.widget-box {
		box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
		border-radius: 5px;
		border: 0;
		padding: 15px;
		.badge-design {
			background: rgba(255, 91, 55, 0.1);
			color: #86E53E;
			padding: 10px;
			margin-right: 15px;
			transition: all 0.5s ease;
			-moz-transition: all 0.5s ease;
			-o-transition: all 0.5s ease;
			-ms-transition: all 0.5s ease;
			-webkit-transition: all 0.5s ease;
			&:hover {
				background: rgba(255, 91, 55, 0.3);
				transition: all 0.5s ease;
				-moz-transition: all 0.5s ease;
				-o-transition: all 0.5s ease;
				-ms-transition: all 0.5s ease;
				-webkit-transition: all 0.5s ease;
			}
		}
	}
}
.proposal-box {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 22px;
	.favour-border {
		border: 1px solid #F5365C;
		transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
		&:hover {
			background-color: #86E53E;
			border-color: #86E53E;
			color: #fff;
			transition: all 0.5s ease;
			-moz-transition: all 0.5s ease;
			-o-transition: all 0.5s ease;
			-ms-transition: all 0.5s ease;
			-webkit-transition: all 0.5s ease;
		}
	}
}
.project-rate {
	li {
		i {
			font-weight: 500;
			margin-right: 10px;
		}
	}
}
.proposal-value {
	h4 {
		font-size: 25px;
		color: #86E53E;
		font-weight: 600;
		margin-bottom: 0;
	}
	span {
		font-size: 16px;
	}
}
.favourites-btn.btn-primary,
.proposal-btn.btn-primary {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	min-height: 47px;
	font-size: 16px;
}
.favourites-btn {
	&.btn-primary {
		background: transparent;
		color: #F5365C;
		margin: 0px 15px;
		min-width: 174px;
		i {
			margin-left: 7px;
		}
	}
}
.proposal-btn {
	&.btn-primary {
		background: #86E53E;
		min-width: 194px;
		&:hover {
			background: #86E53E;
		}
		i {
			margin-left: 4px;
		}
	}
}
.feed-back-detail {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 30px;
	li {
		list-style: none;
		padding: 0px 10px;
		border-right: 1px solid #13323C;
		font-size: 15px;
		color: #5C5C5C;
		font-weight: 500;
		span {
			padding-left: 10px;
			color: #F15C3D;
		}
		&:last-child {
			border: 0;
		}
	}
}
.feed-back-detail li:first-child,
.watch-list li:first-child {
	padding-left: 0;
}
.feed-back-detail li img,
.watch-list li img {
	margin-right: 10px;
}
.watch-list {
	margin: 22px 0px 30px;
	li {
		padding: 0px 10px;
		list-style: none;
		text-transform: uppercase;
		color: #000;
		font-size: 14px;
		font-weight: 600;
	}
}
.experiance-list {
	background: #F9F9F9;
	border-radius: 20px;
	padding: 20px;
	h4 {
		font-size: 16px;
		color: #292929;
		font-weight: 500;
		margin-bottom: 15px;
	}
	h5 {
		color: #F38247;
		margin-bottom: 5px;
	}
	p {
		color: #000000;
	}
}
.experiance-list h5,
.experiance-list p {
	font-size: 14px;
}
.experiance-logo {
	background: #86E53E;
	border-radius: 10px;
	width: 50px;
	height: 50px;
	margin-bottom: 10px;
	&.logo-bg {
		background: #F5365C;
	}
}
.develop-feedback {
	border: 1px solid #E8E8E8;
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	border-radius: 5px;
	.author-details {
		h6 {
			color: #F38247;
			font-size: 14px;
		}
		.blog-author-name {
			font-size: 14px;
		}
		p {
			font-size: 14px;
			color: #000;
		}
	}
}
.feedback-view {
	margin-left: auto;
	color: #86E53E;
	font-size: 14px;
	font-weight: 500;
}
.project-gallery {
	.project-widget {
		position: relative;
		.pro-image {
			a {
				&:before {
					content: "";
					position: unset !important;
					width: 100%;
					height: 100%;
					border-radius: 5px;
					opacity: 0;
					background: linear-gradient(0deg, rgba(251, 84, 0, 0.79), rgba(251, 84, 0, 0.79));
					transition: all 0.5s ease;
					-moz-transition: all 0.5s ease;
					-o-transition: all 0.5s ease;
					-ms-transition: all 0.5s ease;
					-webkit-transition: all 0.5s ease;
				}
				&:hover {
					&:before {
						opacity: 1;
						transition: all 0.5s ease;
						-moz-transition: all 0.5s ease;
						-o-transition: all 0.5s ease;
						-ms-transition: all 0.5s ease;
						-webkit-transition: all 0.5s ease;
					}
				}
			}
		}
		&:hover {
			.project-footer {
				opacity: 1;
				transition: all 0.5s ease;
				-moz-transition: all 0.5s ease;
				-o-transition: all 0.5s ease;
				-ms-transition: all 0.5s ease;
				-webkit-transition: all 0.5s ease;
			}
		}
	}
	.pro-image {
		img {
			width: 100%;
			border-radius: 5px;
		}
	}
	.more-btn {
		min-width: 135px;
		padding: 10px 15px;
		font-size: 15px;
	}
}
.view-image {
	margin-left: auto;
	i {
		width: 28px;
		height: 28px;
		background: #fff;
		color: #86E53E;
		border-radius: 50px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
}
.project-footer .pro-name,
.project-footer .pro-designation {
	color: #fff;
}
.project-footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	background: rgba(0, 0, 0, 0.47);
	border-radius: 0 0 5px 5px;
	padding: 10px;
	opacity: 0;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	.pro-name {
		font-size: 13px;
		margin-bottom: 0;
	}
	.pro-designation {
		font-size: 12px;
	}
	.pro-detail {
		padding: 0;
		text-align: left;
	}
}
.technical-skill {
	.tags {
		.badge-skills {
			width: auto;
			cursor: pointer;
		}
	}
	.badge-skills {
		background: rgba(255, 91, 55, 0.1);
		border-radius: 40px;
		color: #86E53E;
		font-size: 15px;
		font-weight: 500;
		padding: 10px 15px;
		margin-bottom: 0;
		transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
		&:hover {
			background: rgba(255, 91, 55, 0.3);
			transition: all 0.5s ease;
			-moz-transition: all 0.5s ease;
			-o-transition: all 0.5s ease;
			-ms-transition: all 0.5s ease;
			-webkit-transition: all 0.5s ease;
		}
	}
}
.develop-social-link {
	.profile-head {
		padding: 0;
	}
	.social-link-profile {
		padding: 0;
		li {
			a {
				i {
					transition: all 0.5s ease;
					-moz-transition: all 0.5s ease;
					-o-transition: all 0.5s ease;
					-ms-transition: all 0.5s ease;
					-webkit-transition: all 0.5s ease;
					&:hover {
						background: #13323C;
						border: 1px solid #13323C;
						color: #fff;
						transition: all 0.5s ease;
						-moz-transition: all 0.5s ease;
						-o-transition: all 0.5s ease;
						-ms-transition: all 0.5s ease;
						-webkit-transition: all 0.5s ease;
					}
				}
			}
		}
	}
	.pro-title {
		margin-bottom: 34px;
	}
}
.link-box {
	border-radius: 5px;
	.latest-posts {
		li {
			border-bottom: 1px solid #EDEDED;
			padding: 15px 20px;
			a {
				font-size: 14px;
				color: #6D7E8F;
			}
			&:last-child {
				padding-bottom: 5px;
			}
		}
	}
	.widget-title-box {
		padding: 20px 20px 0;
	}
}
.profile-link {
	.input-group {
		.form-control {
			color: #868686;
			font-size: 14px;
		}
	}
}
.develop-list-select {
	.list-grid {
		margin-right: 21px;
	}
}
.develop-list-pro {
	margin-left: auto;
	text-align: right;
	.freelancers-price {
		margin: 19px 0px;
	}
	.btn-cart {
		min-width: 164px;
		padding: 8px 15px;
	}
	.favourite {
		position: relative;
		top: inherit;
		right: inherit;
	}
}
.about-list-author {
	.about-author-img {
		overflow: inherit;
		img {
			border-radius: 50%;
		}
	}
	.verified {
		background-color: #fff;
		color: #159C5B;
		font-size: 18px;
		position: absolute;
		right: 0px;
		bottom: 5px;
		border-radius: 100%;
		z-index: 2;
		width: 25px;
		height: 25px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
	}
}
.list-develop-group {
	margin-bottom: 20px;
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	.freelance-location {
		margin-bottom: 0px;
	}
}
.wallet-group {
	background: #EDF8FF;
	border-radius: 5px;
	padding: 20px;
	width: 100%;
}
.wallet-history {
	.table {
		.thead-pink {
			th {
				color: #13323C;
				font-size: 15px;
			}
		}
		tr {
			td {
				color: #13323C;
				font-size: 14px;
				span {
					padding: 0;
					color: #646464;
					font-weight: 400;
					font-size: 11px;
				}
				&:last-child {
					color: #4AA44D;
				}
				&:nth-child(4) {
					i {
						color: #4AA44D;
					}
				}
			}
		}
	}
}
.wallet-btn {
	.btn-primary {
		min-width: 131px;
		padding: 8px 15px;
		font-size: 12px;
		border-radius: 130px;
		font-weight: 600;
	}
}
.wallet-img {
	width: 45px;
	height: 45px;
	margin-right: 15px;
	background: #129BE8;
	border-radius: 50px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.wallet-rupee {
	.profile-group {
		.input-group {
			border: 1px solid #FFE6E1;
			border-radius: 5px;
			padding: 0;
		}
	}
	.dol-btn {
		background: #EDEDED;
		border-radius: 5px;
		padding: 0;
		width: 64px;
		height: 64px;
	}
	.badge-skills {
		padding: 10px 37px;
		margin-right: 10px;
		font-size: 14px;
		color: #6E727D;
	}
	.form-control {
		border-color: #ffe6e1;
		height: 64px;
	}
	label {
		font-weight: 400;
		color: #343434;
	}
	.custom_radio {
		.checkmark {
			height: 25px;
			width: 25px;
			&:after {
				top: 5px;
				left: 5px;
				width: 13px;
				height: 13px;
			}
		}
	}
	.bootstrap-tags {
		padding: 0;
		margin-bottom: 15px;
	}
}
.wallet-rupee .form-group,
.wallet-rupee .custom_radio {
	margin-bottom: 15px;
}
.balance-total {
	h3 {
		font-size: 16px;
		color: #13323C;
		font-weight: 400;
		margin-bottom: 5px;
	}
	h2 {
		font-size: 18px;
		color: #129BE8;
		font-weight: 600;
		margin-bottom: 0;
	}
}
.total-credit {
	background: #E4FFEA;
	.wallet-img {
		background: #159C5B;
	}
	.balance-total {
		h2 {
			color: #159C5B;
		}
	}
}
.total-depit {
	background: #FFF0F3;
	.wallet-img {
		background: #FF2C00;
	}
	.balance-total {
		h2 {
			color: #FF2C00;
		}
	}
}
.wallet-list {
	padding: 0;
	box-shadow: none;
	background: transparent;
	.dol-btn {
		color: #000;
		background: #EDEDED;
		border: 1px solid #EDEDED;
	}
}
#wallet {
	.modal-header {
		border-bottom: 0;
		padding: 0;
		span {
			position: absolute;
			right: 30px;
			top: 30px;
			z-index: 9;
		}
	}
}
.wallet-head {
	h2 {
		font-size: 24px;
		margin: 23px 0px 40px;
	}
}
.freelance-box {
	padding: 25px;
	.freelance-tags {
		span {
			margin-bottom: 10px;
		}
	}
}
.book-mark .freelance-img,
.book-mark .freelance-img img {
	width: 86px;
	height: 86px;
}
.book-mark {
	padding-bottom: 0px;
	.freelance-info {
		h3 {
			font-size: 16px;
			font-weight: 500;
		}
	}
	.freelance-tags {
		span {
			font-size: 10px;
		}
	}
	.freelance-img {
		.verified {
			width: 16px;
			height: 16px;
			border: 1px solid #FFFFFF;
			border-radius: 50%;
			i {
				font-size: 16px;
			}
		}
	}
	.favourite {
		width: 27px;
		height: 27px;
	}
	.freelance-specific {
		margin: 5px 0px;
	}
	.freelance-location {
		margin-bottom: 6px;
	}
	.freelance-content {
		padding: 15px;
	}
	.freelance-widget {
		border: 1px solid #EFEFEF;
		box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
		margin-bottom: 25px;
	}
	.rating {
		i {
			color: #6E727D;
			&.filled {
				color: #febe42;
			}
		}
		.average-rating {
			font-weight: 400;
		}
	}
	.badge-design {
		background: #F6F6F6;
		&:hover {
			background: #FFF0F0;
		}
	}
	.btn-cart {
		padding: 6px 15px;
	}
	.list-pagination {
		margin-top: 20px;
	}
}
.book-mark .freelance-specific,
.book-mark .freelance-location,
.book-mark .rating i,
.book-mark .rating .average-rating,
.book-mark .btn-cart {
	font-size: 12px;
}
.favour-book {
	.freelance-info {
		h3 {
			a {
				color: #000;
			}
		}
	}
	.freelancers-price {
		color: #55545B;
	}
}
.book-mark .freelance-info h3 a:hover,
.tab-reviews .review-info h3 a:hover {
	color: #86E53E;
}
.list-grid {
	margin-left: auto;
	margin-bottom: 0;
	li {
		list-style: none;
		&:first-child {
			margin-right: 15px;
		}
		i {
			background: #FFFFFF;
			border: 1px solid #E9E9E9;
			width: 34px;
			height: 34px;
			border-radius: 5px;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
		}
		.favour-active {
			i {
				background: #86E53E;
				border: 1px solid #86E53E;
				color: #fff;
			}
		}
	}
}
.list-book-mark {
	.about-author {
		padding: 15px;
	}
	.freelance-tags {
		margin-bottom: 0;
		span {
			margin-bottom: 5px;
		}
	}
	.verified {
		width: 16px;
		height: 16px;
		border: 1px solid #FFFFFF;
		border-radius: 50%;
		right: 5px;
		i {
			font-size: 16px;
		}
	}
	.author-details {
		margin-left: 108px;
	}
}
.relavance-rate {
	margin-left: auto;
	.sort-by {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		select {
			padding: 0 4px;
			max-width: 110px;
		}
		label {
			margin-bottom: 0;
			margin-right: 4px;
		}
	}
}
.relavance-result {
	margin-bottom: 20px;
	h4 {
		font-size: 14px;
		color: #86E53E;
	}
}
.bookmark-projects {
	.counter-stats {
		margin-top: 0px;
		ul {
			li {
				padding-left: 0;
				padding-right: 0;
				h5 {
					margin-bottom: 3px;
				}
			}
		}
	}
	.freelance-content {
		padding: 10px;
	}
	.widget-author {
		.profile-img {
			width: 86px;
			height: 86px;
			padding: 0;
			border: 1px solid #C4C4C4;
			background: transparent;
			margin-bottom: 5px;
		}
		.author-location {
			a {
				font-size: 16px;
				margin-bottom: 5px;
				text-transform: capitalize;
				color: #86E53E;
			}
		}
	}
	.freelance-info {
		h3 {
			line-height: 1.3;
			margin-bottom: 5px;
			a {
				font-size: 12px;
				color: #6E727D;
				font-weight: 400;
			}
		}
	}
	.post-now {
		i {
			font-weight: 400;
		}
	}
	.freelance-tags {
		margin-bottom: 0px;
		span {
			margin-bottom: 5px;
		}
	}
	.freelancers-price {
		margin: 15px 0px;
		color: #55545B;
	}
}
.bookmark-projects .counter-stats ul li h3,
.bookmark-projects .counter-stats ul li h5 {
	font-size: 10px;
}
.favourite-group {
	.card-header {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
}
.post-job-icon {
	margin-left: auto;
	width: 34px;
	height: 34px;
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	border-radius: 5px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.blue-color {
	background: #3398FB;
}
.orange-color {
	background: #86E53E;
}
.pink-color {
	background: #F5365C;
}
.green-color {
	background: #5BB054;
}
.project-duration {
	.job-type {
		margin-bottom: 25px;
	}
	.pro-content {
		padding-top: 0;
	}
}
.requirement-img {
	position: relative;
}
.file-name {
	position: absolute;
	bottom: 10px;
	left: 10px;
	h4 {
		color: #000;
	}
	h5 {
		color: #A3A3A3;
	}
}
.file-name h4,
.file-name h5 {
	font-size: 14px;
	font-weight: 400;
}
.project-details-view .pro-post .pro-title,
.project-client-view .pro-post .pro-title {
	border-bottom: 0;
	text-transform: uppercase;
	font-size: 17px;
}
.project-client-view {
	.pro-post {
		border: 1px solid #E8E8E8;
		box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
		border-radius: 5px;
	}
	.widget-author {
		.author-location {
			a {
				font-size: 22px;
				color: #86E53E;
				margin-bottom: 5px;
				text-transform: capitalize;
				font-weight: 500;
			}
		}
		.profile-img {
			box-shadow: none;
			background-color: transparent;
			padding: 0;
			border-radius: 50%;
			border: 1px solid #C4C4C4;
			width: 86px;
			height: 86px;
			margin: 0 auto 15px;
		}
	}
	.freelance-info {
		h4 {
			color: #6E727D;
			font-size: 14px;
			font-weight: 500;
		}
	}
	.rating {
		i {
			color: #6E727D;
			&.filled {
				color: #febe42;
			}
		}
		.average-rating {
			font-weight: 400;
		}
	}
	.rounded-pill {
		padding: 8px 20px;
		font-size: 18px;
	}
	.follow-details {
		margin: 19px 0px;
	}
}
.client-about {
	.pro-post {
		border: 0;
		box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
		border-radius: 5px;
	}
	.client-list {
		margin-bottom: 25px;
		width: 100%;
	}
}
.link-project {
	.pro-title {
		margin-bottom: 26px;
	}
}
.pro-member {
	h6 {
		font-size: 15px;
		font-weight: 600;
	}
	span {
		font-weight: 500;
	}
}
.project-verified {
	position: absolute;
	bottom: 0;
	right: 5px;
	i {
		color: #00cc52;
		background: #fff;
		border-radius: 50%;
	}
}
.btn-get {
	font-weight: 900;
	font-size: 24px;
	color: #FFF;
	text-transform: uppercase;
	min-width: 300px;
	border-radius: 50px;
	padding: 16px;
}
.multistep-form {
	background: #F9F9F9;
	border-radius: 10px;
	.on-board {
		display: none;
		width: 100%;
		.form-focus {
			&.focused {
				.focus-label {
					z-index: 9;
				}
			}
		}
	}
	.group-select {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
	& > .card-body {
		padding: 50px;
	}
	.acc-title {
		color: #3C00F2;
		margin-bottom: 40px;
		font-weight: 900;
		font-size: 36px;
		text-align: center;
	}
	.form-group {
		margin-bottom: 25px;
		width: 100%;
	}
	.form-control {
		height: 50px;
	}
	label {
		font-size: 16px !important;
		color: #343434 !important;
	}
}
.workin-check {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	.form-check-input {
		border: 1px solid #FFE6E1;
		width: 25px;
		height: 25px;
		margin-right: 15px;
		&:focus {
			box-shadow: none;
		}
	}
}
.form-switch {
	.form-check-input {
		cursor: pointer;
	}
}
.on-board {
	.form-focus {
		&.focused {
			.focus-label {
				z-index: 99999;
			}
		}
	}
}
.account-onborad {
	max-width: 700px;
	text-align: center;
	margin: 0 auto;
	a {
		font-weight: 900;
		font-size: 24px;
		color: #8A2BE2;
		display: inline-block;
	}
	&.complte-board {
		// padding: 80px;
	}
}
.onboard-head {
	h2 {
		font-size: 22px;
		font-weight: 600;
		margin-bottom: 8px;
		span {
			color: #8A2BE2;
		}
	}
	p {
		font-size: 16px;
		color: #343434;
		font-weight: 500;
		margin-bottom: 40px;
	}
	a {
		padding: 21px;
		width: 200px;
		height: 200px;
	}
}
.back-home {
	img {
		margin-bottom: 40px;
	}
	.btn-primary {
		background: #86E53E;
		border-radius: 5px;
		color: #fff;
		font-size: 16px;
		min-width: 177px;
	}
	h2 {
		font-size: 20px;
		color: #000000;
	}
	p {
		font-size: 16px;
		color: #343434;
		font-weight: 400;
		margin: 8px 0px 42px;
	}
}
.multi-step {
	width: 40px;
	height: 40px;
	background: #E8E8E8;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-radius: 50%;
	color: #000000;
	font-weight: 700;
	font-size: 14px;
	margin-right: 15px;
	line-height: 0;
}
.multistep-progress {
	background: #FFFFFF;
	box-shadow: 0px 0px 50px rgba(132, 39, 225, 0.2);
	border-radius: 50px;
	padding: 10px 10px 0;
	margin: 0 auto 50px;
	max-width: 1046px;
	width: fit-content;
}
.progressbar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: auto;
	margin-top: 0;
	li {
		display: flex;
		align-items: center;
		margin: 0;
		margin-right: 30px;
		h5 {
			margin-bottom: 0;
			color: #343434;
			font-weight: 500;
			font-size: 15px;
		}
	}
}
.board-logo {
	width: 20%;
}
.progress-active {
	.multi-step {
		background: #86E53E;
		color: #fff;
	}
}
.progress-activated {
	.multi-step {
		background: #86E53E;
		font-size: 0;
		color: #fff;
		&:before {
			content: "\f00c";
			font-family: 'Font Awesome 5 Free';
			font-weight: 900;
			font-size: 12px;
		}
	}
	h5 {
		color: #86E53E;
	}
}
.progress-25 {
	display: block;
}
.progress-50, .progress-75, .progress-100 {
	display: none;
}
.progress-50 {
	.progress-bar {
		background: #159C5B;
		width: 50%;
	}
}
.progress-75 {
	.progress-bar {
		background: #159C5B;
		width: 75%;
	}
}
.progress-100 {
	.progress-bar {
		background: #159C5B;
		width: 100%;
	}
}
.circle-bar1 {
	height: 55px;
	margin-bottom: 10px;
}
.circle-graph1 {
	height: 55px;
}
.circle-bar {
	& > div {
		display: inline-block;
		position: relative;
		text-align: center;
		p {
			left: 0;
			position: absolute;
			top: 50%;
			right: 0;
			text-align: center;
			margin: 0 auto;
			transform: translateY(-50%);
			font-weight: 500;
			font-size: 14px;
			color: #19D1AF;
		}
		canvas {
			width: 55px !important;
			height: 55px !important;
		}
	}
}
.circle-percent-0 {
	p {
		color: #8B8E97 !important;
	}
}
.btn-prev {
	border: 0;
	// background: url(../img/icons/arrow-left.svg) no-repeat;
	padding-left: 30px;
	font-weight: 900;
	font-size: 24px;
	background-position: left;
	margin-right: 30px;
}
.field-card {
	padding: 30px;
}
.acc-title-01 {
	display: block;
}
.acc-title-02, .acc-title-03, .acc-title-04, .acc-title-05 {
	display: none;
}
.account-wrap {
	.header {
		box-shadow: none;
		background: transparent;
	}
	.page-wrapper {
		padding-top: 0;
		height: 100%;
		& > .content {
			padding: 30px;
			height: 100%;
		}
	}
	.acc-content {
		height: 100%;
		background: #F9F9F9;
		border-radius: 10px;
	}
}
.board-screen {
	.content {
		padding: 0;
	}
	.multistep-progress {
		width: 100%;
		max-width: 100%;
		margin: 0;
		padding: 15px;
		box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
		border-radius: 0;
	}
	.progressbar {
		margin-bottom: 0;
		max-width: 70%;
		margin-left: auto;
	}
	.multistep-form {
		background: #fff;
	}
}
.first-progress,
.second-progress {
	display: flex;
	align-items: center;
	height: 53px;
}
.select-account {
	padding: 80px 0px;
	.pro-form-img {
		.btn-primary {
			min-width: 191px;
			background: transparent;
			border: 1px solid #FFE6E1;
			color: #86E53E;
			padding: 12px 15px;
			font-size: 16px;
		}
	}
	.prev_btn {
		min-width: 150px;
		padding: 10px 15px;
		font-size: 16px;
		text-transform: capitalize;
		background: rgba(20, 20, 20, 0.7);
		border-radius: 5px;
		color: #fff;
	}
	.btn-primary {
		min-width: 150px;
		padding: 9px 15px;
		font-size: 16px;
		text-transform: capitalize;
		background: #86E53E;
		border-radius: 5px;
		font-weight: 600;
	}
}
.select-box {
	margin-bottom: 130px;
	.frelance-img {
		&:first-child {
			margin-right: 30px;
		}
	}
	input {
		margin: 0;
		padding: 0;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		&:active {
			& + .employee-level {
				opacity: .9;
			}
		}
		&:checked {
			& + .employee-level {
				border: 1px solid #159C5B;
			}
		}
	}
	.employee-level {
		&:first-child {
			margin-right: 25px;
		}
	}
}

.guardian-onboard-info {
	background: #FFFFFF;
	box-shadow: 0px 0px 15px rgba(215, 215, 215, 0.56);
	border-radius: 10px;
	max-width: 90%;
	margin: auto;
	margin-bottom: 40px;
	padding: 30px;
	.form-control {
		&::placeholder {
			color: #888888;
		}
	}
	label {
		color: #343434;
		font-size: 16px;
		span {
			color: #FF0000;
		}
	}
}

.personal-info {
	background: #FFFFFF;
	border: 1px solid #EDEDED;
	box-shadow: 0px 0px 15px rgba(215, 215, 215, 0.56);
	border-radius: 10px;
	max-width: 90%;
	margin: auto;
	margin-bottom: 40px;
	padding: 30px;
	.form-control {
		border-color: #FFE6E1;
		&::placeholder {
			color: #888888;
		}
	}
	label {
		color: #343434;
		font-size: 16px;
		span {
			color: #FF0000;
		}
	}
}
.space-info {
	padding-bottom: 5px;
}
.pro-form-img {
	margin-bottom: 20px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	img {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		margin-right: 15px;
	}
	.file-upload {
		margin: 0;
		min-width: 191px;
		text-align: center;
	}
	label {
		&.file-upload {
			&.image-upbtn {
				background: transparent;
				color: #86E53E !important;
				border: 1px solid #FFE6E1;
			}
		}
	}
}
.min-characters {
	p {
		color: #888888;
		margin-bottom: 0;
		margin-top: 5px;
	}
	.form-control {
		height: auto;
	}
}
.select-level {
	width: 100%;
	color: #888888;
	position: relative;
	background-image: url(../../assets/img/icon/icon-18.svg);
	background-repeat: no-repeat, no-repeat;
	background-position: 95%;
}
.select-edu {
	background-position: 88%;
}
.new-addd {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: end;
	min-height: 50px;
	.add-new {
		margin-bottom: 0;
		color: #159C5B;
		margin-left: 7px;
		cursor: pointer;
		font-size: 16px;
		font-weight: 500;
		i {
			font-size: 12px;
			margin-right: 5px;
		}
	}
	.remove_row {
		color: #FF0000;
	}
}
.remove_row {
	cursor: pointer;
}
.employe-cal-icon {
	&:after {
		color: #141414B2;
	}
}
.other-info-list {
	ul {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		li {
			width: 50px;
			height: 50px;
			background: #C4C4C4;
			border-radius: 50px;
			list-style: none;
			color: #fff;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
			font-size: 12px;
			font-weight: 600;
			margin-right: 26px;
			cursor: pointer;
		}
		.active-hour {
			background: #86E53E;
		}
	}
}
.time-box {
	.form-group {
		margin-right: 20px;
		max-width: 118px;
	}
}
.check-hour {
	display: none;
}
.employee-level {
	cursor: pointer;
	background-size: contain;
	background-repeat: no-repeat;
	display: inline-block;
	width: 200px;
	height: 200px;
	border: 1px solid #FFE6E1;
	border-radius: 5px;
	margin-bottom: 0;
	font-weight: 600;
	span {
		color: #343434;
		font-size: 14px;
		font-weight: 600;
	}
	img {
		margin-bottom: 20px;
	}
}
.free-lance-img {
	margin-right: 25px;
}
.select-btn {
	padding: 80px 0px;
}
.work-check {
	margin-bottom: 0;
	.form-check-input {
		width: 39px;
		height: 22px;
		position: absolute;
		top: 10px;
		right: 20px;
		&:checked {
			background-color: #86E53E;
			border-color: #86E53E;
		}
		&:focus {
			box-shadow: none;
		}
	}
}
.blog-tricks .blog-slider .owl-carousel .owl-nav .owl-prev{
	background: #fff;
	color:#86E53E;
	width:44px;
	height:44px;
	font-size:16px;
	border-radius: 50px;
	position: absolute;
	border:1px solid #EBEBEB;
	top:40%;
	left:-25px;
}
.blog-tricks .blog-slider .owl-carousel .owl-nav .owl-next{
	background: #fff;
	color:#86E53E;
	width:44px;
	height:44px;
	font-size:16px;
	border-radius: 50px;
	position: absolute;
	border:1px solid #EBEBEB;
	top:40%;
	right:-25px;
}
.blog-tricks .blog-slider .owl-carousel .owl-nav .owl-next:hover{
  background:#86E53E ;
  color:#fff;
}
.blog-trick .blog-slider .owl-carousel .owl-nav .owl-prev:hover{
background:#86E53E ;
   color:#fff;
}
.testimonial-section .owl-carousel .owl-nav button .owl-prev{
	display: block;
	float:left;
	width: 32px;
	height: 32px;
	font-size: 13px;
	color:#fff;
	background: #13323C;
	text-align: center;
	border-radius: 10px !important;
}
.trend-projects.owl-carousel .trend-slider.owl-dots{
	position: absolute;
	margin:0;
	right:0;
	min-width:90px;
	top:-94px;
}
.trend-projects .owl-carousel .trend-slider .owl-nav{
	position:absolute;
	margin:0;
	right: 0;
	top: -97;
	min-width: 90px;
	display: flex;
	-webkit-box-align:center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content:space-between;
}
.trend-projects .owl-carousel .trend-slider .owl-nav button.owl-prev{
	background:0 0;
	color:inherit;
	border:none;
	padding: 0 !important;

}
.developers-slider .owl-carousel .owl-nav .owl-prev{
	display: block;
    float: left;
    width: 32px;
    height: 32px;
    font-size: 13px;
    color: #fff;
    border: 0;
    background: #13323C;
    text-align: center;
    -webkit-transition: color .7s ease, background-color .7s ease, border-color .7s ease;
    transition: color .7s ease, background-color .7s ease, border-color .7s ease;
    box-shadow: 0px 1px 4px rgba(183, 183, 183, 0.25);
    border-radius: 10px !important;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.developers-slider .owl-carousel .owl-nav .owl-next{
	display: block;
    float: left;
    width: 32px;
    height: 32px;
    font-size: 13px;
    color: #fff;
    border: 0;
    background: #13323C !important;
    text-align: center;
    -webkit-transition: color .7s ease, background-color .7s ease, border-color .7s ease;
    transition: color .7s ease, background-color .7s ease, border-color .7s ease;
    box-shadow: 0px 1px 4px rgba(183, 183, 183, 0.25);
    border-radius: 10px !important;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.developers-slider .owl-carousel .owl-nav .owl-prev i{
	transform: rotate(-45deg);
}
.developers-slider .owl-carousel .owl-nav .owl-next i{
	transform: rotate(-45deg);
}
.client-img .slick-prev {
	display: none !important;
}
.client-img .slick-next{
	display: none !important;
}

.blog-trick-five.blog-slider.owl-carousel.owl-nav button.owl-next{
	background: #FFF5F1;
}
.dashboard-page .content > .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
}
.footer{
	padding:30px;
}

@media only screen and (min-width:768px){
	.avatar-xxl {
		width: 8rem;
		height: 8rem;
		.border {
			border-width: 4px !important;
		}
		.rounded {
			border-radius: 12px !important;
		}
		.avatar-title {
			font-size: 42px;
		}
	}
	.avatar-xxl.avatar-away:before,
		.avatar-xxl.avatar-offline:before,
		.avatar-xxl.avatar-online:before {
		border-width: 4px;
	}

}
@media (min-width: 992px){
	.container {
		max-width: 1440px;
	}
	.main-nav {
		& > li {
			margin-right: 30px;
			&:last-child {
				margin-right: 0;
			}
			& > a {
				padding: 30px 0;
				& > i {
					font-size: 10px;
					margin-left: 2px;
				}
			}
			.submenu {
				li {
					&:first-child {
						a {
							border-top: 0;
						}
					}
				}
				& > li {
					.submenu {
						left: 100%;
						top: 0;
						margin-top: 10px;
					}
				}
			}
			&.has-submenu {
				&:hover {
					& > .submenu {
						& > li {
							&.has-submenu {
								&:hover {
									& > .submenu {
										visibility: visible;
										opacity: 1;
										margin-top: -1px;
										margin-right: 0;
									}
								}
							}
						}
					}
				}
			}
			& > i {
				color: #fff;
				line-height: 75px;
				&:hover {
					cursor: pointer;
				}
			}
		}
		li {
			display: block;
			position: relative;
			& > ul {
				background-color: #fff;
				box-shadow: 0 0 3px  rgba(0, 0, 0, 0.1);
				display: block;
				font-size: 14px;
				left: 0;
				margin: 0;
				min-width: 200px;
				opacity: 0;
				padding: 0;
				position: absolute;
				-webkit-transition: all .2s ease;
				transition: all .2s ease;
				-webkit-transform: translateY(20px);
				-ms-transform: translateY(20px);
				transform: translateY(20px);
				top: 100%;
				visibility: hidden;
				z-index: 1000;
			}
			&.has-submenu {
				&:hover {
					& > .submenu {
						visibility: visible;
						opacity: 1;
						margin-top: 0;
						-webkit-transform: translateY(0);
						-ms-transform: translateY(0);
						transform: translateY(0);
					}
				}
			}
		}
		.has-submenu {
			&.active {
				.submenu {
					li {
						&.active {
							& > a {
								color: #fff;
								background-color: #86E53E;
							}
						}
					}
				}
			}
		}
		& > .has-submenu {
			& > .submenu {
				& > .has-submenu {
					& > .submenu {
						&::before {
							top: 20px;
							margin-left: -35px;
							box-shadow: 1px 1px 0 0 rgba(0,0,0,.15);
							border-color: transparent #fff #fff transparent;
						}
					}
				}
			}
		}
	}
	.main-nav > li .submenu li a:hover,
		.header-three .main-nav > li .submenu li a:hover {
		background-color: #86E53E;
		color: #fff;
		border-color: transparent;
	}
	.searchbar {
		line-height: 60px;
	}
	.header-navbar-rht {
		.dropdown-toggle {
			&.show {
				& + .dropdown-menu {
					visibility: visible;
					opacity: 1;
					margin-top: 0;
					-webkit-transform: translateY(0);
					-ms-transform: translateY(0);
					transform: translateY(0);
				}
			}
		}
	}

}
@media only screen and (max-width: 1399px){
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name,
 .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
	max-width: 180px;
}
}
@media only screen and (max-width: 1199.98px){
	.header-nav {
		padding-left: 20px;
		padding-right: 20px;
	}
	.header-navbar-rht {
		li {
			&.lang-item {
				display: none;
			}
			a {
				font-size: 12px;
				align-items: center;
			}
		}
		.login-btn {
			padding: 8px 30px;
			font-size: 14px;
		}
	}
	.main-nav {
		li {
			a {
				font-size: 13px;
			}
		}
		& > li {
			margin-right: 15px;
		}
	}
	.project-content {
		h5 {
			font-size: 12px;
		}
	}
	.work-content {
		width: 83%;
		padding: 70px 50px 50px 60px;
		h2 {
			font-size: 26px;
		}
	}
	.footer-title {
		font-size: 19px;
	}
	.footer {
		.footer-menu {
			ul {
				li {
					a {
						font-size: 15px;
					}
				}
			}
		}
	}
	.agency-content {
		padding: 35px;
	}
	.payment-detail {
		padding: 25px;
		p {
			font-size: 18px;
		}
	}
	.profile-list {
		li {
			width: calc(100%/3);
		}
	}
	.btn-list {
		margin-bottom: 7px;
	}
	.profile-title {
		font-size: 30px;
	}
	.follow-posts {
		width: 240px;
	}
	.left-content {
		h4 {
			font-size: 12px;
		}
	}
	.bid-btn, .btn-view-outline {
		font-size: 11px;
	}
	.profile-right {
		display: inline-block;
		li {
			margin-right: 0;
		}
	}
	.job-type {
		h6 {
			font-size: 17px;
		}
	}
	.amt-hr {
		font-size: 28px;
	}
	.bid-btn, .dev-right h6 {
		font-size: 13px;
	}
	.dev-right {
		p {
			font-size: 15px;
		}
	}
	.job-category {
		p {
			font-size: 14px;
		}
	}
	.stats-detail {
		padding: 18px;
	}
	.stats-detail h3, .create-milestone h3 {
		font-size: 24px;
	}
	.table-borderless {
		&.table {
			tbody {
				tr {
					td {
						font-size: 20px;
					}
				}
			}
		}
	}
	.dash-widget-info {
		font-size: 17px;
	}
	.proposals-info {
		display: block;
	}
	.proposals-detail {
		width: 100%;
	}
	.project-hire-info {
		width: 100%;
		&.project {
			width: 100%;
		}
	}
	.content-divider-1 {
		display: none;
	}
	.projects-details {
		display: block;
	}
	.project-info {
		&.project {
			width: 100%;
		}
	}
	.content-divider {
		display: none;
	}
	.proposer-info {
		&.project {
			padding-left: 0;
		}
	}
	.projects-action {
		&.project {
			padding-left: 0;
		}
	}
	.need-project {
		h2 {
			font-size: 38px;
		}
	}
	.develope-project,
		.news-two,
		.get-discount,
		.most-hired,
		.projects-two {
		padding: 60px 0px;
	}
	.perfect-group {
		h2 {
			font-size: 20px;
		}
	}
	.img-perfect {
		img {
			width: 100%;
		}
	}
	.develop-bottom {
		h5 {
			padding: 16px 25px;
		}
	}
	.board-logo {
		width: 10%;
	}
	.social-link-profile {
		flex-wrap: wrap;
		li {
			margin-bottom: 15px;
		}
	}
	.develop-social-link {
		&.widget-box {
			margin-bottom: 5px;
		}
	}
	.work {
		&:after {
			width: 35px;
			transform: inherit;
		}
	}
	.banner-content {
		h1 {
			font-size: 38px;
		}
		p {
			font-size: 18px;
		}
		.form-control {
			height: 58px;
		}
		.sub-btn {
			height: 40px;
			font-size: 14px;
		}
	}
	.all-btn {
		padding: 10px 35px;
		font-size: 14px;
		padding: 10px 30px;
		min-width: 180px;
	}
	.job-content-blk {
		h1 {
			font-size: 28px;
		}
		p {
			font-size: 16px;
		}
	}
	.owl-carousel {
		.owl-item {
			.review-blog {
				.review-img {
					img {
						width: 60px;
					}
				}
			}
		}
		&.trend-slider {
			.owl-nav {
				display: none;
			}
		}
	}
	.home-two {
		.market-place {
			h3 {
				font-size: 20px;
			}
		}
	}
	.home-count {
		ul {
			li {
				font-size: 20px;
				span {
					font-size: 16px;
				}
			}
		}
	}
	.platform-group {
		h1 {
			font-size: 24px;
		}
	}
	.feature-project-list {
		li {
			font-size: 15px;
		}
	}
	.feature-content {
		h4 {
			font-size: 18px;
		}
	}
	.about-it-content {
		p {
			font-size: 15px;
		}
	}
	.more-project {
		.btn-primary {
			min-width: 183px;
			padding: 8px 15px;
			font-size: 14px;
		}
	}
	.blog-slider {
		&.owl-carousel {
			.owl-nav {
				button {
					&.owl-prev {
						left: -5px;
					}
					&.owl-next {
						right: -5px;
					}
				}
			}
		}
	}
	.blog-slider.owl-carousel .owl-nav button.owl-prev,
		.blog-slider.owl-carousel .owl-nav button.owl-next {
		width: 35px;
		height: 35px;
		font-size: 12px;
	}
	.great-about {
		padding: 80px 0px 410px;
	}
	.dot-slider {
		.slick-list {
			height: 445px !important;
		}
		&.slick-vertical {
			.slick-slide {
				height: 445px;
			}
		}
	}
	.home-three {
		.banner-content {
			h1 {
				font-size: 28px;
			}
		}
	}
	.home-three .slick-prev:before, .home-three .slick-next:before {
		width: 15px;
		height: 15px;
		background-size: 91%;
	}
	.home-three .slick-prev, .home-three .slick-next {
		width: 40px;
		height: 40px;
	}
	.header-four {
		.header-navbar-rht {
			li {
				a {
					&.reg-btn {
						font-size: 14px;
					}
				}
			}
		}
	}
	.home-four {
		.banner-content {
			h5 {
				font-size: 18px;
			}
			h1 {
				font-size: 36px;
			}
		}
	}
	.stacker-active {
		font-size: 13px;
		bottom: -2px;
		width: 16px;
		height: 16px;
	}
	.fullstacker-img {
		width: 70px;
		height: 70px;
	}
	.fullstacker-name {
		h4 {
			font-size: 16px;
		}
	}
	.complete-content {
		h3 {
			font-size: 18px;
		}
	}
	.complete-icon {
		width: 45px;
		height: 45px;
	}
	.market-list-out {
		li {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
		}
	}
	.market-place-group {
		h2 {
			font-size: 26px;
		}
	}
	.market-place-btn .btn-primary,
		.market-place-btn .market-developer {
		font-size: 14px;
	}
	.popular-slider {
		&.developers-slider {
			.owl-nav {
				bottom: 100%;
				right: 22%;
				margin-bottom: 55px;
			}
		}
	}
	.review-four {
		padding: 80px 0px 120px;
	}
	.market-place-btn {
		&.platform-btn {
			.btn-primary {
				min-width: 180px;
			}
		}
	}
	.register-profesion {
		.avatar-group {
			.avatar {
				width: 38px;
				height: 38px;
				border: 2px solid #fff;
			}
		}
	}
	.header-five {
		.header-navbar-rht {
			li {
				.reg-btn {
					padding: 8px 15px;
					font-size: 14px;
					min-width: 170px;
				}
			}
		}
	}
	.banner-blk-five {
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
	}
	.home-five {
		.banner-content {
			.sub-btn {
				height: 42px;
				min-width: 42px;
				padding: 10px 6px;
				margin: 6px;
				i {
					font-size: 21px;
				}
			}
		}
	}
	.need-job-group {
		.about-great-content {
			h4 {
				font-size: 18px;
			}
		}
	}
	.project-item {
		.feature-foot {
			p {
				font-size: 14px;
			}
		}
	}
	.demand-professional {
		h2 {
			font-size: 24px;
		}
	}
	.demand-post-img {
		margin-right: 20px;
	}
	.join-market-world {
		padding-left: 45px;
		h2 {
			font-size: 22px;
			margin-bottom: 20px;
		}
		p {
			margin-bottom: 30px;
		}
	}
	.join-platform-img {
		&:before {
			background-size: 100%;
		}
	}
	.compay-hire {
		.compay-hire-blk {
			margin-bottom: 25px;
		}
	}
	.img-reviews {
		img {
			max-width: 170px;
		}
	}
	.review-quote {
		img {
			max-width: 45px;
		}
	}
	.testimonial-two-head {
		h3 {
			font-size: 20px;
		}
		p {
			font-size: 14px;
		}
	}
	.grid-blog {
		.blog-title {
			font-size: 18px;
		}
	}
	.great-find-job {
		&.job-next-blk {
			h2 {
				font-size: 24px;
			}
		}
	}
	.job-next-blk {
		p {
			font-size: 15px;
		}
	}
	.blog-tricks-five {
		padding: 80px 0px 120px;
	}
	.opportunity-img {
		max-width: 500px;
	}
	.hire-company-list {
		ul {
			li {
				border: 1px solid #EBEBEB !important;
			}
		}
	}
	.talent-developer {
		&:before {
			display: none;
		}
	}
	.whirly-loader {
		width: 80px;
		height: 80px;
	}
	.loader-img {
		img {
			width: 40px;
		}
	}
	.scroll-top {
		width: 40px;
		height: 40px;
	}

}
@media only screen and (max-width: 991.98px){
	h1, .h1 {
		font-size: 32px;
	}
	h2, .h2 {
		font-size: 28px;
	}
	h3, .h3 {
		font-size: 20px;
	}
	h4, .h4 {
		font-size: 18px;
	}
	h5, .h5 {
		font-size: 16px;
	}
	h6, .h6 {
		font-size: 15px;
	}
	.banner-content {
		h1 {
			font-size: 30px;
			line-height: 39px;
		}
		p {
			font-size: 19px;
		}
	}
	.courses-btn, .get-btn {
		font-size: 15px;
		padding: 14px 28px;
	}
	.container {
		max-width: 100%;
	}
	.feature-item {
		padding: 30px 10px;
	}
	.feature {
		.feature-content {
			h3 {
				font-size: 26px;
			}
			p {
				font-size: 18px;
			}
		}
	}
	.myprofile-header {
		h2 {
			font-size: 20px;
		}
	}
	.bg1, .bg2 {
		background-size: 150px;
	}
	.setting-tab {
		.nav-link {
			padding: 15px 20px;
			font-size: 16px;
		}
	}
	.setting-content {
		.pro-head {
			h2 {
				font-size: 22px;
			}
		}
	}
	.work-content {
		width: 90%;
		padding: 40px;
		.long-arrow {
			width: 50px;
			height: 50px;
			line-height: 50px;
			font-size: 22px;
		}
		h2 {
			font-size: 26px;
		}
	}
	.work-content p, .section-header p {
		font-size: 16px;
	}
	.all-btn {
		padding: 12px 24px;
		font-size: 16px;
	}
	.review-blog {
		padding: 20px;
		.review-img {
			img {
				width: 100px;
				height: 100px;
				width: 60px;
				height: 60px;
				margin-right: 10px;
			}
		}
		.review-content {
			p {
				font-size: 15px;
			}
		}
	}
	.review-info {
		flex-grow: 1;
		h3 {
			font-size: 18px;
		}
	}
	.review-bottom {
		.review-img {
			img {
				width: 50px;
				height: 50px;
			}
		}
		.review-info {
			h3 {
				font-size: 16px;
			}
		}
	}
	.news-content {
		margin-right: 0;
		.entry-meta {
			li {
				font-size: 14px;
				&.name {
					i {
						font-size: 16px;
					}
				}
			}
		}
		h2 {
			font-size: 16px;
		}
		p {
			font-size: 15px;
		}
	}
	.service-item {
		padding: 25px;
	}
	.service-content {
		h5 {
			font-size: 17px;
		}
	}
	.social-icon {
		ul {
			li {
				a {
					font-size: 13px;
				}
			}
		}
	}
	.news-blog {
		align-items: unset;
		.d-flex {
			display: block !important;
		}
	}
	.expert, .about, .job-counter {
		padding: 50px 0;
	}
	.about {
		.about-img {
			text-align: center;
		}
	}
	.agency-content {
		width: 100%;
		padding: 20px;
		h2 {
			font-size: 20px;
			margin-bottom: 8px;
			line-height: 24px;
		}
	}
	.counter {
		h2 {
			font-size: 28px;
		}
		h4 {
			font-size: 18px;
		}
	}
	.follow-posts {
		width: 280px;
	}
	.payment-detail {
		h2 {
			font-size: 32px;
		}
	}
	.profile-list {
		li {
			width: calc(100%/3);
		}
	}
	.profile-title {
		font-size: 28px;
	}
	.profile-right, .profile-preword {
		display: block;
	}
	.profile-right {
		li {
			margin-right: 0;
		}
	}
	.btn-list {
		margin-bottom: 7px;
	}
	.payment-list {
		label {
			font-size: 15px;
		}
	}
	.click-btn {
		font-size: 16px;
		padding: 10px 35px;
	}
	.left-content {
		h4 {
			font-size: 12px;
		}
	}
	#accordion {
		.card-header {
			font-size: 17px;
		}
	}
	.footer-user {
		top: 92px;
	}
	.sidebar-overlay {
		&.opened {
			display: block;
		}
	}
	.price-features {
		margin-top: 0;
		.header-price {
			h3 {
				font-size: 28px;
			}
		}
	}
	.column-5 {
		width: calc(100%/3);
		margin-bottom: 20px;
	}
	.header {
		&.min-header {
			background-color: #fff4f0;
		}
	}
	.main-nav {
		padding: 0;
		-ms-flex-direction: column;
		flex-direction: column;
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;
		ul {
			background-color: #ffe7e1;
			display: none;
			list-style: none;
			margin: 0;
			padding-left: 0;
		}
		& > li {
			border-bottom: 1px solid #fddbd2;
			margin-left: 0;
			margin-right: 0;
			& > a {
				line-height: 1.5;
				padding: 15px 20px !important;
				color: #263238 !important;
				font-size: 14px;
				font-weight: 500;
				background: transparent !important;
				border-radius: 0 !important;
				& > i {
					float: right;
					margin-top: 5px;
				}
			}
			.submenu {
				li {
					a {
						border-top: 0;
						color: #263238;
						padding: 10px 15px 10px 35px;
						font-size: 14px;
					}
				}
				ul {
					li {
						a {
							padding: 10px 15px 10px 45px;
						}
					}
				}
				& > li {
					&.has-submenu {
						& > a {
							&::after {
								content: "\f078";
							}
						}
					}
				}
			}
			&.active {
				& > a {
					&:before {
						display: none;
					}
				}
			}
		}
		li {
			& + li {
				margin-left: 0;
			}
			a {
				&:hover {
					background: transparent;
					border-radius: 0;
					line-height: 1.5;
					margin: 0;
				}
			}
		}
		.has-submenu {
			&.active {
				.submenu {
					li {
						&.active {
							& > a {
								color: #86E53E;
							}
						}
					}
				}
			}
		}
		.log-select {
			display: none;
		}
	}
	.main-nav .has-submenu.active > a, .main-nav > li.active > a {
		color: #86E53E !important;
	}
	.login-left {
		display: none;
	}
	.searchbar {
		display: none;
	}
	.main-menu-wrapper {
		order: 3;
		width: 260px;
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		overflow-x: hidden;
		overflow-y: auto;
		z-index: 1060;
		transform: translateX(-260px);
		transition: all 0.4s;
		-moz-transition: all 0.4s;
		-webkit-transition: all 0.4s;
		-o-transition: all 0.4s;
		background-color: #fff;
	}
	.menu-header {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.navbar-header {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
	}
	#mobile_btn {
		display: inline-block;
		line-height: 0;
	}
	.services, .projects, .subscribe, .developer, .review, .news {
		padding: 60px 0;
	}
	.section-text {
		margin-bottom: 40px;
	}
	.footer-top {
		padding: 60px 0 0 0;
	}
	.footer-widget {
		margin-bottom: 25px;
	}
	.transaction-table {
		.head-title {
			font-size: 24px;
		}
	}
	.change-btn, .plan-btn, .pay-btn {
		font-size: 16px;
	}
	.table-borderless {
		&.table {
			tbody {
				tr {
					td {
						font-size: 18px;
					}
				}
			}
		}
	}
	.stone-btn {
		font-size: 15px;
		padding: 15px 25px;
	}
	.position-title {
		h3 {
			margin-bottom: 10px !important;
		}
		.sort-by {
			float: left;
			padding: 0 15px 0 0;
		}
	}
	.stats-content {
		display: block;
	}
	.stats-detail {
		width: 100%;
		margin-bottom: 10px;
	}
	.chat-cont-left, .chat-cont-right {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
		transition: left 0.3s ease-in-out 0s, right 0.3s ease-in-out 0s;
		width: 100%;
	}
	.chat-cont-left {
		border-right: 0;
	}
	.chat-cont-right {
		position: absolute;
		right: -100%;
		top: 0;
		opacity: 0;
		visibility: hidden;
		.chat-header {
			justify-content: start;
			-webkit-justify-content: start;
			-ms-flex-pack: start;
			.back-user-list {
				display: block;
			}
			.chat-options {
				margin-left: auto;
			}
		}
	}
	.chat-window {
		&.chat-slide {
			.chat-cont-left {
				left: -106%;
			}
			.chat-cont-right {
				right: 0;
				opacity: 1;
				visibility: visible;
			}
		}
	}
	.chat-footer {
		.avatar {
			display: none;
		}
	}
	.plan-details {
		h4 {
			font-size: 24px;
		}
	}
	.pro-head {
		h2 {
			font-size: 18px;
		}
	}
	.earn-info {
		p {
			font-size: 15px;
		}
	}
	.breadcrumb-bar {
		.breadcrumb-title {
			font-size: 28px;
		}
	}
	.projects-details {
		display: block;
	}
	.project-info {
		width: 100%;
	}
	.content-divider {
		display: none;
	}
	.content-divider-1 {
		display: none;
	}
	.projects-amount {
		width: 50%;
		text-align: left;
	}
	.projects-action {
		width: 50%;
		padding-left: 0;
		text-align: right !important;
	}
	.project-hire-info {
		display: flex;
		align-items: center;
		margin-top: 20px;
	}
	.bread-crumb-bar {
		padding-top: 64px;
	}
	.projects-btn {
		margin: 0 0 10px auto;
		margin: 0 0 10px auto;
		width: 120px;
		&.project {
			margin: 0 0 10px auto;
		}
	}
	.prj-proposal-count {
		top: 0;
		-ms-transform: translateY(0);
		transform: translateY(0);
		&.hired {
			top: 0%;
			-ms-transform: translateY(0%);
			transform: translateY(0%);
		}
	}
	.projects-card {
		margin-bottom: 15px;
	}
	.projects-list {
		h2 {
			font-size: 24px;
		}
	}
	.banner-content .sub-btn, .banner-content .form-control, .banner-content .select2-container .select2-selection--single .select2-selection__rendered {
		font-size: 15px;
	}
	.bookmark {
		margin-bottom: 0;
	}
	.chat-page {
		.content {
			padding-top: 80px;
		}
	}
	.dashboard-page {
		.content {
			padding-top: 120px;
		}
		.content-page {
			padding-top: 60px;
		}
	}
	.dashboard-page .content > .container-fluid,
		.header-nav {
		padding-left: 15px;
		padding-right: 15px;
	}
	.header-title {
		font-size: 24px;
	}
	.table-responsive {
		white-space: nowrap;
	}
	.project-company {
		.projects-action {
			.projects-btn {
				margin: 0 0 10px auto;
				margin: 0 0 10px auto;
			}
		}
	}
	.img-profile {
		img {
			width: 100px;
			height: 100px;
		}
	}
	.developer-box {
		h2 {
			font-size: 30px;
			margin-bottom: 30px;
			i {
				font-size: 28px;
			}
		}
	}
	.proposal-value {
		h4 {
			font-size: 28px;
		}
	}
	.favourites-btn.btn-primary,
		.proposal-btn.btn-primary {
		font-size: 16px;
		min-height: 50px;
		min-width: 160px;
	}
	.feed-back-detail {
		li {
			font-size: 13px;
		}
	}
	.experiance-list {
		margin-bottom: 25px;
	}
	.technical-skill {
		.badge-skills {
			margin-bottom: 10px;
		}
	}
	.board-screen {
		.progressbar {
			max-width: 100%;
			margin-left: 0px;
		}
	}
	.progressbar li h5,
		.progress-info h5 {
		font-size: 14px;
	}
	.progress-info {
		h5 {
			margin-bottom: 0;
		}
	}
	.progressbar {
		justify-content: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
	.multi-step {
		width: 30px;
		height: 30px;
		margin-right: 10px;
		font-size: 12px;
	}
	.select-account {
		padding: 60px 0px;
		.prev_btn {
			min-width: 135px;
			padding: 8px 15px;
			font-size: 16px;
			margin-right: 10px;
		}
		.btn-primary {
			min-width: 135px;
			padding: 7px 15px;
			font-size: 16px;
		}
		.personal-info {
			.form-control {
				font-size: 13px;
			}
		}
	}
	.select-box {
		margin-bottom: 80px;
	}
	.multistep-form {
		label {
			font-size: 15px !important;
			font-size: 14px !important;
		}
	}
	.btn-prev {
		margin-right: 0;
	}
	.new-addd .add-new,
		.new-addd .remove_row {
		font-size: 12px;
	}
	.personal-info {
		input {
			&::placeholder {
				font-size: 13px;
			}
		}
	}
	.wallet-group {
		margin-bottom: 15px;
	}
	.onboard-head {
		padding: 0px 15px;
		p {
			font-size: 15px;
		}
	}
	.select-level {
		background-image: url(../../assets/img/icon/icon-19.png);
		background-position: 95%;
	}
	.wallet-rupee {
		.form-control {
			border-color: #ffe6e1;
			height: 50px;
		}
		.dol-btn {
			width: 50px;
			height: 50px;
		}
	}
	.work {
		&:after {
			display: none;
		}
	}
	.bg1 {
		&:before {
			width: 20px;
			top: 20px;
			left: 20px;
			height: 20px;
		}
	}
	.want-works {
		.work-content {
			width: 100%;
			padding: 40px;
		}
	}
	.review-img {
		flex-shrink: 0;
		margin-right: 0px;
	}
	.owl-carousel {
		.owl-item {
			.review-blog {
				.review-img {
					img {
						width: 60px;
					}
				}
			}
			.project-img {
				img {
					width: 100%;
				}
			}
			.hour-dollr {
				img {
					width: inherit;
				}
			}
		}
	}
	.register-job-blk {
		display: block;
	}
	.job-content-blk {
		margin-bottom: 15px;
		h1 {
			font-size: 28px;
		}
	}
	.home-two {
		min-height: 490px;
		.banner-content {
			h1 {
				margin-bottom: 25px;
			}
		}
	}
	.home-count {
		margin-top: 35px;
		ul {
			li {
				font-size: 20px;
				& + li {
					margin-left: 10px;
					padding-left: 10px;
				}
				span {
					font-size: 15px;
				}
				.list-count {
					font-size: 15px;
				}
			}
		}
	}
	.skill-head {
		.nav-pills {
			.nav-link {
				font-size: 20px;
			}
		}
	}
	.btn-cart {
		font-size: 14px;
	}
	.app-version-blk {
		h2 {
			font-size: 22px;
		}
		p {
			font-size: 15px;
		}
	}
	.entry-meta {
		li {
			margin-right: 0;
			font-size: 13px;
		}
	}
	.grid-blog {
		.blog-title {
			font-size: 18px;
		}
	}
	.great-about {
		padding: 80px 0px 338px;
	}
	.header-two {
		.main-nav {
			& > li {
				&:last-child {
					display: none;
				}
			}
		}
	}
	.owl-carousel.trend-slider .owl-nav,
		.owl-carousel.trend-slider .owl-dots {
		min-width: 110px;
	}
	.dot-slider .slick-list,
		.dot-slider.slick-vertical .slick-slide {
		height: 334px !important;
	}
	.home-four {
		margin-top: 60px;
		.banner-content {
			h5 {
				font-size: 16px;
			}
			h1 {
				font-size: 30px;
			}
		}
	}
	.market-place-group {
		h2 {
			font-size: 24px;
		}
	}
	.view-locations {
		.btn-primary {
			min-width: 175px;
		}
	}
	.popular-slider {
		&.developers-slider {
			.owl-nav {
				right: 25%;
			}
		}
	}
	.platform-location {
		.market-place-group {
			padding: 30px 0px;
		}
	}
	.market-place-btn {
		&.platform-btn {
			.btn-primary {
				min-width: 165px;
			}
		}
	}
	.next-great-job {
		.job-next-blk {
			h2 {
				color: #2E2E2E;
				font-size: 24px;
				margin-bottom: 20px;
			}
		}
	}
	.job-next-blk {
		p {
			font-size: 15px;
		}
	}
	.banner-blk-five {
		.banner-content {
			.form-control {
				height: 54px;
			}
		}
	}
	.header-navbar-rht {
		.login-btn {
			padding: 6px 25px;
			font-size: 14px;
		}
	}
	.header-five {
		.header-navbar-rht {
			li {
				.reg-btn {
					padding: 6px 15px;
					min-width: 160px;
				}
			}
		}
	}
	.section-header-five {
		&:before {
			left: 48%;
		}
	}
	.need-job-group {
		flex-wrap: wrap;
		.about-it-item {
			width: 50%;
			border: 0;
		}
	}
	.recent-pro-img {
		margin-bottom: 20px;
		img {
			width: 100%;
		}
	}
	.talent-foot-list {
		ul {
			justify-content: space-evenly;
			-webkit-justify-content: space-evenly;
			-ms-flex-pack: space-evenly;
		}
	}
	.img-reviews {
		img {
			max-width: 130px;
		}
	}
	.review-quote {
		img {
			max-width: 40px;
		}
	}
	.review-five .slick-prev,
		.review-five .slick-next {
		width: 35px;
		height: 35px;
	}
	.testimonial-two-head {
		span {
			margin-bottom: 10px;
		}
	}
	.review-five .slick-prev:before,
		.review-five .slick-next:before {
		font-size: 14px;
	}
	.hire-company-list {
		ul {
			li {
				width: 150px;
				min-height: 70px;
				img {
					max-width: 80px;
				}
			}
		}
	}

}
@media only screen and (max-width:767.98px){
	body {
		font-size: 14px;
	}
	h1, .h1 {
		font-size: 28px;
	}
	h2, .h2 {
		font-size: 26px;
	}
	h3, .h3 {
		font-size: 20px;
	}
	h4, .h4 {
		font-size: 18px;
	}
	h5, .h5 {
		font-size: 15px;
	}
	h6, .h6 {
		font-size: 16px;
	}
	.banner-content {
		padding: 80px 0;
		h1 {
			font-size: 23px;
			line-height: 29px;
		}
		p {
			font-size: 18px;
			line-height: 20px;
		}
		&.discount-box {
			padding: 0;
		}
	}
	.banner-img {
		display: none;
	}
	.courses-btn, .get-btn {
		font-size: 14px;
		padding: 12px 24px;
	}
	.work-content {
		width: 90%;
		.long-arrow {
			width: 50px;
			height: 50px;
			line-height: 50px;
			font-size: 20px;
		}
		h2 {
			font-size: 25px;
		}
		p {
			font-size: 22px;
		}
	}
	.bg1, .bg2 {
		background-size: 150px;
	}
	.services {
		padding: 60px 0;
	}
	.projects {
		padding: 60px 0;
	}
	.all-btn {
		padding: 12px 26px;
		font-size: 14px;
	}
	.news-blog {
		margin-bottom: 20px;
	}
	.client-img {
		margin-bottom: 20px;
	}
	.social-icon {
		ul {
			li {
				a {
					font-size: 12px;
					margin-right: 1px;
					line-height: 18px;
				}
			}
		}
	}
	.about-content {
		h2 {
			font-size: 24px;
		}
	}
	.about {
		padding: 40px 0;
		.about-img {
			text-align: center;
		}
	}
	.agency-content {
		width: 100%;
		padding: 33px;
	}
	.job-counter {
		.counter {
			margin-bottom: 20px;
		}
	}
	.counter {
		h2 {
			font-size: 26px;
		}
		h4 {
			font-size: 16px;
		}
	}
	.expert {
		padding: 40px 0;
	}
	.profile-list {
		.proj-title {
			font-size: 14px;
		}
		li {
			width: calc(100%/3);
			font-size: 15px;
			padding: 15px 0px;
		}
	}
	.provider-widget {
		-ms-flex-direction: column;
		flex-direction: column;
		text-align: center;
	}
	.pro-info-right {
		margin-left: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.pro-info-left {
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.profile-box {
		.pro-info-right {
			margin-left: 0;
			-ms-flex: 0 0 100%;
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
	.camera-bg {
		bottom: 0;
	}
	.bottom-text {
		position: relative;
	}
	.profile-title {
		font-size: 18px;
	}
	.profile-preword, .profile-right {
		text-align: center;
		display: block;
	}
	.profile-inf {
		text-align: center;
	}
	.provider-img {
		margin: 0 auto 20px;
	}
	.provider-action {
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
	}
	.submit-section {
		.submit-btn {
			padding: 12px 22px;
			font-size: 13px;
		}
	}
	.management-widget {
		.profile-btn {
			padding: 12px 0;
			font-size: 14px;
		}
	}
	.pro-title, .billing .card-header p, .payment-list label, .transaction h5 {
		font-size: 16px;
	}
	.bookmark-btn, .share-icon, .click-btn {
		font-size: 17px;
	}
	.payment-detail {
		padding: 20px;
		h2 {
			font-size: 25px;
		}
		p {
			font-size: 15px;
		}
	}
	#accordion {
		.card-header {
			font-size: 16px;
		}
	}
	.footer-top {
		padding: 50px 0 40px 0;
	}
	.footer-user {
		top: 0;
	}
	.job-category {
		p {
			font-size: 15px;
		}
	}
	.login-header {
		h3 {
			font-size: 21px;
		}
	}
	.login-btn {
		font-size: 17px;
	}
	.settings-widget {
		margin-bottom: 25px;
	}
	.earn-info {
		margin-bottom: 15px;
	}
	.column-5 {
		width: calc(100%/2);
	}
	.news-content {
		.entry-meta {
			li {
				&.name {
					img {
						width: 30px;
						height: 30px;
					}
				}
			}
		}
	}
	.nav-tabs {
		border-bottom: 0;
		position: relative;
		background-color: #fff;
		padding: 5px 0;
		border: 1px solid #ddd;
		border-radius: 3px;
		&.nav-justified {
			border-bottom: 1px solid #ddd;
		}
		.nav-item {
			margin-bottom: 0;
		}
	}
	.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active, .user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:hover, .user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:focus {
		border-left-width: 2px;
		color: #ff508b;
	}
	.nav-tabs.nav-tabs-bottom > li > a.active, .nav-tabs.nav-tabs-bottom > li > a.active:hover, .nav-tabs.nav-tabs-bottom > li > a.active:focus {
		background-color: #f5f5f5;
	}
	.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, .nav-tabs > li > a.active, .nav-tabs > li > a.active:hover, .nav-tabs > li > a.active:focus {
		border-color: transparent transparent transparent #ff508b !important;
		border-left-width: 2px;
	}
	.user-tabs .nav-tabs .nav-item, .provider-tabs .nav-tabs .nav-item {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
	}
	.copyright-text {
		padding-bottom: 5px;
	}
	.right-text, .center-text {
		text-align: left;
	}
	.left-content {
		h4 {
			font-size: 13px;
		}
	}
	.transaction-table {
		.head-title {
			font-size: 22px;
		}
		.sortby-fliter {
			width: 110px;
		}
	}
	.plan-top {
		i {
			font-size: 30px;
		}
	}
	.change-btn, .plan-btn {
		font-size: 18px;
	}
	.price-features {
		.header-price {
			h3 {
				font-size: 26px;
			}
		}
		li {
			font-size: 15px;
		}
	}
	.stone-btn {
		font-size: 14px;
		padding: 12px 14px;
	}
	.transaction-table .sort-title,  .sort-title {
		font-size: 14px;
	}
	.wallet, .payment-process, .wallet-detail {
		margin-bottom: 0;
	}
	.profit-title {
		padding: 15px 20px;
		h3 {
			font-size: 24px;
			margin-bottom: 15px;
		}
	}
	.stats-detail {
		width: 50%;
	}
	.stats-content {
		display: flex;
	}
	.table-borderless.table tbody tr td, .portfolio-detail .pro-name {
		font-size: 16px;
	}
	.finance-statement {
		margin-bottom: 25px;
		height: auto;
	}
	.profit-right {
		.sort-by {
			margin-top: 10px;
		}
	}
	.user-tabs {
		ul {
			li {
				& + li {
					margin-left: 0;
				}
			}
		}
	}
	.projects-list h2, .page-title h3, .earn-feature .price, .freelance-title h3 {
		font-size: 22px;
	}
	.prolist-details p, .prolist-details h5 {
		font-size: 15px;
	}
	.project-table {
		h3 {
			font-size: 20px;
		}
	}
	.yr-amt, .freelance-title p {
		font-size: 15px;
	}
	.plan-details h3, .member-detail h3 {
		font-size: 25px;
	}
	.package-feature {
		ul {
			li {
				font-size: 14px;
			}
		}
	}
	.package-detail .package-price, .member-detail h4 {
		font-size: 24px;
	}
	.port-title h3, .modal-title {
		font-size: 18px;
	}
	.modal {
		label {
			font-size: 15px;
		}
	}
	.header-navbar-rht {
		li {
			margin-right: 20px;
		}
	}
	.inner-banner {
		padding: 30px 0;
	}
	.transaction-shortby {
		.sort-show {
			padding: 5px;
		}
	}
	.projects-action {
		.projects-btn {
			& ~ .projects-btn {
				margin: 10px 0 0;
			}
		}
	}
	.header-title {
		font-size: 20px;
	}
	.subscribe {
		h3 {
			font-size: 28px;
		}
	}
	#mobile_btn {
		line-height: 0;
	}
	.discount-box {
		.btn-primary {
			margin: 0;
		}
	}
	.about-list {
		ul {
			display: block;
			li {
				padding: 0;
				border: 0;
				margin-bottom: 5px;
			}
		}
	}
	.profile-main {
		h2 {
			font-size: 20px;
		}
	}
	.abouts-view {
		ul {
			li {
				.active-about {
					&:before {
						display: none;
					}
				}
			}
		}
	}
	.profile-baner {
		padding: 40px 0px;
	}
	.provider-tabs {
		&.abouts-view {
			.nav-tabs {
				&.nav-tabs-solid {
					& > li {
						& > a {
							p {
								font-size: 15px;
							}
						}
					}
				}
			}
		}
	}
	.social-link-profile {
		flex-wrap: wrap;
		padding: 20px;
		padding-top: 10px;
		margin: 0;
		li {
			margin-bottom: 15px;
			a {
				i {
					width: 40px;
					height: 40px;
				}
			}
		}
	}
	.develop-social-link {
		&.widget-box {
			padding-bottom: 5px;
		}
	}
	.location-widget {
		.pro-title {
			margin-bottom: 25px;
		}
	}
	.abouts-detail {
		.pro-content {
			padding: 5px 0 0;
		}
	}
	.company-profile {
		.pro-content {
			padding: 10px 0 0;
		}
		.widget-box {
			.latest-posts {
				li {
					padding: 13px 20px 13px;
				}
			}
		}
	}
	.project-company {
		.projects-action {
			.projects-btn {
				margin-bottom: 10px;
				margin: 10px 0 10px;
			}
		}
	}
	.company-gallery {
		.pro-image {
			img {
				width: 100%;
			}
		}
	}
	.company-post {
		.pro-title {
			font-size: 18px;
			margin-bottom: 20px;
		}
		&.widget-box {
			padding: 20px;
		}
	}
	.tab-reviews {
		flex-wrap: wrap;
		.rating {
			margin-top: 15px;
		}
	}
	.post-btn {
		.more-btn {
			font-size: 20px;
			padding: 6px 15px;
			min-width: 160px;
		}
	}
	.profile-head {
		padding: 20px 20px 6px 20px;
	}
	.map-location {
		padding: 0px 20px;
	}
	.contact-btn {
		margin-top: 0px;
		padding: 20px;
		.btn-primary {
			padding: 8px 15px;
			font-size: 18px;
		}
	}
	.company-review {
		.review-content {
			p {
				font-size: 14px;
			}
		}
	}
	.img-profile {
		img {
			width: 60px;
			height: 60px;
			top: 16px;
			right: 25px;
			border: 2px solid #E0E0E0;
		}
	}
	.developer-box {
		h2 {
			font-size: 24px;
			margin-bottom: 20px;
			i {
				font-size: 22px;
				margin-left: 0px;
			}
		}
	}
	.develope-list-rate {
		flex-wrap: wrap;
		display: block;
		li {
			padding-left: 0;
			margin-bottom: 10px;
			border: 0;
		}
	}
	.list-book-mark {
		.verified {
			width: 12px;
			height: 12px;
			right: 0;
			i {
				font-size: 12px;
			}
		}
	}
	.project-rate {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.project-details-view {
		.pro-post-head {
			h6 {
				font-size: 16px;
			}
		}
	}
	.project-details-view .job-type,
		.requirement-img {
		margin-bottom: 15px;
	}
	.wallet-rupee {
		.modal-body {
			padding: 20px;
		}
		.dol-btn {
			width: 58px;
			height: 48px;
		}
		.form-control {
			height: 48px;
		}
		.badge-skills {
			padding: 8px 28px;
		}
		.custom_radio {
			.checkmark {
				height: 20px;
				width: 20px;
				&:after {
					top: 3px;
					left: 3px;
					width: 12px;
					height: 12px;
				}
			}
		}
	}
	.proposal-value {
		font-size: 14px;
		margin-bottom: 0px;
		h4 {
			font-size: 18px;
		}
		span {
			font-size: 12px;
		}
	}
	.feed-back-detail {
		flex-wrap: wrap;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		li {
			margin-bottom: 10px;
			font-size: 13px;
		}
	}
	.watch-list {
		li {
			font-size: 12px;
		}
	}
	.favourites-btn {
		&.btn-primary {
			min-width: 80px;
			margin: 0px 10px;
		}
	}
	.proposal-box {
		flex-wrap: wrap;
	}
	.proposal-btn {
		&.btn-primary {
			min-width: 120px;
		}
	}
	.favourites-btn.btn-primary,
		.proposal-btn.btn-primary {
		min-height: 38px;
		font-size: 12px;
	}
	.technical-skill {
		.badge-skills {
			margin-bottom: 10px;
		}
	}
	.board-screen {
		.progressbar {
			max-width: 100%;
			margin: 10px 0px;
		}
		.multistep-progress {
			height: auto;
			text-align: center;
		}
	}
	.multi-step {
		width: 30px;
		height: 30px;
	}
	.progressbar {
		justify-content: space-evenly;
		li {
			margin-bottom: 10px;
			h5 {
				font-size: 14px;
			}
		}
	}
	.board-logo {
		margin: auto;
	}
	.progress-info {
		width: 100%;
	}
	.employee-level {
		width: 140px;
		height: 140px;
		img {
			margin-bottom: 6px;
		}
	}
	.onboard-head {
		padding: 0px 15px;
		a {
			width: 140px;
			height: 140px;
			padding: 15px;
		}
		h2 {
			font-size: 22px;
		}
		p {
			font-size: 14px;
		}
	}
	.free-lance-img {
		margin-right: 15px;
	}
	.select-account {
		padding: 40px 0px;
		.btn-primary {
			min-width: 110px;
			padding: 7px 15px;
			font-size: 15px;
		}
		.prev_btn {
			min-width: 110px;
			padding: 8px 15px;
			font-size: 15px;
			margin-right: 10px;
		}
		&.select-btn {
			padding: 40px 0px 80px;
		}
		.personal-info {
			.form-control {
				font-size: 12px;
			}
		}
	}
	.other-info-list {
		ul {
			li {
				width: 30px;
				height: 30px;
				background: #86E53E;
				border-radius: 50%;
				font-size: 10px;
				margin-right: 8px;
			}
		}
	}
	.first-progress, .second-progress {
		display: block;
		height: 100%;
	}
	.select-box {
		margin-bottom: 60px;
	}
	.multistep-form {
		label {
			font-size: 14px !important;
		}
		.form-control {
			min-height: 40px;
			height: 40px;
		}
	}
	.new-addd {
		margin-bottom: 10px;
	}
	.personal-info {
		margin-bottom: 40px;
		padding: 15px;
		input {
			&::placeholder {
				font-size: 12px;
			}
		}
	}
	.back-home {
		.btn-primary {
			background: #86E53E;
			border-radius: 5px;
			color: #fff;
			font-size: 16px;
			min-width: 160px;
		}
		p {
			font-size: 14px;
		}
		img {
			max-width: 230px;
		}
	}
	.new-addd .add-new,
		.select-level {
		font-size: 12px;
	}
	.bread-crumb-bar {
		padding-top: 60px;
	}
	.bread-crumb-bar .breadcrumb-list .page-breadcrumb ol li,
		.bread-crumb-bar .breadcrumb-list .page-breadcrumb .breadcrumb-item + .breadcrumb-item:before {
		font-size: 12px;
	}
	.develop-list-select {
		.list-grid {
			margin-left: 0;
		}
	}
	.freelance-view {
		margin-bottom: 15px;
	}
	.list-develop-group .about-author,
		.list-develop-group .about-author .about-list-author {
		display: block!important;
	}
	.about-list-author {
		.about-author-img {
			position: relative;
		}
		.author-details {
			margin-left: 0;
		}
	}
	.book-mark {
		.about-list-author {
			.about-author-img {
				margin-bottom: 10px;
			}
		}
	}
	.develop-list-pro {
		text-align: left;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		margin-top: 10px;
		.btn-cart {
			min-width: auto;
		}
		.freelancers-price {
			margin: 0px 10px;
			font-size: 18px;
		}
	}
	.wallet-group {
		margin-bottom: 15px;
	}
	.total-depit {
		margin-bottom: 0;
	}
	.balance-total {
		h2 {
			font-size: 18px;
		}
	}
	.account-onborad {
		&.complte-board {
			padding: 10px;
		}
	}
	.select-level {
		background-image: url(../../assets/img/icon/icon-19.png);
		background-position: 95%;
	}
	.bg1 {
		&:before {
			content: "";
			position: absolute;
			width: 20px;
			top: 15px;
			left: 9px;
			height: 20px;
			border-radius: 50%;
			background-color: #fff;
		}
	}
	.want-works {
		.work-content {
			width: 100%;
			padding: 40px;
		}
	}
	.developers-slider {
		.owl-nav {
			margin-bottom: 10px;
		}
	}
	.register-job-blk {
		display: block;
	}
	.job-content-blk {
		margin-bottom: 15px;
		h1 {
			font-size: 20px;
		}
		p {
			font-size: 15px;
		}
	}
	.footer {
		.input-group {
			flex-wrap: inherit;
		}
		.banner-content {
			padding: 0;
			.sub-btn {
				flex: inherit;
				width: auto;
				line-height: 18px;
				padding: 6px 30px;
				height: 28px;
				line-height: 0;
			}
			.form-control {
				height: 35px;
				min-height: 35px;
			}
		}
		&.footer-four {
			.banner-content {
				.sub-btn {
					min-width: 135px;
					font-size: 12px;
					padding: 5px 15px;
				}
			}
			.footer-menu {
				ul {
					li {
						a {
							font-size: 14px;
						}
					}
				}
			}
		}
	}
	.footer-bottom-nav {
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
	}
	.copyright-text,
		.footer-bottom-logo {
		text-align: center;
	}
	.app-version-blk {
		h2 {
			font-size: 20px;
		}
		p {
			font-size: 14px;
			margin-bottom: 20px;
		}
	}
	.testimonial-section {
		.owl-nav {
			margin-bottom: 10px;
		}
	}
	.home-count {
		ul {
			li {
				margin-right: 10px;
				font-size: 20px;
				& + li {
					margin-left: 0;
					padding-left: 0px;
					border: 0;
				}
				span {
					font-size: 15px;
				}
			}
		}
	}
	.platform {
		&:before {
			display: none;
		}
	}
	.feature-project-list {
		li {
			font-weight: 500;
			font-size: 14px;
		}
	}
	.blog-slider.owl-carousel .owl-nav button.owl-prev,
		.blog-slider.owl-carousel .owl-nav button.owl-next {
		width: 30px;
		height: 30px;
		font-size: 11px;
		margin: 0;
	}
	.blog-slider {
		&.owl-carousel {
			.owl-nav {
				button {
					&.owl-prev {
						left: -10px;
					}
					&.owl-next {
						top: 40%;
						right: -10px;
					}
				}
			}
		}
	}
	.about-position {
		position: relative;
		bottom: 0;
		padding: 80px 0px 55px;
	}
	.great-about {
		padding: 80px 0px 55px;
	}
	.about-it-blk {
		margin-bottom: 20px;
	}
	.home-three {
		background-image: url(../img/slider/slider-01.jpg);
		.banner-kofe {
			position: relative;
			transform: unset;
			top: 0;
		}
		.banner-content {
			padding: 80px 0;
			h1 {
				font-size: 26px;
			}
		}
	}
	.home-three .slick-prev, .home-three .slick-next,
		.dot-slider .slick-dots,
		.dot-slider.slick-dotted.slick-slider {
		display: none !Important;
	}
	.section-header {
		p {
			font-size: 14px;
		}
	}
	.section-locate {
		flex-wrap: wrap;
		p {
			margin-bottom: 10px;
		}
	}
	.different-categories {
		padding: 60px 0px 35px;
	}
	.best-marketplace,
		.trend-projects,
		.review-people,
		.top-skill {
		padding: 60px 0px;
	}
	.market-list-out {
		li {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
		}
	}
	.market-place-btn .btn-primary,
		.market-place-btn .market-developer {
		min-width: 130px;
		font-size: 14px;
	}
	.feature-count-head {
		.header-title {
			text-align: center;
			font-size: 20px;
		}
	}
	.owl-carousel {
		&.trend-slider {
			.owl-nav {
				top: -36px;
				min-width: 160px;
			}
			.owl-dots {
				top: -33px;
				min-width: 160px;
			}
		}
	}
	.review-love-list {
		display: block;
	}
	.review-profile-blk {
		margin-bottom: 10px;
	}
	.next-job-btn {
		.btn-primary {
			font-size: 14px;
			min-width: 140px;
		}
	}
	.footer-social-group .footer-bottom-nav,
		.footer-social-group .social-icon {
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
	}
	.market-place-group {
		margin-bottom: 20px;
		padding-bottom: 0px;
		h2 {
			font-size: 22px;
			margin-bottom: 15px;
		}
		p {
			font-size: 14px;
		}
	}
	.job-next-blk {
		h2 {
			font-size: 24px;
		}
	}
	.fullstack-blk,
		.coplete-job {
		display: none;
	}
	.home-four {
		.banner-content {
			h1 {
				font-size: 26px;
			}
		}
	}
	.popular-slider {
		&.developers-slider {
			.owl-nav {
				right: 0;
				margin-bottom: 34px;
			}
		}
	}
	.popular-slider.developers-slider.owl-carousel .owl-nav button.owl-next,
		.popular-slider.developers-slider.owl-carousel .owl-nav button.owl-prev {
		width: 35px;
		height: 35px;
	}
	.popular-list-box {
		flex-wrap: wrap;
		ul {
			margin-bottom: 10px;
		}
	}
	.popular-foot,
		.popular-list-box h4 {
		font-size: 14px;
	}
	.head-popular {
		h4 {
			font-size: 16px;
		}
	}
	.market-place-btn {
		&.platform-btn {
			.btn-primary {
				min-width: 140px;
				margin-bottom: 15px;
			}
		}
		&.bidding-btn {
			.btn-primary {
				min-width: 126px;
			}
		}
		.btn-primary {
			padding: 8px 0px;
			font-size: 14px;
		}
		&.company-register-btn {
			.market-project {
				min-width: 130px;
			}
			.project-post {
				min-width: 155px;
			}
		}
	}
	.article-blog-content h4,
		.article-blog-content p {
		font-size: 15px;
	}
	.article-blog-content {
		span {
			font-size: 14px;
		}
	}
	.footer-four {
		.free-customer {
			h6 {
				font-size: 15px;
			}
		}
	}
	.register-profesion {
		position: relative;
		left: 0;
	}
	.markrt-place-img {
		margin-bottom: 20px;
	}
	.trust-slider.developers-slider.owl-carousel .owl-nav button.owl-next,
		.trust-slider.developers-slider.owl-carousel .owl-nav button.owl-prev {
		width: 38px;
		height: 38px;
	}
	.trust-slider {
		&.developers-slider {
			.owl-nav {
				bottom: 58%;
				right: 0;
			}
		}
	}
	.need-job-group {
		.about-it-item {
			width: 100%;
		}
	}
	.demand-professional {
		h2 {
			font-size: 20px;
		}
	}
	.join-market-world {
		padding: 0px 15px 20px;
		width: 100%;
		h2 {
			font-size: 20px;
		}
	}
	.hire-company-list {
		ul {
			li {
				width: 145px;
			}
		}
	}
	.img-reviews {
		max-width: 170px;
		margin: auto;
	}
	.testimonial-two-head {
		margin-top: 20px;
		h3 {
			font-size: 18px;
		}
	}
	.review-five {
		.slider-nav {
			width: 200px;
			margin: auto;
		}
		.testimonial-thumb {
			img {
				width: 40px;
			}
		}
		.slick-prev {
			left: 0;
		}
		.slick-next {
			right: 75%;
		}
	}
	.blog-trick-five {
		&.blog-slider {
			&.owl-carousel {
				.owl-nav {
					button {
						&.owl-prev {
							left: 50%;
						}
					}
				}
			}
		}
	}
	.great-find-job {
		&.job-next-blk {
			h2 {
				font-size: 20px;
			}
		}
	}
	.opportunity-img {
		display: none;
	}

}
.settings-widget{
	position: sticky;
	top: 100px;
}


@media only screen and (max-width:575.98px){
	h1, .h1 {
		font-size: 26px;
	}
	h2, .h2 {
		font-size: 22px;
	}
	h3, .h3 {
		font-size: 18px;
	}
	h4, .h4 {
		font-size: 16px;
	}
	h5, .h5 {
		font-size: 15px;
	}
	h6, .h6 {
		font-size: 12px;
	}
	.section-header {
		margin-bottom: 35px;
		p {
			font-size: 14px;
			line-height: 24px;
		}
		h5 {
			font-size: 18px;
		}
	}
	.developer .section-header, .review  .section-header {
		margin-bottom: 55px;
	}
	.banner-content {
		padding: 50px 0;
		h1 {
			font-size: 26px;
			margin: 10px 0;
		}
		p {
			font-size: 16px;
			line-height: 20px;
			margin-bottom: 20px;
		}
		.select2-container {
			border: 0;
			.select2-selection--single {
				.select2-selection__rendered {
					font-size: 15px;
				}
			}
		}
		.form-inner {
			border-radius: 30px;
			padding: 8px;
		}
		.form-control {
			width: 100%;
			font-size: 15px;
		}
		.sub-btn {
			-ms-flex: 0 0 100%;
			flex: 0 0 100%;
			height: 45px;
			font-size: 14px;
			width: 100%;
		}
	}
	.banner-content .rating .checked, .banner-content h5 {
		font-size: 12px;
	}
	.feature {
		.feature-content {
			h3 {
				font-size: 22px;
			}
		}
	}
	.feature-item {
		margin-bottom: 25px;
	}
	.work-content {
		.long-arrow {
			width: 40px;
			height: 40px;
			line-height: 40px;
			font-size: 19px;
		}
		h2 {
			font-size: 22px;
		}
		p {
			font-size: 16px;
		}
	}
	.services, .projects, .subscribe, .developer, .review, .news {
		padding: 40px 0;
	}
	.all-btn {
		padding: 10px 22px;
		font-size: 12px;
	}
	.services-item {
		.content-part {
			h3 {
				font-size: 16px;
			}
			h4 {
				font-size: 15px;
			}
		}
		.img-part {
			img {
				height: auto;
			}
		}
	}
	.courses-btn, .get-btn {
		font-size: 13px;
		padding: 10px 20px;
	}
	.btn-view {
		font-size: 16px;
	}
	.social-icon {
		ul {
			li {
				a {
					font-size: 12px;
				}
			}
		}
	}
	.breadcrumb-bar {
		.breadcrumb-title {
			font-size: 25px;
		}
	}
	.terms-text {
		h4 {
			font-size: 20px;
		}
	}
	.review-blog {
		padding: 25px;
		.review-img {
			img {
				width: 80px;
				height: 80px;
				margin-right: 9px;
			}
		}
		.review-content {
			p {
				font-size: 15px;
			}
		}
	}
	.review-content {
		h4 {
			font-size: 18px;
		}
	}
	.header-nav {
		padding-left: 15px;
		padding-right: 15px;
	}
	.header-navbar-rht {
		display: none;
	}
	.main-nav {
		li {
			&.login-link {
				display: block;
			}
		}
	}
	.navbar-header {
		width: 100%;
	}
	.navbar-brand {
		&.logo {
			width: 100%;
			text-align: center;
			margin-right: 0;
		}
	}
	#mobile_btn {
		margin-right: 0;
	}
	.profile-detail {
		.clg {
			.icon {
				margin-right: 7px;
			}
		}
	}
	.review-bottom {
		.review-img {
			img {
				width: 50px;
				height: 50px;
				margin-right: 5px;
			}
		}
		.review-info {
			h3 {
				font-size: 16px;
			}
		}
	}
	.review-icon {
		img {
			max-width: 42px;
		}
	}
	.review-info {
		h3 {
			font-size: 18px;
		}
		h5 {
			font-size: 14px;
			margin-bottom: 5px;
		}
	}
	.news {
		.d-flex {
			display: block !important;
		}
	}
	.news-content {
		margin-right: 0;
		h2 {
			font-size: 16px;
		}
		p {
			font-size: 15px;
		}
	}
	.client-img {
		margin-bottom: 30px;
	}
	.expert {
		h2 {
			font-size: 22px;
		}
	}
	.blog-view {
		.blog-title {
			font-size: 18px;
		}
	}
	.blog-comments {
		.comments-list {
			li {
				padding: 10px 0 0 50px;
				img {
					&.avatar {
						border-radius: 40px;
						height: 40px;
						width: 40px;
					}
				}
			}
		}
	}
	.profile-list {
		li {
			width: calc(100%/2);
		}
		.proj-title {
			font-size: 12px;
		}
	}
	.pro-title, .billing .card-header p, .payment-list label, .transaction h5 {
		font-size: 15px;
	}
	.about-widget ul li p, .about-widget ul li h6, .latest-posts li a {
		font-size: 14px;
	}
	.left-content p, .pro-content .left-content p, .dev-right h6 {
		font-size: 10px;
	}
	.management-widget {
		.profile-btn {
			padding: 10px 0;
			font-size: 12px;
		}
	}
	.follow-posts {
		width: 235px;
		li {
			border-right: 0;
		}
	}
	.client-list {
		padding: 10px 7px;
		h6 {
			font-size: 20px;
		}
	}
	.pro-content {
		p {
			font-size: 14px;
		}
	}
	.attach-detail {
		p {
			font-size: 12px;
		}
	}
	.about-author-img {
		width: 50px;
		height: 50px;
	}
	.author-details {
		margin-left: 65px;
	}
	.btn-list {
		margin-bottom: 7px;
	}
	.left {
		.left-img {
			&::before {
				width: 15px;
				height: 15px;
				font-size: 9px;
			}
		}
	}
	.bookmark-btn, .share-icon, .click-btn {
		font-size: 15px;
	}
	.follow-btn {
		font-size: 15px;
	}
	.login-header {
		h3 {
			font-size: 19px;
		}
	}
	.login-btn {
		font-size: 16px;
	}
	.title {
		h3 {
			font-size: 17px;
		}
	}
	.next-btn, .cancel-btn {
		font-size: 13px;
		padding: 11px 50px;
	}
	.left-content {
		h4 {
			font-size: 12px;
		}
	}
	.submit-section {
		.submit-btn {
			padding: 10px 20px;
			font-size: 12px;
		}
	}
	.pro-widget-profile {
		.dev-text {
			.left {
				&.left-bid {
					margin-top: -20px;
				}
			}
		}
	}
	.bid-btn {
		font-size: 12px;
	}
	.column-5 {
		width: 100%;
	}
	.footer-top {
		padding: 50px 0 7px 0;
		margin-top: 20px;
	}
	.position-title h3, .quick-title h3 {
		margin-bottom: 10px !important;
	}
	.align-right {
		text-align: left;
	}
	.profit-title h3, .stats-detail h3, .create-milestone h3, .pay-table .head-title {
		font-size: 22px;
	}
	.position-title .sort-by, .manage-tab .stone-btn {
		margin-bottom: 10px;
	}
	.manage-tab {
		.btn-group {
			float: left;
		}
	}
	.milestone-content {
		.profile-group {
			width: auto;
		}
	}
	.category-box {
		.category-img {
			padding: 7px;
		}
	}
	.chat-cont-left {
		.nav-link {
			font-size: 10px;
			padding: 8px;
		}
	}
	.plan-details a, .pro-info  .list-details .slot {
		margin-bottom: 15px;
	}
	.banner-content .input-group-prepend, .drop-detail, .banner-content .input-group-append {
		width: 100%;
	}
	.proposals-content {
		display: block;
	}
	.proposal-img {
		margin-bottom: 15px;
	}
	.proposal-client {
		margin-bottom: 15px;
		margin-right: 0;
	}
	.title-info {
		font-size: 14px;
	}
	.client-price {
		font-size: 22px;
		font-size: 18px;
	}
	.project-hire-info {
		display: block;
	}
	.proposer-info {
		&.project {
			width: 100%;
		}
	}
	.projects-action {
		text-align: left !important;
		&.project {
			width: 100%;
			text-align: left !important;
		}
	}
	.projects-btn {
		margin: 10px 0 0;
		&.project {
			margin: 10px 0;
		}
	}
	.amnt-type {
		padding-top: 2px;
		font-size: 13px;
	}
	.proposer-detail {
		li {
			padding-left: 0;
			border-right: 0;
		}
	}
	.project-info {
		h2 {
			font-size: 18px;
		}
	}
	.card-body {
		padding: 0.85rem;
	}
	.completed-badge {
		margin-top: 10px;
	}
	.proposal-delete {
		padding-top: 10px;
	}
	.page-title {
		h3 {
			font-size: 22px;
			margin-bottom: 20px;
		}
	}
	.card-top {
		.dev-text {
			padding: 10px;
		}
	}

}

@media only screen and (max-width:479px){
	.card-top {
		.dev-text {
			padding: 5px;
		}
	}
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name,
		.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
		max-width: 160px;
	}
	.section-features, .section-features, .section-specialities {
		background-color: #fff;
		padding: 30px 0;
	}
	.login-header {
		h3 {
			text-align: center;
			a {
				color: #0de0fe;
				float: none;
				font-size: 15px;
				margin-top: 10px;
				text-align: center;
				display: block;
			}
		}
	}
	.user-tabs {
		.nav-tabs {
			.nav-item {
				-ms-flex: 0 0 100%;
				flex: 0 0 100%;
			}
		}
	}
	.fc-header-toolbar {
		.fc-left {
			margin-bottom: 10px;
		}
		.fc-right {
			margin-bottom: 10px;
		}
	}
	.pro-widget-profile {
		.dev-text {
			.left {
				img {
					width: 40px;
				}
				.left-content {
					margin-left: 45px;
				}
			}
		}
	}
	.left-content {
		h4 {
			font-size: 11px;
		}
	}
	.proposal-widget {
		.custom_check {
			align-items: baseline;
		}
	}
	.prolist-details {
		li {
			&:first-child {
				margin-bottom: 10px;
			}
		}
	}
	.submit-section {
		.click-btn {
			margin-bottom: 5px;
		}
		.submit-btn {
			min-width: 112px;
			& + .submit-btn {
				margin-left: 8px;
			}
		}
	}
	.bid-btn {
		font-size: 10px;
	}
	.star {
		font-size: 10px;
		width: 25px;
		height: 25px;
		line-height: 25px;
	}
	.dev-right {
		p {
			font-size: 13px;
		}
	}
	.paginations {
		li {
			padding-right: 15px;
		}
	}
	.pro-head {
		display: block;
	}
	.testimonial-two {
		.owl-item {
			&.active {
				width: 296px !important;
			}
		}
	}

}